<br><br>
<div class="container">
    <!-- <app-report-drop-downs (valuesChanged)="filtersSelected($event)" [showFacilitys]="true"></app-report-drop-downs> -->
    <app-report-drop-downs [showFacilitys]="true"></app-report-drop-downs>
    <div class="row">
        <div class="col-12">
            <app-financial-report-table [exportData]="exportData" (dataRetrieved)="dataRetrieved($event)"
                [facility_name]="selectedFacility"></app-financial-report-table>
        </div>
        <div class="col-12">
            <div class="container background-white give-size">
                <div class="row float-right">
                    <de-button (click)="exportExcel()" type="secondary" [loading]="isButtonLoading">
                        <div>Export Data &nbsp; <i class="fas fa-caret-up"></i></div>
                    </de-button>
                </div>
            </div>
        </div>
    </div>
</div>