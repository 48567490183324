import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SegmentService } from 'src/app/services/segment.service';
import { APIServiceCodegen } from 'src/app/API.service';
import { CustomerAccountsModel } from 'src/app/components/models/admin.model'
import { Subscription } from 'rxjs'
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
    selector: 'app-customer-total-box',
    templateUrl: './customer-total-box.component.html',
    styleUrls: ['./customer-total-box.component.scss']
})

export class CustomerTotalBoxComponent {
    public customerAccounts: CustomerAccountsModel = new CustomerAccountsModel();
    public segmentId: string;
    public enrollment_count: number;
    public pending_count: number;
    public wait_list_count: number;
    private subscription: Subscription;
    
    constructor(
        public apiService: APIServiceCodegen,
        public segService: SegmentService
    ) {
        this.subscription = this.segService.currentSegment$.subscribe( res => {
            this.updateByParticipationSegment(res);
        })
     }

     updateByParticipationSegment(segmentId: string){
        this.apiService.GetProgramAccount( segmentId ).then(response => {
                this.customerAccounts =  (<CustomerAccountsModel> response); 
            console.log(this.customerAccounts)
        })
        .catch( err => {
            console.log(ErrorDialogComponent.API_GENERIC_ERROR_500_MSG)
            console.log(err)
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
     }
}