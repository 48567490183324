/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export type CustomerDetailCreateCriteriaInput = {
  account_number?: string | null;
  business_partner_id?: string | null;
  customer_segment_id?: string | null;
  allocation_size_kw?: number | null;
  max_subscription_size_kw?: number | null;
  res_non_res?: string | null;
  is_low_income?: boolean | null;
  created_by?: string | null;
  account_notes?: string | null;
  enrollment_channel_id?: number | null;
  zpart_year?: string | null;
  zpart_month?: string | null;
};

export type CustomerDetail = {
  __typename: "CustomerDetail";
  account_number?: string | null;
  jurisdiction_code?: string | null;
  account_notes?: string | null;
  customer_segment?: CustomerSegment | null;
  business_partner_id?: string | null;
  max_subscription_size_kw?: number | null;
  current_allocation_size_kw?: number | null;
  premise_address?: Address | null;
  billing_address?: Address | null;
  main_customer?: CustomerModel | null;
  allocations?: Array<CustomerDetailAllocation | null> | null;
  eligibility?: Array<IneligibilityReasonsList | null> | null;
  low_income?: LowIncomeInformation | null;
  lmi_file_validation?: Array<LmiFileValidation | null> | null;
  is_residential?: boolean | null;
  email_updates_interval?: string | null;
  wait_list_position?: number | null;
};

export type CustomerSegment = {
  __typename: "CustomerSegment";
  customer_segment_id?: string | null;
  customer_segment_description?: string | null;
};

export type Address = {
  __typename: "Address";
  city?: string | null;
  line1?: string | null;
  state?: string | null;
  zip?: string | null;
};

export type CustomerModel = {
  __typename: "CustomerModel";
  full_name?: string | null;
  email?: string | null;
  phone?: string | null;
};

export type CustomerDetailAllocation = {
  __typename: "CustomerDetailAllocation";
  allocation_id?: string | null;
  account_id?: string | null;
  allocation_size_kw?: number | null;
  max_sub_kw?: number | null;
  allocation_status_id?: number | null;
  allocation_status_description?: string | null;
  created_at?: string | null;
  pending_date?: string | null;
  active_date?: string | null;
  cancelled_date?: string | null;
  cancel_reason_id?: number | null;
  enrollment_channel_id?: number | null;
  customer_connect_enrollment_code?: string | null;
};

export type IneligibilityReasonsList = {
  __typename: "IneligibilityReasonsList";
  ineligibility_reasons?: Array<string | null> | null;
  is_eligible?: boolean | null;
  product_code?: string | null;
};

export type LowIncomeInformation = {
  __typename: "LowIncomeInformation";
  low_income_flag?: boolean | null;
  nes_participant?: boolean | null;
  low_income_verification_status?: boolean | null;
  low_income_verification_type_id?: string | null;
  low_income_verification_date?: string | null;
};

export type LmiFileValidation = {
  __typename: "LmiFileValidation";
  link?: string | null;
  file_name?: string | null;
  customer_status?: string | null;
  verification_date?: string | null;
};

export type CustomerDetailCancelCriteriaInput = {
  account_id?: string | null;
  business_partner_id?: string | null;
  allocation_id?: string | null;
  cancel_reason_id?: number | null;
  account_notes?: string | null;
  created_by?: string | null;
};

export type CustomerDetailEditCriteriaInput = {
  account_id?: string | null;
  business_partner_id?: string | null;
  allocation_id?: string | null;
  allocation_size_kw?: number | null;
  account_notes?: string | null;
  created_by?: string | null;
  new_allocation_status_id?: number | null;
};

export type PendingAccountsInput = {
  account_id?: string | null;
  business_partner_id?: string | null;
  allocation_id?: string | null;
};

export type StringMessage = {
  __typename: "StringMessage";
  message?: string | null;
};

export type UpdateAccountInput = {
  query?: string | null;
  customer_segment_id?: string | null;
  account_id?: string | null;
  business_partner_id?: string | null;
  allocation_id?: string | null;
};

export type ModelCustomerAlertConditionInput = {
  account_id?: ModelStringInput | null;
  alert_id?: ModelIntInput | null;
  and?: Array<ModelCustomerAlertConditionInput | null> | null;
  created_date?: ModelStringInput | null;
  full_name?: ModelStringInput | null;
  issue?: ModelStringInput | null;
  not?: ModelCustomerAlertConditionInput | null;
  notes?: ModelStringInput | null;
  or?: Array<ModelCustomerAlertConditionInput | null> | null;
};

export type ModelStringInput = {
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  contains?: string | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
  ne?: string | null;
  notContains?: string | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  _null = "_null",
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet"
}

export type ModelSizeInput = {
  between?: Array<number | null> | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
};

export type ModelIntInput = {
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  between?: Array<number | null> | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
};

export type UpdateCustomerAlertInput = {
  account_id?: string | null;
  alert_id?: number | null;
  created_date?: string | null;
  full_name?: string | null;
  id: string;
  issue?: string | null;
  notes?: string | null;
};

export type CustomerAlert = {
  __typename: "CustomerAlert";
  account_id?: string | null;
  alert_id?: number | null;
  created_date?: string | null;
  full_name?: string | null;
  id: string;
  issue?: string | null;
  notes?: string | null;
};

export type ModelFacilityAlertConditionInput = {
  alert_id?: ModelStringInput | null;
  and?: Array<ModelFacilityAlertConditionInput | null> | null;
  created_date?: ModelStringInput | null;
  facility_name?: ModelStringInput | null;
  issue?: ModelStringInput | null;
  not?: ModelFacilityAlertConditionInput | null;
  notes?: ModelStringInput | null;
  or?: Array<ModelFacilityAlertConditionInput | null> | null;
};

export type UpdateFacilityAlertInput = {
  alert_id?: string | null;
  created_date?: string | null;
  facility_name?: string | null;
  id: string;
  issue?: string | null;
  notes?: string | null;
};

export type FacilityAlert = {
  __typename: "FacilityAlert";
  alert_id?: string | null;
  created_date?: string | null;
  facility_name?: string | null;
  id: string;
  issue?: string | null;
  notes?: string | null;
};

export type ModelEnrollmentAlertConditionInput = {
  account_id?: ModelStringInput | null;
  and?: Array<ModelEnrollmentAlertConditionInput | null> | null;
  created_date?: ModelStringInput | null;
  enrollment_audit_id?: ModelIntInput | null;
  full_name?: ModelStringInput | null;
  not?: ModelEnrollmentAlertConditionInput | null;
  notes?: ModelStringInput | null;
  or?: Array<ModelEnrollmentAlertConditionInput | null> | null;
  status_change?: ModelStringInput | null;
};

export type UpdateEnrollmentAlertInput = {
  account_id?: string | null;
  created_date?: string | null;
  enrollment_audit_id?: number | null;
  full_name?: string | null;
  id: string;
  notes?: string | null;
  status_change?: string | null;
};

export type EnrollmentAlert = {
  __typename: "EnrollmentAlert";
  account_id?: string | null;
  created_date?: string | null;
  enrollment_audit_id?: number | null;
  full_name?: string | null;
  id: string;
  notes?: string | null;
  status_change?: string | null;
};

export type WaitListReorderInput = {
  account_id?: string | null;
  business_partner_id?: string | null;
  allocation_id?: string | null;
  customer_segment_id?: string | null;
  current_wait_list_position?: number | null;
  new_wait_list_position?: number | null;
};

export type WaitListReverifyInput = {
  account_id?: string | null;
  business_partner_id?: string | null;
  allocation_id?: string | null;
};

export type TransferListReorderInput = {
  installation_id?: string | null;
  operand_key?: string | null;
  customer_segment_id?: string | null;
  current_wait_list_position?: number | null;
  new_wait_list_position?: number | null;
};

export type EnrolledAllocationsReverifyInput = {
  account_id?: string | null;
  business_partner_id?: string | null;
  allocation_id?: string | null;
};

export type WaitListProcessInput = {
  customer_segment_id?: string | null;
  max_num_items?: number | null;
  created_by?: string | null;
  disable_eligibility_check?: string | null;
};

export type TransferListProcessInput = {
  customer_segment_id?: string | null;
  max_num_items?: number | null;
  created_by?: string | null;
};

export type CustomerSegmentCreateInput = {
  customer_segment_id?: string | null;
  customer_segment_description?: string | null;
  is_in_rsp_mode?: boolean | null;
  customer_segment_alert_message?: string | null;
};

export type ConfigurableCustomerSegment = {
  __typename: "ConfigurableCustomerSegment";
  customer_segment_id?: string | null;
  customer_segment_description?: string | null;
  is_in_rsp_mode?: boolean | null;
  customer_segment_alert_message?: string | null;
  customer_segment_alert_expiry_date?: string | null;
};

export type CustomerSegmentEditInput = {
  customer_segment_id?: string | null;
  is_in_rsp_mode?: boolean | null;
  customer_segment_alert_message?: string | null;
  customer_segment_alert_expiry_date?: string | null;
};

export type GetAdminUserInfoInput = {
  user_id?: string | null;
};

export type AdminUserInfo = {
  __typename: "AdminUserInfo";
  user_id?: string | null;
  account_id_view_as?: string | null;
  user_role_id?: number | null;
  features?: Array<number | null> | null;
};

export type UpdateAdminUserInput = {
  user_id?: string | null;
  account_id_view_as?: string | null;
  user_role_id?: number | null;
};

export type UpdateLoginKillSwitchInput = {
  show_login_boxes?: boolean | null;
  created_by?: string | null;
};

export type AccountCalculatorCriteriaInput = {
  subscription_size_kw?: number | null;
  customer_segment_id?: string | null;
};

export type AccountCalculations = {
  __typename: "AccountCalculations";
  monthly_subscription_fee?: string | null;
  avg_monthly_credit_first_year?: string | null;
  avg_monthly_credit_fifth_year?: string | null;
  avg_monthly_credit_tenth_year?: string | null;
  avg_monthly_net_savings_first_year?: string | null;
  avg_monthly_net_savings_fifth_year?: string | null;
  avg_monthly_net_savings_tenth_year?: string | null;
  subscription_size_kw?: string | null;
  is_in_rsp_mode?: boolean | null;
};

export type EnvironmentalImpact = {
  __typename: "EnvironmentalImpact";
  cars_avoided?: number | null;
  co2_tons_avoided?: number | null;
  id: string;
  month?: string | null;
  trees_planted?: number | null;
  year?: string | null;
};

export type Financial = {
  __typename: "Financial";
  credits_earned?: number | null;
  enrollment_fees?: number | null;
  fees_collected?: number | null;
  id: string;
  month?: string | null;
  savings?: number | null;
  subscription_fees?: number | null;
  year?: string | null;
};

export type PendingAccounts = {
  __typename: "PendingAccounts";
  account_id?: string | null;
  business_partner_id?: string | null;
  allocation_id?: string | null;
};

export type ProgramAccountCount = {
  __typename: "ProgramAccountCount";
  cancelled_ytd_count?: number | null;
  enrollment_count?: number | null;
  id: string;
  pending_count?: number | null;
  prgAccountCntsId: number;
  prgAccountCntsType: string;
  wait_list_count?: number | null;
};

export type ProgramAccountCapacity = {
  __typename: "ProgramAccountCapacity";
  available_kw?: number | null;
  available_mw?: number | null;
  id: string;
  prgAccountCapId: number;
  prgAccountCapsType: string;
  subscribed_kw?: number | null;
  subscribed_mw?: number | null;
  wait_list_kw?: number | null;
  wait_list_mw?: number | null;
};

export type uploadLrgCorpGovAccntsJson = {
  jsonLrgCorpGovAccounts?: string | null;
};

export type MetadataPicklistCriteriaInput = {
  metadata_name?: string | null;
};

export type ModelMetadataPicklistConnection = {
  __typename: "ModelMetadataPicklistConnection";
  metadata_picklist?: Array<MetadataPicklist | null> | null;
  nextToken?: string | null;
};

export type MetadataPicklist = {
  __typename: "MetadataPicklist";
  id?: number | null;
  description?: string | null;
};

export type ReportCriteriaInput = {
  id_token?: string | null;
  customer_segment_id?: string | null;
};

export type AllocationReport = {
  __typename: "AllocationReport";
  allocation_id?: string | null;
  main_customer?: CustomerModel | null;
  res_non_res?: string | null;
  low_income_flag?: boolean | null;
  installation_id?: string | null;
  billing_period?: string | null;
  account_id?: string | null;
  business_partner_id?: string | null;
  premise_address?: Address | null;
  allocation_size_kw?: number | null;
  created_at?: string | null;
  pending_date?: string | null;
  active_date?: string | null;
  cancelled_date?: string | null;
  allocation_status_id?: number | null;
  allocation_status_description?: string | null;
  cancel_reason_description?: string | null;
  enrollment_channel_description?: string | null;
  customer_segment_id?: string | null;
  customer_segment_description?: string | null;
  customer_connect_enrollment_code?: string | null;
  customer_connect_product_code?: string | null;
};

export type EnrolledAllocation = {
  __typename: "EnrolledAllocation";
  allocation_id?: string | null;
  main_customer?: CustomerModel | null;
  account_id?: string | null;
  business_partner_id?: string | null;
  premise_address?: Address | null;
  allocation_size_kw?: number | null;
  active_date?: string | null;
  enrollment_channel_description?: string | null;
  customer_connect_enrollment_code?: string | null;
  customer_connect_product_code?: string | null;
  eligibility_status?: string | null;
  eligibility_check_date?: string | null;
  ineligibility_reasons?: string | null;
};

export type WaitlistReport = {
  __typename: "WaitlistReport";
  allocation_id?: string | null;
  wait_list_position?: string | null;
  main_customer?: CustomerModel | null;
  customer_segment_description?: string | null;
  account_id?: string | null;
  business_partner_id?: string | null;
  premise_address?: Address | null;
  max_sub_kw?: number | null;
  total_subscription_kw?: number | null;
  allocation_size_kw?: number | null;
  created_at?: string | null;
  eligibility_status?: string | null;
  eligibility_check_date?: string | null;
  ineligibility_reasons?: string | null;
  unfilled_subscription_kw_goal?: number | null;
};

export type TransferReport = {
  __typename: "TransferReport";
  main_customer?: CustomerModel | null;
  premise_address?: Address | null;
  customer_segment_id?: string | null;
  customer_segment_description?: string | null;
  transfer_list_position?: string | null;
  account_id?: string | null;
  business_partner_id?: string | null;
  installation_id?: string | null;
  operand_key?: string | null;
  move_in_date?: string | null;
  move_out_date?: string | null;
  active_accounts?: Array<ActiveAccountByBPId | null> | null;
};

export type ActiveAccountByBPId = {
  __typename: "ActiveAccountByBPId";
  account_id?: string | null;
};

export type ReportCriteriaSimpleInput = {
  id_token?: string | null;
};

export type EnrollmentAuditReport = {
  __typename: "EnrollmentAuditReport";
  enrollment_audit_id?: string | null;
  created_at?: string | null;
  account_id?: string | null;
  allocation_id?: string | null;
  change_summary?: string | null;
  allocation_size_change_kw?: string | null;
  max_sub_kw?: number | null;
  old_account_notes?: string | null;
  new_account_notes?: string | null;
  created_by?: string | null;
  customer_connect_request?: string | null;
  customer_connect_response?: string | null;
  wait_list_run_id?: string | null;
  is_successful?: boolean | null;
  error_message?: string | null;
  audit_notes?: string | null;
  cancel_reason_description?: string | null;
  main_customer?: CustomerModel | null;
  premise_address?: Address | null;
};

export type AccountLookupForEmployeeInput = {
  account_number?: string | null;
};

export type AccountLookupForEmployee = {
  __typename: "AccountLookupForEmployee";
  jurisdiction_code?: string | null;
  account_number?: string | null;
  business_partner_id?: string | null;
  is_residential?: boolean | null;
  lmi_file_validation?: Array<LmiFileValidation | null> | null;
  premise_address?: Address | null;
  billing_address?: Address | null;
  main_customer?: CustomerModel | null;
  account_notes?: string | null;
  allocations?: Array<AccountLookupForEmployeeAllocation | null> | null;
  email_updates_interval?: string | null;
  wait_list_position?: number | null;
  eligibility?: IneligibilityReasonsList | null;
  max_subscription_size_kw?: number | null;
  current_allocation_size_kw?: number | null;
  available_size_kw?: number | null;
  customer_segment?: CustomerSegment | null;
  low_income?: LowIncomeInformation | null;
};

export type AccountLookupForEmployeeAllocation = {
  __typename: "AccountLookupForEmployeeAllocation";
  allocation_id?: string | null;
  account_id?: string | null;
  allocation_size_kw?: number | null;
  max_sub_kw?: number | null;
  allocation_status_id?: number | null;
  allocation_status_description?: string | null;
  created_at?: string | null;
  pending_date?: string | null;
  active_date?: string | null;
  cancelled_date?: string | null;
  cancel_reason_id?: number | null;
  enrollment_channel_id?: number | null;
};

export type AccountSearchForEmployeeInput = {
  account_number?: string | null;
  address_line_1?: string | null;
  city?: string | null;
  email?: string | null;
  first_name?: string | null;
  jurisdiction_code?: string | null;
  last_name?: string | null;
  org_name?: string | null;
  phone?: string | null;
  res_non_res?: string | null;
};

export type AccountSearchForEmployee = {
  __typename: "AccountSearchForEmployee";
  account_type?: boolean | null;
  business_partner_id?: string | null;
  account_number?: string | null;
  premise_address?: Address | null;
  billing_address?: Address | null;
  main_customer?: CustomerModel | null;
  jurisdiction_code?: string | null;
  gov_acc_ind?: boolean | null;
  account_class?: string | null;
  account_status?: string | null;
  installations?: Array<Installation | null> | null;
};

export type Installation = {
  __typename: "Installation";
  installation_number?: string | null;
  service_point?: string | null;
  contract?: string | null;
  rate_category?: string | null;
  active_date?: string | null;
  move_out_date?: string | null;
};

export type CustomerLookupCriteriaInput = {
  account_number?: string | null;
  date_of_birth?: string | null;
  email?: string | null;
  last_4_fed_tax_id?: string | null;
  last_4_ssn?: string | null;
  phone?: string | null;
  res_non_res?: string | null;
};

export type ModelCustomerDetailConnection = {
  __typename: "ModelCustomerDetailConnection";
  detailed_accounts?: Array<CustomerDetail | null> | null;
  nextToken?: string | null;
};

export type ModelCustomerAlertFilterInput = {
  account_id?: ModelStringInput | null;
  alert_id?: ModelIntInput | null;
  and?: Array<ModelCustomerAlertFilterInput | null> | null;
  created_date?: ModelStringInput | null;
  full_name?: ModelStringInput | null;
  id?: ModelIDInput | null;
  issue?: ModelStringInput | null;
  not?: ModelCustomerAlertFilterInput | null;
  notes?: ModelStringInput | null;
  or?: Array<ModelCustomerAlertFilterInput | null> | null;
};

export type ModelIDInput = {
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  contains?: string | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
  ne?: string | null;
  notContains?: string | null;
  size?: ModelSizeInput | null;
};

export type ModelCustomerAlertConnection = {
  __typename: "ModelCustomerAlertConnection";
  items?: Array<CustomerAlert | null> | null;
  nextToken?: string | null;
};

export type ModelCustomerDetailFilterInput = {
  account_id?: ModelStringInput | null;
  account_lmi_verification_date?: ModelStringInput | null;
  account_lmi_verification_type_id?: ModelIntInput | null;
  account_notes?: ModelStringInput | null;
  account_number?: ModelStringInput | null;
  account_type?: ModelStringInput | null;
  and?: Array<ModelCustomerDetailFilterInput | null> | null;
  business_partner_id?: ModelIntInput | null;
  current_allocation_size_kw?: ModelIntInput | null;
  eligibility_status?: ModelStringInput | null;
  id?: ModelIDInput | null;
  ineligibility_reasons?: ModelStringInput | null;
  is_low_income?: ModelBooleanInput | null;
  max_subscription_size_kw?: ModelIntInput | null;
  not?: ModelCustomerDetailFilterInput | null;
  or?: Array<ModelCustomerDetailFilterInput | null> | null;
  source_system?: ModelStringInput | null;
};

export type ModelBooleanInput = {
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  eq?: boolean | null;
  ne?: boolean | null;
};

export type ModelCustomerReportFilterInput = {
  account_id?: ModelStringInput | null;
  account_type?: ModelStringInput | null;
  active_allocation_size_kw?: ModelIntInput | null;
  and?: Array<ModelCustomerReportFilterInput | null> | null;
  bill_cycle?: ModelStringInput | null;
  business_partner_id?: ModelIntInput | null;
  id?: ModelIDInput | null;
  jurisdiction_name?: ModelStringInput | null;
  max_subscription_size_kw?: ModelIntInput | null;
  not?: ModelCustomerReportFilterInput | null;
  or?: Array<ModelCustomerReportFilterInput | null> | null;
  state_code?: ModelStringInput | null;
};

export type ModelCustomerReportConnection = {
  __typename: "ModelCustomerReportConnection";
  items?: Array<CustomerReport | null> | null;
  nextToken?: string | null;
};

export type CustomerReport = {
  __typename: "CustomerReport";
  account_id?: string | null;
  account_type?: string | null;
  active_allocation_size_kw?: number | null;
  bill_cycle?: string | null;
  business_partner_id?: number | null;
  id: string;
  jurisdiction_name?: string | null;
  main_customer?: CustomerModel | null;
  max_subscription_size_kw?: number | null;
  premise_address?: Address | null;
  state_code?: string | null;
};

export type ModelEnrollmentAlertFilterInput = {
  account_id?: ModelStringInput | null;
  and?: Array<ModelEnrollmentAlertFilterInput | null> | null;
  created_date?: ModelStringInput | null;
  enrollment_audit_id?: ModelIntInput | null;
  full_name?: ModelStringInput | null;
  id?: ModelIDInput | null;
  not?: ModelEnrollmentAlertFilterInput | null;
  notes?: ModelStringInput | null;
  or?: Array<ModelEnrollmentAlertFilterInput | null> | null;
  status_change?: ModelStringInput | null;
};

export type ModelEnrollmentAlertConnection = {
  __typename: "ModelEnrollmentAlertConnection";
  items?: Array<EnrollmentAlert | null> | null;
  nextToken?: string | null;
};

export type ModelFacilityAlertFilterInput = {
  alert_id?: ModelStringInput | null;
  and?: Array<ModelFacilityAlertFilterInput | null> | null;
  created_date?: ModelStringInput | null;
  facility_name?: ModelStringInput | null;
  id?: ModelIDInput | null;
  issue?: ModelStringInput | null;
  not?: ModelFacilityAlertFilterInput | null;
  notes?: ModelStringInput | null;
  or?: Array<ModelFacilityAlertFilterInput | null> | null;
};

export type ModelFacilityAlertConnection = {
  __typename: "ModelFacilityAlertConnection";
  items?: Array<FacilityAlert | null> | null;
  nextToken?: string | null;
};

export type ModelFacilityReportFilterInput = {
  address_line1?: ModelStringInput | null;
  and?: Array<ModelFacilityReportFilterInput | null> | null;
  city?: ModelStringInput | null;
  commercial_operating_date?: ModelStringInput | null;
  facility_id?: ModelIntInput | null;
  facility_installation_number?: ModelIntInput | null;
  facility_name?: ModelStringInput | null;
  id?: ModelIDInput | null;
  jurisdiction_name?: ModelStringInput | null;
  not?: ModelFacilityReportFilterInput | null;
  one_month_status?: ModelStringInput | null;
  or?: Array<ModelFacilityReportFilterInput | null> | null;
  salesforce_id?: ModelIntInput | null;
  state_code?: ModelStringInput | null;
  total_capacity_kw?: ModelIntInput | null;
  total_capacity_unit?: ModelStringInput | null;
  twelve_month_status?: ModelStringInput | null;
};

export type ModelFacilityReportConnection = {
  __typename: "ModelFacilityReportConnection";
  items?: Array<FacilityReport | null> | null;
  nextToken?: string | null;
};

export type FacilityReport = {
  __typename: "FacilityReport";
  address_line1?: string | null;
  city?: string | null;
  commercial_operating_date?: string | null;
  facility_id?: number | null;
  facility_installation_number?: number | null;
  facility_name?: string | null;
  id: string;
  jurisdiction_name?: string | null;
  one_month_status?: string | null;
  salesforce_id?: number | null;
  state_code?: string | null;
  total_capacity_kw?: number | null;
  total_capacity_unit?: string | null;
  twelve_month_status?: string | null;
};

export type GetCustomerSegmentByIdInput = {
  customer_segment_id?: string | null;
};

export type WaitListRunsInput = {
  customer_segment_id?: string | null;
  item_limit?: number | null;
};

export type WaitListRun = {
  __typename: "WaitListRun";
  num_items_at_start?: number | null;
  error_message?: string | null;
  is_successful?: boolean | null;
  customer_segment_id?: string | null;
  created_by?: string | null;
  start_time?: string | null;
  end_time?: string | null;
  num_items_at_end?: number | null;
  wait_list_run_id?: string | null;
  enrollment_audits?: Array<EnrollmentAuditForWaitListRun | null> | null;
};

export type EnrollmentAuditForWaitListRun = {
  __typename: "EnrollmentAuditForWaitListRun";
  is_successful?: boolean | null;
  new_allocation_size_kw?: number | null;
  account_id?: string | null;
  customer_connect_request?: string | null;
  customer_connect_response?: string | null;
};

export type TransferListRunsInput = {
  customer_segment_id?: string | null;
  item_limit?: number | null;
};

export type TransferListRun = {
  __typename: "TransferListRun";
  num_items_at_start?: number | null;
  error_message?: string | null;
  is_successful?: boolean | null;
  customer_segment_id?: string | null;
  created_by?: string | null;
  start_time?: string | null;
  end_time?: string | null;
  num_items_at_end?: number | null;
  transfer_list_run_id?: string | null;
};

export type WaitListSummary = {
  __typename: "WaitListSummary";
  wait_list_current_counts?: WaitListCurrentCounts | null;
  segment_current_capacity?: SegmentCurrentCapacity | null;
  segment_percentage?: SegmentPercentage | null;
};

export type WaitListCurrentCounts = {
  __typename: "WaitListCurrentCounts";
  eligible?: number | null;
  ineligible?: number | null;
  total?: number | null;
  totalkw?: number | null;
  eligiblekw?: number | null;
  ineligiblekw?: number | null;
};

export type SegmentCurrentCapacity = {
  __typename: "SegmentCurrentCapacity";
  segment_taken_capacity_kw?: number | null;
  segment_available_capacity_kw?: number | null;
  segment_total_capacity_kw?: number | null;
};

export type SegmentPercentage = {
  __typename: "SegmentPercentage";
  segment_capacity_percentage?: string | null;
  facilities_total_capacity_kw?: number | null;
  segment_total_capacity_kw?: number | null;
};

export type UsersReport = {
  __typename: "UsersReport";
  user_id?: string | null;
  account_id_view_as?: string | null;
  user_role_id?: number | null;
  user_role_description?: string | null;
};

export type LoginKillSwitch = {
  __typename: "LoginKillSwitch";
  show_login_boxes?: boolean | null;
};

export type LoginKillSwitchMetadata = {
  __typename: "LoginKillSwitchMetadata";
  value?: boolean | null;
  login_kill_switch_audit_id?: string | null;
  created_by?: string | null;
  created_at?: string | null;
};

export type LmiDocumentAuditReport = {
  __typename: "LmiDocumentAuditReport";
  business_partner_id?: string | null;
  file_name?: string | null;
  customer_status?: string | null;
  document_type?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  anti_virus_scan_phase?: string | null;
  lmi_verification_phase?: string | null;
  redaction_phase?: string | null;
  verification_date?: string | null;
  response?: string | null;
};

export type BusinessPartnerIdInput = {
  business_partner_id?: string | null;
};

export type LmiDocumentAuditReportDetailsByBusinessPartnerId = {
  __typename: "LmiDocumentAuditReportDetailsByBusinessPartnerId";
  account_id?: string | null;
  account_notes?: string | null;
  enrollment_audits?: Array<LmiDocumentAuditReportDetailsByBusinessPartnerIdEnrollmentAudit | null> | null;
};

export type LmiDocumentAuditReportDetailsByBusinessPartnerIdEnrollmentAudit = {
  __typename: "LmiDocumentAuditReportDetailsByBusinessPartnerIdEnrollmentAudit";
  created_by?: string | null;
  created_at?: string | null;
  old_allocation_status_id?: number | null;
  old_allocation_status_description?: string | null;
  new_allocation_status_id?: number | null;
  new_allocation_status_description?: string | null;
};

export type HistoricalBillingInput = {
  account_id?: string | null;
  data_format?: string | null;
};

export type HistoricalBilling = {
  __typename: "HistoricalBilling";
  account_id?: string | null;
  bill_date?: string | null;
  bill_month?: number | null;
  bill_year?: number | null;
  billed_usage?: number | null;
  billing_amount?: string | null;
  cec_credit?: string | null;
  cec_credit_kwh?: number | null;
  cec_fee?: string | null;
  cec_fee_kwh?: number | null;
  installation_id?: string | null;
  offset?: number | null;
  partition?: number | null;
  timestamp?: number | null;
  topic?: string | null;
  upsert_date?: string | null;
};

export type CreateSubscriptionMutation = {
  __typename: "CustomerDetail";
  account_number?: string | null;
  jurisdiction_code?: string | null;
  account_notes?: string | null;
  customer_segment?: {
    __typename: "CustomerSegment";
    customer_segment_id?: string | null;
    customer_segment_description?: string | null;
  } | null;
  business_partner_id?: string | null;
  max_subscription_size_kw?: number | null;
  current_allocation_size_kw?: number | null;
  premise_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  billing_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  main_customer?: {
    __typename: "CustomerModel";
    full_name?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
  allocations?: Array<{
    __typename: "CustomerDetailAllocation";
    allocation_id?: string | null;
    account_id?: string | null;
    allocation_size_kw?: number | null;
    max_sub_kw?: number | null;
    allocation_status_id?: number | null;
    allocation_status_description?: string | null;
    created_at?: string | null;
    pending_date?: string | null;
    active_date?: string | null;
    cancelled_date?: string | null;
    cancel_reason_id?: number | null;
    enrollment_channel_id?: number | null;
    customer_connect_enrollment_code?: string | null;
  } | null> | null;
  eligibility?: Array<{
    __typename: "IneligibilityReasonsList";
    ineligibility_reasons?: Array<string | null> | null;
    is_eligible?: boolean | null;
    product_code?: string | null;
  } | null> | null;
  low_income?: {
    __typename: "LowIncomeInformation";
    low_income_flag?: boolean | null;
    nes_participant?: boolean | null;
    low_income_verification_status?: boolean | null;
    low_income_verification_type_id?: string | null;
    low_income_verification_date?: string | null;
  } | null;
  lmi_file_validation?: Array<{
    __typename: "LmiFileValidation";
    link?: string | null;
    file_name?: string | null;
    customer_status?: string | null;
    verification_date?: string | null;
  } | null> | null;
  is_residential?: boolean | null;
  email_updates_interval?: string | null;
  wait_list_position?: number | null;
};

export type CancelSubscriptionMutation = {
  __typename: "CustomerDetail";
  account_number?: string | null;
  jurisdiction_code?: string | null;
  account_notes?: string | null;
  customer_segment?: {
    __typename: "CustomerSegment";
    customer_segment_id?: string | null;
    customer_segment_description?: string | null;
  } | null;
  business_partner_id?: string | null;
  max_subscription_size_kw?: number | null;
  current_allocation_size_kw?: number | null;
  premise_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  billing_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  main_customer?: {
    __typename: "CustomerModel";
    full_name?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
  allocations?: Array<{
    __typename: "CustomerDetailAllocation";
    allocation_id?: string | null;
    account_id?: string | null;
    allocation_size_kw?: number | null;
    max_sub_kw?: number | null;
    allocation_status_id?: number | null;
    allocation_status_description?: string | null;
    created_at?: string | null;
    pending_date?: string | null;
    active_date?: string | null;
    cancelled_date?: string | null;
    cancel_reason_id?: number | null;
    enrollment_channel_id?: number | null;
    customer_connect_enrollment_code?: string | null;
  } | null> | null;
  eligibility?: Array<{
    __typename: "IneligibilityReasonsList";
    ineligibility_reasons?: Array<string | null> | null;
    is_eligible?: boolean | null;
    product_code?: string | null;
  } | null> | null;
  low_income?: {
    __typename: "LowIncomeInformation";
    low_income_flag?: boolean | null;
    nes_participant?: boolean | null;
    low_income_verification_status?: boolean | null;
    low_income_verification_type_id?: string | null;
    low_income_verification_date?: string | null;
  } | null;
  lmi_file_validation?: Array<{
    __typename: "LmiFileValidation";
    link?: string | null;
    file_name?: string | null;
    customer_status?: string | null;
    verification_date?: string | null;
  } | null> | null;
  is_residential?: boolean | null;
  email_updates_interval?: string | null;
  wait_list_position?: number | null;
};

export type EditSubscriptionMutation = {
  __typename: "CustomerDetail";
  account_number?: string | null;
  jurisdiction_code?: string | null;
  account_notes?: string | null;
  customer_segment?: {
    __typename: "CustomerSegment";
    customer_segment_id?: string | null;
    customer_segment_description?: string | null;
  } | null;
  business_partner_id?: string | null;
  max_subscription_size_kw?: number | null;
  current_allocation_size_kw?: number | null;
  premise_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  billing_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  main_customer?: {
    __typename: "CustomerModel";
    full_name?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
  allocations?: Array<{
    __typename: "CustomerDetailAllocation";
    allocation_id?: string | null;
    account_id?: string | null;
    allocation_size_kw?: number | null;
    max_sub_kw?: number | null;
    allocation_status_id?: number | null;
    allocation_status_description?: string | null;
    created_at?: string | null;
    pending_date?: string | null;
    active_date?: string | null;
    cancelled_date?: string | null;
    cancel_reason_id?: number | null;
    enrollment_channel_id?: number | null;
    customer_connect_enrollment_code?: string | null;
  } | null> | null;
  eligibility?: Array<{
    __typename: "IneligibilityReasonsList";
    ineligibility_reasons?: Array<string | null> | null;
    is_eligible?: boolean | null;
    product_code?: string | null;
  } | null> | null;
  low_income?: {
    __typename: "LowIncomeInformation";
    low_income_flag?: boolean | null;
    nes_participant?: boolean | null;
    low_income_verification_status?: boolean | null;
    low_income_verification_type_id?: string | null;
    low_income_verification_date?: string | null;
  } | null;
  lmi_file_validation?: Array<{
    __typename: "LmiFileValidation";
    link?: string | null;
    file_name?: string | null;
    customer_status?: string | null;
    verification_date?: string | null;
  } | null> | null;
  is_residential?: boolean | null;
  email_updates_interval?: string | null;
  wait_list_position?: number | null;
};

export type UpdatePendingToEnrollmentMutation = {
  __typename: "StringMessage";
  message?: string | null;
};

export type UpdateCustomerAlertMutation = {
  __typename: "CustomerAlert";
  account_id?: string | null;
  alert_id?: number | null;
  created_date?: string | null;
  full_name?: string | null;
  id: string;
  issue?: string | null;
  notes?: string | null;
};

export type UpdateFacilityAlertMutation = {
  __typename: "FacilityAlert";
  alert_id?: string | null;
  created_date?: string | null;
  facility_name?: string | null;
  id: string;
  issue?: string | null;
  notes?: string | null;
};

export type UpdateEnrollmentAlertMutation = {
  __typename: "EnrollmentAlert";
  account_id?: string | null;
  created_date?: string | null;
  enrollment_audit_id?: number | null;
  full_name?: string | null;
  id: string;
  notes?: string | null;
  status_change?: string | null;
};

export type WaitListReorderMutation = {
  __typename: "StringMessage";
  message?: string | null;
};

export type WaitListReverifyMutation = {
  __typename: "StringMessage";
  message?: string | null;
};

export type TransferListReorderMutation = {
  __typename: "StringMessage";
  message?: string | null;
};

export type EnrolledAllocationsReverifyMutation = {
  __typename: "StringMessage";
  message?: string | null;
};

export type WaitListProcessMutation = {
  __typename: "StringMessage";
  message?: string | null;
};

export type TransferListProcessMutation = {
  __typename: "StringMessage";
  message?: string | null;
};

export type CreateCustomerSegmentMutation = {
  __typename: "ConfigurableCustomerSegment";
  customer_segment_id?: string | null;
  customer_segment_description?: string | null;
  is_in_rsp_mode?: boolean | null;
  customer_segment_alert_message?: string | null;
  customer_segment_alert_expiry_date?: string | null;
};

export type EditCustomerSegmentMutation = {
  __typename: "ConfigurableCustomerSegment";
  customer_segment_id?: string | null;
  customer_segment_description?: string | null;
  is_in_rsp_mode?: boolean | null;
  customer_segment_alert_message?: string | null;
  customer_segment_alert_expiry_date?: string | null;
};

export type AdminUserInfoMutation = {
  __typename: "AdminUserInfo";
  user_id?: string | null;
  account_id_view_as?: string | null;
  user_role_id?: number | null;
  features?: Array<number | null> | null;
};

export type UpdateAdminUserMutation = {
  __typename: "StringMessage";
  message?: string | null;
};

export type UpdateLoginKillSwitchMutation = {
  __typename: "StringMessage";
  message?: string | null;
};

export type GetAccountCalculationsQuery = {
  __typename: "AccountCalculations";
  monthly_subscription_fee?: string | null;
  avg_monthly_credit_first_year?: string | null;
  avg_monthly_credit_fifth_year?: string | null;
  avg_monthly_credit_tenth_year?: string | null;
  avg_monthly_net_savings_first_year?: string | null;
  avg_monthly_net_savings_fifth_year?: string | null;
  avg_monthly_net_savings_tenth_year?: string | null;
  subscription_size_kw?: string | null;
  is_in_rsp_mode?: boolean | null;
};

export type GetEnrollmentAlertQuery = {
  __typename: "EnrollmentAlert";
  account_id?: string | null;
  created_date?: string | null;
  enrollment_audit_id?: number | null;
  full_name?: string | null;
  id: string;
  notes?: string | null;
  status_change?: string | null;
};

export type GetEnvironmentalImpactQuery = {
  __typename: "EnvironmentalImpact";
  cars_avoided?: number | null;
  co2_tons_avoided?: number | null;
  id: string;
  month?: string | null;
  trees_planted?: number | null;
  year?: string | null;
};

export type GetFacilityAlertQuery = {
  __typename: "FacilityAlert";
  alert_id?: string | null;
  created_date?: string | null;
  facility_name?: string | null;
  id: string;
  issue?: string | null;
  notes?: string | null;
};

export type GetCustomerAlertQuery = {
  __typename: "CustomerAlert";
  account_id?: string | null;
  alert_id?: number | null;
  created_date?: string | null;
  full_name?: string | null;
  id: string;
  issue?: string | null;
  notes?: string | null;
};

export type GetFinancialQuery = {
  __typename: "Financial";
  credits_earned?: number | null;
  enrollment_fees?: number | null;
  fees_collected?: number | null;
  id: string;
  month?: string | null;
  savings?: number | null;
  subscription_fees?: number | null;
  year?: string | null;
};

export type GetPendingAccountsQuery = {
  __typename: "PendingAccounts";
  account_id?: string | null;
  business_partner_id?: string | null;
  allocation_id?: string | null;
};

export type GetProgramAccountQuery = {
  __typename: "ProgramAccountCount";
  cancelled_ytd_count?: number | null;
  enrollment_count?: number | null;
  id: string;
  pending_count?: number | null;
  prgAccountCntsId: number;
  prgAccountCntsType: string;
  wait_list_count?: number | null;
};

export type GetProgramAccountCapacityQuery = {
  __typename: "ProgramAccountCapacity";
  available_kw?: number | null;
  available_mw?: number | null;
  id: string;
  prgAccountCapId: number;
  prgAccountCapsType: string;
  subscribed_kw?: number | null;
  subscribed_mw?: number | null;
  wait_list_kw?: number | null;
  wait_list_mw?: number | null;
};

export type ListMetadataPicklistQuery = {
  __typename: "ModelMetadataPicklistConnection";
  metadata_picklist?: Array<{
    __typename: "MetadataPicklist";
    id?: number | null;
    description?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListAllocationReportQuery = {
  __typename: "AllocationReport";
  allocation_id?: string | null;
  main_customer?: {
    __typename: "CustomerModel";
    full_name?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
  res_non_res?: string | null;
  low_income_flag?: boolean | null;
  installation_id?: string | null;
  billing_period?: string | null;
  account_id?: string | null;
  business_partner_id?: string | null;
  premise_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  allocation_size_kw?: number | null;
  created_at?: string | null;
  pending_date?: string | null;
  active_date?: string | null;
  cancelled_date?: string | null;
  allocation_status_id?: number | null;
  allocation_status_description?: string | null;
  cancel_reason_description?: string | null;
  enrollment_channel_description?: string | null;
  customer_segment_id?: string | null;
  customer_segment_description?: string | null;
  customer_connect_enrollment_code?: string | null;
  customer_connect_product_code?: string | null;
};

export type ListEnrolledAllocationsQuery = {
  __typename: "EnrolledAllocation";
  allocation_id?: string | null;
  main_customer?: {
    __typename: "CustomerModel";
    full_name?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
  account_id?: string | null;
  business_partner_id?: string | null;
  premise_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  allocation_size_kw?: number | null;
  active_date?: string | null;
  enrollment_channel_description?: string | null;
  customer_connect_enrollment_code?: string | null;
  customer_connect_product_code?: string | null;
  eligibility_status?: string | null;
  eligibility_check_date?: string | null;
  ineligibility_reasons?: string | null;
};

export type ListWaitlistReportQuery = {
  __typename: "WaitlistReport";
  allocation_id?: string | null;
  wait_list_position?: string | null;
  main_customer?: {
    __typename: "CustomerModel";
    full_name?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
  customer_segment_description?: string | null;
  account_id?: string | null;
  business_partner_id?: string | null;
  premise_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  max_sub_kw?: number | null;
  total_subscription_kw?: number | null;
  allocation_size_kw?: number | null;
  created_at?: string | null;
  eligibility_status?: string | null;
  eligibility_check_date?: string | null;
  ineligibility_reasons?: string | null;
  unfilled_subscription_kw_goal?: number | null;
};

export type ListTransferReportQuery = {
  __typename: "TransferReport";
  main_customer?: {
    __typename: "CustomerModel";
    full_name?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
  premise_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  customer_segment_id?: string | null;
  customer_segment_description?: string | null;
  transfer_list_position?: string | null;
  account_id?: string | null;
  business_partner_id?: string | null;
  installation_id?: string | null;
  operand_key?: string | null;
  move_in_date?: string | null;
  move_out_date?: string | null;
  active_accounts?: Array<{
    __typename: "ActiveAccountByBPId";
    account_id?: string | null;
  } | null> | null;
};

export type ListEnrollmentAuditReportQuery = {
  __typename: "EnrollmentAuditReport";
  enrollment_audit_id?: string | null;
  created_at?: string | null;
  account_id?: string | null;
  allocation_id?: string | null;
  change_summary?: string | null;
  allocation_size_change_kw?: string | null;
  max_sub_kw?: number | null;
  old_account_notes?: string | null;
  new_account_notes?: string | null;
  created_by?: string | null;
  customer_connect_request?: string | null;
  customer_connect_response?: string | null;
  wait_list_run_id?: string | null;
  is_successful?: boolean | null;
  error_message?: string | null;
  audit_notes?: string | null;
  cancel_reason_description?: string | null;
  main_customer?: {
    __typename: "CustomerModel";
    full_name?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
  premise_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
};

export type GetAccountLookupForEmployeeQuery = {
  __typename: "AccountLookupForEmployee";
  jurisdiction_code?: string | null;
  account_number?: string | null;
  business_partner_id?: string | null;
  is_residential?: boolean | null;
  lmi_file_validation?: Array<{
    __typename: "LmiFileValidation";
    link?: string | null;
    file_name?: string | null;
    customer_status?: string | null;
    verification_date?: string | null;
  } | null> | null;
  premise_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  billing_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  main_customer?: {
    __typename: "CustomerModel";
    full_name?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
  account_notes?: string | null;
  allocations?: Array<{
    __typename: "AccountLookupForEmployeeAllocation";
    allocation_id?: string | null;
    account_id?: string | null;
    allocation_size_kw?: number | null;
    max_sub_kw?: number | null;
    allocation_status_id?: number | null;
    allocation_status_description?: string | null;
    created_at?: string | null;
    pending_date?: string | null;
    active_date?: string | null;
    cancelled_date?: string | null;
    cancel_reason_id?: number | null;
    enrollment_channel_id?: number | null;
  } | null> | null;
  email_updates_interval?: string | null;
  wait_list_position?: number | null;
  eligibility?: {
    __typename: "IneligibilityReasonsList";
    ineligibility_reasons?: Array<string | null> | null;
    is_eligible?: boolean | null;
    product_code?: string | null;
  } | null;
  max_subscription_size_kw?: number | null;
  current_allocation_size_kw?: number | null;
  available_size_kw?: number | null;
  customer_segment?: {
    __typename: "CustomerSegment";
    customer_segment_id?: string | null;
    customer_segment_description?: string | null;
  } | null;
  low_income?: {
    __typename: "LowIncomeInformation";
    low_income_flag?: boolean | null;
    nes_participant?: boolean | null;
    low_income_verification_status?: boolean | null;
    low_income_verification_type_id?: string | null;
    low_income_verification_date?: string | null;
  } | null;
};

export type ListAccountSearchForEmployeeQuery = {
  __typename: "AccountSearchForEmployee";
  account_type?: boolean | null;
  business_partner_id?: string | null;
  account_number?: string | null;
  premise_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  billing_address?: {
    __typename: "Address";
    city?: string | null;
    line1?: string | null;
    state?: string | null;
    zip?: string | null;
  } | null;
  main_customer?: {
    __typename: "CustomerModel";
    full_name?: string | null;
    email?: string | null;
    phone?: string | null;
  } | null;
  jurisdiction_code?: string | null;
  gov_acc_ind?: boolean | null;
  account_class?: string | null;
  account_status?: string | null;
  installations?: Array<{
    __typename: "Installation";
    installation_number?: string | null;
    service_point?: string | null;
    contract?: string | null;
    rate_category?: string | null;
    active_date?: string | null;
    move_out_date?: string | null;
  } | null> | null;
};

export type ListCustomerLookupQuery = {
  __typename: "ModelCustomerDetailConnection";
  detailed_accounts?: Array<{
    __typename: "CustomerDetail";
    account_number?: string | null;
    jurisdiction_code?: string | null;
    account_notes?: string | null;
    customer_segment?: {
      __typename: "CustomerSegment";
      customer_segment_id?: string | null;
      customer_segment_description?: string | null;
    } | null;
    business_partner_id?: string | null;
    max_subscription_size_kw?: number | null;
    current_allocation_size_kw?: number | null;
    premise_address?: {
      __typename: "Address";
      city?: string | null;
      line1?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
    billing_address?: {
      __typename: "Address";
      city?: string | null;
      line1?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
    main_customer?: {
      __typename: "CustomerModel";
      full_name?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null;
    allocations?: Array<{
      __typename: "CustomerDetailAllocation";
      allocation_id?: string | null;
      account_id?: string | null;
      allocation_size_kw?: number | null;
      max_sub_kw?: number | null;
      allocation_status_id?: number | null;
      allocation_status_description?: string | null;
      created_at?: string | null;
      pending_date?: string | null;
      active_date?: string | null;
      cancelled_date?: string | null;
      cancel_reason_id?: number | null;
      enrollment_channel_id?: number | null;
      customer_connect_enrollment_code?: string | null;
    } | null> | null;
    eligibility?: Array<{
      __typename: "IneligibilityReasonsList";
      ineligibility_reasons?: Array<string | null> | null;
      is_eligible?: boolean | null;
      product_code?: string | null;
    } | null> | null;
    low_income?: {
      __typename: "LowIncomeInformation";
      low_income_flag?: boolean | null;
      nes_participant?: boolean | null;
      low_income_verification_status?: boolean | null;
      low_income_verification_type_id?: string | null;
      low_income_verification_date?: string | null;
    } | null;
    lmi_file_validation?: Array<{
      __typename: "LmiFileValidation";
      link?: string | null;
      file_name?: string | null;
      customer_status?: string | null;
      verification_date?: string | null;
    } | null> | null;
    is_residential?: boolean | null;
    email_updates_interval?: string | null;
    wait_list_position?: number | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListCustomerAlertQuery = {
  __typename: "ModelCustomerAlertConnection";
  items?: Array<{
    __typename: "CustomerAlert";
    account_id?: string | null;
    alert_id?: number | null;
    created_date?: string | null;
    full_name?: string | null;
    id: string;
    issue?: string | null;
    notes?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListCustomerDetailQuery = {
  __typename: "ModelCustomerDetailConnection";
  detailed_accounts?: Array<{
    __typename: "CustomerDetail";
    account_number?: string | null;
    jurisdiction_code?: string | null;
    account_notes?: string | null;
    customer_segment?: {
      __typename: "CustomerSegment";
      customer_segment_id?: string | null;
      customer_segment_description?: string | null;
    } | null;
    business_partner_id?: string | null;
    max_subscription_size_kw?: number | null;
    current_allocation_size_kw?: number | null;
    premise_address?: {
      __typename: "Address";
      city?: string | null;
      line1?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
    billing_address?: {
      __typename: "Address";
      city?: string | null;
      line1?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
    main_customer?: {
      __typename: "CustomerModel";
      full_name?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null;
    allocations?: Array<{
      __typename: "CustomerDetailAllocation";
      allocation_id?: string | null;
      account_id?: string | null;
      allocation_size_kw?: number | null;
      max_sub_kw?: number | null;
      allocation_status_id?: number | null;
      allocation_status_description?: string | null;
      created_at?: string | null;
      pending_date?: string | null;
      active_date?: string | null;
      cancelled_date?: string | null;
      cancel_reason_id?: number | null;
      enrollment_channel_id?: number | null;
      customer_connect_enrollment_code?: string | null;
    } | null> | null;
    eligibility?: Array<{
      __typename: "IneligibilityReasonsList";
      ineligibility_reasons?: Array<string | null> | null;
      is_eligible?: boolean | null;
      product_code?: string | null;
    } | null> | null;
    low_income?: {
      __typename: "LowIncomeInformation";
      low_income_flag?: boolean | null;
      nes_participant?: boolean | null;
      low_income_verification_status?: boolean | null;
      low_income_verification_type_id?: string | null;
      low_income_verification_date?: string | null;
    } | null;
    lmi_file_validation?: Array<{
      __typename: "LmiFileValidation";
      link?: string | null;
      file_name?: string | null;
      customer_status?: string | null;
      verification_date?: string | null;
    } | null> | null;
    is_residential?: boolean | null;
    email_updates_interval?: string | null;
    wait_list_position?: number | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListCustomerReportQuery = {
  __typename: "ModelCustomerReportConnection";
  items?: Array<{
    __typename: "CustomerReport";
    account_id?: string | null;
    account_type?: string | null;
    active_allocation_size_kw?: number | null;
    bill_cycle?: string | null;
    business_partner_id?: number | null;
    id: string;
    jurisdiction_name?: string | null;
    main_customer?: {
      __typename: "CustomerModel";
      full_name?: string | null;
      email?: string | null;
      phone?: string | null;
    } | null;
    max_subscription_size_kw?: number | null;
    premise_address?: {
      __typename: "Address";
      city?: string | null;
      line1?: string | null;
      state?: string | null;
      zip?: string | null;
    } | null;
    state_code?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListEnrollmentAlertQuery = {
  __typename: "ModelEnrollmentAlertConnection";
  items?: Array<{
    __typename: "EnrollmentAlert";
    account_id?: string | null;
    created_date?: string | null;
    enrollment_audit_id?: number | null;
    full_name?: string | null;
    id: string;
    notes?: string | null;
    status_change?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListFacilityAlertQuery = {
  __typename: "ModelFacilityAlertConnection";
  items?: Array<{
    __typename: "FacilityAlert";
    alert_id?: string | null;
    created_date?: string | null;
    facility_name?: string | null;
    id: string;
    issue?: string | null;
    notes?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListFacilityReportQuery = {
  __typename: "ModelFacilityReportConnection";
  items?: Array<{
    __typename: "FacilityReport";
    address_line1?: string | null;
    city?: string | null;
    commercial_operating_date?: string | null;
    facility_id?: number | null;
    facility_installation_number?: number | null;
    facility_name?: string | null;
    id: string;
    jurisdiction_name?: string | null;
    one_month_status?: string | null;
    salesforce_id?: number | null;
    state_code?: string | null;
    total_capacity_kw?: number | null;
    total_capacity_unit?: string | null;
    twelve_month_status?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetCustomerSegmentByIdQuery = {
  __typename: "ConfigurableCustomerSegment";
  customer_segment_id?: string | null;
  customer_segment_description?: string | null;
  is_in_rsp_mode?: boolean | null;
  customer_segment_alert_message?: string | null;
  customer_segment_alert_expiry_date?: string | null;
};

export type ListCustomerSegmentsQuery = {
  __typename: "ConfigurableCustomerSegment";
  customer_segment_id?: string | null;
  customer_segment_description?: string | null;
  is_in_rsp_mode?: boolean | null;
  customer_segment_alert_message?: string | null;
  customer_segment_alert_expiry_date?: string | null;
};

export type ListWaitListRunsQuery = {
  __typename: "WaitListRun";
  num_items_at_start?: number | null;
  error_message?: string | null;
  is_successful?: boolean | null;
  customer_segment_id?: string | null;
  created_by?: string | null;
  start_time?: string | null;
  end_time?: string | null;
  num_items_at_end?: number | null;
  wait_list_run_id?: string | null;
  enrollment_audits?: Array<{
    __typename: "EnrollmentAuditForWaitListRun";
    is_successful?: boolean | null;
    new_allocation_size_kw?: number | null;
    account_id?: string | null;
    customer_connect_request?: string | null;
    customer_connect_response?: string | null;
  } | null> | null;
};

export type ListTransferListRunsQuery = {
  __typename: "TransferListRun";
  num_items_at_start?: number | null;
  error_message?: string | null;
  is_successful?: boolean | null;
  customer_segment_id?: string | null;
  created_by?: string | null;
  start_time?: string | null;
  end_time?: string | null;
  num_items_at_end?: number | null;
  transfer_list_run_id?: string | null;
};

export type GetWaitListSummaryQuery = {
  __typename: "WaitListSummary";
  wait_list_current_counts?: {
    __typename: "WaitListCurrentCounts";
    eligible?: number | null;
    ineligible?: number | null;
    total?: number | null;
    totalkw?: number | null;
    eligiblekw?: number | null;
    ineligiblekw?: number | null;
  } | null;
  segment_current_capacity?: {
    __typename: "SegmentCurrentCapacity";
    segment_taken_capacity_kw?: number | null;
    segment_available_capacity_kw?: number | null;
    segment_total_capacity_kw?: number | null;
  } | null;
  segment_percentage?: {
    __typename: "SegmentPercentage";
    segment_capacity_percentage?: string | null;
    facilities_total_capacity_kw?: number | null;
    segment_total_capacity_kw?: number | null;
  } | null;
};

export type ListUsersReportQuery = {
  __typename: "UsersReport";
  user_id?: string | null;
  account_id_view_as?: string | null;
  user_role_id?: number | null;
  user_role_description?: string | null;
};

export type ListEnrollmentAlertsQuery = {
  __typename: "EnrollmentAlert";
  account_id?: string | null;
  created_date?: string | null;
  enrollment_audit_id?: number | null;
  full_name?: string | null;
  id: string;
  notes?: string | null;
  status_change?: string | null;
};

export type GetLoginKillSwitchQuery = {
  __typename: "LoginKillSwitch";
  show_login_boxes?: boolean | null;
};

export type GetLoginKillSwitchMetadataQuery = {
  __typename: "LoginKillSwitchMetadata";
  value?: boolean | null;
  login_kill_switch_audit_id?: string | null;
  created_by?: string | null;
  created_at?: string | null;
};

export type ListLmiDocumentAuditReportQuery = {
  __typename: "LmiDocumentAuditReport";
  business_partner_id?: string | null;
  file_name?: string | null;
  customer_status?: string | null;
  document_type?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  anti_virus_scan_phase?: string | null;
  lmi_verification_phase?: string | null;
  redaction_phase?: string | null;
  verification_date?: string | null;
  response?: string | null;
};

export type ListLmiDocumentAuditReportDetailsByBusinessPartnerIdQuery = {
  __typename: "LmiDocumentAuditReportDetailsByBusinessPartnerId";
  account_id?: string | null;
  account_notes?: string | null;
  enrollment_audits?: Array<{
    __typename: "LmiDocumentAuditReportDetailsByBusinessPartnerIdEnrollmentAudit";
    created_by?: string | null;
    created_at?: string | null;
    old_allocation_status_id?: number | null;
    old_allocation_status_description?: string | null;
    new_allocation_status_id?: number | null;
    new_allocation_status_description?: string | null;
  } | null> | null;
};

export type GetHistoricalBillingQuery = {
  __typename: "HistoricalBilling";
  account_id?: string | null;
  bill_date?: string | null;
  bill_month?: number | null;
  bill_year?: number | null;
  billed_usage?: number | null;
  billing_amount?: string | null;
  cec_credit?: string | null;
  cec_credit_kwh?: number | null;
  cec_fee?: string | null;
  cec_fee_kwh?: number | null;
  installation_id?: string | null;
  offset?: number | null;
  partition?: number | null;
  timestamp?: number | null;
  topic?: string | null;
  upsert_date?: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIServiceCodegen {
  async CreateSubscription(
    input: CustomerDetailCreateCriteriaInput
  ): Promise<CreateSubscriptionMutation> {
    const statement = `mutation CreateSubscription($input: CustomerDetailCreateCriteriaInput!) {
        createSubscription(input: $input) {
          __typename
          account_number
          jurisdiction_code
          account_notes
          customer_segment {
            __typename
            customer_segment_id
            customer_segment_description
          }
          business_partner_id
          max_subscription_size_kw
          current_allocation_size_kw
          premise_address {
            __typename
            city
            line1
            state
            zip
          }
          billing_address {
            __typename
            city
            line1
            state
            zip
          }
          main_customer {
            __typename
            full_name
            email
            phone
          }
          allocations {
            __typename
            allocation_id
            account_id
            allocation_size_kw
            max_sub_kw
            allocation_status_id
            allocation_status_description
            created_at
            pending_date
            active_date
            cancelled_date
            cancel_reason_id
            enrollment_channel_id
            customer_connect_enrollment_code
          }
          eligibility {
            __typename
            ineligibility_reasons
            is_eligible
            product_code
          }
          low_income {
            __typename
            low_income_flag
            nes_participant
            low_income_verification_status
            low_income_verification_type_id
            low_income_verification_date
          }
          lmi_file_validation {
            __typename
            link
            file_name
            customer_status
            verification_date
          }
          is_residential
          email_updates_interval
          wait_list_position
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSubscriptionMutation>response.data.createSubscription;
  }
  async CancelSubscription(
    input: CustomerDetailCancelCriteriaInput
  ): Promise<CancelSubscriptionMutation> {
    const statement = `mutation CancelSubscription($input: CustomerDetailCancelCriteriaInput!) {
        cancelSubscription(input: $input) {
          __typename
          account_number
          jurisdiction_code
          account_notes
          customer_segment {
            __typename
            customer_segment_id
            customer_segment_description
          }
          business_partner_id
          max_subscription_size_kw
          current_allocation_size_kw
          premise_address {
            __typename
            city
            line1
            state
            zip
          }
          billing_address {
            __typename
            city
            line1
            state
            zip
          }
          main_customer {
            __typename
            full_name
            email
            phone
          }
          allocations {
            __typename
            allocation_id
            account_id
            allocation_size_kw
            max_sub_kw
            allocation_status_id
            allocation_status_description
            created_at
            pending_date
            active_date
            cancelled_date
            cancel_reason_id
            enrollment_channel_id
            customer_connect_enrollment_code
          }
          eligibility {
            __typename
            ineligibility_reasons
            is_eligible
            product_code
          }
          low_income {
            __typename
            low_income_flag
            nes_participant
            low_income_verification_status
            low_income_verification_type_id
            low_income_verification_date
          }
          lmi_file_validation {
            __typename
            link
            file_name
            customer_status
            verification_date
          }
          is_residential
          email_updates_interval
          wait_list_position
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CancelSubscriptionMutation>response.data.cancelSubscription;
  }
  async EditSubscription(
    input: CustomerDetailEditCriteriaInput
  ): Promise<EditSubscriptionMutation> {
    const statement = `mutation EditSubscription($input: CustomerDetailEditCriteriaInput!) {
        editSubscription(input: $input) {
          __typename
          account_number
          jurisdiction_code
          account_notes
          customer_segment {
            __typename
            customer_segment_id
            customer_segment_description
          }
          business_partner_id
          max_subscription_size_kw
          current_allocation_size_kw
          premise_address {
            __typename
            city
            line1
            state
            zip
          }
          billing_address {
            __typename
            city
            line1
            state
            zip
          }
          main_customer {
            __typename
            full_name
            email
            phone
          }
          allocations {
            __typename
            allocation_id
            account_id
            allocation_size_kw
            max_sub_kw
            allocation_status_id
            allocation_status_description
            created_at
            pending_date
            active_date
            cancelled_date
            cancel_reason_id
            enrollment_channel_id
            customer_connect_enrollment_code
          }
          eligibility {
            __typename
            ineligibility_reasons
            is_eligible
            product_code
          }
          low_income {
            __typename
            low_income_flag
            nes_participant
            low_income_verification_status
            low_income_verification_type_id
            low_income_verification_date
          }
          lmi_file_validation {
            __typename
            link
            file_name
            customer_status
            verification_date
          }
          is_residential
          email_updates_interval
          wait_list_position
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EditSubscriptionMutation>response.data.editSubscription;
  }
  async UpdatePendingToEnrollment(
    input: PendingAccountsInput
  ): Promise<UpdatePendingToEnrollmentMutation> {
    const statement = `mutation UpdatePendingToEnrollment($input: PendingAccountsInput!) {
        updatePendingToEnrollment(input: $input) {
          __typename
          message
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePendingToEnrollmentMutation>(
      response.data.updatePendingToEnrollment
    );
  }
  async UpdateAccountSegmentId(
    input?: UpdateAccountInput
  ): Promise<string | null> {
    const statement = `mutation UpdateAccountSegmentId($input: UpdateAccountInput) {
        updateAccountSegmentId(input: $input)
      }`;
    const gqlAPIServiceArguments: any = {};
    if (input) {
      gqlAPIServiceArguments.input = input;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.updateAccountSegmentId;
  }
  async UpdateCustomerAlert(
    input: UpdateCustomerAlertInput,
    condition?: ModelCustomerAlertConditionInput
  ): Promise<UpdateCustomerAlertMutation> {
    const statement = `mutation UpdateCustomerAlert($condition: ModelCustomerAlertConditionInput, $input: UpdateCustomerAlertInput!) {
        updateCustomerAlert(condition: $condition, input: $input) {
          __typename
          account_id
          alert_id
          created_date
          full_name
          id
          issue
          notes
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCustomerAlertMutation>response.data.updateCustomerAlert;
  }
  async UpdateFacilityAlert(
    input: UpdateFacilityAlertInput,
    condition?: ModelFacilityAlertConditionInput
  ): Promise<UpdateFacilityAlertMutation> {
    const statement = `mutation UpdateFacilityAlert($condition: ModelFacilityAlertConditionInput, $input: UpdateFacilityAlertInput!) {
        updateFacilityAlert(condition: $condition, input: $input) {
          __typename
          alert_id
          created_date
          facility_name
          id
          issue
          notes
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateFacilityAlertMutation>response.data.updateFacilityAlert;
  }
  async UpdateEnrollmentAlert(
    input: UpdateEnrollmentAlertInput,
    condition?: ModelEnrollmentAlertConditionInput
  ): Promise<UpdateEnrollmentAlertMutation> {
    const statement = `mutation UpdateEnrollmentAlert($condition: ModelEnrollmentAlertConditionInput, $input: UpdateEnrollmentAlertInput!) {
        updateEnrollmentAlert(condition: $condition, input: $input) {
          __typename
          account_id
          created_date
          enrollment_audit_id
          full_name
          id
          notes
          status_change
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEnrollmentAlertMutation>response.data.updateEnrollmentAlert;
  }
  async WaitListReorder(
    input: WaitListReorderInput
  ): Promise<WaitListReorderMutation> {
    const statement = `mutation WaitListReorder($input: WaitListReorderInput!) {
        waitListReorder(input: $input) {
          __typename
          message
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <WaitListReorderMutation>response.data.waitListReorder;
  }
  async WaitListReverify(
    input: WaitListReverifyInput
  ): Promise<WaitListReverifyMutation> {
    const statement = `mutation WaitListReverify($input: WaitListReverifyInput!) {
        waitListReverify(input: $input) {
          __typename
          message
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <WaitListReverifyMutation>response.data.waitListReverify;
  }
  async TransferListReorder(
    input: TransferListReorderInput
  ): Promise<TransferListReorderMutation> {
    const statement = `mutation TransferListReorder($input: TransferListReorderInput!) {
        transferListReorder(input: $input) {
          __typename
          message
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TransferListReorderMutation>response.data.transferListReorder;
  }
  async EnrolledAllocationsReverify(
    input: EnrolledAllocationsReverifyInput
  ): Promise<EnrolledAllocationsReverifyMutation> {
    const statement = `mutation EnrolledAllocationsReverify($input: EnrolledAllocationsReverifyInput!) {
        enrolledAllocationsReverify(input: $input) {
          __typename
          message
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EnrolledAllocationsReverifyMutation>(
      response.data.enrolledAllocationsReverify
    );
  }
  async WaitListProcess(
    input: WaitListProcessInput
  ): Promise<WaitListProcessMutation> {
    const statement = `mutation WaitListProcess($input: WaitListProcessInput!) {
        waitListProcess(input: $input) {
          __typename
          message
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <WaitListProcessMutation>response.data.waitListProcess;
  }
  async TransferListProcess(
    input: TransferListProcessInput
  ): Promise<TransferListProcessMutation> {
    const statement = `mutation TransferListProcess($input: TransferListProcessInput!) {
        transferListProcess(input: $input) {
          __typename
          message
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <TransferListProcessMutation>response.data.transferListProcess;
  }
  async CreateCustomerSegment(
    input: CustomerSegmentCreateInput
  ): Promise<CreateCustomerSegmentMutation> {
    const statement = `mutation CreateCustomerSegment($input: CustomerSegmentCreateInput!) {
        createCustomerSegment(input: $input) {
          __typename
          customer_segment_id
          customer_segment_description
          is_in_rsp_mode
          customer_segment_alert_message
          customer_segment_alert_expiry_date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCustomerSegmentMutation>response.data.createCustomerSegment;
  }
  async EditCustomerSegment(
    input: CustomerSegmentEditInput
  ): Promise<EditCustomerSegmentMutation> {
    const statement = `mutation EditCustomerSegment($input: CustomerSegmentEditInput!) {
        editCustomerSegment(input: $input) {
          __typename
          customer_segment_id
          customer_segment_description
          is_in_rsp_mode
          customer_segment_alert_message
          customer_segment_alert_expiry_date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <EditCustomerSegmentMutation>response.data.editCustomerSegment;
  }
  async AdminUserInfo(
    input: GetAdminUserInfoInput
  ): Promise<AdminUserInfoMutation> {
    const statement = `mutation AdminUserInfo($input: GetAdminUserInfoInput!) {
        adminUserInfo(input: $input) {
          __typename
          user_id
          account_id_view_as
          user_role_id
          features
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AdminUserInfoMutation>response.data.adminUserInfo;
  }
  async UpdateAdminUser(
    input: UpdateAdminUserInput
  ): Promise<UpdateAdminUserMutation> {
    const statement = `mutation UpdateAdminUser($input: UpdateAdminUserInput!) {
        updateAdminUser(input: $input) {
          __typename
          message
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAdminUserMutation>response.data.updateAdminUser;
  }
  async UpdateLoginKillSwitch(
    input: UpdateLoginKillSwitchInput
  ): Promise<UpdateLoginKillSwitchMutation> {
    const statement = `mutation UpdateLoginKillSwitch($input: UpdateLoginKillSwitchInput!) {
        updateLoginKillSwitch(input: $input) {
          __typename
          message
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLoginKillSwitchMutation>response.data.updateLoginKillSwitch;
  }
  async GetAccountCalculations(
    input: AccountCalculatorCriteriaInput
  ): Promise<GetAccountCalculationsQuery> {
    const statement = `query GetAccountCalculations($input: AccountCalculatorCriteriaInput!) {
        getAccountCalculations(input: $input) {
          __typename
          monthly_subscription_fee
          avg_monthly_credit_first_year
          avg_monthly_credit_fifth_year
          avg_monthly_credit_tenth_year
          avg_monthly_net_savings_first_year
          avg_monthly_net_savings_fifth_year
          avg_monthly_net_savings_tenth_year
          subscription_size_kw
          is_in_rsp_mode
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAccountCalculationsQuery>response.data.getAccountCalculations;
  }
  async GetEnrollmentAlert(id: string): Promise<GetEnrollmentAlertQuery> {
    const statement = `query GetEnrollmentAlert($id: ID!) {
        getEnrollmentAlert(id: $id) {
          __typename
          account_id
          created_date
          enrollment_audit_id
          full_name
          id
          notes
          status_change
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEnrollmentAlertQuery>response.data.getEnrollmentAlert;
  }
  async GetEnvironmentalImpact(
    id: string
  ): Promise<GetEnvironmentalImpactQuery> {
    const statement = `query GetEnvironmentalImpact($id: ID!) {
        getEnvironmentalImpact(id: $id) {
          __typename
          cars_avoided
          co2_tons_avoided
          id
          month
          trees_planted
          year
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEnvironmentalImpactQuery>response.data.getEnvironmentalImpact;
  }
  async GetFacilityAlert(id: string): Promise<GetFacilityAlertQuery> {
    const statement = `query GetFacilityAlert($id: ID!) {
        getFacilityAlert(id: $id) {
          __typename
          alert_id
          created_date
          facility_name
          id
          issue
          notes
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFacilityAlertQuery>response.data.getFacilityAlert;
  }
  async GetCustomerAlert(id: string): Promise<GetCustomerAlertQuery> {
    const statement = `query GetCustomerAlert($id: ID!) {
        getCustomerAlert(id: $id) {
          __typename
          account_id
          alert_id
          created_date
          full_name
          id
          issue
          notes
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomerAlertQuery>response.data.getCustomerAlert;
  }
  async GetFinancial(id: string): Promise<GetFinancialQuery> {
    const statement = `query GetFinancial($id: ID!) {
        getFinancial(id: $id) {
          __typename
          credits_earned
          enrollment_fees
          fees_collected
          id
          month
          savings
          subscription_fees
          year
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFinancialQuery>response.data.getFinancial;
  }
  async GetPendingAccounts(
    customer_segment_id?: string
  ): Promise<Array<GetPendingAccountsQuery>> {
    const statement = `query GetPendingAccounts($customer_segment_id: String) {
        getPendingAccounts(customer_segment_id: $customer_segment_id) {
          __typename
          account_id
          business_partner_id
          allocation_id
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customer_segment_id) {
      gqlAPIServiceArguments.customer_segment_id = customer_segment_id;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetPendingAccountsQuery>>response.data.getPendingAccounts;
  }
  async GetProgramAccount(id: string): Promise<GetProgramAccountQuery> {
    const statement = `query GetProgramAccount($id: ID!) {
        getProgramAccount(id: $id) {
          __typename
          cancelled_ytd_count
          enrollment_count
          id
          pending_count
          prgAccountCntsId
          prgAccountCntsType
          wait_list_count
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProgramAccountQuery>response.data.getProgramAccount;
  }
  async GetProgramAccountCapacity(
    id: string
  ): Promise<GetProgramAccountCapacityQuery> {
    const statement = `query GetProgramAccountCapacity($id: ID!) {
        getProgramAccountCapacity(id: $id) {
          __typename
          available_kw
          available_mw
          id
          prgAccountCapId
          prgAccountCapsType
          subscribed_kw
          subscribed_mw
          wait_list_kw
          wait_list_mw
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProgramAccountCapacityQuery>(
      response.data.getProgramAccountCapacity
    );
  }
  async GetRspRandomize(customer_segment_id?: string): Promise<string | null> {
    const statement = `query GetRspRandomize($customer_segment_id: String) {
        getRspRandomize(customer_segment_id: $customer_segment_id)
      }`;
    const gqlAPIServiceArguments: any = {};
    if (customer_segment_id) {
      gqlAPIServiceArguments.customer_segment_id = customer_segment_id;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.getRspRandomize;
  }
  async GetUploadLrgCorpGovAccnts(
    json?: uploadLrgCorpGovAccntsJson
  ): Promise<string | null> {
    const statement = `query GetUploadLrgCorpGovAccnts($json: uploadLrgCorpGovAccntsJson) {
        getUploadLrgCorpGovAccnts(json: $json)
      }`;
    const gqlAPIServiceArguments: any = {};
    if (json) {
      gqlAPIServiceArguments.json = json;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.getUploadLrgCorpGovAccnts;
  }
  async ListMetadataPicklist(
    metadata_picklist: MetadataPicklistCriteriaInput
  ): Promise<ListMetadataPicklistQuery> {
    const statement = `query ListMetadataPicklist($metadata_picklist: MetadataPicklistCriteriaInput!) {
        listMetadataPicklist(metadata_picklist: $metadata_picklist) {
          __typename
          metadata_picklist {
            __typename
            id
            description
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      metadata_picklist
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMetadataPicklistQuery>response.data.listMetadataPicklist;
  }
  async ListAllocationReport(
    input: ReportCriteriaInput
  ): Promise<Array<ListAllocationReportQuery>> {
    const statement = `query ListAllocationReport($input: ReportCriteriaInput!) {
        listAllocationReport(input: $input) {
          __typename
          allocation_id
          main_customer {
            __typename
            full_name
            email
            phone
          }
          res_non_res
          low_income_flag
          installation_id
          billing_period
          account_id
          business_partner_id
          premise_address {
            __typename
            city
            line1
            state
            zip
          }
          allocation_size_kw
          created_at
          pending_date
          active_date
          cancelled_date
          allocation_status_id
          allocation_status_description
          cancel_reason_description
          enrollment_channel_description
          customer_segment_id
          customer_segment_description
          customer_connect_enrollment_code
          customer_connect_product_code
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ListAllocationReportQuery>>response.data.listAllocationReport;
  }
  async ListEnrolledAllocations(
    input: ReportCriteriaInput
  ): Promise<Array<ListEnrolledAllocationsQuery>> {
    const statement = `query ListEnrolledAllocations($input: ReportCriteriaInput!) {
        listEnrolledAllocations(input: $input) {
          __typename
          allocation_id
          main_customer {
            __typename
            full_name
            email
            phone
          }
          account_id
          business_partner_id
          premise_address {
            __typename
            city
            line1
            state
            zip
          }
          allocation_size_kw
          active_date
          enrollment_channel_description
          customer_connect_enrollment_code
          customer_connect_product_code
          eligibility_status
          eligibility_check_date
          ineligibility_reasons
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ListEnrolledAllocationsQuery>>(
      response.data.listEnrolledAllocations
    );
  }
  async ListWaitlistReport(
    input: ReportCriteriaInput
  ): Promise<Array<ListWaitlistReportQuery>> {
    const statement = `query ListWaitlistReport($input: ReportCriteriaInput!) {
        listWaitlistReport(input: $input) {
          __typename
          allocation_id
          wait_list_position
          main_customer {
            __typename
            full_name
            email
            phone
          }
          customer_segment_description
          account_id
          business_partner_id
          premise_address {
            __typename
            city
            line1
            state
            zip
          }
          max_sub_kw
          total_subscription_kw
          allocation_size_kw
          created_at
          eligibility_status
          eligibility_check_date
          ineligibility_reasons
          unfilled_subscription_kw_goal
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ListWaitlistReportQuery>>response.data.listWaitlistReport;
  }
  async ListTransferReport(
    input: ReportCriteriaInput
  ): Promise<Array<ListTransferReportQuery>> {
    const statement = `query ListTransferReport($input: ReportCriteriaInput!) {
        listTransferReport(input: $input) {
          __typename
          main_customer {
            __typename
            full_name
            email
            phone
          }
          premise_address {
            __typename
            city
            line1
            state
            zip
          }
          customer_segment_id
          customer_segment_description
          transfer_list_position
          account_id
          business_partner_id
          installation_id
          operand_key
          move_in_date
          move_out_date
          active_accounts {
            __typename
            account_id
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ListTransferReportQuery>>response.data.listTransferReport;
  }
  async ListEnrollmentAuditReport(
    input: ReportCriteriaSimpleInput
  ): Promise<Array<ListEnrollmentAuditReportQuery>> {
    const statement = `query ListEnrollmentAuditReport($input: ReportCriteriaSimpleInput!) {
        listEnrollmentAuditReport(input: $input) {
          __typename
          enrollment_audit_id
          created_at
          account_id
          allocation_id
          change_summary
          allocation_size_change_kw
          max_sub_kw
          old_account_notes
          new_account_notes
          created_by
          customer_connect_request
          customer_connect_response
          wait_list_run_id
          is_successful
          error_message
          audit_notes
          cancel_reason_description
          main_customer {
            __typename
            full_name
            email
            phone
          }
          premise_address {
            __typename
            city
            line1
            state
            zip
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ListEnrollmentAuditReportQuery>>(
      response.data.listEnrollmentAuditReport
    );
  }
  async GetAccountLookupForEmployee(
    input: AccountLookupForEmployeeInput
  ): Promise<GetAccountLookupForEmployeeQuery> {
    const statement = `query GetAccountLookupForEmployee($input: AccountLookupForEmployeeInput!) {
        getAccountLookupForEmployee(input: $input) {
          __typename
          jurisdiction_code
          account_number
          business_partner_id
          is_residential
          lmi_file_validation {
            __typename
            link
            file_name
            customer_status
            verification_date
          }
          premise_address {
            __typename
            city
            line1
            state
            zip
          }
          billing_address {
            __typename
            city
            line1
            state
            zip
          }
          main_customer {
            __typename
            full_name
            email
            phone
          }
          account_notes
          allocations {
            __typename
            allocation_id
            account_id
            allocation_size_kw
            max_sub_kw
            allocation_status_id
            allocation_status_description
            created_at
            pending_date
            active_date
            cancelled_date
            cancel_reason_id
            enrollment_channel_id
          }
          email_updates_interval
          wait_list_position
          eligibility {
            __typename
            ineligibility_reasons
            is_eligible
            product_code
          }
          max_subscription_size_kw
          current_allocation_size_kw
          available_size_kw
          customer_segment {
            __typename
            customer_segment_id
            customer_segment_description
          }
          low_income {
            __typename
            low_income_flag
            nes_participant
            low_income_verification_status
            low_income_verification_type_id
            low_income_verification_date
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAccountLookupForEmployeeQuery>(
      response.data.getAccountLookupForEmployee
    );
  }
  async ListAccountSearchForEmployee(
    input: AccountSearchForEmployeeInput
  ): Promise<Array<ListAccountSearchForEmployeeQuery>> {
    const statement = `query ListAccountSearchForEmployee($input: AccountSearchForEmployeeInput!) {
        listAccountSearchForEmployee(input: $input) {
          __typename
          account_type
          business_partner_id
          account_number
          premise_address {
            __typename
            city
            line1
            state
            zip
          }
          billing_address {
            __typename
            city
            line1
            state
            zip
          }
          main_customer {
            __typename
            full_name
            email
            phone
          }
          jurisdiction_code
          gov_acc_ind
          account_class
          account_status
          installations {
            __typename
            installation_number
            service_point
            contract
            rate_category
            active_date
            move_out_date
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ListAccountSearchForEmployeeQuery>>(
      response.data.listAccountSearchForEmployee
    );
  }
  async ListCustomerLookup(
    detailed_accounts: CustomerLookupCriteriaInput
  ): Promise<ListCustomerLookupQuery> {
    const statement = `query ListCustomerLookup($detailed_accounts: CustomerLookupCriteriaInput!) {
        listCustomerLookup(detailed_accounts: $detailed_accounts) {
          __typename
          detailed_accounts {
            __typename
            account_number
            jurisdiction_code
            account_notes
            customer_segment {
              __typename
              customer_segment_id
              customer_segment_description
            }
            business_partner_id
            max_subscription_size_kw
            current_allocation_size_kw
            premise_address {
              __typename
              city
              line1
              state
              zip
            }
            billing_address {
              __typename
              city
              line1
              state
              zip
            }
            main_customer {
              __typename
              full_name
              email
              phone
            }
            allocations {
              __typename
              allocation_id
              account_id
              allocation_size_kw
              max_sub_kw
              allocation_status_id
              allocation_status_description
              created_at
              pending_date
              active_date
              cancelled_date
              cancel_reason_id
              enrollment_channel_id
              customer_connect_enrollment_code
            }
            eligibility {
              __typename
              ineligibility_reasons
              is_eligible
              product_code
            }
            low_income {
              __typename
              low_income_flag
              nes_participant
              low_income_verification_status
              low_income_verification_type_id
              low_income_verification_date
            }
            lmi_file_validation {
              __typename
              link
              file_name
              customer_status
              verification_date
            }
            is_residential
            email_updates_interval
            wait_list_position
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      detailed_accounts
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomerLookupQuery>response.data.listCustomerLookup;
  }
  async ListCustomerAlert(
    filter?: ModelCustomerAlertFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomerAlertQuery> {
    const statement = `query ListCustomerAlert($filter: ModelCustomerAlertFilterInput, $limit: Int, $nextToken: String) {
        listCustomerAlert(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            account_id
            alert_id
            created_date
            full_name
            id
            issue
            notes
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomerAlertQuery>response.data.listCustomerAlert;
  }
  async ListCustomerDetail(
    filter?: ModelCustomerDetailFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomerDetailQuery> {
    const statement = `query ListCustomerDetail($filter: ModelCustomerDetailFilterInput, $limit: Int, $nextToken: String) {
        listCustomerDetail(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          detailed_accounts {
            __typename
            account_number
            jurisdiction_code
            account_notes
            customer_segment {
              __typename
              customer_segment_id
              customer_segment_description
            }
            business_partner_id
            max_subscription_size_kw
            current_allocation_size_kw
            premise_address {
              __typename
              city
              line1
              state
              zip
            }
            billing_address {
              __typename
              city
              line1
              state
              zip
            }
            main_customer {
              __typename
              full_name
              email
              phone
            }
            allocations {
              __typename
              allocation_id
              account_id
              allocation_size_kw
              max_sub_kw
              allocation_status_id
              allocation_status_description
              created_at
              pending_date
              active_date
              cancelled_date
              cancel_reason_id
              enrollment_channel_id
              customer_connect_enrollment_code
            }
            eligibility {
              __typename
              ineligibility_reasons
              is_eligible
              product_code
            }
            low_income {
              __typename
              low_income_flag
              nes_participant
              low_income_verification_status
              low_income_verification_type_id
              low_income_verification_date
            }
            lmi_file_validation {
              __typename
              link
              file_name
              customer_status
              verification_date
            }
            is_residential
            email_updates_interval
            wait_list_position
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomerDetailQuery>response.data.listCustomerDetail;
  }
  async ListCustomerReport(
    filter?: ModelCustomerReportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCustomerReportQuery> {
    const statement = `query ListCustomerReport($filter: ModelCustomerReportFilterInput, $limit: Int, $nextToken: String) {
        listCustomerReport(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            account_id
            account_type
            active_allocation_size_kw
            bill_cycle
            business_partner_id
            id
            jurisdiction_name
            main_customer {
              __typename
              full_name
              email
              phone
            }
            max_subscription_size_kw
            premise_address {
              __typename
              city
              line1
              state
              zip
            }
            state_code
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCustomerReportQuery>response.data.listCustomerReport;
  }
  async ListEnrollmentAlert(
    filter?: ModelEnrollmentAlertFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEnrollmentAlertQuery> {
    const statement = `query ListEnrollmentAlert($filter: ModelEnrollmentAlertFilterInput, $limit: Int, $nextToken: String) {
        listEnrollmentAlert(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            account_id
            created_date
            enrollment_audit_id
            full_name
            id
            notes
            status_change
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEnrollmentAlertQuery>response.data.listEnrollmentAlert;
  }
  async ListFacilityAlert(
    filter?: ModelFacilityAlertFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFacilityAlertQuery> {
    const statement = `query ListFacilityAlert($filter: ModelFacilityAlertFilterInput, $limit: Int, $nextToken: String) {
        listFacilityAlert(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            alert_id
            created_date
            facility_name
            id
            issue
            notes
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFacilityAlertQuery>response.data.listFacilityAlert;
  }
  async ListFacilityReport(
    filter?: ModelFacilityReportFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListFacilityReportQuery> {
    const statement = `query ListFacilityReport($filter: ModelFacilityReportFilterInput, $limit: Int, $nextToken: String) {
        listFacilityReport(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            address_line1
            city
            commercial_operating_date
            facility_id
            facility_installation_number
            facility_name
            id
            jurisdiction_name
            one_month_status
            salesforce_id
            state_code
            total_capacity_kw
            total_capacity_unit
            twelve_month_status
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListFacilityReportQuery>response.data.listFacilityReport;
  }
  async GetCustomerSegmentById(
    input: GetCustomerSegmentByIdInput
  ): Promise<GetCustomerSegmentByIdQuery> {
    const statement = `query GetCustomerSegmentById($input: GetCustomerSegmentByIdInput!) {
        getCustomerSegmentById(input: $input) {
          __typename
          customer_segment_id
          customer_segment_description
          is_in_rsp_mode
          customer_segment_alert_message
          customer_segment_alert_expiry_date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCustomerSegmentByIdQuery>response.data.getCustomerSegmentById;
  }
  async ListCustomerSegments(): Promise<Array<ListCustomerSegmentsQuery>> {
    const statement = `query ListCustomerSegments {
        listCustomerSegments {
          __typename
          customer_segment_id
          customer_segment_description
          is_in_rsp_mode
          customer_segment_alert_message
          customer_segment_alert_expiry_date
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ListCustomerSegmentsQuery>>response.data.listCustomerSegments;
  }
  async ListWaitListRuns(
    input: WaitListRunsInput
  ): Promise<Array<ListWaitListRunsQuery>> {
    const statement = `query ListWaitListRuns($input: WaitListRunsInput!) {
        listWaitListRuns(input: $input) {
          __typename
          num_items_at_start
          error_message
          is_successful
          customer_segment_id
          created_by
          start_time
          end_time
          num_items_at_end
          wait_list_run_id
          enrollment_audits {
            __typename
            is_successful
            new_allocation_size_kw
            account_id
            customer_connect_request
            customer_connect_response
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ListWaitListRunsQuery>>response.data.listWaitListRuns;
  }
  async ListTransferListRuns(
    input: TransferListRunsInput
  ): Promise<Array<ListTransferListRunsQuery>> {
    const statement = `query ListTransferListRuns($input: TransferListRunsInput!) {
        listTransferListRuns(input: $input) {
          __typename
          num_items_at_start
          error_message
          is_successful
          customer_segment_id
          created_by
          start_time
          end_time
          num_items_at_end
          transfer_list_run_id
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ListTransferListRunsQuery>>response.data.listTransferListRuns;
  }
  async GetWaitListSummary(
    input: GetCustomerSegmentByIdInput
  ): Promise<GetWaitListSummaryQuery> {
    const statement = `query GetWaitListSummary($input: GetCustomerSegmentByIdInput!) {
        getWaitListSummary(input: $input) {
          __typename
          wait_list_current_counts {
            __typename
            eligible
            ineligible
            total
            totalkw
            eligiblekw
            ineligiblekw
          }
          segment_current_capacity {
            __typename
            segment_taken_capacity_kw
            segment_available_capacity_kw
            segment_total_capacity_kw
          }
          segment_percentage {
            __typename
            segment_capacity_percentage
            facilities_total_capacity_kw
            segment_total_capacity_kw
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetWaitListSummaryQuery>response.data.getWaitListSummary;
  }
  async ListUsersReport(): Promise<Array<ListUsersReportQuery>> {
    const statement = `query ListUsersReport {
        listUsersReport {
          __typename
          user_id
          account_id_view_as
          user_role_id
          user_role_description
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ListUsersReportQuery>>response.data.listUsersReport;
  }
  async ListEnrollmentAlerts(): Promise<Array<ListEnrollmentAlertsQuery>> {
    const statement = `query ListEnrollmentAlerts {
        listEnrollmentAlerts {
          __typename
          account_id
          created_date
          enrollment_audit_id
          full_name
          id
          notes
          status_change
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ListEnrollmentAlertsQuery>>response.data.listEnrollmentAlerts;
  }
  async GetLoginKillSwitch(): Promise<GetLoginKillSwitchQuery> {
    const statement = `query GetLoginKillSwitch {
        getLoginKillSwitch {
          __typename
          show_login_boxes
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <GetLoginKillSwitchQuery>response.data.getLoginKillSwitch;
  }
  async GetLoginKillSwitchMetadata(): Promise<
    Array<GetLoginKillSwitchMetadataQuery>
  > {
    const statement = `query GetLoginKillSwitchMetadata {
        getLoginKillSwitchMetadata {
          __typename
          value
          login_kill_switch_audit_id
          created_by
          created_at
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<GetLoginKillSwitchMetadataQuery>>(
      response.data.getLoginKillSwitchMetadata
    );
  }
  async ListLmiDocumentAuditReport(): Promise<
    Array<ListLmiDocumentAuditReportQuery>
  > {
    const statement = `query ListLmiDocumentAuditReport {
        listLmiDocumentAuditReport {
          __typename
          business_partner_id
          file_name
          customer_status
          document_type
          first_name
          last_name
          anti_virus_scan_phase
          lmi_verification_phase
          redaction_phase
          verification_date
          response
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <Array<ListLmiDocumentAuditReportQuery>>(
      response.data.listLmiDocumentAuditReport
    );
  }
  async ListLmiDocumentAuditReportDetailsByBusinessPartnerId(
    input: BusinessPartnerIdInput
  ): Promise<Array<ListLmiDocumentAuditReportDetailsByBusinessPartnerIdQuery>> {
    const statement = `query ListLmiDocumentAuditReportDetailsByBusinessPartnerId($input: BusinessPartnerIdInput!) {
        listLmiDocumentAuditReportDetailsByBusinessPartnerId(input: $input) {
          __typename
          account_id
          account_notes
          enrollment_audits {
            __typename
            created_by
            created_at
            old_allocation_status_id
            old_allocation_status_description
            new_allocation_status_id
            new_allocation_status_description
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<ListLmiDocumentAuditReportDetailsByBusinessPartnerIdQuery>>(
      response.data.listLmiDocumentAuditReportDetailsByBusinessPartnerId
    );
  }
  async GetHistoricalBilling(
    input: HistoricalBillingInput
  ): Promise<Array<GetHistoricalBillingQuery>> {
    const statement = `query GetHistoricalBilling($input: HistoricalBillingInput!) {
        getHistoricalBilling(input: $input) {
          __typename
          account_id
          bill_date
          bill_month
          bill_year
          billed_usage
          billing_amount
          cec_credit
          cec_credit_kwh
          cec_fee
          cec_fee_kwh
          installation_id
          offset
          partition
          timestamp
          topic
          upsert_date
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetHistoricalBillingQuery>>response.data.getHistoricalBilling;
  }
}
