import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from 'src/app/services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountCalculatorCriteriaInput, APIServiceCodegen } from 'src/app/API.service';
import { BehaviorSubject, from, of } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';

class InputData {
  account_id: number;
  subscription_size_kw: number;
  customer_segment_id: string;
}

@Component({
  selector: 'app-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.scss']
})
export class DetailsDialogComponent implements OnInit {
  detailsForm: FormGroup = null;
  monthlySubscriptionFee = 0.00;
  subscriptionSize = 0;
  monthlyBillAmount = 150;
  avgMonthlyNetSavingsFirstYear = 0;
  avgMonthlyNetSavingsFifthYear = 0;
  avgMonthlyNetSavingsTenthYear = 0;
  monthlyCreditFirstYear = 0;
  monthlyCreditFifthYear = 0;
  monthlyCreditTenthYear = 0;
  isInRSP = false;
  minSubscriptionSize = 0;
  maxSubscriptionSize = 1500;
  disabledSlider$: BehaviorSubject<boolean>;


  constructor(
    private detailsDialogRef: MatDialogRef<DetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData,
    private dataSvc: DataService,
    public api: APIServiceCodegen,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.detailsDialogRef.disableClose = true;
    this.subscriptionSize = data.subscription_size_kw;
    this.setForm();
    this.calculateValues();
    this.detailsForm.get('subscriptionSize').valueChanges.pipe(debounceTime(1000)).subscribe(subSize => {
      this.subscriptionSize = subSize;
      this.calculateValues();
    })
  }

  ngOnInit() {

  }

  closeModal() {
    this.detailsDialogRef.close();
  }

  onSliderChange(kw: number) {
    setTimeout(() => {
      this.detailsForm.patchValue({
          subscriptionSize: kw,
      });
      this.subscriptionSize = kw;
      this.calculateValues();
  }, 100)

  }

  calculateValues() {
    from(this.api.GetAccountCalculations(<AccountCalculatorCriteriaInput>{
      subscription_size_kw: this.detailsForm.get('subscriptionSize').value,
      customer_segment_id: this.data.customer_segment_id + ""
    })).pipe(catchError(error =>  {
      error.errors.forEach(iError => {
          this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
      })
      return of(error)
    }))
      .subscribe(res => {
        this.avgMonthlyNetSavingsFirstYear = res.avg_monthly_net_savings_first_year;
        this.avgMonthlyNetSavingsFifthYear = res.avg_monthly_net_savings_fifth_year;
        this.avgMonthlyNetSavingsTenthYear = res.avg_monthly_net_savings_tenth_year;
        this.monthlySubscriptionFee = res.monthly_subscription_fee;
        this.monthlyCreditFirstYear = res.avg_monthly_credit_first_year,
          this.monthlyCreditFifthYear = res.avg_monthly_credit_fifth_year,
          this.monthlyCreditTenthYear = res.avg_monthly_credit_tenth_year,
          this.isInRSP = res.is_in_rsp_mode
      });
  }

  getTextColor(number: string) {
    if ((number + "").includes('-')) {
      return 'red-text';
    }
    return 'blue-text'
  }

  setForm() {
    this.detailsForm = new FormGroup({
      subscriptionSize: new FormControl(this.data.subscription_size_kw, [Validators.required, Validators.min(1)])
    });
  }

  onSave() {
    this.dataSvc.setSubscriptionSize(this.detailsForm.get('subscriptionSize').value);
    this.closeModal();
  }
}
