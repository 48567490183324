import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, DataService } from 'src/app/services';
import { IneligibilityReasonsDialogComponent } from '../ineligibility-reasons-dialog/ineligibility-reasons-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { AccountLookupForEmployee, AccountLookupForEmployeeAllocation } from 'src/app/API.service';
import { AllocationStatus, Permissions } from '../models/admin.model';

@Component({
    selector: 'app-account-panel',
    templateUrl: './account-panel.component.html',
    styleUrls: ['./account-panel.component.scss']
})
export class AccountPanelComponent implements OnInit {
    @Input() accountDetail: AccountLookupForEmployee = null;
    @Input() index: number;
    @Input() closePanel: EventEmitter<any> = new EventEmitter();
    @Input() prevPage = '';
    @Output() panelOpened = new EventEmitter();
    public ineligibilityReasonsDialogRef: MatDialogRef<IneligibilityReasonsDialogComponent>;
    public isExpanded: boolean = true;
    public currentAllocationSizeNumber: number;
    public isButtonLoading = false;
    public hasAllocationNewPerms = false;
    public hasAllocationEditPerms = false;
    public hasAllocationViewPerms = false;
    public hasAllocationCancelPerms = false;
    public get allocationStatus(): typeof AllocationStatus {
        return AllocationStatus; 
      }

    constructor(public apisvc: ApiService,
        public router: Router,
        public datasvc: DataService,
        public dialog: MatDialog) {
            
         }

    ngOnInit() {
        let permList = this.datasvc.getPermissions();
        if (permList.find(num => num == Permissions.AllocationNew)) {
            this.hasAllocationNewPerms = true;
        }
        if (permList.find(num => num == Permissions.AllocationEdit)) {
            this.hasAllocationEditPerms = true;
        }
        if (permList.find(num => num == Permissions.AllocationView)) {
            this.hasAllocationViewPerms = true;
        }
        if (permList.find(num => num == Permissions.AllocationCancel)) {
            this.hasAllocationCancelPerms = true;
        }
        this.accountDetail.max_subscription_size_kw = this.accountDetail.max_subscription_size_kw;
        this.currentAllocationSizeNumber = +this.accountDetail.current_allocation_size_kw;
    }

    canMakeNewAllocation(account) {
        return account.allocations.find(allo => allo.allocation_status_id == 1
        || allo.allocation_status_id == 2 || allo.allocation_status_id == 3
        || allo.allocation_status_id == 4 || allo.allocation_status_id == 5) == null;
      }


    showIneligibilityReasons() {
        this.ineligibilityReasonsDialogRef = this.dialog.open(IneligibilityReasonsDialogComponent, {
            id: 'ineligibilityReasonsDialog',
            minWidth: '60vw',
            data: {
                account_details: this.accountDetail
            }
        });
    }
    
    dateToShow(allocation: AccountLookupForEmployeeAllocation) {
        const dateToReturn = allocation.cancelled_date !== null ? 
            allocation.cancelled_date : allocation.active_date !== null ?
            allocation.active_date : allocation.pending_date !== null ?
            allocation.pending_date : allocation.created_at
        return dateToReturn
    }

    onNavigateTo(index, mode) {
        let extras = {};
        extras = {Mode: mode};
        if (this.prevPage) {
            extras = { Mode: mode, ReturnAdd: this.prevPage};
        }
        this.router.navigate(['admin/subscription', extras], {
            state: {
                accountDetails: this.accountDetail,
                index: index
            }
        });
    }

    onNewSubscription() {
        this.isButtonLoading = true;
        this.onNavigateTo(null, 'New');
    }

}
