import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './components/admin/admin.component';
import { CustomerLookupComponent } from './components/customer-lookup/customer-lookup.component';
import { CustomerDetailComponent } from './components/customer-detail/customer-detail.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { FacilityManagementReportComponent } from './components/facility-management/facility-management-report.component';
import { AllocationReportComponent } from './components/allocation-report/allocation-report.component';
import { SolarFacilityComponent } from './components/solar-facility/solar-facility.component';
import { ProgramReportComponent } from './components/program-report/program-report.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { ProgramDetailComponent } from './components/program-detail/program-detail.component';
import { WaitListComponent } from './components/wait-list/wait-list.component';
import { TransferListComponent } from './components/transfer-list/transfer-list.component';
import { ViewFacilityComponent } from './components/view-facility/view-facility.component';
import { FinancialReportComponent } from './components/financial-report/financial-report.component';
import { UserAdminSettingComponent } from './components/user-admin-setting/user-admin-setting.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { FacilityAlertsReportComponent } from './components/facility-alerts-report/facility-alerts-report.component';
import { EnrollmentAlertsReportComponent } from './components/enrollment-alerts-report/enrollment-alerts-report.component';
import { CustomerAlertsReportComponent } from './components/customer-alerts-report/customer-alerts-report.component';
import { AlertMainPageComponent } from './components/alert-main-page/alert-main-page.component';
import { RSPControlsComponent } from './components/rsp-controls/rsp-controls.component';
import { LoginTurnOffComponent } from './components/login-turn-off/login-turn-off.component';
import { EnrollmentAuditReportComponent } from './components/enrollment-audit-report/enrollment-audit-report.component';
import { EnrolledAllocationsReportComponent } from './components/enrolled-allocations-report/enrolled-allocations-report.component';

const routes: Routes = [
  { path: '', redirectTo: '/admin/admin-dashboard', pathMatch: 'full' },
  { path: 'unauthorized', component: UnauthorizedComponent},
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: 'financial-report',
        component: FinancialReportComponent
      },
      {
        path: 'admin-settings',
        component: UserAdminSettingComponent
      },
      {
        path: 'rsp-controls',
        component: RSPControlsComponent
      },
      {
        path: 'wait-list',
        component: WaitListComponent
      },
      {
        path: 'transfer-list',
        component: TransferListComponent
      },
      {
        path: 'allocation-report',
        component: AllocationReportComponent
      },
      {
        path: 'enrolled-allocations-report',
        component: EnrolledAllocationsReportComponent
      },
      {
        path: 'enrollment-audit-report',
        component: EnrollmentAuditReportComponent
      },
      {
        path: 'alert-main',
        component: AlertMainPageComponent
      },
      {
        path: 'program-report',
        component: ProgramReportComponent
      },
      {
        path: 'program-detail/:id',
        component: ProgramDetailComponent
      },
      {
        path: 'customer-lookup',
        component: CustomerLookupComponent
      },
      {
        path: 'customer-detail',
        component: CustomerDetailComponent
      },
      {
        path: 'subscription',
        component: SubscriptionComponent
      },
      {
        path: 'facility-management',
        component: FacilityManagementReportComponent
      },
        {path: 'facility-setup', redirectTo: 'facility-setup/', pathMatch: 'full'},
       {
        path: 'facility-setup/:id',
        component: SolarFacilityComponent
      },
      {
        path: 'view-facility/:id',
        component: ViewFacilityComponent
      },
      {
        path: 'facility-alert-report',
        component: FacilityAlertsReportComponent
      },
      {
        path: 'enrollment-alert-report',
        component: EnrollmentAlertsReportComponent
      },
      {
        path: 'customer-alert-report',
        component: CustomerAlertsReportComponent
      },
      {
        path: 'admin-dashboard',
        component: AdminDashboardComponent
      },
      {
        path: 'login-kill-switch',
        component: LoginTurnOffComponent
      }]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { 
    useHash: false, 
    relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
