<form [formGroup]="dateRowForm" novalidate class="row formGroup">
    <div class="col-1"></div>
    <div class="col-2">
        <mat-form-field class="filter" floatLabel="never">
            <input matInput [matDatepicker]="feeDate" formControlName="fee_date">
            <mat-datepicker-toggle matSuffix [for]="feeDate"></mat-datepicker-toggle>
            <mat-datepicker #feeDate></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-2"></div>
    <div class="col-2">
        $            
        <mat-form-field class="filter col-5" floatLabel="never">
            <input matInput class="" formControlName="residential_fee">
        </mat-form-field>/kW
    </div>
    <div class="col-2"></div>
    <div class="col-2">            
        $            
        <mat-form-field class="filter col-5" floatLabel="never">
            <input matInput class="" formControlName="non_residential_fee">
        </mat-form-field>/kW
    </div>
    <div class="col-1"></div>
</form>