import { Component, OnInit } from '@angular/core';
import {DropDownSelection, AccountPerms, UpdateUsersRequest, UserRole, UserRoleModel, Permissions } from '../models/admin.model';
import { DataService } from 'src/app/services';
import { APIServiceCodegen, UpdateAdminUserInput, UsersReport } from 'src/app/API.service';
import { MatTableDataSource, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * @title Basic use of `<table mat-table>`
 */
@Component({
    selector: 'app-user-admin-setting',
    styleUrls: ['./user-admin-setting.component.scss'],
    templateUrl: './user-admin-setting.component.html'
})
export class UserAdminSettingComponent implements OnInit {
    constructor(
        public apiService: APIServiceCodegen,
        public router: Router,
        public datasvc: DataService,
        public snackBar: MatSnackBar) {
    }

    displayedColumns: string[] = ['userid', 'currentrole', 'role'];

    dataSource: MatTableDataSource<UsersReport>;
    possibleRoles: DropDownSelection[];
    searchForm: FormGroup;
    roleSelectForm: FormGroup[] = [];
    roles = UserRole;
    hasUserAdminSettingsPerms = false;

    ngOnInit() {
        let permList = this.datasvc.getPermissions();
        if (permList.find(num => num == Permissions.UserAdminSettings)) {
            this.hasUserAdminSettingsPerms = true;
        } else {
          return;
        }
        setTimeout(() => {
            this.datasvc.setHeaderTitle('User Admin Settings');
          });
        this.dataSource = new MatTableDataSource(null);
        this.setupSearchForm();
        this.searchForm.get('searchFilter').valueChanges.subscribe(x => {
            this.dataSource.filter = x;
        });
        this.setRoles();
        this.getData();
        // if (!(this.datasvc.getAccountPermType() === AccountPerms.FullAccess)) {
        //     this.roleSelectForm.forEach(x => {
        //         x.controls['roleSelection'].disable();
        //     });
        // }
    }

    setupSearchForm() {
        this.searchForm  = new FormGroup({
            searchFilter: new FormControl('')
          });
    }

    setRoles() {
        let roles = [{'user_role_id': 1, 'user_role_description': 'General User'},
        {'user_role_id': 2, 'user_role_description': 'Admin'},
        {'user_role_id': 3, 'user_role_description': 'Elevated Admin'},
        {'user_role_id': 4, 'user_role_description': 'Login Kill Switch'}]
        let roles_data = this.mapUserRoleDropDown(roles);
        this.possibleRoles = roles_data;
    }

    addNewRoleForm() {
        this.roleSelectForm.push(
            new FormGroup({
                roleSelection: new FormControl('')
            })
        );
    }

    mapUserRoleDropDown(user_role: UserRoleModel[]) {
        return user_role.map(role => <DropDownSelection>{
            name: role.user_role_description,
            value: role.user_role_id
        });
    }

    getData() {
        this.dataSource = new MatTableDataSource(null);
        from(this.apiService.ListUsersReport()).pipe(catchError(error => {
            error.errors.forEach(iError => {
                this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
            })
            return of(error)
          })).subscribe(resp => {
            this.dataSource.filterPredicate = this.createSearchFilter();
            var row_index = 0;
            resp.forEach(x => {
                x.index = row_index
                this.addNewRoleForm()
                this.patchValues(x, row_index);
                row_index++;
            });
            this.dataSource = new MatTableDataSource(resp);
        })
    }

    patchValues(userSetting: UsersReport, row_index) {
        this.roleSelectForm[row_index].patchValue({
            roleSelection: userSetting.user_role_id
         }, row_index);
    }

    onSave() {
        const users = this.dataSource.data;
        let row_index = 0
        users.forEach(user => {
            const userRequest = {
                user_id: user.user_id, 
                account_id_view_as: null, 
                user_role_id: this.roleSelectForm[row_index].get('roleSelection').value
            };
            from(this.apiService.UpdateAdminUser(<UpdateAdminUserInput>userRequest))
            .pipe(catchError(error => {
                error.errors.forEach(iError => {
                    this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
                })
                return of(error)
              })).subscribe(() => {
                this.getData();
            })
            row_index++;
        });
    }

    onCancel() {
        this.getData();
    }

    createSearchFilter(): (data: any, filter: string) => boolean {
        const filterFunction = function (data: UsersReport, filter: string): boolean {
            return (filter === '' || data.user_id.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
        };
        return filterFunction;
    }
}
