import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AccountDetailModel } from '../models/customer-detail.model';

class InputData {
  errors: string[];
}

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  public static API_GENERIC_ERROR_500_MSG = 'Error code: 500. There has been a system error!!';

  constructor(
    private errorDialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData) {
    this.errorDialogRef.disableClose = true;
    
  }

  ngOnInit() {
  }

  // Close modal window...
  closeModal() {
    this.errorDialogRef.close();
  }
}
