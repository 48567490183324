<span class="close cursor-pointer" (click)="closeModal()">&times;</span>
<div class="row to-center">
    <h4>Accounts To Be Proccessed to Enrolled</h4>
</div>
<br>
<br>
<div class="container">
    <div class="row pb-2">
        <div class="col-3 line-items"><b>Status</b></div>
        <div class="col-3 line-items"><b>Account Id</b></div>
        <div class="col-3 line-items"><b>Allocation Id</b></div>
        <div class="col-3 line-items"><b>Business Partner Id</b></div>
    </div>
    <div class="overflow-scrolly pl-3 pt-3 pb-3">
        <div class="row justify-content-between" *ngFor="let account of listOfPendingAccounts">
            <div class="col-3 line-items">{{account.status}}</div>
            <div class="col-3 line-items">{{account.account_id}}</div>
            <div class="col-3 line-items">{{account.allocation_id}}</div>
            <div class="col-3 line-items">{{account.business_partner_id}}</div>
            <div class="col-11  div-underline"></div>
        </div>
    </div>
</div>
<div class="float-right row">
    <de-button (click)="closeModal()" type="secondary">CANCEL</de-button>
    <de-button (click)="onRunEnrollment()" type="primary">Enroll Accounts</de-button>
</div>