import { Component, OnInit, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import {  Allocation } from '../models/customer-report.model';
import { DataService, ApiService } from 'src/app/services';
import { Permissions } from '../models/admin.model';

@Component({
  selector: 'app-alert-main-page',
  templateUrl: './alert-main-page.component.html',
  styleUrls: ['./alert-main-page.component.scss']
})
export class AlertMainPageComponent implements OnInit {
  public selectedProgramId: number;
  // public selectedJurisdictionStateId: number;
  // public selectedJurisdictionId: number;
  public dataSource = new MatTableDataSource<any>(null);
  public exportData = new EventEmitter();
  //public jurisdictionResponse: JurisdictionResponse = null;
  public facilityDataLoaded: EventEmitter<any> = new EventEmitter();
  hasAllAlertsPerms = false;

  constructor(public datasvc: DataService,
    public apisvc: ApiService) { }

  ngOnInit() {
    let permList = this.datasvc.getPermissions();
    if (permList.find(num => num == Permissions.AllAlerts)) {
        this.hasAllAlertsPerms = true;
    }

    setTimeout(() => {
      this.datasvc.setHeaderTitle('Alerts');
    });
  }

  dataRetrieved($event) {
    this.facilityDataLoaded.emit($event);
}

  // filtersSelected(event) {
  //   //this.jurisdictionResponse = event.jurisdictionResponse;
  //   // this.selectedJurisdictionStateId = event.state_id;
  //   // this.selectedJurisdictionId = event.jurisdiction_id;
  //   this.selectedProgramId = event.program_id;
  // }


  exportExcel() {
    this.exportData.emit('');
  }

}
