<div class="mat-table-format">
    <de-loader *ngIf="gettingFacilityAlertData" [showOverlay]="showOverlay"></de-loader>
    <table *ngIf="!gettingFacilityAlertData" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="facilityName">
        <th mat-header-cell *matHeaderCellDef>
          <div>Facility Name</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.facility_name}} </td>
      </ng-container>
      <ng-container matColumnDef="issue">
        <th mat-header-cell *matHeaderCellDef>
          <div>Issue</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.issue}} </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>
          <div>Date</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.created_date |date}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onShowDialog(row.alert_id)" class="cursor-pointer"></tr>
    </table>
  </div>
  <div class="bottomOfTable background-white cursor-pointer"  (click)="viewMore()"><a>View More</a></div>