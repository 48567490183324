<div class="container dashboard-card">
    <br>
    <div class="row title">
        &nbsp;&nbsp;
        <span class="yellow-text">
            <i class="fa fa-2x fa-exclamation-triangle"></i>
        </span>
       &nbsp; Recent Alerts
    </div>

    <div class="row underlined pt-2">
       <div class="col-6"><b>Item</b></div>
       <div class="col-4"><b>Date</b></div>
       <div class="col-2"><b>Note</b></div>
    </div>
    <div class="row underlined pt-2 pb-2 cursor-pointer" (click)="onShowDialog(alert.alert_id)" *ngFor="let alert of alerts; let index = index;">
        <div class="col-6">{{alert.issue}}</div>
        <div class="col-4">{{alert.created_date| date}}</div>
        <div class="col-2"><i class="fas fa-comment-alt"></i></div>
     </div>
    <br>
</div>