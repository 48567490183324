import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { DataService, ApiService } from 'src/app/services';
import { Permissions } from '../models/admin.model';
//import { JurisdictionResponse } from '../models/customer-report.model';

import { APIServiceCodegen, ConfigurableCustomerSegment, CustomerSegmentEditInput } from 'src/app/API.service';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-customer-alerts-report',
    templateUrl: './customer-alerts-report.component.html',
    styleUrls: ['./customer-alerts-report.component.scss']
})

export class CustomerAlertsReportComponent implements OnInit {

    public selectedFacility = '';
    // public selectedJurisdictionStateId: number;
    // public selectedJurisdictionId: number;
    // public selectedProgramId: number;
    public dataSource = new MatTableDataSource<any>(null);
    public exportData = new EventEmitter();
    //public jurisdictionResponse: JurisdictionResponse = null;
    public customerDataLoaded: EventEmitter<any> = new EventEmitter();
    hasCustomerAlertsPerms = false;

    configurableCustomerSegments = []
    public customerSegmentTypes: ConfigurableCustomerSegment[] | null;

    custSeg1Form: FormGroup = new FormGroup({
        custSegMsg1: new FormControl('', Validators.minLength(2)),
        custSegDays1: new FormControl('', Validators.minLength(1)),
        custSegHours1: new FormControl('', Validators.minLength(1)),
        custSegMinutes1: new FormControl('', Validators.minLength(1)),
    });

    custSeg2Form: FormGroup = new FormGroup({
        custSegMsg2: new FormControl('', Validators.minLength(2)),
        custSegDays2: new FormControl('', Validators.minLength(1)),
        custSegHours2: new FormControl('', Validators.minLength(1)),
        custSegMinutes2: new FormControl('', Validators.minLength(1)),
    });

    public customerSegment1;
    public customerSegment2;

    constructor(public datasvc: DataService,
        public apiService: APIServiceCodegen,
        public snackBar: MatSnackBar) { }

    ngOnInit() {
        let permList = this.datasvc.getPermissions();
        if (permList.find(num => num == Permissions.CustomerAlerts)) {
            this.hasCustomerAlertsPerms = true;
        }
        setTimeout(() => {
            this.datasvc.setHeaderTitle('Customer Alerts');
          });

        this.customerSegment1 = this.getCustomerSegments('1');

        setTimeout(() => {
            this.customerSegment2 = this.getCustomerSegments('2');
        }, 1000);
        
    }

    dataRetrieved($event) {
        this.customerDataLoaded.emit($event);
    }

    // filtersSelected(event) {
    //     this.jurisdictionResponse = event.jurisdictionResponse;
    //     this.selectedJurisdictionStateId = event.state_id;
    //     this.selectedJurisdictionId = event.jurisdiction_id;
    //     this.selectedFacility = event.facility_name;
    //     this.selectedProgramId = event.program_id;
    // }

    exportExcel() {
        this.exportData.emit('');
    }

    // this.custSeg1Form.get('custSegMsg1').value   String(CustomerSegments.LowIncome)ResidentialSMB
    editCustomerPortalMessageSegment1() {

        console.log(this.custSeg1Form.get('custSegMsg1').value)

        from(this.apiService.EditCustomerSegment(<CustomerSegmentEditInput>{
            customer_segment_id: this.customerSegment1[0].customer_segment_id,
            is_in_rsp_mode: this.customerSegment1[0].is_in_rsp_mode,
            customer_segment_alert_message: this.custSeg1Form.get('custSegMsg1').value,
            customer_segment_alert_expiry_date: (this.calculateExpiryDate(this.custSeg1Form.get('custSegDays1').value, this.custSeg1Form.get('custSegHours1').value,
                                                                         this.custSeg1Form.get('custSegMinutes1').value)).toString() 
        })).pipe(catchError(error => {
            error.errors.forEach(iError => {
                this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
            })
            return of(error)
            })).subscribe(resp => {
            
            alert('Your IQ Alert has been broadcasted.')
            // this.configurableCustomerSegments[index] = resp
        })
    }

    editCustomerPortalMessageSegment2() {

        console.log(this.custSeg2Form.get('custSegMsg2').value)

        from(this.apiService.EditCustomerSegment(<CustomerSegmentEditInput>{
            customer_segment_id: this.customerSegment2[1].customer_segment_id,
            is_in_rsp_mode: this.customerSegment2[1].is_in_rsp_mode,
            customer_segment_alert_message: this.custSeg2Form.get('custSegMsg2').value,
            customer_segment_alert_expiry_date: (this.calculateExpiryDate(this.custSeg2Form.get('custSegDays2').value, this.custSeg2Form.get('custSegHours2').value,
                                                                         this.custSeg2Form.get('custSegMinutes2').value)).toString()
        })).pipe(catchError(error => {
            error.errors.forEach(iError => {
                this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
            })
            return of(error)
            })).subscribe(resp => {

            alert('Your MR Alert has been broadcasted.')
            // this.configurableCustomerSegments[index] = resp
        })
    }


    getCustomerSegments(custSegment) {
        
        from(
          this.apiService.ListCustomerSegments()
        ).pipe(catchError(error => {
          error.errors.forEach(iError => {
            this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
          })
          return of(error)
        })).subscribe((resp: any) => {
          this.customerSegmentTypes = <ConfigurableCustomerSegment[]>resp;
          // console.log(this.customerSegmentTypes)
          const customerSegment = this.customerSegmentTypes.filter((custSeg) => {
                return custSeg.customer_segment_id == custSegment
          })

          this.configurableCustomerSegments.push(customerSegment[0])
          console.log(this.configurableCustomerSegments)
        })

        return this.configurableCustomerSegments;

    }

    calculateExpiryDate(days, hours, minutes) {

        const currentDate = new Date();
      
        // Calculate the milliseconds to add
        const millisecondsToAdd = days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000;
      
        // Create a new Date by adding the calculated milliseconds to the current date
        const newDate = new Date(currentDate.getTime() + millisecondsToAdd);
        console.log(newDate);

        return newDate;
    }

}








