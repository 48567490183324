import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { RSPEnrollmentDialogComponent } from '../rsp-enrollment-dialog/rsp-enrollment-dialog.component';
import { APIServiceCodegen, uploadLrgCorpGovAccntsJson, PendingAccountsInput, CustomerSegmentEditInput } from 'src/app/API.service';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * @title Basic use of `<table mat-table>`
 */

class CSVRecord {
    public customer_segment_id: any;
    public account_id: any;
    public business_partner_id: any;
    public subscription_kw: any;
}

class AllocationRSPResponseToExcel {
    public account_id: any;
    public allocation_id: any;
    public allocation_status_id: any;
    public allocation_size_kw: any;
    public enrollment_channel_id: any;
    public pending_date: any;
    public business_partner_id: any;
    public account_notes: any;
}

@Component({
    selector: 'app-rsp-controls',
    styleUrls: ['./rsp-controls.component.scss'],
    templateUrl: './rsp-controls.component.html'
})
export class RSPControlsComponent implements OnInit {
    @ViewChild('fileUpload') fileUpload: ElementRef<HTMLElement>;
    customerSegmentId = <number>null;
    accountIdIndex = null;
    businessPartnerIndex = null;
    customerSegmentIndex = null;
    subscriptionSizeIndex = null;
    configurableCustomerSegments = []
    public rspEnrollmentDialogRef: MatDialogRef<RSPEnrollmentDialogComponent>;
    pendingAccounts: PendingAccountsInput[] = [];
    constructor(
        public router: Router,
        public datasvc: DataService,
        public dialog: MatDialog,
        public apiService: APIServiceCodegen,
        public snackBar: MatSnackBar
    ) {
    }

    ngOnInit() {
        console.log('imhehehe');
        this.listCustomerSegments()
    }

    listCustomerSegments() {
        // Fetch list of Customer Segments (to show if RSP mode is on or not on left side of page)...
        from(this.apiService.ListCustomerSegments()).pipe(catchError(error => {
            error.errors.forEach(iError => {
                this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
            })
            return of(error)
          })).subscribe(resp => {
            this.configurableCustomerSegments = resp;
        })
    }

    onRspChange(event, configurableCustSeg, index) {
        // Save whether we are in RSP mode or not for the given Customer Segment...
        if (event != configurableCustSeg.is_in_rsp_mode) {
            from(this.apiService.EditCustomerSegment(<CustomerSegmentEditInput>{
                customer_segment_id: configurableCustSeg.customer_segment_id,
                is_in_rsp_mode: event,
                customer_segment_alert_message: configurableCustSeg.customer_segment_alert_message
            })).pipe(catchError(error => {
                error.errors.forEach(iError => {
                    this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
                })
                return of(error)
              })).subscribe(resp => {
                this.configurableCustomerSegments[index] = resp
            })
        }
    }

    filtersSelected(event) {
        this.customerSegmentId = event.customer_segment_id;
    }

    public changeListener(files: FileList) {
        if (files && files.length > 0) {
            let file: File = files.item(0);
            let reader: FileReader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e) => {
                let csv: string = reader.result as string;
                let csvRecordsArray = (<string>csv).split(/\r\n|\n/);
                this.getHeaderArray(csvRecordsArray);
                this.checkHeaders();
                let records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray);
                this.sendFileData(records);
            }
            reader.onerror = function () {
                alert('Error has occured while reading file!')
            };
        }
    }

    sendFileData(records: any) {
        from(this.apiService.GetUploadLrgCorpGovAccnts(
                <uploadLrgCorpGovAccntsJson>{
                    jsonLrgCorpGovAccounts: JSON.stringify(records)
                }
            )
        ).pipe(catchError(error => {
            error.errors.forEach(iError => {
                this.datasvc.showErrorAlert(iError.message);
            })
            return of(error)
          })).subscribe(resp => {
            this.datasvc.showGenericAlert((<string>resp).replace('{message={message=', '').replace('}}', ''));
        })
    }

    checkHeaders() {
        if (this.customerSegmentIndex == null || this.businessPartnerIndex == null 
            || this.subscriptionSizeIndex == null || this.accountIdIndex == null) {
                this.datasvc.showErrorAlert('Error: One Of the required columns is Missing');
                throw new Error('Error: One Of the required columns is Missing');
            }
    }

    getHeaderArray(csvRecordsArr: any) {
        let headers = (<string>csvRecordsArr[0]).split(',');
        let headerArray = [];
        if (headers.length != 4) {
            this.datasvc.showErrorAlert('Error: Number of columns should be 4');
            throw new Error('Error: Number of columns should be 4');
        }
        for (let j = 0; j < headers.length; j++) {
            headerArray.push(headers[j]);
            switch(headers[j].toLowerCase()) {
                case 'account_id':
                    this.accountIdIndex = j;
                    break;
                case 'business_partner_id':
                    this.businessPartnerIndex = j;
                    break;
                case 'subscription_kw':
                    this.subscriptionSizeIndex = j;
                    break;
                case 'customer_segment_id':
                    this.customerSegmentIndex = j;
            }
        }

        return headerArray;
    }

    onProcessRSP() {
        if (this.customerSegmentId != 1 && this.customerSegmentId != 2) {
            console.log(1);
            return;
        }
        from(
                this.apiService.GetRspRandomize(this.customerSegmentId + "")
            ).pipe(catchError(error => {
            error.errors.forEach(iError => {
                this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
            })
            return of(error)
          })).subscribe(resp => {
              let listOfAccounts = (<string>resp).split('{created_at=');
              listOfAccounts.forEach(account => { console.log(account)  });
              let excelAllocationList: AllocationRSPResponseToExcel[] = [];
              listOfAccounts = listOfAccounts.slice(1,listOfAccounts.length);
              listOfAccounts.forEach(account => { console.log(account)  });
              listOfAccounts.forEach(account => {
                 let startIndex =  account.indexOf('[{');
                 let endIndex =  account.indexOf('}]');
                 let allocationsString = account.substring(startIndex, endIndex);
                 let allocationsList = (<string>allocationsString).split('},');
                 allocationsList.forEach( al => {

                     excelAllocationList.push(<AllocationRSPResponseToExcel> {
                        account_id: ((al.split('account_id='))[1].split(','))[0],
                        allocation_id: ((al.split('allocation_id='))[1].split(','))[0],
                        allocation_status_id: ((al.split('allocation_status_id='))[1].split(','))[0],
                        allocation_size_kw:  ((al.split('allocation_size_kw='))[1].split(','))[0],
                        enrollment_channel_id: ((al.split('enrollment_channel_id='))[1].split(','))[0],
                        pending_date:  ((al.split('pending_date='))[1].split(','))[0],
                        business_partner_id: ((account.split('business_partner_id='))[1].split('}'))[0],
                        account_notes: ((account.split('account_notes='))[1].split(','))[0],
                     });
                 })

              })
            this.snackBar.open('Success', 'Ok', { panelClass: 'snackbar' });
            this.exportRSPProcessExcel(excelAllocationList);
        })
    }

    onUploadLargeFile() {
        let el: HTMLElement = this.fileUpload.nativeElement;
        el.click();
    }

    getDataRecordsArrayFromCSVFile(csvRecordsArray: any) {
        let csvArr = [];

        for (let i = 1; i < csvRecordsArray.length; i++) {
            let currentRecord = (<string>csvRecordsArray[i]).split(',');
            if (currentRecord.length != 4) {
                this.datasvc.showErrorAlert('Error: Number of columns should be 4');
                throw new Error('Error: Number of columns should be 4');
            }
            if (currentRecord[this.customerSegmentIndex].trim() !== '3' && currentRecord[this.customerSegmentIndex].trim() !== '4') {
                this.datasvc.showErrorAlert('Error: Customer Segment Id not in range should be 3 or 4');
                throw new Error('Error: Customer Segment Id not in range should be 3 or 4');
            }
            let csvRecord: CSVRecord = new CSVRecord();
            csvRecord.account_id = currentRecord[this.accountIdIndex].trim();
            csvRecord.business_partner_id = currentRecord[this.businessPartnerIndex].trim();
            csvRecord.subscription_kw = +currentRecord[this.subscriptionSizeIndex].trim();
            csvRecord.customer_segment_id = currentRecord[this.customerSegmentIndex].trim();
            csvArr.push(csvRecord);
        }
        return csvArr;
    }

    onGetRSPList() {
        if (this.customerSegmentId != null) {
            this.rspEnrollmentDialogRef = this.dialog.open(RSPEnrollmentDialogComponent, {
                id: 'rspEnrollmentDialog',
                minWidth: '60vw',
                data: {
                    customer_segment_id: this.customerSegmentId
                }
            });
        }
    }

    exportRSPProcessExcel(rspResponse: AllocationRSPResponseToExcel[] ) {
        const exportAllocationList = rspResponse.map(rec => {
          return {
            'Account Id': rec.account_id,
            'Allocation Id': rec.allocation_id,
            'Allocation Status Id': rec.allocation_status_id,
            'Allocation Size Kw': rec.allocation_size_kw,
            'Pending Date': rec.pending_date,
            'Business Partner Id': rec.business_partner_id,
            'Account Notes': rec.account_notes
          };
        });
        this.datasvc.exportExcelSpreadsheet(exportAllocationList,
          'RSPProcessResponse' + this.datasvc.getDate(),
          'RSPProcessResponse' + this.datasvc.getDate() + '.xlsx');
      }

}
