import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class AllocationTableService {

createFilter(): (data: any, filter: string) => boolean {
    const filterFunction = function(data, filter): boolean {
      const searchTerms = JSON.parse(filter);
      return data.customerName.toLowerCase().indexOf(searchTerms.customerName.toLowerCase()) !== -1
       && data.email.toLowerCase().indexOf(searchTerms.email.toLowerCase()) !== -1
       && data.phoneNumber.replace(/\-/g, '').toLowerCase().indexOf(searchTerms.phoneNumber.toLowerCase().replace(/\-/g, '').trim()) !== -1
       && data.address.toLowerCase().indexOf(searchTerms.address.toLowerCase()) !== -1
       && data.accountNumber.toLowerCase().indexOf(searchTerms.accountNumber.toLowerCase()) !== -1
       && (!searchTerms.jurisdiction || data.jurisdiction.toLowerCase().indexOf(searchTerms.jurisdiction.toLowerCase()) !== -1)
       && (!searchTerms.minKwFilter || Number(data.allocation.toLowerCase().replace('kw', '')) >=
       Number(searchTerms.minKwFilter.toLowerCase().replace('kw', '')))
       && (!searchTerms.maxKwFilter || Number(data.allocation.toLowerCase().replace('kw', '')) <=
       Number(searchTerms.maxKwFilter.toLowerCase().replace('kw', '')))
       && data.reservationDate.toLowerCase().indexOf(searchTerms.reservationDate.toLowerCase()) !== -1
       && data.activeDate.toLowerCase().indexOf(searchTerms.activeDate.toLowerCase()) !== -1
       && data.accountType.toLowerCase().indexOf(searchTerms.accountType.toLowerCase()) !== -1
       && data.ecommerce.toLowerCase().indexOf(searchTerms.ecommerce.toLowerCase()) !== -1
       && data.status.toLowerCase().indexOf(searchTerms.status.toLowerCase()) !== -1
       && data.incomeQualified.toLowerCase().indexOf(searchTerms.incomeQualified.toLowerCase()) !== -1;
    };

    return filterFunction;
  }

}
