import { Component, OnInit } from '@angular/core';
import { HeaderLink } from '@de-electron/electron-angular-components';
import { DataService } from 'src/app/services';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APIServiceCodegen, GetAdminUserInfoInput } from 'src/app/API.service';
import { Permissions } from '../models/admin.model';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styles: []
})
export class AdminComponent implements OnInit {
  headerLinks: HeaderLink[] = [];
  activeLink = { viewValue: 'Usage', value: 'usage' };
  activeSubLink = { viewValue: 'Usage', value: 'usage' };
  selectedComponentView: String = 'usage';
  selectedDetailView: String = 'overview';
  permissionList = <Permissions[]>[];
  headerCount = 0;
  public showDropDownSelection: Boolean = true

  constructor(public datasvc: DataService,
    public route: ActivatedRoute,
    private router: Router,
    public api: APIServiceCodegen
  ) { }

  ngOnInit() {
    this.onStart();
    this.datasvc.resetUnauthorizedCount();
  }

  onStart() {
    let idToken = this.datasvc.getIdTokenFromLocalStorage();
    if (idToken) {
      let email = (<any>jwt_decode(idToken[1])).email;
      this.datasvc.setUserName(email);
      this.getAndStoreUserPerms();
    }
  }

  onNavigate(link): void {
    if (link.value.substring(0, 4) === 'none' || link.value.substring(0, 4) === 'user') {
      return;
    }
    this.setupHeader();
    setTimeout(() => {
      this.router.navigate(['admin/' + link.value]);
    }, 1000);
    this.router.navigate(['admin/']);

    if (link.value === 'customer-lookup'
      || link.value === 'facility-setup'
      || link.value === 'admin-settings') {
      this.showDropDownSelection = false;
    } else {
      this.showDropDownSelection = true;
    }
  }

  getAndStoreUserPerms() {
    from(
      this.api.AdminUserInfo(<GetAdminUserInfoInput>{
        user_id: this.datasvc.getUserName()
      })
    ).pipe(catchError(error => {
      error.errors.forEach(iError => {
        // this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
      })
      return of(error)
    })).subscribe((resp: any) => {
      this.datasvc.setUserObject(resp);
      this.datasvc.setPermissions(resp.features)
      this.permissionList = this.datasvc.getPermissions();
      this.setupHeader();
      if (this.permissionList.find(num => num == Permissions.Dashboard)) {
        if(!(window.location.pathname.indexOf('rsp-controls') > 0)) {
          this.onNavigate({value:'admin-dashboard'});
        }
      }
      else if (this.permissionList.find(num => num == Permissions.LoginKillSwitch)) {
        this.onNavigate({value: 'login-kill-switch'});
      }
    });

  }

  setupHeader() {
    this.headerLinks = [];
    if (this.permissionList.find(num => num == Permissions.Dashboard)) {
      this.headerLinks.push({ viewValue: 'Dashboard', value: 'admin-dashboard' });
    }
    if (this.permissionList.find(num => num == Permissions.EnrolledAllocationsReport || num == Permissions.AllocationReport
      || num == Permissions.WaitListReport || num == Permissions.CustomerLookup || num == Permissions.EnrollmentAuditReport
      || num == Permissions.TransferListReport)) {
      let customerReportTab = { viewValue: 'Customer Reports', value: 'none-1', subLinks: [] }
      
      if (this.permissionList.find(num => num == Permissions.CustomerLookup)) {
        customerReportTab.subLinks.push({ viewValue: 'Customer Lookup', value: 'customer-lookup' });
      }
      if (this.permissionList.find(num => num == Permissions.AllocationReport)) {
        customerReportTab.subLinks.push({ viewValue: 'Allocation Report', value: 'allocation-report' });
      }
      if (this.permissionList.find(num => num == Permissions.WaitListReport)) {
        customerReportTab.subLinks.push({ viewValue: 'Wait List Report', value: 'wait-list' });
      }
      if (this.permissionList.find(num => num == Permissions.WaitListReport)) {
        customerReportTab.subLinks.push({ viewValue: 'Transfer List Report', value: 'transfer-list' });
      }
      if (this.permissionList.find(num => num == Permissions.EnrolledAllocationsReport)) {
        customerReportTab.subLinks.push({ viewValue: 'Enrolled Allocations Report', value: 'enrolled-allocations-report' });
      }
      if (this.permissionList.find(num => num == Permissions.AllocationReport)) {
        customerReportTab.subLinks.push({ viewValue: 'Enrollment Audit Report', value: 'enrollment-audit-report' });
      }
      this.headerLinks.push(customerReportTab);
    }
    if (this.permissionList.find(num => num == Permissions.FacilitiesReport || num == Permissions.AddFacility)) {
      let FacilitiesReportTab = { viewValue: 'Facility Management', value: 'none-2', subLinks: [] }
      if (this.permissionList.find(num => num == Permissions.FacilitiesReport)) {
        FacilitiesReportTab.subLinks.push({ viewValue: 'Facilities Report', value: 'facility-management' });
      }
      if (this.permissionList.find(num => num == Permissions.AddFacility)) {
        FacilitiesReportTab.subLinks.push({ viewValue: 'Add Facility', value: 'facility-setup' });
      }
      this.headerLinks.push(FacilitiesReportTab);
    }
    if (this.permissionList.find(num => num == Permissions.AllAlerts || num == Permissions.CustomerAlerts
      || num == Permissions.FacilityAlerts || num == Permissions.EnrollmentAlerts)) {
      let AlertsTab = { viewValue: 'Alerts', value: 'none-3', subLinks: [] }
      if (this.permissionList.find(num => num == Permissions.AllAlerts)) {
        AlertsTab.subLinks.push({ viewValue: 'All Alerts', value: 'alert-main' });
      }
      if (this.permissionList.find(num => num == Permissions.CustomerAlerts)) {
        AlertsTab.subLinks.push({ viewValue: 'Customer Alerts', value: 'customer-alert-report' });
      }
      if (this.permissionList.find(num => num == Permissions.FacilityAlerts)) {
        AlertsTab.subLinks.push({ viewValue: 'Facility Alerts', value: 'facility-alert-report' });
      }
      if (this.permissionList.find(num => num == Permissions.EnrollmentAlerts)) {
        AlertsTab.subLinks.push({ viewValue: 'Enrollment Alerts', value: 'enrollment-alert-report' });
      }
      this.headerLinks.push(AlertsTab);
    }
    if (this.permissionList.find(num => num == Permissions.UserAdminSettings || num == Permissions.LoginKillSwitch)) {
      let adminHeader = {
        viewValue: 'Administration', value: 'none-4', subLinks: []
      };
      if (this.permissionList.find(num => num == Permissions.UserAdminSettings)) {
        adminHeader.subLinks.push( { viewValue: 'User Admin Settings', value: 'admin-settings' });
      }
      if (this.permissionList.find(num => num == Permissions.LoginKillSwitch)) {
        adminHeader.subLinks.push( { viewValue: 'Login Kill Switch', value: 'login-kill-switch' });
      }
      this.headerLinks.push(adminHeader);
    }

  }

}
