import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AccountDetailModel } from '../models/customer-detail.model';
import { FacilitySetupResponse, FacilitySetupModel, FeeDate } from '../models/admin.model';
import { FormGroup, FormControl } from '@angular/forms';

class InputData {
  facility_data: FacilitySetupModel;
}

@Component({
  selector: 'app-enrollment-fee-dialog',
  templateUrl: './enrollment-fee-dialog.component.html',
  styleUrls: ['./enrollment-fee-dialog.component.scss']
})
export class EnrollmentFeeDialogComponent implements OnInit {
    feeDateItems: FeeDate[] = [];

  constructor(
    private enrollmentFeeDialogRef: MatDialogRef<EnrollmentFeeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData) {
    this.enrollmentFeeDialogRef.disableClose = true;
  }

  ngOnInit() {
      this.addDateRow(null);
  }

  addDateRow(dateRow: any) {
      if (dateRow) {
        this.feeDateItems.push(<FeeDate>{
           fee_date: dateRow.fee_date,
           residential_fee: dateRow.residential_fee,
           non_residential_fee: dateRow.non_residential_fee
        });
      } else {
        this.feeDateItems.push(<FeeDate> {
            fee_date: null,
            residential_fee: null,
            non_residential_fee: null
        });
      }

  }

  addDate() {
      this.addDateRow(null);
  }

  // Close modal window...
  closeModal() {
    this.enrollmentFeeDialogRef.close();
  }
}
