import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { APIServiceCodegen, PendingAccountsInput, StringMessage } from 'src/app/API.service';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataService } from 'src/app/services';

class InputData {
  customer_segment_id: string;
}

class PendingAccountsInfo {
  error: string;
  business_partner_id: string;
  account_id: string;
  allocation_id: string;
  status: string;
}

@Component({
  selector: 'app-rsp-enrollment-dialog',
  templateUrl: './rsp-enrollment-dialog.component.html',
  styleUrls: ['./rsp-enrollment-dialog.component.scss']
})
export class RSPEnrollmentDialogComponent implements OnInit {
  listOfPendingAccounts:PendingAccountsInfo[] = [];
  constructor(
    private detailsDialogRef: MatDialogRef<RSPEnrollmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData,
    public api: APIServiceCodegen,
    public snackBar: MatSnackBar,
    public apiService: APIServiceCodegen,
    public datasvc: DataService
  ) {
    this.getRSPList();
  }

  ngOnInit() {

  }

  closeModal() {
    this.detailsDialogRef.close();
  }


  getRSPList() {
      from(
          this.api.GetPendingAccounts(this.data.customer_segment_id + "")
        ).pipe(catchError(error => {
          error.errors.forEach(iError => {
           // this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
          })
          return of(error)
        })).subscribe((resp: any) => {
          this.listOfPendingAccounts = resp;
        })
  }

  onRunEnrollment() {
    this.runEnrollmentLoop(0);
  }

  exportEnrollExcel(rspResponse: PendingAccountsInfo[] ) {
    const exportAllocationList = rspResponse.map(rec => {
      return {
        'Account Id': rec.account_id,
        'Allocation Id': rec.allocation_id,
        'Business Partner Id': rec.business_partner_id,
        'Status': rec.status,
        'Error': rec.error

      };
    });
    this.datasvc.exportExcelSpreadsheet(exportAllocationList,
      'EnrolledResponse' + this.datasvc.getDate(),
      'EnrolledResponse' + this.datasvc.getDate() + '.xlsx');
  }

  runEnrollmentLoop(index: number) { //should be ran outside of it with a starting value of 0
    if (this.listOfPendingAccounts.length <= index) {
      this.exportEnrollExcel(this.listOfPendingAccounts);
      return;
    }
    this.listOfPendingAccounts[index].error = '';
    this.listOfPendingAccounts[index].status = 'Processing...';
    from(
      this.apiService.UpdatePendingToEnrollment(<PendingAccountsInput> {
            account_id: this.listOfPendingAccounts[index].account_id,
            business_partner_id: this.listOfPendingAccounts[index].business_partner_id,
            allocation_id: this.listOfPendingAccounts[index].allocation_id
      })
    ).pipe(catchError(error => {
      this.listOfPendingAccounts[index].status = 'Failed';
      error.errors.forEach(iError => {
       this.listOfPendingAccounts[index].error = this.listOfPendingAccounts[index].error + iError.message + ' | '
    })
      setTimeout(x => {
        this.runEnrollmentLoop(index + 1);
      },500)
      return null;
    })).subscribe((resp: StringMessage) => {
      this.listOfPendingAccounts[index].status = resp.message;
      setTimeout(x => {
        this.runEnrollmentLoop(index + 1);
      },500)
    })
  }
}
