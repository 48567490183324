<span class="close" (click)="closeModal()">&times;</span>
<div class="container">
    <form [formGroup]="alertForm" novalidate>
        <br><br>
        <div class="row">
            <div class="title" *ngIf="data.dialog_type === 'Customer' || data.dialog_type === 'Enrollment'"><b>{{data.full_name}}</b></div>
            <div class="title" *ngIf="data.dialog_type === 'Facility'"><b>{{data.facility_name}}</b></div>
        </div>
        <br>
        <br>
        <div class="row">
            <mat-label class="main-label">Issue</mat-label>
        </div>
        <div class="main-text">
            {{data.issue}}
        </div>
        <br>
        <div class="row">
            <mat-label class="main-label">Date</mat-label>
        </div>
        <br>
        <div class="row main-text">
            {{data.createdAt | date}}
        </div>
        <br>
        <div class="row" *ngIf="data.dialog_type === 'Customer' || data.dialog_type === 'Enrollment'">
            <mat-label class="main-label">Account</mat-label>
        </div>
        <div class="row main-text" *ngIf="data.dialog_type === 'Customer' || data.dialog_type === 'Enrollment'">
            {{data.account}}
        </div>
        <br>
        <div class="row">
            <mat-label class="main-label">Comment</mat-label>
        </div>
        <div class="row">
            <mat-form-field style="width: 900px; font-size: 16px;" appearance="outline" hintLabel="Max 300 characters">
                <textarea matInput formControlName="notes" class="pl-3 main-text" rows="4" [maxlength]="300"></textarea>
                 <mat-hint style="font-size: 16px;" align="end">{{data.notes?.length || 0}}/300</mat-hint> 
            </mat-form-field>
            <br>
        </div>
        <br><br>
        <div class="float-right row">
            <de-button (click)="closeModal()" type="secondary">CANCEL</de-button>
            <de-button (click)="onSave()" type="primary" size="small">SAVE</de-button>
        </div>
    </form>
</div>