<div *ngIf="hasFacilityAlertPerms" class="container">
    <div class="row">
        <div class="col-9 autoMargins">
            <!-- <app-facility-alerts-report-table [exportData]="exportData" (dataRetrieved)="dataRetrieved($event)"
                 [jurisdiction_id]="selectedJurisdictionId" [program_id]="selectedProgramId">
            </app-facility-alerts-report-table> -->
            <app-facility-alerts-report-table [exportData]="exportData" (dataRetrieved)="dataRetrieved($event)"></app-facility-alerts-report-table>
            <div class="background-white col-12 give-size">
                <div class="row float-right">
                    <de-button (click)="exportExcel()" type="secondary">
                        <div>Export Data &nbsp; <i class="fas fa-caret-up"></i></div>
                    </de-button>
                </div>
            </div>
        </div>
    </div>
</div>