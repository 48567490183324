<span class="close cursor-pointer" (click)="closeModal()">&times;</span>
<br><br>
<div class="title"><b>Ineligible Account</b></div>
<div class="table">
    <div class="row no-gutters">
        <div class="col-md-2 table-head">Account Number</div>
        <div class="col-md-3 table-head">Name on the Account</div>
        <div class="col-md-3 table-head">Service Address</div>
        <div class="col-md-2 table-head">Max Subscription</div>
        <div class="col-md-1 table-head">Eligible</div>
    </div>
    <br>
    <div>
        <div class="row no-gutters align-items-center">
            <div class="col-md-2 table-cell">{{data.account_details.account_number}}</div>
            <div class="col-md-3 table-cell">{{data.account_details.main_customer.full_name}}</div>
            <div class="col-md-3 table-cell">{{data.account_details.premise_address.line1}}</div>
            <div class="col-md-2 table-cell">{{data.account_details.max_subscription_size_kw}}</div>
            <div class="col-md-1 text-center table-cell">
            <i class="fas fa-times"></i>
            </div>
        </div>
    </div>
</div>
<div class="title"><b>Ineligibility Reasons</b></div>
<br>
<!-- <div *ngFor="let reason of data.account_details.ineligibility_reasons" class="row no-gutters align-items-center reason"> -->
<!-- TODO: Change to look at actual refined data sent in, not first index of eligibility -->
<div *ngFor="let reason of data.account_details.eligibility.ineligibility_reasons" class="row no-gutters align-items-center reason">
    <span class="dot"></span>{{reason}}
    <br>
</div>