<div class="">
    <div class="row title">
        Total Available Capacity
    </div>
    <br>
    <div class="row label-item">
        Low Income Allocation
    </div>
    <div class="row size-item">
        <div class="col-3"></div>
        <span class="size-area"> 700 </span>&nbsp; kW
    </div>
    <br>
    <div class="row label-item">
        Market Rate Allocation
    </div>
    <div class="row size-item">
        <div class="col-3"></div>
       <span class="size-area">1287</span>&nbsp; Kw
    </div>
</div>