import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';
import {
    DropDownSelection, PanelTypeResponse, FacilityReportModel, ReportFacility, StateResponse,
    CountyResponse,
    ProjectedCapacityFactor,
    AccountPerms,
    WaitListModel,
    CustomerAlertResponse,
    CustomerAlertModel,
    FacilityAlertModel,
    EnrollmentAlertModel,
    CountyModel,
    UserRole,
    UserRoleModel,
    Permissions
} from '../components/models/admin.model';
import { Allocation, CustomerResponse } from '../components/models/customer-report.model';
import * as _ from 'lodash';
import { AccountDetailModel, AccountLookupModel, CustomerModel, Address, CustomerDetailAllocation } from '../components/models/customer-detail.model';
import { JurisdictionModel, State }  from '../components/models/customer-report.model';


import { DateAdapter, MatSnackBar } from '@angular/material';
import { AllocationReport, EnrollmentAuditReport, CustomerDetail, ListWaitlistReportQuery, AccountLookupForEmployee, AccountSearchForEmployeeInput } from '../API.service';
@Injectable({
    providedIn: 'root'
})

export class DataService {

    private headerTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private projectedMonthlyCapacity$: BehaviorSubject<ProjectedCapacityFactor> = new BehaviorSubject<ProjectedCapacityFactor>(null);
    public subscriptionSizeSelection: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(public snackBar: MatSnackBar) {}

    incrementUnauthorized() {
        sessionStorage.getItem('unAuthorized') ?
        sessionStorage.setItem('unAuthorized', '1') :
        sessionStorage.setItem('unAuthorized', ((+sessionStorage.getItem('unAuthorized')) + 1).toString());

    }

    setUserObject(user) {
        console.log(user);
        sessionStorage.setItem('userobj', JSON.stringify(user));
    }

    getUserObject() {
        return JSON.parse(sessionStorage.getItem('userobj'));
    }

    getUnauthorizedCount() {
        return sessionStorage.getItem('unAuthorized');
    }

    resetUnauthorizedCount() {
        sessionStorage.setItem('unAuthorized', '0');
    }

    setHeaderTitle(header: string) {
        this.headerTitle$.next(header);
    }
    setSubscriptionSize(size: number) {
        this.subscriptionSizeSelection.next(size);
    }
    getHeaderTitle() {
        return this.headerTitle$.getValue();
    }
    actAsFullAccess() {
        sessionStorage.setItem('accessLevel', 'Full Access');
    }
    actAsPartialAccess() {
        sessionStorage.setItem('accessLevel', 'Partial Access');
    }
    actAsLimitedAccess() {
        sessionStorage.setItem('accessLevel', 'Limited Access');
    }
    setPermissions(permissionList: Permissions[]) {
        sessionStorage.setItem('permissions', JSON.stringify(permissionList));
    }
    getPermissions() {
        return JSON.parse(sessionStorage.getItem('permissions'));
    }
    setWaitListCustomerSegmentId(customerSegmentId: string) {
        sessionStorage.setItem('wait_list_customer_segment_id', customerSegmentId);
    }
    getWaitListCustomerSegmentId() {
        if (sessionStorage.getItem('wait_list_customer_segment_id')) {
            return sessionStorage.getItem('wait_list_customer_segment_id');
        } else {
            return null;
        }
    }
    setEnrolledAllocationsReportCustomerSegmentId(customerSegmentId: string) {
        sessionStorage.setItem('enrolled_allocations_report_customer_segment_id', customerSegmentId);
    }
    getEnrolledAllocationsReportCustomerSegmentId() {
        if (sessionStorage.getItem('enrolled_allocations_report_customer_segment_id')) {
            return sessionStorage.getItem('enrolled_allocations_report_customer_segment_id');
        } else {
            return null;
        }
    }
    getAccountPermType() {
        return sessionStorage.getItem('accessLevel') ? (
            sessionStorage.getItem('') === 'Full Access' ? AccountPerms.FullAccess :
                sessionStorage.getItem('accessLevel') === 'Partial Access' ? AccountPerms.PartialAccess :
                    sessionStorage.getItem('accessLevel') === 'Limited Access' ? AccountPerms.LimitedAccess :
                        AccountPerms.FullAccess) : AccountPerms.FullAccess;
    }
    getCurrentUserFullName() {
         return "Duke Energy User"
    }
    setUserName(username: string) {
        sessionStorage.setItem('Username', username );
    }
    getUserName() {
        return sessionStorage.getItem('Username');
    }
    getIdTokenFromLocalStorage() {
        const storage = Object.entries(localStorage);
        const found = storage.find(([internalKey]) => /.*idToken$/.test(internalKey));
        return found;
    }
    setProjectedMonthlyCapacity(projectedCapacity) {
        this.projectedMonthlyCapacity$.next(projectedCapacity);
    }
    getProjectedMonthlyCapacity() {
        return this.projectedMonthlyCapacity$.getValue();
    }

    showErrorAlert(message: string) {
        this.snackBar.open('Error: ' + message, 'Ok', { panelClass: 'snackbar' });
    }

    showSuccessAlert(message: string) {
        this.snackBar.open('Success: ' + message, 'Ok', { panelClass: 'snackbar' });
    }

    showGenericAlert(message: string) {
        this.snackBar.open(message, 'Ok', { panelClass: 'snackbar' });
    }

    setAccountLookupForEmployee(account: AccountLookupForEmployee) {
        sessionStorage.setItem('account_lookup_for_employee', JSON.stringify(account));
    }

    getAccountLookupForEmployee() {
        if (sessionStorage.getItem('account_lookup_for_employee')) {
            return <AccountLookupForEmployee>JSON.parse(sessionStorage.getItem('account_lookup_for_employee'));
        } else {
            return null;
        }
    }

    setAccountSearchForEmployeeInput(input: AccountSearchForEmployeeInput) {
        sessionStorage.setItem('account_search_for_employee_input', JSON.stringify(input));
    }

    getAccountSearchForEmployeeInput() {
        if (sessionStorage.getItem('account_search_for_employee_input')) {
            return <AccountSearchForEmployeeInput>JSON.parse(sessionStorage.getItem('account_search_for_employee_input'));
        } else {
            return null;
        }
    }

    setSelectedFacility(facility: FacilityReportModel) {
        sessionStorage.setItem('selectedFacility', JSON.stringify(facility));
    }
    getSelectedFacility() {
        return JSON.parse(sessionStorage.getItem('selectedFacility'));
    }
    setLast4SSN(last4: string) {
        sessionStorage.setItem('last4SSN', last4);
    }
    getLast4SSN() {
        return sessionStorage.getItem('last4SSN');
    }
    setLast4Tax(last4: string) {
        sessionStorage.setItem('last4Tax', last4);
    }
    getLast4Tax() {
        return sessionStorage.getItem('last4Tax');
    }
    setSelectedAccountType(accountType: string) {
        sessionStorage.setItem('accountType', accountType);
    }
    getSelectedAccountType() {
        let accountType = sessionStorage.getItem('accountType');
        if (!accountType || accountType.trim().length === 0) {
            accountType = 'Residential';
        }
        return accountType;
    }
    getMeasureTypes() {
        return <DropDownSelection[]>[
            {
                name: 'kW',
                value: 'kW'
            },
            {
                name: 'mW',
                value: 'mW'
            }
        ];
    }

    exportExcelSpreadsheet(exportData: any, exportSheetName: string, exportFileName: string) {
        const workSheet = XLSX.utils.json_to_sheet(exportData);
        const workBook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, exportSheetName);
        XLSX.writeFile(workBook, exportFileName);
    }

    getDate() {
        return formatDate(new Date(), 'yyyy-MM-dd', 'en');
    }

    mapPanelTypesDropDown(panelTypes: PanelTypeResponse) {
        return panelTypes.panel_types.map(type => <DropDownSelection>{
            name: type.panel_type_name,
            value: type.panel_type_id
        });
    }

    mapUserRolesDropDown(user_roles: UserRoleModel[]) {
        return user_roles.map(role => <DropDownSelection>{
            name: role.user_role_description,
            value: role.user_role_id
        });
    }

    mapStatesDropDown(states: StateResponse) {
        return states.states.map(state => <DropDownSelection>{
            name: state.state_name,
            value: state.state_id
        });
    }

    mapCountyDropDown(counties: CountyResponse) {
        return counties.counties.map(county => <DropDownSelection>{
            name: county.county_name,
            value: county.county_id
        });
    }

    mapFacilityManagementTableData(data: FacilityReportModel[]) {
        const facilityModelList = [];
        data.forEach(facility => {
            facilityModelList.push(<ReportFacility>{
                facility_id: facility.facility_id,
                facility_name: facility.facility_name,
                city: facility.city,
                state_code: facility.state_code, // will be state_code
                street: facility.address_line1,
                jurisdiction: facility.jurisdiction_name,
                installation_number: facility.facility_installation_number.toString(),
                salesforce_id: facility.salesforce_id,
                total_capacity_kw: facility.total_capacity_kw.toString(),
                month_status: facility.one_month_status,
                year_status: facility.twelve_month_status,
                commercial_operation_date: new Date(facility.commercial_operating_date).toDateString()
            });
        });
        return facilityModelList;
    }

    createCustomerFilter(): (data: any, filter: string) => boolean {



        const filterFunction = function (data: CustomerResponse, filter): boolean {

            console.log(data)

            const searchTerms = JSON.parse(filter);
            return (data.main_customer && data.main_customer.full_name ? data.main_customer.full_name.toLowerCase() : '')
                .indexOf(searchTerms.name.toLowerCase()) !== -1 &&
                (data.main_customer && data.main_customer.email ? data.main_customer.email.toLowerCase() : '')
                .indexOf(searchTerms.email.toLowerCase()) !== -1 &&
                (data.main_customer && data.main_customer.phone ? data.main_customer.phone.toLowerCase() : '')
                .indexOf(searchTerms.primary_phone.toLowerCase()) !== -1 &&
                (data.account_id ? data.account_id.toLowerCase() : '')
                .indexOf(searchTerms.account_number.toLowerCase()) !== -1 &&
                (data.business_partner_id ? data.business_partner_id.toString() : '')
                .indexOf(searchTerms.business_partner_id.toLowerCase()) !== -1 &&
                (data.premise_address && data.premise_address.line1 ? data.premise_address.line1.toLowerCase() : '')
                .indexOf(searchTerms.street.toLowerCase()) !== -1 &&
                (data.premise_address && data.premise_address.city ? data.premise_address.city.toLowerCase() : '')
                .indexOf(searchTerms.city.toLowerCase()) !== -1 &&
                // (data.premise_address && data.premise_address.state_code ? data.premise_address.state_code.toLowerCase() : '')
                // .indexOf(searchTerms.state.toLowerCase()) !== -1 &&
                (data.premise_address ? data.premise_address.zip.toLowerCase() : '')
                .indexOf(searchTerms.zip.toLowerCase()) !== -1 &&
                (((data.bill_cycle ? (+data.bill_cycle).toString() : '') ===
                searchTerms.bill_cycle.toString() ) || searchTerms.bill_cycle.toString()  === '');
        };
        return filterFunction;
    }

    createCustomerAlertsFilter(): (data: CustomerAlertModel, filter: string) => boolean {
        const filterFunction = function (data: CustomerAlertModel, filter): boolean {
            const searchTerms = JSON.parse(filter);
            let date = (data.created_date ? new Date(data.created_date).toDateString().toLowerCase().substring(4) : '');
            if (date[4] === '0') {
                date = date.replace('0', '');
            }
            return (data.full_name ? data.full_name.toLowerCase() : '').indexOf(searchTerms.full_name.toLowerCase()) !== -1 &&
                (data.issue ? data.issue.toLowerCase() : '').indexOf(searchTerms.issue.toLowerCase()) !== -1 &&
                date.indexOf(searchTerms.date.toLowerCase()) !== -1;
        };
        return filterFunction;
    }

    createFacilityAlertsFilter(): (data: FacilityAlertModel, filter: string) => boolean {
        const filterFunction = function (data: FacilityAlertModel, filter): boolean {
            const searchTerms = JSON.parse(filter);
            let date = (data.created_date ? new Date(data.created_date).toDateString().toLowerCase().substring(4) : '');
            if (date[4] === '0') {
                date = date.replace('0', '');
            }
            return (data.facility_name ? data.facility_name.toLowerCase() : '').indexOf(searchTerms.facilityName.toLowerCase()) !== -1 &&
                (data.issue ? data.issue.toLowerCase() : '').indexOf(searchTerms.issue.toLowerCase()) !== -1 &&
                date.indexOf(searchTerms.date.toLowerCase()) !== -1;
        };
        return filterFunction;
    }

    createEnrollmentAlertsFilter(): (data: EnrollmentAlertModel, filter: string) => boolean {
        const filterFunction = function (data: EnrollmentAlertModel, filter): boolean {
            const searchTerms = JSON.parse(filter);
            let date = (data.created_date ? new Date(data.created_date).toDateString().toLowerCase().substring(4) : '');
            if (date[4] === '0') {
                date = date.replace('0', '');
            }
            return (data.full_name ? data.full_name.toLowerCase() : '').indexOf(searchTerms.customerName.toLowerCase()) !== -1 &&
                (data.account_id ? data.account_id.toLowerCase() : '').indexOf(searchTerms.accountNumber.toLowerCase()) !== -1 &&
                (data.status_change ? data.status_change.toLowerCase() : '').indexOf(searchTerms.status.toLowerCase()) !== -1 &&
                date.indexOf(searchTerms.date.toLowerCase()) !== -1;
        };
        return filterFunction;
    }

    createAllocationFilter(): (data: any, filter: string) => boolean {
        const filterFunction = function (data: AllocationReport, filter): boolean {
            const searchTerms = JSON.parse(filter);
            console.log(data)
            let activeDate = (data.active_date ? new Date(data.active_date).toDateString().toLowerCase().substring(4) : '');
            if (activeDate[4] === '0') {
               activeDate = activeDate.replace('0', '');
            }
            let reservationDate = (data.created_at ? new Date(data.created_at).toDateString().toLowerCase().substring(4) : '');
            if (reservationDate[4] === '0') {
                reservationDate = reservationDate.replace('0', '');
             }
            return (data.main_customer && data.main_customer.full_name ? data.main_customer.full_name.toLowerCase() : '')
                .indexOf(searchTerms.customerName.toLowerCase()) !== -1 &&
                (searchTerms.lowIncome === 'Yes' && data.low_income_flag|| (searchTerms.lowIncome === 'No' &&
                 (data.low_income_flag === false)) || !searchTerms.lowIncome ) &&
                ((searchTerms.resNonRes === 'R' && data.res_non_res === 'R') || 
                (searchTerms.resNonRes === 'N' && data.res_non_res === 'N') || 
                !searchTerms.resNonRes) &&
                (data.account_id ? data.account_id.toString() : '')
                    .indexOf(searchTerms.accountNumber.toLowerCase()) !== -1  &&
                (data.business_partner_id ? data.business_partner_id.toString() : '')
                .indexOf(searchTerms.businessPartnerId.toLowerCase()) !== -1 &&
                (data.installation_id ? data.installation_id.toString() : '')
                .indexOf(searchTerms.installation_id.toLowerCase()) !== -1 &&
                (data.billing_period ? data.billing_period.toString() : '')
                .indexOf(searchTerms.billing_period.toLowerCase()) !== -1 &&
                (data.premise_address && data.premise_address.line1 ? data.premise_address.line1.toLowerCase() : '')
                .indexOf(searchTerms.street.toLowerCase()) !== -1 &&
                (data.premise_address && data.premise_address.city ? data.premise_address.city.toLowerCase() : '')
                .indexOf(searchTerms.city.toLowerCase()) !== -1 &&
                reservationDate.indexOf(searchTerms.createdDate.toLowerCase()) !== -1 &&
                activeDate.indexOf(searchTerms.activeDate.toLowerCase()) !== -1 &&
                (data.allocation_status_description ? data.allocation_status_description.toLowerCase() : '')
                .indexOf(searchTerms.status.toLowerCase()) !== -1 &&
                (data.cancel_reason_description ? data.cancel_reason_description.toLowerCase() : '')
                .indexOf(searchTerms.cancelReason.toLowerCase()) !== -1 &&
                (data.enrollment_channel_description ? data.enrollment_channel_description.toLowerCase() : '')
                .indexOf(searchTerms.enrollment.toLowerCase()) !== -1 &&
                (data.main_customer && data.main_customer.email ? data.main_customer.email.toLowerCase() : '')
                .indexOf(searchTerms.email.toLowerCase()) !== -1 &&
                (data.main_customer && data.main_customer.phone ? data.main_customer.phone.toLowerCase() : '')
                .indexOf(searchTerms.phone.toLowerCase()) !== -1;
        };
        return filterFunction;
    }

    createEnrollmentAuditFilter(): (data: any, filter: string) => boolean {
        const filterFunction = function (data: EnrollmentAuditReport, filter): boolean {
            const searchTerms = JSON.parse(filter);
            console.log(data)
            // let activeDate = (data.active_date ? new Date(data.active_date).toDateString().toLowerCase().substring(4) : '');
            // if (activeDate[4] === '0') {
            //    activeDate = activeDate.replace('0', '');
            // }
            let reservationDate = (data.created_at ? new Date(data.created_at).toDateString().toLowerCase().substring(4) : '');
            if (reservationDate[4] === '0') {
                reservationDate = reservationDate.replace('0', '');
             }
            return (data.main_customer && data.main_customer.full_name ? data.main_customer.full_name.toLowerCase() : '')
                .indexOf(searchTerms.customerName.toLowerCase()) !== -1 &&
                (data.account_id ? data.account_id.toString() : '')
                    .indexOf(searchTerms.account.toLowerCase()) !== -1  &&
                (data.premise_address && data.premise_address.line1 ? data.premise_address.line1.toLowerCase() : '')
                .indexOf(searchTerms.street.toLowerCase()) !== -1 &&
                (data.premise_address && data.premise_address.city ? data.premise_address.city.toLowerCase() : '')
                .indexOf(searchTerms.city.toLowerCase()) !== -1 &&
                reservationDate.indexOf(searchTerms.createdDate.toLowerCase()) !== -1  &&
                (data.cancel_reason_description ? data.cancel_reason_description.toLowerCase() : '')
                .indexOf(searchTerms.cancelReason.toLowerCase()) !== -1 &&
                (data.main_customer && data.main_customer.email ? data.main_customer.email.toLowerCase() : '')
                .indexOf(searchTerms.email.toLowerCase()) !== -1 &&
                (data.main_customer && data.main_customer.phone ? data.main_customer.phone.toLowerCase() : '')
                .indexOf(searchTerms.phone.toLowerCase()) !== -1;
        };
        return filterFunction;
    }

}
