<div class="mat-table-format">
    <de-loader *ngIf="gettingEnrollmentAlertData" [showOverlay]="showOverlay"></de-loader>
    <table *ngIf="!gettingEnrollmentAlertData" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef>
          <div>Customer Name</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.full_name}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <div>Status Change</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.status_change}} </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>
          <div>Date</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.created_date|date}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onShowDialog(row.enrollment_audit_id)" class="cursor-pointer"></tr>
    </table>
  </div>
  <div class="bottomOfTable background-white cursor-pointer" (click)="viewMore()"><a>View More</a></div>