import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { MatPaginator, MatSnackBar, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataService, AllocationTableService } from 'src/app/services';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AccountLookupForEmployee, EnrolledAllocation, APIServiceCodegen, AccountLookupForEmployeeInput, ReportCriteriaInput, EnrolledAllocationsReverifyInput } from 'src/app/API.service';
import { from, of } from 'rxjs';
import { AllocationStatus, Permissions } from '../models/admin.model';

@Component({
  selector: 'app-enrolled-allocations-report-table',
  templateUrl: './enrolled-allocations-report-table.component.html',
  styleUrls: ['./enrolled-allocations-report-table.component.scss']
})
export class EnrolledAllocationsReportTableComponent implements OnInit {
  @Input() customer_segment_id: string | null;
  @Input() exportData: EventEmitter<any> = new EventEmitter();
  @Output() dataRetrieved = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['eligibility', 'account','businessPartnerId','allocationSize', 'dates', 
  'status', 'enrollmentCode','productCode', 'enrollmentChannel', 'actions'];
  dataSource = new MatTableDataSource<EnrolledAllocation>();
  allAllocations: EnrolledAllocation[];
  public hasAllocationEditPerms = false;
  public hasAllocationViewPerms = false;
  public hasAllocationCancelPerms = false;
  public get allocationStatus(): typeof AllocationStatus {
    return AllocationStatus; 
  }
  subscriptionList: Subscription[] = [];
  gettingEnrolledAllocationsReportData = false;
  gettingDetailData = false;
  isReverifyEligibilityLoading = false;
  itemsReverified: number;
  showOverlay = true;
  pageLength = 0;
  pageIndex = 0;
  pageSize = 10;

  constructor(public datasvc: DataService,
    public router: Router,
    public allocationTableSvc: AllocationTableService,
    public api: APIServiceCodegen,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    let permList = this.datasvc.getPermissions();
    if (permList.find(num => num == Permissions.AllocationEdit)) {
        this.hasAllocationEditPerms = true;
    }
    if (permList.find(num => num == Permissions.AllocationView)) {
        this.hasAllocationViewPerms = true;
    }
    if (permList.find(num => num == Permissions.AllocationCancel)) {
        this.hasAllocationCancelPerms = true;
    }
    this.subscriptionList.push(this.exportData.subscribe(() => {
      this.exportExcel();
    }));
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptionList.forEach(sub => {
      sub.unsubscribe();
    });
  }

  ngOnChanges() {
    if (!this.allAllocations) {
      return;
    }
    this.getData();
  }

  getData() {
    if (this.gettingEnrolledAllocationsReportData) {
      return;
    }
    this.gettingEnrolledAllocationsReportData = true;
    this.subscriptionList.push(
      from(
        this.api.ListEnrolledAllocations(<ReportCriteriaInput>{
          id_token: this.datasvc.getIdTokenFromLocalStorage()[1].toString(),
          customer_segment_id: this.customer_segment_id
        })
      ).pipe(
        catchError(error => {
          error.errors.forEach(iError => {
            this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
          });
          return of(error)
        })
      ).subscribe((enrolledAllocations: EnrolledAllocation[]) => {
        this.pageLength = enrolledAllocations.length;
        this.dataSource = new MatTableDataSource<EnrolledAllocation>(enrolledAllocations);
        this.allAllocations = enrolledAllocations;
        this.dataRetrieved.emit(enrolledAllocations);
        this.gettingEnrolledAllocationsReportData = false;
        this.dataSource.paginator = this.paginator;
      })
    );
  }

  goToAllocation(allocation_id, account_id, mode) {
    this.gettingDetailData = true;
    from(
    this.api.GetAccountLookupForEmployee(<AccountLookupForEmployeeInput>{
        account_number: account_id
    })
    ).pipe(catchError(error =>  {
        this.gettingDetailData = false;
        error.errors.forEach(iError => {
          this.gettingDetailData  = false;
          this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
        })
        return of(error)
    }))
    .subscribe ((searchResults: AccountLookupForEmployee) => {
        if ((<any>searchResults).errors) {
          this.gettingDetailData  = false;
          return;
        }
        // Fetch our account and then the specific allocation index for the one we care about...
        this.gettingDetailData  = false;
        this.datasvc.setAccountLookupForEmployee(searchResults);
        this.router.navigate(['admin/subscription', { Mode: mode, From: 'EnrolledAllocationsReport'}], {
          state: {
            accountDetails: searchResults,
            index: searchResults.allocations.findIndex(x => x.allocation_id === allocation_id)
          }
        });
    });
  }

  exportExcel() {
    const exportAllocationList = this.dataSource.filteredData.map(item => {
      return {
        'Customer Name': item.main_customer.full_name,
        'Account Number': item.account_id,
        'Business Partner Id': item.business_partner_id,
        'Street': item.premise_address.line1,
        'City': item.premise_address.city,
        'Zip Code': item.premise_address.zip,
        'Subscription Size (kW)': item.allocation_size_kw,
        'Active Date': item.active_date,
        'Status': 'Enrolled',
        'Eligibility': item.eligibility_status,
        'Eligibility Check Date': item.eligibility_check_date,
        'Ineligibility Reasons': item.ineligibility_reasons !== null ? item.ineligibility_reasons : "",
        'Enrollment Channel': item.enrollment_channel_description,
        'Email': item.main_customer.email,
        'Phone': item.main_customer.phone,
        'Customer Connect Enrollment Code': item.customer_connect_enrollment_code,
        'Customer Connect Product Code': item.customer_connect_product_code
      };
    });
    this.datasvc.exportExcelSpreadsheet(exportAllocationList,
      'Enrolled Allocations ' + this.datasvc.getDate(),
      'EnrolledAllocations' + this.datasvc.getDate() + '.xlsx');
  }

  goToAdminDetailView(account_id, from_location) {
    this.gettingDetailData = true;
    from(
    this.api.GetAccountLookupForEmployee(<AccountLookupForEmployeeInput>{
        account_number: account_id,
    })
    ).pipe(catchError(error =>  {
        this.gettingDetailData = false;
        error.errors.forEach(iError => {
          this.gettingDetailData  = false;
          this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
        })
        return of(error)
    }))
    .subscribe((searchResults: AccountLookupForEmployee) => {
        if ((<any>searchResults).errors) {
          this.gettingDetailData  = false;
          return;
        }
        this.datasvc.setAccountLookupForEmployee(searchResults);
        this.gettingDetailData  = false;
        this.router.navigate(['admin/customer-detail', {From: from_location}]);
    });
  }

  async onReverifyEligibility() {
    if (this.isReverifyEligibilityLoading = false) {
      return;
    }
    this.isReverifyEligibilityLoading = true;
    this.itemsReverified = 0;
    // Loop through our report items and reverify eligibility for each one.  When done, refresh list and 'REVERIFY ELIGIBILITY' button...
    await this.allAllocations.forEach(enrolledAllocationReportItem => {
      from(this.api.EnrolledAllocationsReverify(<EnrolledAllocationsReverifyInput>{
        account_id: enrolledAllocationReportItem.account_id,
        business_partner_id: String(enrolledAllocationReportItem.business_partner_id),
        allocation_id: String(enrolledAllocationReportItem.allocation_id)
      })).pipe(
        catchError(error => {
          error.errors.forEach(iError => {
            this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
          });
          return of(error)
        })
      ).subscribe((response) => {
        console.log(response);
        this.itemsReverified++;
        if (this.itemsReverified === this.allAllocations.length) {
          this.getData();
          this.isReverifyEligibilityLoading = false;
        }
      });
    });
  }
}
