<div *ngIf="hasEnrolledAllocationsReportPerms" class="container-fluid">
    <BR>
    <div class="d-flex justify-content-center">
        <app-report-drop-downs (valuesChanged)="customerSegmentChanged($event)" [canShowAll]="false" 
        [defaultValue]="customerSegmentId"></app-report-drop-downs>
    </div>
    <div class="col-12">
        <app-enrolled-allocations-report-table [exportData]="exportData" (dataRetrieved)="dataRetrieved($event)"
            [customer_segment_id]="customerSegmentId"></app-enrolled-allocations-report-table>
        <div class="background-white col-12 give-size">
            <div class="row float-right">
                <de-button (click)="onNewCustomer()" type="secondary">New Customer</de-button>
                <de-button (click)="exportExcel()" type="secondary">
                    <div>Export Data &nbsp; <i class="fas fa-caret-up"></i></div>
                </de-button>
            </div>
        </div>
    </div>
</div>