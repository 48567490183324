<div class="row">
    <div class="col">
        <div class="container">
            <br>
            <span class="biggerFont"><b>Random Selection Process Controls</b></span>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-5">
        <br>
        <br><br>
        <h3>RSP Mode On?</h3>
        <div class="row" *ngFor="let custSeg of configurableCustomerSegments; let i = index">
            <div class="col-2">
                {{custSeg.is_in_rsp_mode ? 'On' : 'Off'}}
                <mat-slide-toggle
                    (change)="onRspChange($event.checked, custSeg, i)"
                    [checked]="custSeg.is_in_rsp_mode">
                </mat-slide-toggle>
            </div>
            <div class="col-10"><h5>{{custSeg.customer_segment_description}}</h5></div>
            <div class="col-12"><hr></div>
        </div>
    </div>
    <div class="col-7">
        <div class="container">
            <br>
            <br><br>
            <div class="row width100 alignCenter">
                <label class="width100"><b>First Select a Customer Segment</b></label>
            </div>
            <div class="row">
                <div class="col"></div>
                <div class="col">
                    <app-report-drop-downs (valuesChanged)="filtersSelected($event)"></app-report-drop-downs>
                </div>
                <div class="col"></div>
            </div>
            <br><br>
            <br>
            <br>
            <div class="row width100 alignCenter">
                <label class="width100"><b>Run RSP Process for Processing Status</b></label>
            </div>
            <div class="row">
                <div class="col"></div>
                <div class="col">
                    <de-button size="small" (click)="onProcessRSP()" [disabled]="customerSegmentId == null"
                        type="primary">
                        Process RSP</de-button>
                </div>
                <div class="col"></div>
            </div>
            <br>
            <div class="row width100 alignCenter">
                <label class="width100"> <b>Get List Of Pending Enrollment Accounts</b></label>
            </div>
            <div class="row">
                <div class="col"></div>
                <div class="col">
                    <de-button size="small" (click)="onGetRSPList()" [disabled]="customerSegmentId == null"
                        type="primary">
                        Show</de-button>
                </div>
                <div class="col"></div>
            </div>
            <br>
            <div class="row width100 alignCenter">
                <label class="width100"><b>Upload Govt/Commercial Large file (Does Not Require a Customer Segment
                    Selected)</b></label>
            </div>
                
            <div class="row">
                <div class="col-3"></div>
                <div class="col-5">
                    <de-button class="pl-3" size="large" (click)="onUploadLargeFile()" type="primary">Upload Gov/Comm File
                    </de-button>
                </div>
                <div class="col-4"></div>
                <input #fileUpload type="file" class="upload" (change)="changeListener($event.target.files)" style="display: none;">
            </div>
        </div>
    </div>
</div>