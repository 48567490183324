import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, DataService } from 'src/app/services';
import { AccountLookupForEmployee, APIServiceCodegen } from 'src/app/API.service';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {
  accountDetails: AccountLookupForEmployee = null;
  gettingCustomerData = false;
  showOverlay = true;
  previousPage = '';
  backToText = 'Customer Lookup';
  customerDetailForm: FormGroup = new FormGroup({
    filterOptions: new FormControl('')
  });
  public closePanelsOtherThanEmitter: EventEmitter<number> = new EventEmitter();

  constructor(public apisvc: ApiService,
    public api: APIServiceCodegen,
    public datasvc: DataService,
    public route: ActivatedRoute,
    private router: Router) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['From']) {
        this.previousPage = params['From'];
        this.setBackToText();
      }
    });
    this.fetchDetailedAccountsFromSession()
  }

  fetchDetailedAccountsFromSession() {
    this.gettingCustomerData = true;
    this.accountDetails = this.datasvc.getAccountLookupForEmployee();
    this.gettingCustomerData = false;
  }

  closePanelsOtherThan($event) {
    this.closePanelsOtherThanEmitter.emit(<number>$event);
  }

  onGoBack() {
    if (this.previousPage === 'AllocationReport') {
      this.router.navigate(['admin/allocation-report']);
    } else if (this.previousPage === 'EnrolledAllocationsReport') {
      this.router.navigate(['admin/enrolled-allocations-report']);
    } else if (this.previousPage === 'WaitListReport') {
      this.router.navigate(['admin/wait-list']);
    } else if (this.previousPage === 'TransferListReport') {
      this.router.navigate(['admin/transfer-list']);
    } else if (this.previousPage === 'EnrollmentAuditReport') {
      this.router.navigate(['admin/enrollment-audit-report']);
    } else {
      this.router.navigate(['admin/customer-lookup']);
    }
  }

  setBackToText() {
    if (this.previousPage === 'AllocationReport') {
      this.backToText = 'Allocation Report';
    } else if (this.previousPage === 'EnrolledAllocationsReport') {
        this.backToText = 'Enrolled Allocations Report';
    } else if (this.previousPage === 'WaitListReport') {
      this.backToText = 'Wait List Report';
    } else if (this.previousPage === 'TransferListReport') {
      this.backToText = 'Transfer List Report';
    } else if (this.previousPage === 'EnrollmentAuditReport') {
      this.backToText = 'Enrollment Audit Report';
    } else {
      this.backToText = 'Customer Lookup';
    }
  }
}
