<form [formGroup]="facilityAlertForm" novalidate>
  <div class="mat-table-format">
    <de-loader *ngIf="gettingFacilityAlertData" [showOverlay]="showOverlay"></de-loader>
    <table *ngIf="!gettingFacilityAlertData" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="facilityName">
        <th mat-header-cell *matHeaderCellDef>
          <div>Facility Name</div>
          <mat-form-field class="filter" floatLabel="never" *ngIf="showFilters">
            <mat-label>Facility Name</mat-label>
            <input matInput class="long" formControlName="facilityNameFilter">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.facility_name}} </td>
      </ng-container>
      <ng-container matColumnDef="issue">
        <th mat-header-cell *matHeaderCellDef>
          <div>Issue</div>
          <mat-form-field class="filter" floatLabel="never" *ngIf="showFilters">
            <mat-label>Issue</mat-label>
            <input matInput class="long" formControlName="issueFilter">
        </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.issue}} </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>
          <div>Date</div>
          <mat-form-field class="filter" floatLabel="never" *ngIf="showFilters">
            <mat-label>Date</mat-label>
            <input matInput class="long" formControlName="dateFilter">
        </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.createdAt |date}} </td>
      </ng-container>
      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef>
         <div>Note</div>
        </th>
        <td mat-cell *matCellDef="let element">
            <i class="fas fa-comment-alt" (click)="onShowDialog(element.id)"></i>
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>
          <div>Delete</div>
        </th>
        <td mat-cell *matCellDef="let element" class="alignCenter"><i class="fas fa-trash-alt"></i></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onShowDialog(row.id)" style="cursor: pointer;"></tr>
    </table>
  </div>
  <div *ngIf="!showFilters" class="bottomOfTable background-white"><a>View More</a></div>
</form>
<mat-paginator [ngStyle]="{display: showFilters ? 'block' : 'none'}" [pageSizeOptions]="[10]"></mat-paginator>
