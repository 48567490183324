<form [formGroup]="customerAlertForm" novalidate>
  <div class="mat-table-format">
    <de-loader *ngIf="gettingCustomerAlertData" [showOverlay]="showOverlay"></de-loader>
    <table *ngIf="!gettingCustomerAlertData" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="full_name">
        <th mat-header-cell *matHeaderCellDef>
          <div>Customer Name</div>
          <mat-form-field class="filter" floatLabel="never" *ngIf="showFilters">
            <mat-label>Customer Name</mat-label>
            <input matInput class="long" formControlName="full_nameFilter">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.full_name}} </td>
      </ng-container>
      <ng-container matColumnDef="issue">
        <th mat-header-cell *matHeaderCellDef>
          <div>Issue</div>
          <mat-form-field class="filter" floatLabel="never" *ngIf="showFilters">
            <mat-label>Issue</mat-label>
            <input matInput class="long" formControlName="issueFilter">
        </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.issue}} </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>
          <div>Date</div>
          <mat-form-field class="filter" floatLabel="never" *ngIf="showFilters">
            <mat-label>Date</mat-label>
            <input matInput class="long" formControlName="dateFilter">
        </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.created_date|date}} </td>
      </ng-container>
      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef>
         <div>Note</div>
        </th>
        <td mat-cell *matCellDef="let element">
            <i class="fas fa-comment-alt" (click)="onShowDialog(element.alert_id)"></i>
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef class="alignCenter">
          <div>Delete</div>
        </th>
        <td mat-cell *matCellDef="let element" class="alignCenter"><i class="fas fa-trash-alt"></i></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div *ngIf="!showFilters" class="bottomOfTable background-white"><a>View More</a></div>
</form>
<mat-paginator [ngStyle]="{display: showFilters ? 'block' : 'none'}" [pageSizeOptions]="[10]"></mat-paginator>
