import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styles: []
})
export class UnauthorizedComponent implements OnInit {
  constructor(public router: Router, public datasvc: DataService) {
  }

  // ngOnInit() {
  //   this.navigateToDashboard();
  // }
  //   navigateToDashbaord(){
      
  //   }

  // }

  ngOnInit() {
    this.datasvc.incrementUnauthorized();
    if (+this.datasvc.getUnauthorizedCount() < 4) {
      setTimeout(() => {
        this.router.navigate(['admin/admin-dashboard']);
      }, 10000);
    }

  }

}
