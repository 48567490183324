<br><br>
<div class="d-flex justify-content-center">
    <form [formGroup]="subscriptionForm" *ngIf="subscriptionForm" novalidate>
        <div class="container">
            <mat-card class="cardPadding">
                <div *ngIf="action !== 'Transfer'" class="row sectionHeader">
                    {{action}} Subscription for {{accountNumber}}
                </div>
                <div *ngIf="action == 'Transfer'" class="row sectionHeader">
                    {{action}} Subscription 
                </div>
                <br><br>
                <section *ngIf="action == 'Transfer'">
                <mat-label class="main-label">Account Number</mat-label>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput type="number" class="long" formControlName="accountNumber">
                </mat-form-field>
                <mat-label class="main-label">Business Partner Id</mat-label>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput type="number" class="long" formControlName="businessPartner">
                </mat-form-field>
                <mat-label class="main-label">ZPart Year</mat-label>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput type="number" class="long" formControlName="zYear">
                </mat-form-field>
                <mat-label class="main-label">ZPart Month</mat-label>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput type="number" class="long" formControlName="zMonth">
                </mat-form-field>
                </section>
                <br>
                <mat-label class="main-label">Subscription Size (kW)
                    <a *ngIf="action == 'Edit' || action == 'New'" class="inner-label" (click)="showViewDetail()"
                        href="javascript:;"> View Details</a>
                </mat-label>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput type="number" class="long" formControlName="subscriptionSize">
                </mat-form-field>
                <br>
                <mat-label class="main-label">Enrollment Channel</mat-label>
                <mat-form-field class="filter" floatLabel="never">
                    <mat-select formControlName="enrollment_channel" class="long">
                        <mat-option *ngFor="let channel of enrollmentChannels; let i = index"
                            [(value)]="enrollmentChannels[i].id">
                            {{channel.description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
                <br *ngIf="action !== 'New' && action !== 'Transfer' ">
                <section *ngIf="createdDate">
                    <mat-label class="main-label">Created Date</mat-label>
                    <br>
                    <br>
                    <span>{{createdDate| date}}</span>
                    <br>
                    <br>
                </section>
                <section *ngIf="activeDate">
                    <mat-label class="main-label">Active Date</mat-label>
                    <br>
                    <br>
                    <span>{{activeDate| date}}</span>
                    <br>
                    <br>
                </section>
                <section *ngIf="cancelledDate">
                    <mat-label class="main-label">Cancelled Date</mat-label>
                    <br>
                    <br>
                    <span>{{cancelledDate| date}}</span>
                    <br>
                    <br>
                </section>
                <section *ngIf="action !== 'Transfer'">
                <mat-label class="main-label">Customer Segment</mat-label>
                <mat-form-field class="filter" floatLabel="never">
                    <mat-select formControlName="customerSegmentType" class="long">
                        <mat-option *ngFor="let custSegmentType of customerSegmentTypes;"
                            [(value)]="custSegmentType.customer_segment_id"
                            [(selected)]="this.accountDetails.customer_segment.customer_segment_id === custSegmentType.customer_segment_id"
                        > 
                            {{custSegmentType.customer_segment_description}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                </section>
                <section *ngIf="action == 'Transfer'">
                    <mat-label class="main-label">Customer Segment</mat-label>
                    <mat-form-field class="filter" floatLabel="never">
                        <mat-select formControlName="customerSegmentType" class="long">
                            <mat-option *ngFor="let custSegmentType of customerSegmentTypes;"
                                [(value)]="custSegmentType.customer_segment_id"
                            > 
                                {{custSegmentType.customer_segment_description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    </section>
                <br>
                <ng-container *ngIf="action !== 'New' && action !== 'Transfer'">
                    <mat-label class="main-label">Status</mat-label>
                    <span><br><br>{{status}} <span *ngIf="cc_enrollment_code"> - {{cc_enrollment_code}}</span><br></span>
                    <mat-form-field *ngIf="action == 'Edit' && statusId == allocationStatus.AwaitingVerification" class="filter" floatLabel="never">
                        <mat-label class="">Change Status...</mat-label>
                        <mat-select formControlName="newAllocationStatus" class="long">
                            <mat-option [value]="nullValue"></mat-option>
                            <mat-option [value]="allocationStatus.Waitlist">Waitlist</mat-option>
                            <mat-option [value]="allocationStatus.VerificationFailed">Verification Failed</mat-option>
                        </mat-select>
                    </mat-form-field>
                </ng-container>           
                <div class="lowIncomeArea" *ngIf="showCancellationItems == true">
                    <mat-label class="main-label">Cancel Reason</mat-label>
                    <mat-form-field class="filter" floatLabel="never">
                        <mat-select formControlName="cancelReason" class="long">
                            <mat-option *ngFor="let reason of cancelationReasons; let i = index"
                                [(value)]="cancelationReasons[i].id">
                                {{reason.description}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <br>
                    <br>
                </div>
                <br>
                <div>
                    <mat-label class="main-label">Notes</mat-label>
                    <mat-form-field class="filter" floatLabel="never">
                        <textarea matInput formControlName="notes" class="long" rows="4">
                    </textarea>
                    </mat-form-field>
                    <br>
                </div>
                <div class="float-right row" *ngIf="action === 'Cancel'">
                    <de-button type="secondary" (click)="onCancel()" [loading]="areButtonsLoading">Back</de-button>
                    <de-button type="secondary" (click)="onCancelEnrollment()" [loading]="areButtonsLoading"
                        [disabled]="!hasCancelReason()">Cancel Enrollment</de-button>
                </div>
                <div class="float-right row" *ngIf="action === 'New'">
                    <de-button type="secondary" (click)="onCancel()" [loading]="areButtonsLoading">Back</de-button>
                    <de-button type="secondary" (click)="onEnroll()" [loading]="areButtonsLoading"
                        [disabled]="!subscriptionForm.valid">Save
                    </de-button>
                </div>
                <div class="float-right row" *ngIf="action === 'Transfer'">
                    <de-button type="secondary" (click)="onCancel()" [loading]="areButtonsLoading">Back</de-button>
                    <de-button type="secondary" (click)="onTransfer()" [loading]="areButtonsLoading"
                        [disabled]="!subscriptionForm.valid">Save
                    </de-button>
                </div>
                <div class="float-right row" *ngIf="action === 'Edit'">
                    <de-button type="secondary" (click)="onCancel()" [loading]="areButtonsLoading">Back</de-button>
                    <de-button type="secondary" (click)="onUpdate()" [loading]="areButtonsLoading"
                        [disabled]="!subscriptionForm.valid">Update
                    </de-button>
                </div>
                <div class="float-right row" *ngIf="action === 'View'">
                    <de-button type="secondary" (click)="onCancel()" [loading]="areButtonsLoading">Back</de-button>
                </div>
                <br><br><br><br>
            </mat-card>
        </div>
    </form>
</div>