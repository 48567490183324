import { Component, OnInit, Input } from '@angular/core';
import { DataService, ApiService } from 'src/app/services';
import { SegmentService } from 'src/app/services/segment.service';
import { APIServiceCodegen } from 'src/app/API.service';
import { Subscription } from 'rxjs';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { FinancialResponse, FinancialModel } from '../models/admin.model';
import { map } from "rxjs/operators";
import { reject } from 'lodash';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
    selector: 'app-financials-box',
    templateUrl: './financials-box.component.html',
    styleUrls: ['./financials-box.component.scss']
})

export class FinancialsBoxComponent {
    public feesCollected: number;
    public creditsProvided: number;
    public savings: String;
    public financialModel: FinancialModel = new FinancialModel();
    //private isMonthly: boolean = true;
    private subscription: Subscription;

    constructor(public datasvc: DataService,
        public segService: SegmentService,
        public api: APIServiceCodegen) {
            this.subscription = this.segService.currentSegment$.subscribe( res => {
                this.updateByParticipationSegment(res);
            })
         }
        
         updateByParticipationSegment(segmentId: string){
            this.api.GetFinancial(segmentId).then( response => {
                this.financialModel = (<FinancialModel>response);
                console.log(this.financialModel)
            })
            .catch( err => {
                console.log(ErrorDialogComponent.API_GENERIC_ERROR_500_MSG)
                console.log(err)
            });
        }    
        
        ngOnDestroy() {
            this.subscription.unsubscribe()
         }
}