import { Component, OnInit, Input, ViewChild, EventEmitter, OnDestroy, Output } from '@angular/core';
import { MatPaginator, MatSnackBar, MatTableDataSource } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataService, AllocationTableService } from 'src/app/services';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { AccountLookupForEmployee, AllocationReport, APIServiceCodegen, AccountLookupForEmployeeInput, ReportCriteriaInput, UpdateAdminUserInput } from 'src/app/API.service';
import { from, of } from 'rxjs';
import { AllocationStatus, Permissions } from '../models/admin.model';
import { environments } from 'src/config/env.config';
import { env } from 'src/config/env';

@Component({
  selector: 'app-allocation-table',
  templateUrl: './allocation-table.component.html',
  styleUrls: ['./allocation-table.component.scss']
})
export class AllocationTableComponent implements OnInit, OnDestroy {
  @Input() customer_segment_id: string | null;
  @Input() exportData: EventEmitter<any> = new EventEmitter();
  @Output() dataRetrieved = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['accountNumber','customerSegment', 'resNonRes', 'lowIncome', 'installationId', 'billingPeriod',
  'businessPartnerId','subscriptionSize', 'createdDate', 'status', 'cancelReason', 'enrollment', 'enrollmentCode','productCode', 'actions'];
  dataSource = new MatTableDataSource<AllocationReport>();
  allAllocations: AllocationReport[];
  public hasAllocationEditPerms = false;
  public hasAllocationViewPerms = false;
  public hasAllocationCancelPerms = false;
  public hasAllocationActAsPerms = false;
  public get allocationStatus(): typeof AllocationStatus {
    return AllocationStatus; 
  }
  subscriptionList: Subscription[] = [];
  gettingAlloactionData = false;
  gettingDetailData = false;
  showOverlay = true;
  pageLength = 0;
  pageIndex = 0;
  allocationForm: FormGroup = new FormGroup({
    customerNameFilter: new FormControl(''),
    resNonResFilter: new FormControl(''),
    lowIncomeFilter: new FormControl(''),
    installationIdFilter: new FormControl(''),
    billingPeriodFilter: new FormControl(''),
    accountNumberFilter: new FormControl(''),
    businessPartnerIdFilter: new FormControl(''),
    streetFilter: new FormControl(''),
    cityFilter: new FormControl(''),
    zipFilter: new FormControl(''),
    createdDateFilter: new FormControl(''),
    pendingDateFilter: new FormControl(''),
    activeDateFilter: new FormControl(''),
    cancelledDateFilter: new FormControl(''),
    statusFilter: new FormControl(''),
    cancelReasonFilter: new FormControl(''),
    enrollmentFilter: new FormControl(''),
    emailFilter: new FormControl(''),
    phoneFilter: new FormControl(''),
  });
  pageSize = 10;
  filterByAccountNumber: string = null;

  resNonResOpts = [{value: "R", description: "Residential"}, {value: "N", description: "Non-Residential"}, { value: "", description: ""}]
  lowIncomeOpts = [{ value: "true", description: "Low" }, { value: "false", description: "Market" }, { value: "", description: ""}];
  zipOpts = [];
  statusOpts = [];
  cancelReasonOpts = [];
  enrollmentOpts = [];
  config: any;

  filterValues = {
    customerName: "",
    resNonRes: "",
    lowIncome: "",
    installationId: "",
    billingPeriod: "",
    accountNumber: "",
    businessPartnerId: "",
    street: "",
    city: "",
    zip: "",
    createdDate: "",
    pendingDate: "",
    activeDate: "",
    cancelledDate: "",
    status: "",
    cancelReason: "",
    enrollment: "",
    email: "",
    phone: "",
  };
  private portalUrl : any;

  constructor(public datasvc: DataService,
    public router: Router,
    public allocationTableSvc: AllocationTableService,
    public api: APIServiceCodegen,
    public snackBar: MatSnackBar) { 
      this.portalUrl =  environments[env.env].portal_uri;
    }

  ngOnInit() {
    console.log(JSON.stringify(this.datasvc.getUserObject()));
    let permList = this.datasvc.getPermissions();
    if (permList.find(num => num == Permissions.AllocationEdit)) {
        this.hasAllocationEditPerms = true;
    }
    if (permList.find(num => num == Permissions.AllocationView)) {
        this.hasAllocationViewPerms = true;
    }
    if (permList.find(num => num == Permissions.AllocationCancel)) {
        this.hasAllocationCancelPerms = true;
    }
    if (permList.find(num => num == Permissions.UserAdminSettings)) {
      this.hasAllocationActAsPerms = true;
  }
    this.subscriptionList.push(this.exportData.subscribe(() => {
      this.exportExcel();
    }));
    this.getData();
  }

  ngOnDestroy() {
    this.subscriptionList.forEach(sub => {
      sub.unsubscribe();
    });
  }

  ngOnChanges() {
    if (!this.allAllocations) {
      return;
    }
    this.getData();
  }

  copyText(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  getData() {
    this.resetFormFilter();
    if (this.gettingAlloactionData) {
      return;
    }
    this.gettingAlloactionData = true;
    this.subscriptionList.push(
      from(
        this.api.ListAllocationReport(<ReportCriteriaInput>{
          id_token: this.datasvc.getIdTokenFromLocalStorage()[1].toString(),
          customer_segment_id: this.customer_segment_id,
        })
      ).pipe(
        catchError(error => {
          error.errors.forEach(iError => {
            this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
          });
          return of(error)
        })
      ).subscribe((allocationResults: AllocationReport[]) => {
        this.zipOpts = [...new Set(allocationResults.map(x => x.premise_address.zip))];
        this.statusOpts = [...new Set(allocationResults.map(x => x.allocation_status_description))];
        this.cancelReasonOpts = [...new Set(allocationResults.map(x => x.cancel_reason_description))];
        this.enrollmentOpts = [...new Set(allocationResults.map(x => x.enrollment_channel_description))];
        this.zipOpts.push("");
        this.statusOpts.push("");
        this.enrollmentOpts.push("");

        this.pageLength = allocationResults.length;
        this.dataSource = new MatTableDataSource<AllocationReport>(allocationResults);
        this.dataSource.filterPredicate = this.datasvc.createAllocationFilter();
        this.allAllocations = allocationResults;
        this.dataRetrieved.emit(allocationResults);
        this.gettingAlloactionData = false;
        this.setUpFormValueChanges();
        this.dataSource.paginator = this.paginator;
      })
    );
  }

  resetFormFilter() {
    this.allocationForm.setValue({
      customerNameFilter: '',
      resNonResFilter: '',
      lowIncomeFilter: '',
      installationIdFilter: '',
      billingPeriodFilter: '',
      accountNumberFilter: '',
      businessPartnerIdFilter: '',
      streetFilter: '',
      cityFilter: '',
      zipFilter: '',
      createdDateFilter: '',
      pendingDateFilter: '',
      activeDateFilter: '',
      cancelledDateFilter: '',
      statusFilter: '',
      cancelReasonFilter: '',
      enrollmentFilter: '',
      emailFilter: '',
      phoneFilter: ''
    });
  }

  setUpFormValueChanges() {
      this.displayedColumns.forEach(column => {
        if (this.allocationForm.get(column + 'Filter') == null) {return; }
          this.allocationForm.get(column + 'Filter').valueChanges.subscribe(value => {                                                                                                  
            this.filterValues[column] = value;
            this.dataSource.filter = JSON.stringify(this.filterValues);
          });
      });

  }

  goToViewAs(accountId) {
      let row_index = 0
      let user = this.datasvc.getUserObject();

          const userRequest = {
              user_id: user.user_id, 
              account_id_view_as: accountId, 
              user_role_id: user.user_role_id,
          };
          from(this.api.UpdateAdminUser(<UpdateAdminUserInput>userRequest))
          .pipe(catchError(error => {
              error.errors.forEach(iError => {
                  this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
              })
              return of(error)
            })).subscribe(() => {
              window.open(this.portalUrl + `/admin;Username=${user.user_id}`, '_blank');
          })
        ;
  }

  goToAllocation(allocation_id, account_id, mode) {
    this.gettingDetailData = true;
    from(
    this.api.GetAccountLookupForEmployee(<AccountLookupForEmployeeInput>{
        account_number: account_id
    })
    ).pipe(catchError(error =>  {
        this.gettingDetailData = false;
        error.errors.forEach(iError => {
          this.gettingDetailData  = false;
          this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
        })
        return of(error)
    }))
    .subscribe ((searchResults: AccountLookupForEmployee) => {
        if ((<any>searchResults).errors) {
          this.gettingDetailData  = false;
          return;
        }
        // Fetch our account and then the specific allocation index for the one we care about...
        this.gettingDetailData  = false;
        this.datasvc.setAccountLookupForEmployee(searchResults);
        this.router.navigate(['admin/subscription', { Mode: mode, From: 'AllocationReport'}], {
          state: {
            accountDetails: searchResults,
            index: searchResults.allocations.findIndex(x => x.allocation_id === allocation_id)
          }
        });
    });
  }

  exportExcel() {
    const exportAllocationList = this.dataSource.filteredData.map(rec => {
      return {
        'Customer Name': rec.main_customer.full_name,
        'Customer Segment': rec.customer_segment_description,
        'Res / Non-Res': rec.res_non_res == 'R' ? 'Res' : 'Non-Res',
        'Low Income': rec.low_income_flag ? 'Low Income' : 'Market Rate',
        'Account Number': rec.account_id,
        'Business Partner Id': rec.business_partner_id,
        'Installation Id': rec.installation_id,
        'Contract Id': rec.billing_period,
        'Street': rec.premise_address.line1,
        'City': rec.premise_address.city,
        'Zip Code': rec.premise_address.zip,
        'Subscription Size': rec.allocation_size_kw,
        'Created Date': rec.created_at,
        'Pending Date': rec.pending_date,
        'Active Date': rec.active_date,
        'Cancelled Date': rec.cancelled_date,
        'Status': rec.allocation_status_description,
        'Cancel Reason': rec.cancel_reason_description,
        'Enrollment': rec.enrollment_channel_description,
        'Email': rec.main_customer.email,
        'Phone': rec.main_customer.phone,
        'Customer Connect Enrollment Code': rec.customer_connect_enrollment_code,
        'Customer Connect Product Code': rec.customer_connect_product_code,
      };
    });
    this.datasvc.exportExcelSpreadsheet(exportAllocationList,
      'Allocation Export ' + this.datasvc.getDate(),
      'AllocationExport' + this.datasvc.getDate() + '.xlsx');
  }

  goToAdminDetailView(account_id, from_location) {
    this.gettingDetailData = true;
    from(
    this.api.GetAccountLookupForEmployee(<AccountLookupForEmployeeInput>{
        account_number: account_id,
    })
    ).pipe(catchError(error =>  {
        this.gettingDetailData = false;
        error.errors.forEach(iError => {
          this.gettingDetailData  = false;
          this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
        })
        return of(error)
    }))
    .subscribe((searchResults: AccountLookupForEmployee) => {
        if ((<any>searchResults).errors) {
          this.gettingDetailData  = false;
          return;
        }
        this.datasvc.setAccountLookupForEmployee(searchResults);
        this.gettingDetailData  = false;
        this.router.navigate(['admin/customer-detail', {From: from_location}]);
    });
  }
}
