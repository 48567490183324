<span class="close" (click)="closeModal()">&times;</span>
<br><br>
<div class="title"><b>{{data.facility_data.facility_name}} - {{data.facility_data.total_capacity_kw}}
        kW</b></div>
<br>
<br>
<div class="row">
    <div class="col-2"></div>
    <div class="description col-8"><b>Use the table below to adjust the enrollment fee per kWh prices. Set a
            date at which the enrollment price changes and it will remain that price until the next date is reached.</b>
    </div>
</div>
<br><br>
<div class="row header-row table-row">
    <div class="col-1"></div>
    <div class="col-2">Date Of Change</div>
    <div class="col-2"></div>
    <div class="col-2">Residential Fee</div>
    <div class="col-2"></div>
    <div class="col-2">Non-Residential Fee</div>
    <div class="col-1"></div>
</div>
<div *ngFor="let feeDate of feeDateItems; let feeIndex = index;" class="row table-row">
    <br>
    <app-date-row [feeDateItem]="feeDate"></app-date-row>
</div>
<br>
<div class="row description">
    <a href="javascript:;" (click)="addDate();" class="formGroup"><b>+ Add Another Date</b></a>
</div>
<br> 
<div class="float-right row">
    <de-button type="secondary" >CANCEL</de-button>
    <de-button type="secondary" >SAVE</de-button>
</div>