<br><br>
<div class="d-flex justify-content-center">
    <form [formGroup]="programForm" novalidate>
        <div class="container">
            <mat-label class="col-2">Program Id</mat-label>
                <mat-form-field class="filter" floatLabel="never">
                    <input type="number" matInput class="long" formControlName="program_id" readonly='true'>
                </mat-form-field>
                <br>
                <mat-label class="col-2">Jurisdiction</mat-label>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput class="long" formControlName="jurisdiction_id" readonly='true'>
                </mat-form-field>
                <br>
                <mat-label class="col-2">Is Low Income</mat-label>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput class="long" formControlName="res_low_income" readonly='true'>
                </mat-form-field>
                <br>
                <mat-label class="col-2">Is Market Rate</mat-label>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput class="long" formControlName="res_market_rate" readonly='true'>
                </mat-form-field>
                <br>
                <mat-label class="col-2">Appliaction Fee</mat-label>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput class="long" formControlName="application_fee" readonly='true'>
                </mat-form-field>
                <br>
                <mat-label class="col-2">Monthly Fee Per Kw</mat-label>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput class="long" formControlName="monthly_fee_per_kw" readonly='true'>
                </mat-form-field>
                <br>
                <mat-label class="col-2">Credit Per Kw</mat-label>
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput class="long" formControlName="credit_per_kw" readonly='true'>
                </mat-form-field>
                <br>   
                <mat-label class="col-2">CC Code</mat-label>            
                 <mat-form-field class="filter" floatLabel="never">
                    <input matInput class="long" formControlName="customer_connect_product_code" readonly='true'>
                </mat-form-field>
                <br>   
                <mat-label class="col-2">Program Capacity Kw</mat-label>             
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput class="long" formControlName="program_capacity_kw" readonly='true'>
                </mat-form-field>
                <br>     
                <mat-label class="col-2">Facility Names</mat-label>           
                <mat-form-field class="filter" floatLabel="never">
                    <input matInput class="long" formControlName="facility_names" readonly='true'>
                </mat-form-field>
                <br>
        </div>
    </form>
</div>