import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {Apollo, gql, QueryRef} from 'apollo-angular';
import {
    CreateAlertsCustomerGQL,
    AllAlertsCustomersGQL
    //QueryAllAlertsFacilitiesArgs,
    //AllAlertsCustomersGQL, 
    //QueryAllAlertsCustomersArgs,
    //AllAlertsCustomersDocument,
    //AllAlertsCustomersQuery, 
    //ProgramAccountCapacity,
    //AllAlertsFacilitiesGQL,
    //AllAlertsFacilitiesQuery,
    //AlertsEnrollment
} from 'src/generated/graphql'
import { map, observeOn } from 'rxjs/operators';
import {
    AccountResponse, CustomerSearchCriteria,
    CustomerSearchResponse,
    Address,
    AccountLookupModel,
    NewEnrollmentRequest,
    EditEnrollmentRequest
} from '../components/models/customer-detail.model';
import {
    VerificationTypeResponse, AccountStatusResponse, AccountTypeResponse, FacilityResponse, PanelTypeResponse, StateResponse,
    FacilityReportResponse,
    FacilitySetupResponse,
    CountyResponse,
    ProgramReportResponse,
    FacilityDetail,
    AlertsResponse,
    AlertsModel,
    ProgramResponse,
    WaitListModel,
    WaitListResponse,
    FinancialResponse,
    CancellationRequestModel,
    ProgramLookupResponse,
    WaitListReorderRequest,
    WaitListReorderResponse,
    UserRoleResponse,
    UserRoleDropDownResponse,
    UpdateUsersRequest,
    SubscriptionCardResponse,
    FacilityGenerationResponse,
    FacilityAlertModel,
    EnrollmentAlertModel,
    CustomerAlertModel,
    EnrollmentAlertResponse,
    FacilityAlertResponse,
    CustomerAlertResponse,
    AlertUpdateRequest
} from '../components/models/admin.model';
import { ReportCustomerResponse, Allocation, CancelReasonResponse,
     AllocationResponseModel} from '../components/models/customer-report.model';
import { EnrollmentChannelResponse } from '../components/models/subscription.model';

// GRAPHQL queries
import { PROGRAMS } from '../graphql';
import { JURISDICTIONS } from '../graphql';
import { FACILITIES } from '../graphql';
import { FINANCIALS } from '../graphql';
import { PRGM_ACCNT_COUNT_BY_ID } from '../graphql';
import { PRGM_ACCNT_CAPACITY_BY_ID } from '../graphql';
import { CUSTOMER_ALERTS } from '../graphql';
import { FACILITY_ALERTS } from '../graphql';
import { ENROLLMENT_ALERTS } from '../graphql';
import { CUSTOMER_REPORTS } from '../graphql';
import { ACCOUNT_LOOKUP } from '../graphql';
import { CUSTOMER_DETAIL,  CUSTOMER_DETAILS} from '../graphql';
import { ALLOCATION_REPORT } from '../graphql';
import { WAITLIST } from '../graphql';
import { CANCEL_REASON } from '../graphql';
import { ENROLLMENT_CHANNEL } from '../graphql';
import { VERIFICATION_TYPE } from '../graphql';
import { STATE } from '../graphql';
import { FACIITY_REPORT } from '../graphql';
import { USERS } from '../graphql';
import { USER_ROLE } from '../graphql';
import { CREATE_ALERTS_CUSTOMER } from '../graphql';
import { now } from 'lodash';


@Injectable({
    providedIn: 'root'
})

export class ApiService {
    private apiUrl: string;

    constructor(
        private createAlertsCustomerGQL: CreateAlertsCustomerGQL,
        private allAlertsCustomersGQL: AllAlertsCustomersGQL,
        private http: HttpClient,
        private apollo: Apollo) {
    }

    getWaitlist(programId: number): Observable<any> {
        let url = this.apiUrl + `reports/wait-list?allocationStatusId=1`;
        if (programId) {
            url = url + '&programId=' + programId;
        }
        return this.apollo.query({
            query: WAITLIST
        })
        //return this.http.get<WaitListResponse>(url);
    }

    getSubscriptionCardResults(subscriptionSizeKw: number, programId: number, resNonRes: string, accountId: string) {
        let url = this.apiUrl + `calculations/subscription-card?subscriptionSizeKw=
        ${subscriptionSizeKw}&programId=${programId}&resNonRes=${resNonRes}`;
        if (accountId) {
            url = url + `&accountId=${accountId}`;
        }
        return this.http.get<SubscriptionCardResponse>(url);
    }

    getFacilityAlertList(jurisdiction_Id: number, program_Id: number, rowLimit: number = null, facilityId: number = null):Observable<any> {
        let url = this.apiUrl + `alerts/facility?`;
        if (jurisdiction_Id || program_Id) {
            if (jurisdiction_Id) {
                url = url + `jurisdictionId=${jurisdiction_Id}`;
            }
            if (jurisdiction_Id && program_Id) {
                url = url + '&';
            }
            if (program_Id) {
                url = url + `programId=${program_Id}`;
            }
            if (rowLimit) {
                url = url + `&`;
            }
        }
        if (rowLimit) {
            url = url + `rowLimit=${rowLimit}`;
        }
        if (facilityId) {
            url = url + (url.indexOf('=') !== 0 ? `&` : '') + `facilityId=${facilityId}`;
        }
        // let queryRef: QueryRef<AllAlertsFacilitiesQuery> = this.allAlertsFacilitiesGQL.watch()
        // return queryRef.valueChanges.pipe(
        //     map( (res) => res.data.allAlertsFacilities.nodes))
        return this.apollo.query({
            query: FACILITY_ALERTS,
            // query:  gql`
            //       query ListFacilityAlert(
            //         $filter: ModelFacilityAlertsFilterInput
            //         $limit: Int
            //         $nextToken: String
            //       ) {
            //         listFacilityAlert(filter: $filter, limit: $limit, nextToken: $nextToken) {
            //           items {
            //             id
            //             alert_id
            //             facility_name
            //             created_date
            //             issue
            //             notes
            //             createdAt
            //             updatedAt
            //           }
            //           nextToken
            //         }
            //       }
            //     `,
            variables: {
                rowLimits: rowLimit
            }
       })
    }

    getEnrollmentAlertList(jurisdiction_Id: number, program_Id: number, rowLimit: number = null):Observable<any> {
        let url = this.apiUrl + `alerts/enrollment?`;
        if (jurisdiction_Id || program_Id) {
            if (jurisdiction_Id) {
                url = url + `jurisdictionId=${jurisdiction_Id}`;
            }
            if (jurisdiction_Id && program_Id) {
                url = url + '&';
            }
            if (program_Id) {
                url = url + `programId=${program_Id}`;
            }
            if (rowLimit) {
                url = url + `&`;
            }
        }
        if (rowLimit) {
            url = url + `rowLimit=${rowLimit}`;
        }
        return this.apollo.query({
            query: ENROLLMENT_ALERTS,
            variables: {
                rowLimits: rowLimit
            }
       })
    }

    getCustomerAlertList(jurisdiction_Id: number, program_Id: number, rowLimit: number = null): Observable<any> {
        let url = this.apiUrl + `alerts/customer?`;
        if (jurisdiction_Id || program_Id) {
            if (jurisdiction_Id) {
                url = url + `jurisdictionId=${jurisdiction_Id}`;
            }
            if (jurisdiction_Id && program_Id) {
                url = url + '&';
            }
            if (program_Id) {
                url = url + `programId=${program_Id}`;
            }
            if (rowLimit) {
                url = url + `&`;
            }
        }
        if (rowLimit) {
            url = url + `rowLimit=${rowLimit}`;
        }
        // let queryRef: QueryRef<AllAlertsCustomersQuery> = this.allAlertsCustomersGQL.fetch()
        // return queryRef.valueChanges.pipe(
        //     map( (res) => res.data.allAlertsCustomers.nodes))

            return this.allAlertsCustomersGQL.fetch().pipe(map (
                res => res.data.allAlertsCustomers.nodes))
        
           //return queryRef.valueChanges.pipe()
        //    return this.apollo.query({
        //     query: CUSTOMER_ALERTS
        //   })
      }

    getEnrollmentChannels(): Observable<any> {
        const url = this.apiUrl + `enrollment-channels`;
        // return this.apollo.query({
        //     query: AllAlertsCustomersDocument
        //   })
        return this.http.get<EnrollmentChannelResponse>(url);
    }

    getFacilityGenerationData(facilityId: number): Observable<FacilityGenerationResponse> {
        const url = this.apiUrl + `facilities/${facilityId}/monthly-output`;
        return this.http.get<FacilityGenerationResponse>(url);
    }

    searchForCustomer(searchDetails: CustomerSearchCriteria): Observable<any> {
        const url = this.apiUrl + `customers/lookup`;

console.log("====searchForCustomer")

        return this.apollo.query({
            query: ACCOUNT_LOOKUP,
            // variables: {
            //     account_number: searchDetails.account_number,
            //     last_4_ssn: searchDetails.last_4_ssn,
            //     last_4_fed_tax_id: searchDetails.last_4_fed_tax_id,
            //     email: searchDetails.email,
            //     phone: searchDetails.phone,
            //     date_of_birth: searchDetails.date_of_birth,
            //     res_non_res: searchDetails.res_non_res
            // }
       })
        return this.http.post<CustomerSearchResponse>(url, searchDetails);
    }

    insertFacility(facility: FacilityDetail) {
        const url = this.apiUrl + `facilities`;
        return this.http.post<any>(url, facility);
    }

    updateFacility(facility: FacilityDetail) {
        const url = this.apiUrl + `facilities`;
        return this.http.post<any>(url, facility);
    }

    getProgramById(id: number) {
        const url = this.apiUrl + `programs/` + id;
        return this.http.get<ProgramResponse>(url);
    }


    getPrograms(): Observable<any> {
        const url = this.apiUrl + `reports/programs`;
        return this.apollo.query<FacilityResponse>({
            query: PROGRAMS
          })
    }

    getUsers(): Observable<any> {
        const url = this.apiUrl + `reports/users`;
        return this.apollo.query({
            query: USERS
          })
        //return this.http.get<UserRoleResponse>(url);
    }

    getUserRoles(): Observable<any> {
        const url = this.apiUrl + `users/roles`;
        return this.apollo.query({
            query: USER_ROLE
          })
        //return this.http.get<UserRoleDropDownResponse>(url);
    }

    updateUsers(usersToUpdate: UpdateUsersRequest): Observable<UserRoleResponse> {
        const url = this.apiUrl + `users`;
        return this.http.put<UserRoleResponse>(url, usersToUpdate);
    }

    getProgramAccountCounts(jurisdiction_Id: number, program_Id: number, facility_Id: number): Observable<any> {
            let url = this.apiUrl + `dashboard/program-account-counts?`;
            if (facility_Id) {
                url = url + `facilityId=${facility_Id}`;
            } else if (program_Id) {
                url = url + `programId=${program_Id}`;
            } else if (jurisdiction_Id) {
                    url = url + `jurisdictionId=${jurisdiction_Id}`;
            }
            return this.apollo.query({
                query: PRGM_ACCNT_COUNT_BY_ID,
                variables: {
                    prgAccountCntsId: jurisdiction_Id
                }
           })
        }

    getProgramCapacity(jurisdiction_Id: number, program_Id: number, facility_Id: number): 
            Observable<any> {
        let url = this.apiUrl + `dashboard/program-capacity?`;
        if (facility_Id) {
            url = url + `facilityId=${facility_Id}`;
        } else if (program_Id) {
            url = url + `programId=${program_Id}`;
        } else if (jurisdiction_Id) {
                url = url + `jurisdictionId=${jurisdiction_Id}`;
        }
        // let queryRef: QueryRef<AllAlertsCustomersQuery> = this.allAlertsCustomersGQL.watch()
        // return queryRef.valueChanges.pipe(
        //     map( (res) => res.data.allAlertsCustomers.nodes))

        return this.apollo.query({
            query: PRGM_ACCNT_CAPACITY_BY_ID,
            variables: {
                prgAccountCapId: jurisdiction_Id
            }
       })
    }
    

    getFinancial(isMonthlyFlag: boolean): Observable<any> {
        return this.apollo.query({
            query: FINANCIALS,
            variables: {
                isMonthly: isMonthlyFlag
            },
            context: {
                headers: {
                    "Authorization": "Bearer eyJraWQiOiJVZjVjNE9IWWE3MHlxUFpXcWVhQjNDd3hKYkFQXC9JWTg2UGtkbnk4c3FBVT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIwYTQ1NTE3Mi1iYWFiLTQyZWItOThjNy03OTAxZjEwMjg0ZTEiLCJhdWQiOiIyZGx1ZTdkZms3MTBlNDkwM3M1ZDloazg0aiIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJldmVudF9pZCI6IjczYmQ1NGQ4LWFkYWYtNDFhYi1iY2ZhLWU1ZmI1ZWYxOWM0MSIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjE0MzYyODMzLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9qSEFDelBZc1ciLCJjb2duaXRvOnVzZXJuYW1lIjoiMGE0NTUxNzItYmFhYi00MmViLTk4YzctNzkwMWYxMDI4NGUxIiwiZXhwIjoxNjE0MzY2NDMzLCJpYXQiOjE2MTQzNjI4MzMsImVtYWlsIjoic2FtLnphaGFiaUBkdWtlLWVuZXJneS5jb20ifQ.w_XRv5_kt9A3Zb8VcDJl1JeP9cB__9_gLFzvQsb91QfCcBrk8kj-ToTjzGMRsry9DaeXLRYkMijtEIaowvzR1JSTRXzI5LGkKlVp5b0JhSN4WSLcW9IW9XZejnIDghhQnV2KpHJmJUclhAVUDsaI_QniO1ldx7fqF6hRFJbxROknjVbvPPHUeQw-9wCKufuRFqnMz_7xLPJfvm6vswm7icnwKLPLreJO_hruWVA-Z7kku9i6aTgw0Zbeyz7drSBP3fg4RKY1b4gRkdK4c6kI0kwjz82AoftTCvPfUgtjqsKrOvABmo-ZCCLPxpHStjDpAn81B0Uj9WNciF2Hn719jg"
                }
            }
        })
    }

    getAlerts(): Observable<AlertsResponse> {
        return of(<AlertsResponse>{
            alerts: <AlertsModel>{
                customer_alerts: [{ name: 'Alert 1', reason: 'Alert Reason 1', date: new Date() },
                { name: 'Alert 2', reason: 'Alert Reason 2', date: new Date() }],
                enrollment_alerts: [{ name: 'Alert 1', reason: 'Alert Reason 1', date: new Date() },
                { name: 'Alert 2', reason: 'Alert Reason 2', date: new Date() }],
                facility_alerts: [{ name: 'Alert 1', reason: 'Alert Reason 1', date: new Date() },
                { name: 'Alert 2', reason: 'Alert Reason 2', date: new Date() }]
            },
            errors: ['']
        });
    }
    getPanelTypes(): Observable<PanelTypeResponse> {
        const url = this.apiUrl + `panel-types`;
        return this.http.get<PanelTypeResponse>(url);
    }

    getStates(): Observable<any> {
        const url = this.apiUrl + `states`;
        return this.apollo.query({
            query: STATE
          })
        return this.http.get<StateResponse>(url);
    }

    getCountiesByState(stateId: number) {
        const url = this.apiUrl + `counties?stateId=` + stateId;
        return this.http.get<CountyResponse>(url);
    }

    getCancelReasons(): Observable<any> {
        const url = this.apiUrl + `cancel-reasons`;
        return this.apollo.query({
            query: CANCEL_REASON
          })
        //return this.http.get<CancelReasonResponse>(url);
    }

    //getJurisdictions(): Observable<JurisdictionResponse> {
    getJurisdictions(): Observable<any> {
        const url = this.apiUrl + `jurisdictions`;
        return this.apollo.query({
            query: JURISDICTIONS
          })
       // return this.http.get<JurisdictionResponse>(url);
    }

    getVerificationTypes(): Observable<any> {
        const url = this.apiUrl + `lmi-verification-types`;
        return this.apollo.query({
            query: VERIFICATION_TYPE
          })
        //return this.http.get<VerificationTypeResponse>(url);
    }

    getAccountStatuses(): Observable<AccountStatusResponse> {
        return of(<AccountStatusResponse>{
            accountStatuses: ['Active', 'Wait Listed', 'Pending', 'Canceled']
        });
    }

    getAccountTypes(): Observable<AccountTypeResponse> {
        return of(<AccountTypeResponse>{
            accountTypes: ['Local Government', 'Large Customer', 'Low Income', 'Residential/Small - Medium Business']
        });
    }

    getCustomerSegments():any {
        return null
    }

    getFacilities(jurisdiction_id: number): Observable<any> {
        let url = this.apiUrl + `facilities`;
        if (jurisdiction_id) {
            url = url + '?jurisdictionId=' + jurisdiction_id;
        }
        return this.apollo.query<FacilityResponse>({
            query: FACILITIES
          })
    }

    getProgramSummary(programId: number): Observable<ProgramLookupResponse> {
        const url = this.apiUrl +
            `programs/${programId}/summary`;
        return this.http.get<ProgramLookupResponse>(url);
    }

    cancelEnrollment(cancelRequest: CancellationRequestModel): Observable<CancelReasonResponse> {
        const url = this.apiUrl + `enrollments/cancel`;
        return this.http.put<CancelReasonResponse>(url, cancelRequest);
    }

    updateNote(noteType: string, updateAlertObj: AlertUpdateRequest, alertId: string) {
       // const url = this.apiUrl + 'alerts/' + `${noteType.toLowerCase()}/${alertId}`;
        // return this.apollo.mutate({
        //     mutation: CREATE_ALERTS_CUSTOMER,
        //     variables:{
        //         alertId: alertId,
        //         notes: updateAlertObj.notes,
        //         accountId: 22,
        //         active: true,
        //         createdDate: new Date(),
        //         issue: "issue"         }
        // })
        //return this.http.put<any>(url, updateAlertObj);
        return this.createAlertsCustomerGQL.mutate({
            alertId: alertId,
            notes: updateAlertObj.notes
        })
    }

    getFacilitiesInRange(jurisdiction_id: number, address: Address): Observable<FacilityResponse> {
        const url = this.apiUrl + `facilities/` + jurisdiction_id + `/within-range`;
        return this.http.post<FacilityResponse>(url, address);
    }

    getFacilityById(id: number) {
        const url = this.apiUrl + `facilities/` + id;
        return this.http.get<FacilitySetupResponse>(url);
    }

    getAdjacentCounties(countyId: number): Observable<CountyResponse> {
        const url = this.apiUrl + `counties/${countyId}/adjacent-counties`;
        return this.http.get<CountyResponse>(url);
    }

    getFacilitiesReport(): Observable<any> {
        const url = this.apiUrl + `reports/facilities`;
        return this.apollo.query({
            query: FACIITY_REPORT
          })
        //return this.http.get<FacilityReportResponse>(url);
    }

    getCustomersData(accounts: AccountLookupModel[]): Observable<any> {
        const url = this.apiUrl + `customers/accounts/details`;
      //   return this.http.post<AccountResponse>(url, <CustomerSearchResponse>{
        //     accounts: accounts
        // });
        return this.apollo.query({
            query: CUSTOMER_DETAILS
          })
        
    }

    getCustomerData(accounts: AccountLookupModel[]): Observable<any> {
        const url = this.apiUrl + `customers/accounts/details`;
      //   return this.http.post<AccountResponse>(url, <CustomerSearchResponse>{
        //     accounts: accounts
        // });
        return this.apollo.query({
            query: CUSTOMER_DETAIL
          })
        
    }

    getCustomerList(jurisdictionId: number, stateId: number): Observable<any> {
        console.log()
        let url = this.apiUrl + `reports/customers`;
        if (jurisdictionId) {
            url += '?jurisdictionId=' + jurisdictionId;
        } else if (stateId) {
            url += '?stateId=' + stateId;
        }
            return this.apollo.query({
                query: CUSTOMER_REPORTS
              })
        return this.http.get<ReportCustomerResponse>(url);
    }

    createNewAllocation(newEnrollment: NewEnrollmentRequest) {
        const url = this.apiUrl + `enrollments/new`;
        return this.http.put<AccountResponse>(url, newEnrollment);
    }

    updateAllocation(editEnrollment: EditEnrollmentRequest) {
        const url = this.apiUrl + `enrollments/edit`;
        return this.http.put<AccountResponse>(url, editEnrollment);
    }

    getAllocationList(jurisdiction_Id: number, program_Id: number, account_Id: string = null, 
            rowLimit: number = 10000, pageNumber: number = 0): Observable<any> {
        let url = this.apiUrl + `reports/allocation?`;
        if (account_Id) {
           url = url + `accountId=${account_Id}&`;
        } else if (jurisdiction_Id || program_Id) {
            if (jurisdiction_Id) {
                url = url + `jurisdictionId=${jurisdiction_Id}`;
            }
            if (jurisdiction_Id && program_Id) {
                url = url + '&';
            }
            if (program_Id) {
                url = url + `programId=${program_Id}`;
            }
                url = url + `&`;
        }
            url = url + `rowLimit=${rowLimit}&pageNumber=${pageNumber}`;
            return this.apollo.query({
                query: ALLOCATION_REPORT
              })
              
        //return this.http.get<AllocationResponseModel>(url);
    }

    updateWaitList(waitListUpdateRequest: WaitListReorderRequest) {
        const url = this.apiUrl + `reports/wait-list/reorder`;
        return this.http.put<WaitListReorderResponse>(url, waitListUpdateRequest);
    }

}
