import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class CustomerTableService {

createFilter(): (data: any, filter: string) => boolean {
    const filterFunction = function(data, filter): boolean {
      const searchTerms = JSON.parse(filter);
      return data.name.toLowerCase().indexOf(searchTerms.name.toLowerCase()) !== -1
       && data.email.toLowerCase().indexOf(searchTerms.email.toLowerCase()) !== -1
       && data.primary_phone.replace(/\-/g, '').toLowerCase().indexOf(searchTerms.primary_phone.toLowerCase()
       .replace(/\-/g, '').trim()) !== -1
       && data.address.toLowerCase().indexOf(searchTerms.address.toLowerCase()) !== -1
       && data.account_number.toLowerCase().indexOf(searchTerms.account_number.toLowerCase()) !== -1
       && (!searchTerms.jurisdiction_name || data.jurisdiction_name.toLowerCase()
       .indexOf(searchTerms.jurisdiction_name.toLowerCase()) !== -1)
       && (!searchTerms.minKwFilter || Number(data.totalAllocation.toLowerCase().replace('kw', '')) >=
       Number(searchTerms.minKwFilter.toLowerCase().replace('kw', '')))
       && (!searchTerms.maxKwFilter || Number(data.totalAllocation.toLowerCase().replace('kw', '')) <=
       Number(searchTerms.maxKwFilter.toLowerCase().replace('kw', '')));
    };

    return filterFunction;
  }

}
