import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { MatDialogRef, MatDialog, MatRadioChange, MatSnackBar, MatTableDataSource } from '@angular/material';
import { APIServiceCodegen, AccountSearchForEmployeeInput, AccountSearchForEmployee, AccountLookupForEmployeeInput, AccountLookupForEmployee } from 'src/app/API.service';
import { from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { of } from 'zen-observable';
import { Permissions } from '../models/admin.model';

@Component({
  selector: 'app-customer-lookup',
  templateUrl: './customer-lookup.component.html',
  styleUrls: ['./customer-lookup.component.scss']
})
export class CustomerLookupComponent implements OnInit {
  @ViewChild("accountNumber") accountNumber: ElementRef;
  public errorDialogRef: MatDialogRef<ErrorDialogComponent>;
  subscriptionList: Subscription[] = [];
  isInternalChange = false;
  lookupForm: FormGroup = new FormGroup({
    resNonRes: new FormControl('R', Validators.required),
    accountNumber: new FormControl('', Validators.minLength(8)),
    addressLine1: new FormControl(''),
    city: new FormControl(''),
    email: new FormControl('', Validators.email),
    firstName: new FormControl('', Validators.minLength(1)),
    lastName: new FormControl('', Validators.minLength(3)),
    orgName: new FormControl('', Validators.minLength(3)),
    phoneNumber: new FormControl('', [Validators.minLength(14)]),
  });
  public isButtonLoading = false;
  hasCustomerLookupPerms = false;
  displayedColumns: string[] = ['account', 'account_info', 'billing_address', 'installations'];
  dataSource = null;

  constructor(public datasvc: DataService,
    public router: Router,
    public dialog: MatDialog,
    public api: APIServiceCodegen,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    // Fetch our permissions to view this component...
    let permList = this.datasvc.getPermissions();
    if (permList.find(num => num == Permissions.CustomerLookup)) {
        this.hasCustomerLookupPerms = true;
    }
    // Set our component's header title...
    setTimeout(() => {
      this.datasvc.setHeaderTitle('Customer Lookup');
    });
    // Attach our methods for handling when the phone number or account number is changed...
    this.lookupForm.get('phoneNumber').valueChanges.subscribe(x => {
      this.onPhoneNumberChange(x);
    });
    this.lookupForm.get('accountNumber').valueChanges.subscribe(x => {
      this.onAccountNumberChange(x);
    })
    // Initialize the values in our search criteria form if we have an existing search in session storage...
    this.setValuesInForm(this.datasvc.getAccountSearchForEmployeeInput());
  }
  
  ngAfterViewInit() {
    // Let's always focus our cursor on our account number field by default...
    setTimeout(() => {
      this.accountNumber.nativeElement.focus();
    });
  }

  setValuesInForm(input: AccountSearchForEmployeeInput) {
    // Initialize values in our form if we have input that was previously used in session...
    if (this.datasvc.getAccountSearchForEmployeeInput() != null) {
      this.lookupForm.patchValue({
        resNonRes: input.res_non_res === null ? 'R' : input.res_non_res,
        accountNumber: input.account_number,
        firstName: input.first_name,
        lastName: input.last_name,
        orgName: input.org_name,
        addressLine1: input.address_line_1,
        city: input.city,
        email: input.email,
        phoneNumber: (input.phone === null) ? '' : input.phone
      });
    }
  }

  radioChange($event: MatRadioChange) {
    // Clear out fields that aren't applicable for the selected radio option for Residential vs. Non-Residential...
    if ($event.value == 'R') {
      this.lookupForm.patchValue({
        orgName: null
      });
    } else {
      this.lookupForm.patchValue({
        firstName: null,
        lastName: null
      });
    }
  }

  convertBlankFormValueToNull(field: string) {
    // Convert any blank strings in form fields to null values, otherwise just get the value...
    return this.lookupForm.get(field).value === '' ? null : this.lookupForm.get(field).value;
  }

  onReset() {
    this.lookupForm.patchValue({
      resNonRes: 'R',
      accountNumber: null,
      firstName: null,
      lastName: null,
      orgName: null,
      addressLine1: null,
      city: null,
      email: null,
      phoneNumber: ''
    });
    this.datasvc.setAccountSearchForEmployeeInput(null);
  }

  onSearch() {
    // When "Search" button is clicked, perform our search...
    if (!this.lookupForm.valid || this.isButtonLoading || !this.isValidScenario()) {
      return;
    }

    this.dataSource = null;
    this.isButtonLoading = true;
    // Perform the search, but first set our "input" search criteria object and save it to session storage.
    // If account number is searched on, that will override all other search criteria...
    const account_number = this.convertBlankFormValueToNull('accountNumber');
    const searchCriteria = <AccountSearchForEmployeeInput>{
      account_number: account_number,
      address_line_1: account_number === null ? this.convertBlankFormValueToNull('addressLine1') : null,
      city: account_number === null ? this.convertBlankFormValueToNull('city') : null,
      email: account_number === null ? this.convertBlankFormValueToNull('email') : null,
      first_name: account_number === null ? this.convertBlankFormValueToNull('firstName') : null,
      jurisdiction_code: 'FL01',
      last_name: account_number === null ? this.convertBlankFormValueToNull('lastName') : null,
      org_name: account_number === null ? this.convertBlankFormValueToNull('orgName') : null,
      phone: account_number === null ? this.convertBlankFormValueToNull('phoneNumber') : null,
      res_non_res: account_number === null ? this.convertBlankFormValueToNull('resNonRes') : null
    };
    this.datasvc.setAccountSearchForEmployeeInput(searchCriteria);
    from(
    this.api.ListAccountSearchForEmployee(searchCriteria)
    ).pipe(catchError(error =>  {
      this.isButtonLoading = false;
      this.snackBar.open('Error: ' + error.message, 'Ok', { panelClass: 'snackbar' });
      return of(error)
    }))
    .subscribe ((searchResults: AccountSearchForEmployee[]) => {
      // Handle the result of our search...
      this.processSearchResults(searchResults)
    });
  }

  processSearchResults(searchResults: AccountSearchForEmployee[]) {
    // Examine our search results...
    this.isButtonLoading = true;
    this.checkForAccountSearchErrors(searchResults);
    this.checkForNoAccountsFound(searchResults);

    if (searchResults.length === 1) {
      // We found one account.  Let's just go straight to it by grabbing it's full details...
      this.goToAdminDetailView(searchResults[0].account_number,'Customer-Lookup');
    } else {
      // We found more than one account.  Let's show them all in our search results table by setting the dataSource...
      this.dataSource = new MatTableDataSource<AccountSearchForEmployee>(searchResults);
      this.isButtonLoading = false;
    }
  }
  
  isValidScenario() {
    // Return whether or not our search criteria is valid.  We need an account number OR at least one other piece of search criteria...
    return this.lookupForm.get('accountNumber').value ||
        (this.lookupForm.get('firstName').value || this.lookupForm.get('lastName').value || this.lookupForm.get('orgName').value || this.lookupForm.get('addressLine1').value || this.lookupForm.get('city').value || this.lookupForm.get('email').value || this.lookupForm.get('phoneNumber').value);
  }

  onAccountNumberChange(val: string) {
    // When the account number field has something in it, clear out the other fields as they become irrelevant...
    const account_number = this.convertBlankFormValueToNull('accountNumber')
    if (account_number != null) {
      this.lookupForm.patchValue({
        firstName: null,
        lastName: null,
        orgName: null,
        addressLine1: null,
        city: null,
        email: null,
        phoneNumber: ''
      });
    }
  }

  onPhoneNumberChange(val: string) {
    // Format the phone number nicely...
    if (this.isInternalChange) {
      this.isInternalChange = false;
      return;
    }
    this.isInternalChange = true;
    // Remove all mask characters (keep only numeric)
    let newVal = val.replace(/\D/g, '');
      // Allows deletes when delete cursor hits a )
    if ((newVal.length <= 3  && (newVal.trim().length >= 1 && val.length > 1 )) && (!val.endsWith(')') && val[val.length - 2] !== ')')) {
      newVal = newVal.substring(0, newVal.length - 1);
    }

    // Don't show braces for empty value
    if (newVal.length > 10) {
      newVal = newVal.substring(0, 10);
    }
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
    }
    // Set the new, improved value...
    this.lookupForm.patchValue( {
      phoneNumber: newVal
    });
  }

  showError(errors: string[]) {
    // If we have an error, let's show it in a dialog popup...
    const errorList = [];
    errors.forEach(x => {
        errorList.push(x.replace('java.lang.Exception:', ''));
    });
    this.errorDialogRef = this.dialog.open(ErrorDialogComponent, {
        id: 'errorDialog',
        minWidth: '60vw',
        data: {
            errors: errorList
        }
    });
  }

  checkForAccountSearchErrors(searchResults: AccountSearchForEmployee[]): void {
    // Check our search result for any errors...
    if ((<any>searchResults).errors) {
      this.isButtonLoading = false;
      this.snackBar.open('Error: ' + (<any>searchResults).message, 'Ok', { panelClass: 'snackbar' });
      this.showError((<any>searchResults).message);
    }
  }

  checkForNoAccountsFound(searchResults: AccountSearchForEmployee[]): void {
    // Check our search result for no accounts found...
    if (searchResults.length === 0) {
      this.showError(['No Accounts Found']);
      this.isButtonLoading = false;
    }
  }

  toggleInstallations(account_number: number) {
    // Toggle the "Installations" link in our search results for whichever one is clicked - show/hide them...
    const x = document.getElementById('INST' + '_' + account_number);
    if (x.style.display === 'none') {
      x.style.display = 'block';
    } else {
      x.style.display = 'none';
    }
  }

  goToAdminDetailView(account_id, from_location) {
    // Fetch and then navigate to the account's full "lookup" results.
    // If we click on an account number (or only found one in our search), we want to pull additional info and see it...
    this.isButtonLoading = true;
    from(
    this.api.GetAccountLookupForEmployee(<AccountLookupForEmployeeInput>{
        account_number: account_id,
    })
    ).pipe(catchError(error =>  {
        this.isButtonLoading = false;
        error.errors.forEach(iError => {
          this.isButtonLoading  = false;
          this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
        })
        return of(error)
    }))
    .subscribe ((searchResults: AccountLookupForEmployee) => {
        if ((<any>searchResults).errors) {
          this.isButtonLoading  = false;
          return;
        }
        // We fetched our "lookup" data.  Now, let's set it in session storage and navigate to the component that shows it...
        this.datasvc.setAccountLookupForEmployee(searchResults);
        this.isButtonLoading  = false;
        this.router.navigate(['admin/customer-detail', {From: from_location}]);
    });
  }
}
