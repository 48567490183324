<br><br>
<de-loader *ngIf="isButtonLoading" [showOverlay]="showOverlay"></de-loader>
<div *ngIf="hasCustomerLookupPerms && !isButtonLoading" class="d-flex justify-content-center">
    <!--Lookup Fields - Search Criteria-->
    <form [formGroup]="lookupForm" novalidate>
        <div class="container">
            <mat-card class="cardPadding">
                <div class="row">
                    <mat-radio-group formControlName="resNonRes" (change)="radioChange($event)">
                        <mat-radio-button  class="pr-2" value="R">Residential</mat-radio-button>
                        <mat-radio-button  value="N">Non-Residential</mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-form-field class="filter" floatLabel="never">
                    <mat-label>Account Number</mat-label>
                    <input type="number" matInput class="long" formControlName="accountNumber" (keydown.enter)="onSearch()" #accountNumber>
                </mat-form-field>
                <br>
                <div class="row">
                    OR 
                </div>
                <br>
                <div *ngIf="convertBlankFormValueToNull('resNonRes') == 'R'">
                    <mat-form-field class="filter" floatLabel="never">
                        <mat-label>First Name</mat-label>
                        <input matInput class="long" formControlName="firstName" (keydown.enter)="onSearch()" [readonly]="accountNumber.value.length > 0">
                    </mat-form-field>
                    <br>
                </div>
                <div *ngIf="convertBlankFormValueToNull('resNonRes') == 'R'">
                    <mat-form-field class="filter" floatLabel="never">
                        <mat-label>Last Name</mat-label>
                        <input matInput class="long" formControlName="lastName" (keydown.enter)="onSearch()" [readonly]="accountNumber.value.length > 0">
                    </mat-form-field>
                    <br>
                </div>
                <div *ngIf="convertBlankFormValueToNull('resNonRes') == 'N'">
                    <mat-form-field class="filter" floatLabel="never">
                        <mat-label>Business Name</mat-label>
                        <input matInput class="long" formControlName="orgName" (keydown.enter)="onSearch()" [readonly]="accountNumber.value.length > 0">
                    </mat-form-field>
                    <br>
                </div>
                <mat-form-field class="filter" floatLabel="never">
                    <mat-label>Address</mat-label>
                    <input matInput class="long" formControlName="addressLine1" (keydown.enter)="onSearch()" [readonly]="accountNumber.value.length > 0">
                </mat-form-field>
                <br>
                <mat-form-field class="filter" floatLabel="never">
                    <mat-label>City</mat-label>
                    <input matInput class="long" formControlName="city" (keydown.enter)="onSearch()" [readonly]="accountNumber.value.length > 0">
                </mat-form-field>
                <br>
                <mat-form-field class="filter" floatLabel="never">
                    <mat-label>Email</mat-label>
                    <input matInput class="long" formControlName="email" (keydown.enter)="onSearch()" [readonly]="accountNumber.value.length > 0">
                </mat-form-field>
                <br>
                <mat-form-field class="filter" floatLabel="never">
                    <mat-label>Phone Number (XXX) XXX-XXXX</mat-label>
                    <input matInput class="long" formControlName="phoneNumber" (keydown.enter)="onSearch()" [readonly]="accountNumber.value.length > 0">
                </mat-form-field>
                <br>
                <div class="float-left">
                    <de-button type="secondary" size="small" (click)="onReset()">RESET</de-button>
                </div>
                <div class="float-right">
                    <de-button type="primary" size="small" [loading]="isButtonLoading" (click)="onSearch()" [disabled]="(!lookupForm.valid) || !isValidScenario()">SEARCH</de-button>
                </div>
                <br><br><br><br>
            </mat-card>
        </div>
    </form>
    <!--Lookup Results (if more than one business partner returned)-->
    <div class="mat-table-format w-100" *ngIf="dataSource">
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="w-100" *ngIf="!isButtonLoading">
            <ng-container matColumnDef="account">
                <th mat-header-cell *matHeaderCellDef>
                    Account / Premise
                </th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        <a href="javascript:;" (click)='goToAdminDetailView(element.account_number, "Customer-Lookup")'>{{element.account_number}}</a><br>
                        <b>{{element.main_customer.full_name}}</b><br>
                        {{element.premise_address.line1}}<br>
                        {{element.premise_address.city}}, {{element.premise_address.state}} {{element.premise_address.zip}}
                    </div>
                    <div *ngIf="element.main_customer.phone">Phone: {{element.main_customer.phone}}</div>
                    <div *ngIf="element.main_customer.email">Email: {{element.main_customer.email}}</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="account_info">
                <th mat-header-cell *matHeaderCellDef>
                    Account Info
                </th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        <b>Class: {{element.account_class}}</b><br>
                        <b>Status: 
                            <span [ngStyle]="{color: element.account_status === 'ACTIVE' ? '#0c964e' : '#9e1b32'}">
                                {{element.account_status}}
                            </span>
                        </b><br>
                        Business Partner: {{element.business_partner_id}}<br>
                        Jurisdiction Code: {{element.jurisdiction_code}}<br>
                        Gov: {{element.gov_acc_ind}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="billing_address">
                <th mat-header-cell *matHeaderCellDef>
                    Billing Address
                </th>
                <td mat-cell *matCellDef="let element">
                    <div>
                        {{element.billing_address.line1}}<br>
                        {{element.billing_address.city}}, {{element.billing_address.state}} {{element.billing_address.zip}}
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="installations">
                <th mat-header-cell *matHeaderCellDef>
                    Installations
                </th>
                <td mat-cell *matCellDef="let element">
                    <a href="javascript:;" class="cursor-pointer" (click)="toggleInstallations(element.account_number)">View Installations ({{element.installations.length}})</a>
                    <div id="INST_{{element.account_number}}" style="display: none;">
                        <div *ngFor="let i of element.installations;let index = index">
                            <b>Installation #: {{i.installation_number}}</b><br>
                            Service Point: {{i.service_point}}<br>
                            Contract: {{i.contract}}<br>
                            Rate Category: {{i.rate_category}}<br>
                            Active: {{i.active_date}}<br>
                            Move Out: {{i.move_out_date}}
                            <hr *ngIf="element.installations.length > 1 && index != element.installations.length - 1">
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="primary_row"></tr>
        </table>
    </div>
</div>