<div class="container dashboard-card-alerts">
    <br>
    <div class="row title" (click)="viewAlertMainPage()" style="width: 335px;">
        <div style="margin-left: 5px;">
            &nbsp;&nbsp;<i class="fa fa-2x fa-exclamation-triangle"></i>
        </div>
        <div class="row" style="width: 93%; ">
            <div class="col-sm-8"style="text-align: left">Alerts</div>
            <div style="text-align: right; margin-left: 45px;;">
               <a class="cursor-pointer view-all-links"><U>View All</U></a>
            </div>
        </div>
       <!-- &nbsp; Alerts &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a [rounterLink]='admin/facility-alert-report'>View All</a> -->
    </div>
    <div class="row">
       &nbsp; Click an alert box to see the full alert report.
    </div>
    <br><br>
    <div class="row justify-content-between">
        <div style="margin-left: -4px;" class="secondary-title cursor-pointer col-8">Customer</div>
        <div class="col-4 view-all-links cursor-pointer" (click)="viewCustomerAlerts()">View All</div>
    </div>
    <div class="row cursor-pointer justify-content-between" (click)="onShowCustomerDialog(custAlert.id)" *ngFor="let custAlert of customerAlerts">
            <div class="col-8 line-items">{{custAlert.full_name}}</div>
            <div class="col-4 line-items">{{custAlert.issue}}</div>
            <div class="col-11  div-underline"></div>
    </div>

    <br><br>
    <div class="row justify-content-between">
        <div style="margin-left: -4px;" class="secondary-title cursor-pointer col-8">Solar Facility</div>
        <div class="col-4 view-all-links cursor-pointer" (click)="viewFacilityAlerts()">View All</div>
    </div>
    <div class="row cursor-pointer justify-content-between" (click)="onShowFacilityDialog(facAlert.id)" *ngFor="let facAlert of facilityAlerts">
            <div class="col-8 line-items">{{facAlert.facility_name}}</div>
            <div class="col-4 line-items">{{facAlert.issue}}</div>
            <div class="col-11  div-underline"></div>
    </div>
    <br><br>
    <div class="row justify-content-between">
        <div style="margin-left: -4px;" class="secondary-title cursor-pointer col-8">Enrollment</div>
        <div class="col-4 view-all-links cursor-pointer" (click)="viewEnrollmentAlerts()">View All</div>
    </div>
    <div class="row cursor-pointer justify-content-between" (click)="onShowEnrollmentDialog(enrollAlert.id)" *ngFor="let enrollAlert of enrollmentAlerts">
            <div class="col-8 line-items">{{enrollAlert.full_name}}</div>
            <div class="col-4 line-items">{{enrollAlert.status_change}}</div>
            <div class="col-11  div-underline"></div>
    </div>
</div>