import { Component, ViewChild, Renderer2, Input, Output, EventEmitter, AfterViewInit, OnChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-kw-slider',
  templateUrl: './kw-slider.component.html',
  styleUrls: ['./kw-slider.component.scss']
})
export class KwSliderComponent implements AfterViewInit, OnChanges {
  @ViewChild('input') input;
  @ViewChild('sliderThumb') thumb;
  @ViewChild('sliderWrapper') wrapper;

  @Input() min: number;
  @Input() max: number;
  @Input() current: number;
  @Input() disabled$: BehaviorSubject<boolean>;
  @Output() inputChange: EventEmitter<number> = new EventEmitter();

  sliderValue;
  sliderMin;
  sliderMax;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.sliderMin = this.input.nativeElement.attributes.min.value;
    this.sliderMax = this.input.nativeElement.attributes.max.value;
    setTimeout(() => {
      this.sliderValue = this.input.nativeElement.value;
      this.renderer.setStyle(this.thumb.nativeElement, 'left', this.calculatePosition(this.sliderValue));
      this.setNewValue(this.current);
    }, 0);
    this.disabled$.subscribe(isDisabled => {
      this.input.nativeElement.disabled = isDisabled;
    });
  }

  ngOnChanges() {
      this.setNewValue(this.current);
  }

  onSliderChange(event) {
    this.sliderValue = this.input.nativeElement.value;
    this.renderer.setStyle(this.thumb.nativeElement, 'left', this.calculatePosition(event.srcElement.value));
  }

  onFinalSliderChange() {
    this.inputChange.emit(this.sliderValue);
  }

  onResize() {
      this.sliderValue = this.input.nativeElement.value;
      this.renderer.setStyle(this.thumb.nativeElement, 'left', this.calculatePosition(this.sliderValue));
  }

  calculatePosition(value) {
    const halfThumbWidth = 35;
    const fontSize = 14;
    const inputWidth = this.input.nativeElement.clientWidth;
    let newValue = (((value - this.sliderMin) / (this.sliderMax - this.sliderMin))
      * ((inputWidth - halfThumbWidth) - halfThumbWidth)) + halfThumbWidth;
    if (value < 10) {
      newValue = newValue - fontSize / 3;
    } else if (value >= 10 && value < 100) {
      newValue = newValue - fontSize / 1.5;
    } else {
      newValue = newValue - fontSize;
    }
    newValue = newValue - fontSize * 0.75;
    return `${newValue}px`;
  }

  setNewRange(newMaxValue) {
    const newMinValue = Math.ceil(newMaxValue * .25);

    this.sliderMax = newMaxValue;
    this.sliderMin = newMinValue;

    this.input.nativeElement.attributes.max.value = newMaxValue;
    this.input.nativeElement.attributes.min.value = newMinValue;
    if (this.sliderValue !== this.input.nativeElement.value) {
      this.sliderValue = this.input.nativeElement.value;
      this.inputChange.emit(this.sliderValue);
    }
    this.onResize();
  }

  setNewValue(newValue) {
    this.sliderValue = newValue;
    this.input.nativeElement.value = this.sliderValue;
    this.inputChange.emit(this.sliderValue);
    this.onResize();
  }
}