<div *ngIf="hasAllAlertsPerms">
    <div class="row pl-3 pr-3">
        <div class="col-4">
            <div class="container background-white give-size padding-title">
                <i class="fas fa-2x fa-exclamation-triangle"></i> &nbsp;&nbsp;
                <b>Customer Alerts</b>
            </div>
            <!-- <app-customer-alerts-main-page class="minHieghtTable"
                (dataRetrieved)="dataRetrieved($event)" [program_id]="selectedProgramId"
                [jurisdiction_id]="selectedJurisdictionId"></app-customer-alerts-main-page> -->
                <app-customer-alerts-main-page class="minHieghtTable"
                (dataRetrieved)="dataRetrieved($event)"></app-customer-alerts-main-page>
        </div>
        <div class="col-4">
            <div class="container background-white give-size padding-title">
                <i class="fas fa-2x fa-exclamation-triangle"></i> &nbsp;&nbsp;
                <b>Facility Alerts</b>
            </div>
            <!-- <app-facility-alerts-main-page (dataRetrieved)="dataRetrieved($event)"
                [program_id]="selectedProgramId" [jurisdiction_id]="selectedJurisdictionId">
            </app-facility-alerts-main-page> -->
            <app-facility-alerts-main-page (dataRetrieved)="dataRetrieved($event)"></app-facility-alerts-main-page>
        </div>
        <div class="col-4">
            <div class="container background-white give-size padding-title">
                <i class="fas fa-2x fa-exclamation-triangle"></i> &nbsp;&nbsp;
                <b>Enrollment Alerts</b>
            </div>
            <!-- <app-facility-alerts-main-page (dataRetrieved)="dataRetrieved($event)"
                [program_id]="selectedProgramId" [jurisdiction_id]="selectedJurisdictionId">
            </app-facility-alerts-main-page> -->
            <app-facility-alerts-main-page (dataRetrieved)="dataRetrieved($event)"></app-facility-alerts-main-page>
        </div>
    </div>
    <br><br><br>
</div>