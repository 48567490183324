import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { AccountDetailModel } from '../models/customer-detail.model';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { DropDownSelection, FacilitySetupModel, FacilitySetupResponse, ProjectedCapacityFactor } from '../models/admin.model';
import { forkJoin, Observable } from 'rxjs';
import { SubscriptionDetail } from '../models/subscription.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, DataService } from 'src/app/services';
//import { JurisdictionModel } from '../models/customer-report.model';
//import { SubscriptionCardDialogComponent } from '../details-dialog/details-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { ParsedVariable } from '@angular/compiler';

@Component({
    selector: 'app-monthly-capacity-factor',
    templateUrl: './monthly-capacity-factor.component.html',
    styleUrls: ['./monthly-capacity-factor.component.scss']
})
export class MonthlyCapacityComponent implements OnInit, OnChanges {
    @Input() ProjectedCapacityFactor: ProjectedCapacityFactor;
    @Input() onSave: EventEmitter<any> = new EventEmitter();
    facilityForm: FormGroup = new FormGroup({
        jan_percent: new FormControl('', Validators.required),
        feb_percent: new FormControl('', Validators.required),
        mar_percent: new FormControl('', Validators.required),
        apr_percent: new FormControl('', Validators.required),
        may_percent: new FormControl('', Validators.required),
        jun_percent: new FormControl('', Validators.required),
        jul_percent: new FormControl('', Validators.required),
        aug_percent: new FormControl('', Validators.required),
        sep_percent: new FormControl('', Validators.required),
        oct_percent: new FormControl('', Validators.required),
        nov_percent: new FormControl('', Validators.required),
        dec_percent: new FormControl('', Validators.required)
    });
    constructor(public apisvc: ApiService,
        public datasvc: DataService) {
    }

    ngOnInit() {
        this.onSave.subscribe(() => {
            this.datasvc.setProjectedMonthlyCapacity(this.facilityForm.value);
        });
        this.patchValues();
    }

    ngOnChanges() {
        this.patchValues();
    }

    patchValues() {
        const pvc = this.ProjectedCapacityFactor;
        if (pvc) {
            this.facilityForm.patchValue({
                jan_percent: pvc.jan_percent,
                feb_percent: pvc.feb_percent,
                mar_percent: pvc.mar_percent,
                apr_percent: pvc.apr_percent,
                may_percent: pvc.may_percent,
                jun_percent: pvc.jun_percent,
                jul_percent: pvc.jul_percent,
                aug_percent: pvc.aug_percent,
                sep_percent: pvc.sep_percent,
                oct_percent: pvc.oct_percent,
                nov_percent: pvc.nov_percent,
                dec_percent: pvc.dec_percent
            });
        }
    }
}
