import { Component, OnInit, ChangeDetectorRef, AfterViewChecked, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { from, of } from 'rxjs';
import { WaitListModel, Permissions, AllocationStatus, CustomerSegments } from '../models/admin.model';
import { DataService } from 'src/app/services';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatTableDataSource, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { APIServiceCodegen, GetCustomerSegmentByIdInput, GetWaitListSummaryQuery, ListWaitlistReportQuery, ReportCriteriaInput, WaitListProcessInput, WaitListReorderInput, WaitListReverifyInput, WaitListRunsInput, WaitListRun, AccountLookupForEmployee, AccountLookupForEmployeeInput } from 'src/app/API.service';
import { catchError } from 'rxjs/operators';
import { FromEventTarget } from 'rxjs/internal/observable/fromEvent';

@Component({
  selector: 'app-wait-list',
  styleUrls: ['./wait-list.component.scss'],
  templateUrl: './wait-list.component.html'
})
export class WaitListComponent implements OnInit, AfterViewChecked, AfterViewInit {
  constructor(
    private api: APIServiceCodegen,
    private cdRef: ChangeDetectorRef,
    public router: Router,
    public datasvc: DataService,
    public snackBar: MatSnackBar) {
  }
  @ViewChild('mainTopContainer', { static: true, read: ElementRef }) mainTopContainerRef: ElementRef;
  displayedColumns: string[] = ['queue', 'eligibility', 'customer_name', 'low_income', 'acct_number', 'busi_partner_id',
    'street', 'city', 'allocation', 'assigned_kw', 'created_date',
    'email', 'phone', 'actions'];

  WAIT_LIST_RUNS_ITEM_LIMIT = 15;
  MAX_NUM_ITEMS_WAIT_LIST_LIMIT = 10;
  refreshButtonSize = "small";
  waitListItemsDataSource: MatTableDataSource<WaitListModel>;
  editingAllocationId = "";
  allWaitListers: WaitListModel[] = [];
  runs: WaitListRun[] = [];
  customerSegmentId: string = String(CustomerSegments.LowIncome);
  processWaitListForm: FormGroup = null;
  gettingWaitlistData = true;
  newWaitlistPosition = null;
  showOverlay = true;
  isOnEdit = false;
  eligibilityCheck = "False";
  public get allocationStatus(): typeof AllocationStatus {
    return AllocationStatus; 
  }
  waitListSummary: GetWaitListSummaryQuery;
  isReverifyEligibilityLoading = false;
  waitListItemsReverified: number;
  isProcessingWaitListLoading = false;
  hasWaitListPerms = false;
  hasReverifyPerms = false;
  hasProcessPerms = false;
  public hasAllocationEditPerms = false;
  public hasAllocationViewPerms = false;
  public hasAllocationCancelPerms = false;

  ngOnInit() {
    let permList = this.datasvc.getPermissions();
    if (permList.find(num => num == Permissions.WaitListReport)) {
      this.hasWaitListPerms = true;
    } else {
      return;
    }
    if (permList.find(num => num == Permissions.ProcessWaitList)) {
      this.hasProcessPerms = true;
    }
    if (permList.find(num => num == Permissions.ReverifyEligibility)) {
      this.hasReverifyPerms = true;
    }
    if (permList.find(num => num == Permissions.AllocationEdit)) {
      this.hasAllocationEditPerms = true;
    }
    if (permList.find(num => num == Permissions.AllocationView)) {
        this.hasAllocationViewPerms = true;
    }
    if (permList.find(num => num == Permissions.AllocationCancel)) {
        this.hasAllocationCancelPerms = true;
    }
    setTimeout(() => {
      this.datasvc.setHeaderTitle('Wait List');
    });
    this.waitListItemsDataSource = new MatTableDataSource(null);
    if (this.datasvc.getWaitListCustomerSegmentId() != null) {
      this.customerSegmentId = this.datasvc.getWaitListCustomerSegmentId();
    }
    this.setProcessWaitListForm();
    this.getData();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {
    this.mainTopContainerRef.nativeElement.parentElement.parentElement.parentElement.parentElement.setAttribute("style", "max-width: 100%");
  }

  setProcessWaitListForm() {
    this.processWaitListForm = new FormGroup({
      inputMaxNumItems: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(this.MAX_NUM_ITEMS_WAIT_LIST_LIMIT)]),
      disableEligibilityCheck: new FormControl()
    });
}

  maxNumItemsChanged(e, input) {
    // Handling changing the "Max Num Items To Process" input...
    // Only allow 1 through 10.
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];
    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }
    const keyValue = +e.key;
    if (isNaN(keyValue)) {
      e.preventDefault();
      return;
    }
    const hasSelection = input.selectionStart !== input.selectionEnd && input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceMaxNumItems(input, e.key)
    } else {
      newValue = input.value + keyValue.toString();
    }

    if (+newValue > this.MAX_NUM_ITEMS_WAIT_LIST_LIMIT || +newValue < 1 || newValue.length > 2) {
      e.preventDefault();
    }
  }

  private replaceMaxNumItems(input, key) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }

  customerSegmentChanged(event) {
    if (String(event.customer_segment_id) !== this.customerSegmentId) {
      this.customerSegmentId = String(event.customer_segment_id);
      this.getData();
    }
  }

  onTogglePosition() {
    this.isOnEdit = !this.isOnEdit;
  }

  onSaveWaitlistPosition(element) {
    if (this.newWaitlistPosition != null) {
      if (element.wait_list_position == this.newWaitlistPosition) {
        this.onCancel();
        return;
      }
      if (this.newWaitlistPosition > this.allWaitListers.length) {
        this.snackBar.open('Error: Waitlist position past end', 'Ok', { panelClass: 'snackbar' });
        return;
      }
      if (this.newWaitlistPosition < 1) {
        this.snackBar.open('Error: Waitlist position must be greater than 0', 'Ok', { panelClass: 'snackbar' });
        return;
      }

      from(
        this.api.WaitListReorder(<WaitListReorderInput>{
          new_wait_list_position: +this.newWaitlistPosition,
          business_partner_id: element.business_partner_id,
          account_id: element.account_id,
          current_wait_list_position: +element.wait_list_position,
          allocation_id: element.allocation_id,
          customer_segment_id: this.customerSegmentId
        })).pipe(catchError(error => {
          error.errors.forEach(iError => {
            this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
          })
          return of(error)
        })).subscribe(resp => {
          console.log(resp);
          this.getData();
        })

    }
    this.editingAllocationId = "";
    this.newWaitlistPosition = null;
  }

  onPositionChange(event) {
    this.newWaitlistPosition = event.target.value;
  }

  onCancel() {
    this.newWaitlistPosition = null;
    this.editingAllocationId = "";
  }

  getData() {
    this.gettingWaitlistData = true;
    let gettingWaitListReportData = true;
    let gettingWaitListRunsData = true;
    let gettingWaitListSummaryData = true;
    this.waitListItemsDataSource = new MatTableDataSource(null);
    this.datasvc.setWaitListCustomerSegmentId(this.customerSegmentId);

    // Get Wait List Report data...
    from(
      this.api.ListWaitlistReport(<ReportCriteriaInput>{
        id_token: this.datasvc.getIdTokenFromLocalStorage()[1].toString(),
        customer_segment_id: this.customerSegmentId
      })
    ).pipe(
      catchError(error => {
        error.errors.forEach(iError => {
          this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
        });
        return of(error)
      })
    ).subscribe((waitListResults: any) => {
      this.waitListItemsDataSource = new MatTableDataSource(waitListResults);
      this.allWaitListers = waitListResults;
      gettingWaitListReportData = false;
      this.gettingWaitlistData = !(!gettingWaitListReportData && !gettingWaitListRunsData && !gettingWaitListSummaryData);
    })

    // Get Wait List Runs data...
    from(
      this.api.ListWaitListRuns(<WaitListRunsInput>{
        item_limit: this.WAIT_LIST_RUNS_ITEM_LIMIT,
        customer_segment_id: this.customerSegmentId
      })
    ).pipe(
      catchError(error => {
        error.errors.forEach(iError => {
          this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
        });
        return of(error)
      })
    ).subscribe((waitListRunResults: any) => {
      this.runs = waitListRunResults;
      gettingWaitListRunsData = false;
      this.gettingWaitlistData = !(!gettingWaitListReportData && !gettingWaitListRunsData && !gettingWaitListSummaryData);
    })

    // Get Wait List Summary data...
    from(
      this.api.GetWaitListSummary(<GetCustomerSegmentByIdInput>{
        customer_segment_id: this.customerSegmentId
      })
    ).pipe(
      catchError(error => {
        error.errors.forEach(iError => {
          this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
        });
        return of(error)
      })
    ).subscribe((waitListSummaryResults: GetWaitListSummaryQuery) => {
      this.waitListSummary = waitListSummaryResults;
      gettingWaitListSummaryData = false;
      this.gettingWaitlistData = !(!gettingWaitListReportData && !gettingWaitListRunsData && !gettingWaitListSummaryData);
    })
  }

  createExportRecordForWaitListItem(item: ListWaitlistReportQuery): any {
    return {
      'Rank': item.wait_list_position,
      'Eligibility': item.eligibility_status,
      'Eligibility Check Date': item.eligibility_check_date,
      'Ineligibility Reasons': item.ineligibility_reasons !== null ? item.ineligibility_reasons : "",
      'Customer Name': item.main_customer.full_name,
      'Customer Segment': item.customer_segment_description,
      'Account Number': item.account_id,
      'Business Partner Id': item.business_partner_id,
      'Street': item.premise_address.line1,
      'City': item.premise_address.city,
      'Allocation Size kW': item.allocation_size_kw,
      'Assigned kW': item.total_subscription_kw,
      'Created Date': item.created_at,
      'Email': item.main_customer.email,
      'Phone': item.main_customer.phone
    };
  }

  exportExcel(items: any) {
    const exportItems = items.map(item => {
      return this.createExportRecordForWaitListItem(item);
    });
    this.datasvc.exportExcelSpreadsheet(exportItems,
      'Wait List Export ' + this.datasvc.getDate(),
      'WaitListExport' + this.datasvc.getDate() + '.xlsx');
  }

  onNewEnrollment() {
    this.router.navigate(['admin/customer-lookup']);
  }

  

  async onReverifyEligibility() {
    if (this.isReverifyEligibilityLoading = false) {
      return;
    }
    this.isReverifyEligibilityLoading = true;
    this.waitListItemsReverified = 0;
    // Loop through our wait list items and reverify eligibility for each one.  When done, refresh list and 'REVERIFY ELIGIBILITY' button...
    await this.allWaitListers.forEach(waitListItem => {
      from(this.api.WaitListReverify(<WaitListReverifyInput>{
        account_id: waitListItem.account_id,
        business_partner_id: String(waitListItem.business_partner_id),
        allocation_id: String(waitListItem.allocation_id)
      })).pipe(
        catchError(error => {
          error.errors.forEach(iError => {
            this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
          });
          return of(error)
        })
      ).subscribe((waitListResults: any) => {
        console.log(waitListResults);
        this.waitListItemsReverified++;
        if (this.waitListItemsReverified === this.allWaitListers.length) {
          this.getData();
          this.isReverifyEligibilityLoading = false;
        }
      });
    });
  }

  disableEligibilityCheck(e){
    if(e.target.checked){
      this.eligibilityCheck = "True";
    }

    if(!e.target.checked){
      this.eligibilityCheck = "False";
    }
  }


  onProcessWaitList() {
    // Process our selected Customer Segment's Wait List for the max number of eligible items specified...
    if (this.isProcessingWaitListLoading = false) {
      return;
    }
    if (!this.processWaitListForm.valid) {
      this.snackBar.open('Enter a maximum number of eligible items to process: 1 through ' + this.MAX_NUM_ITEMS_WAIT_LIST_LIMIT, 'Ok', { panelClass: 'snackbar' });
      return;
    } 
    
    this.isProcessingWaitListLoading = true;

    if(this.eligibilityCheck=="True"){
      if(confirm("The Eligibility Check has been disabled. Are you sure you want to continue processing waitlist?")){
        from(
          this.api.WaitListProcess(
            <WaitListProcessInput>{
              customer_segment_id: this.customerSegmentId,
              max_num_items: +this.processWaitListForm.get('inputMaxNumItems').value,
              created_by: this.datasvc.getUserName(),
              disable_eligibility_check: this.eligibilityCheck
            }
          )
        ).pipe(catchError(error => {
          error.errors.forEach(iError => {
            this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
          })
          return of(error)
        })).subscribe(resp => {
          this.getData();
          this.isProcessingWaitListLoading = false;
        })        
      }
    }else {
      from(
        this.api.WaitListProcess(
          <WaitListProcessInput>{
            customer_segment_id: this.customerSegmentId,
            max_num_items: +this.processWaitListForm.get('inputMaxNumItems').value,
            created_by: this.datasvc.getUserName(),
            disable_eligibility_check: this.eligibilityCheck
          }
        )
      ).pipe(catchError(error => {
        error.errors.forEach(iError => {
          this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
        })
        return of(error)
      })).subscribe(resp => {
        this.getData();
        this.isProcessingWaitListLoading = false;
      })
    }

  }

  getRunEnrollmentAuditTooltip(waitListRunId: number) {
    let tooltip = '';
    if (this.runs.length > 0) {
      const run = this.runs.filter(r => (<any>r).wait_list_run_id == waitListRunId)[0];
      if (!(<any>run).enrollment_audits || (<any>run).enrollment_audits.length <= 0) {
        return "";
      }

      (<any>run).enrollment_audits.forEach(ea => {
        tooltip += 'Account Number: ' + ea.account_id;
        tooltip += '\n';
        tooltip += ea.is_successful ? 'Successfully processed\n\n' : '*FAILURE PROCESSING*\n\n';
        tooltip += 'Status Change: ';
        tooltip += (ea.change_summary == null) ? '' : ea.change_summary + '\n\n';
        tooltip += ea.customer_connect_request ? 'REQUEST To Customer Connect:\n' + ea.customer_connect_request + '\n\n' : '';
        tooltip += ea.customer_connect_response ? 'RESPONSE From Customer Connect:\n' + ea.customer_connect_response + '\n\n' : '';
        tooltip += '\n\n';
      });
    }
    return tooltip;
  }

  copyText(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  onRefresh(): void {
    this.getData();
  }

  getRunErrorCount(enrollmentAudits: any[]) {
    return enrollmentAudits.filter(ea => !ea.is_successful).length;
  }

  goToAllocation(allocation_id, account_id, mode) {
    this.gettingWaitlistData = true;
    from(
    this.api.GetAccountLookupForEmployee(<AccountLookupForEmployeeInput>{
        account_number: account_id
    })
    ).pipe(catchError(error =>  {
        this.gettingWaitlistData = false;
        error.errors.forEach(iError => {
          this.gettingWaitlistData  = false;
          this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
        })
        return of(error)
    }))
    .subscribe ((searchResults: AccountLookupForEmployee) => {
        if ((<any>searchResults).errors) {
          this.gettingWaitlistData  = false;
          return;
        }
        // Fetch our account and then the specific allocation index for the one we care about...
        this.gettingWaitlistData  = false;
        this.datasvc.setAccountLookupForEmployee(searchResults);
        this.router.navigate(['admin/subscription', { Mode: mode, From: 'WaitListReport'}], {
          state: {
            accountDetails: searchResults,
            index: searchResults.allocations.findIndex(x => x.allocation_id === allocation_id)
          }
        });
    });
  }

  goToTransfer() {
        // Fetch our account and then the specific allocation index for the one we care about...
        this.datasvc.setAccountLookupForEmployee(null);
        this.router.navigate(['admin/subscription', { Mode: 'Transfer', From: 'WaitListReport'}], {
          state: {
            accountDetails: null,
            index: null
          }
        });
  }

  goToAdminDetailView(account_id, from_location) {
    this.gettingWaitlistData = true;
    from(
    this.api.GetAccountLookupForEmployee(<AccountLookupForEmployeeInput>{
        account_number: account_id,
    })
    ).pipe(catchError(error =>  {
        this.gettingWaitlistData = false;
        error.errors.forEach(iError => {
          this.gettingWaitlistData  = false;
          this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
        })
        return of(error)
    }))
    .subscribe((searchResults: AccountLookupForEmployee) => {
        if ((<any>searchResults).errors) {
          this.gettingWaitlistData  = false;
          return;
        }
        this.datasvc.setAccountLookupForEmployee(searchResults);
        this.gettingWaitlistData  = false;
        this.router.navigate(['admin/customer-detail', {From: from_location}]);
    });
  }

}
