import { Component, OnInit, OnDestroy, Input, EventEmitter, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataService, ApiService } from 'src/app/services';
import { ProgramModel } from '../models/admin.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-program-table',
  templateUrl: './program-table.component.html',
  styleUrls: ['./program-table.component.scss']
})
export class ProgramTableComponent implements OnInit, OnDestroy {
  @Input() exportData: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['program_id', 'res_market_rate', 'application_fee', 'monthly_fee_per_kw',
   'credit_per_kwh', 'customer_connect_product_code', 'program_capacity_kw', 'facility_names', 'edit'];
  dataSource = new MatTableDataSource<ProgramModel>();
  subscriptionList: Subscription[] = [];


  constructor(public datasvc: DataService,
    public router: Router,
    public apisvc: ApiService) { }

  ngOnInit() {
    this.subscriptionList.push(this.exportData.subscribe(() => {
      this.exportExcel();
    }));
    this.apisvc.getPrograms().subscribe(programs => {
        this.dataSource = new MatTableDataSource<ProgramModel>(programs.programs);
    });
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    this.subscriptionList.forEach(sub => {
      sub.unsubscribe();
    });
  }


  exportExcel() {
    const exportAllocationList = this.dataSource.data.map(rec => {
      return {
        'Program Id': rec.program_id,
        'Is Low Income': rec.res_low_income,
        'Is Market Rate': rec.res_market_rate,
        'Application Fee': rec.application_fee,
        'Monthly Fee Per Kw': rec.monthly_fee_per_kw,
        'Credit Per Kw': rec.credit_per_kwh,
        'Customer Connect Product Code': rec.customer_connect_product_code,
        'Program Capacity Kw': rec.program_capacity_kw,
        'Facilities': rec.facility_names
      };
    });
    this.datasvc.exportExcelSpreadsheet(exportAllocationList,
      'Program Export ' + this.datasvc.getDate(),
      'ProgramExport' + this.datasvc.getDate() + '.xlsx');
  }

  goToDetailView(programId: number) {
    this.router.navigate(['admin/program-detail/' + programId]);
}

}








