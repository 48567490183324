<div class="container" style="width: 100%;">
    <div *ngIf="hasDashboardPerms" class="row">
        <h1><br>CEC Program Admin Tool's "Dashboard" page coming soon.  Choose a different menu option at the top.</h1>
        <!-- <div class="col-lg-8">
            <div class="row">
                <div class="col-lg-6 mt-5">
                    <app-customer-total-box></app-customer-total-box>
                </div>
                <div class="col-lg-6 mt-5">
                    <app-capacity-box ></app-capacity-box>
                </div>
            </div>
             <div class="row">
                <div class="col-lg-6 mt-5">
                    <app-financials-box></app-financials-box>
                </div>
                <div class="col-lg-6 mt-5">
                </div>
            </div> 
        </div>
        <div class="col-lg-4" style="margin-top: 30px;" >
            <app-alerts-box ></app-alerts-box>
        </div> -->
    </div>
</div>