import { Component, OnInit, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormGroup, FormControl, Validators } from '@angular/forms';
//import { CustomerDetail, CustomerSearchCriteria } from '../models/customer-detail.model';
import { Router } from '@angular/router';
import { DataService, ApiService } from 'src/app/services';
//import { JurisdictionResponse } from '../models/customer-report.model';

@Component({
  selector: 'app-financial-report',
  templateUrl: './financial-report.component.html',
  styleUrls: ['./financial-report.component.scss']
})
export class FinancialReportComponent implements OnInit {

  subscriptionList: Subscription[] = [];
  isMonthly: boolean;
  //public jurisdictionResponse: JurisdictionResponse = null;
  public selectedFacility = '';
  public facilityDataLoaded: EventEmitter<any> = new EventEmitter();
  public isButtonLoading = false;

  public exportData = new EventEmitter();


  constructor(public datasvc: DataService,
    public router: Router,
    public apisvc: ApiService) { }

  ngOnInit() {
    setTimeout(() => {
      this.datasvc.setHeaderTitle('Financial Report');
    });
  }

  dataRetrieved($event) {
    this.facilityDataLoaded.emit($event);
}

// filtersSelected(event) {
//     this.jurisdictionResponse = event.jurisdictionResponse;
//   }

  exportExcel() {
    this.isButtonLoading = true;
      return;
    this.isButtonLoading = false;
  }


}
