<div class="card rounded" style="box-shadow: 0px 0px 16px -11px rgba(0,0,0,1);">
    <div class="card-body">
      <h5><b>'Actual vs. Expected' kWh</b></h5>
      <br><br><br><br>
      <div class="card-text" id="outputExpectedContainer" style="overflow-x:hidden;">
        <combo-chart-component
          [view]="view"
          [scheme]="comboBarScheme"
          [colorSchemeLine]="lineChartScheme"
          [results]="barChart"
          [animations]="animations"
          [lineChart]="lineChartSeries"
          [xAxisTickFormatting]="xTickFormat"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [legendPosition]="legendPosition"
          [legendTitle]="legendTitle"
          [showGridLines]="showGridLines"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [barChartLegendTitle]="barChartLegendTitle"
        >
      </combo-chart-component>
      </div>
    </div>
  </div>