import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StyleHelperService } from 'src/app/services/style-service.service';

@Component({
  selector: 'app-detail-output-expected',
  templateUrl: './detail-output-expected.component.html',
  styleUrls: ['./detail-output-expected.component.scss']
})
export class DetailOutputExpectedComponent implements OnInit, OnChanges {
  @Input() chartHeightpx?: number;
  @Input() chartWidthpx?: number;
  @Input() output: any[];
  @Input() expected: any[];

  public view: any[];
  // options...
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  legendTitle = 'Legend';
  legendPosition = 'below';
  showXAxisLabel = false;
  xAxisLabel = 'Time';
  showYAxisLabel = false;
  yAxisLabel = '';
  barChartLegendTitle = 'Expected';
  showGridLines = false;
  innerPadding = '10%';
  animations = true;
  barChart: any[];
  lineChartSeries: any[];
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#A3A0FB', '#54D8FF']
  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#54D8FF']
  };

  showRightYAxisLabel = false;
  yAxisLabelRight = '';
  constructor(public styleHelper: StyleHelperService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.output.currentValue && changes.expected.currentValue) {
      if (changes.output.currentValue !== changes.output.previousValue
        && changes.expected.currentValue !== changes.expected.previousValue) {
          this.view = this.styleHelper.makeChartResponsive('outputExpectedContainer');
          this.lineChartSeries = [
            {
              name: 'Actual',
              series: this.output
            }
          ];
          this.barChart = this.expected;
      }
    }
  }

  onResize() {
    this.view = this.styleHelper.makeChartResponsive('outputExpectedContainer');
  }

  onSelect() {
  }

  xTickFormat(val) {
    return val;
  }

}




