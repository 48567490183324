import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormGroup, FormControl, Validators } from '@angular/forms';
//import { CustomerDetail, CustomerSearchCriteria } from '../models/customer-detail.model';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService, ApiService } from 'src/app/services';
import { ProgramModel, ProgramDetailModel } from '../models/admin.model';

@Component({
  selector: 'app-program-detail',
  templateUrl: './program-detail.component.html',
  styleUrls: ['./program-detail.component.scss']
})
export class ProgramDetailComponent implements OnInit {

  subscriptionList: Subscription[] = [];
  programId: number;
  programForm: FormGroup = new FormGroup({
    program_id: new FormControl(''),
    jurisdiction_id: new FormControl(''),
    res_low_income: new FormControl(''),
    res_market_rate: new FormControl(''),
    application_fee: new FormControl(''),
    monthly_fee_per_kw: new FormControl(''),
    credit_per_kw: new FormControl(''),
    customer_connect_product_code: new FormControl(''),
    program_capacity_kw: new FormControl(''),
    facility_names: new FormControl('')
  });

  constructor(public datasvc: DataService,
    public router: Router,
    private route: ActivatedRoute,
    public apisvc: ApiService) { }

  ngOnInit() {
    setTimeout(() => {
      this.datasvc.setHeaderTitle('Program Detail View');
    });
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.programId = params['id'];
        this.apisvc.getProgramById(this.programId).subscribe(program => {
          this.patchValues(program.program_detail);
        });
      }
    });
  }

  patchValues(programDetail: ProgramDetailModel) {
    this.programForm.patchValue({
      program_id: programDetail.program.program_id,
      jurisdiction_id: 'SC (DEP)',
      res_low_income: programDetail.program.res_low_income,
      res_market_rate: programDetail.program.res_market_rate,
      application_fee: programDetail.program.application_fee,
      monthly_fee_per_kw: programDetail.program.monthly_fee_per_kw,
      credit_per_kw: programDetail.program.credit_per_kwh,
      customer_connect_product_code: programDetail.program.customer_connect_product_code,
      program_capacity_kw: programDetail.program.program_capacity_kw,
      facility_names: programDetail.facility_names
    });
  }

}
