import { Component, OnInit, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { DataService, ApiService } from 'src/app/services';

@Component({
  selector: 'app-enrollment-audit-report',
  templateUrl: './enrollment-audit-report.component.html',
  styleUrls: ['./enrollment-audit-report.component.scss']
})
export class EnrollmentAuditReportComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>(null);

  constructor(public datasvc: DataService,
    private router: Router,
    public apisvc: ApiService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.datasvc.setHeaderTitle('Enrollment Audit Report');
    });
  }
}
