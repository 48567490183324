import { Component, Inject, OnInit, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AccountDetailModel } from '../models/customer-detail.model';
import { FacilitySetupResponse, FacilitySetupModel, FeeDate } from '../models/admin.model';
import { FormGroup, FormControl } from '@angular/forms';


@Component({
  selector: 'app-date-row',
  templateUrl: './date-row.component.html',
  styleUrls: ['./date-row.component.scss']
})
export class DateRowComponent implements OnInit {
    dateRowForm: FormGroup;
   @Input() feeDateItem: FeeDate;
  constructor() {
  }

  ngOnInit() {
      this.addDateRow(null);
  }

  addDateRow(dateRow: any) {
       const newFormGroup = new FormGroup({
            fee_date: new FormControl(''),
            residential_fee: new FormControl(''),
            non_residential_fee: new FormControl('')
          });
      if (dateRow) {
        newFormGroup.patchValue({
           fee_date: dateRow.fee_date,
           residential_fee: dateRow.residential_fee,
           non_residential_fee: dateRow.non_residential_fee
        });
      } else {
        this.feeDateItem = <FeeDate> {
            fee_date: null,
            residential_fee: null,
            non_residential_fee: null
        };
      }
      this.dateRowForm = newFormGroup;
  }

  addDate() {
      this.addDateRow(null);
  }
}
