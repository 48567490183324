<br><br>
<div class="row">
  <div class="col-1"></div>
  <div class="col-10">
    <div class="container dashboard-card">
      <div class="row top-header">
          <div class="col text-left"><a href="javascript:;" (click)='onGoBack();'>Return to List</a></div>
          <div class="col text-center" *ngIf="facilityDetail">{{facilityDetail.facility.facility_name}} -
            {{facilityDetail.facility.total_capacity_kw| number}} kW</div>
          <div class="col text-right"><a href="javascript:;" (click)='onEdit();'>Edit</a></div>
      </div>
    </div>
    <br><br>
    <div class="row">
      <div class="col-8 mt-3">
        <app-detail-output-expected *ngIf="siteOutput" [chartHeightpx]="150" [chartWidthpx]="850" [output]="siteOutput"
          [expected]="siteExpected">
        </app-detail-output-expected>
      </div>
      <div class="col-4 pt-3">
        <app-facility-alerts [facility_id]="facilityId"></app-facility-alerts>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <app-customer-total-box [showVersion]="'Long'" [facilityId]="facilityId"></app-customer-total-box>
      </div>
      <div class="col-6">
        <app-capacity-box [facilityId]="facilityId"></app-capacity-box>
      </div>
    </div>
    <br><br><br>
    <div class="row" *ngIf="facilityDetail">
      <div class="col-12">
        <div class="row">
          <div class="col-12 facility-card">
            <div class="mt-2"></div>
            <span class="title">Site Information</span>
            <br><br>
            <div class="row labelTitle">
              <div class="col-3">Address - Street</div>
              <div class="col-3">City</div>
              <div class="col-3">State</div>
              <div class="col-3">Jurisdiction</div>
            </div>
            <div class="row">
              <div class="col-3">{{facilityDetail.facility.address_line1}}</div>
              <div class="col-3">{{facilityDetail.facility.city}}</div>
              <div class="col-3">{{facilityState}}</div>
              <div class="col-3">{{facilityJurisdiction}}</div>
            </div>
            <br>
            <div class="row labelTitle">
              <div class="col-3">Latitude</div>
              <div class="col-3">Longitude</div>
              <div class="col-3">Facility Installation #</div>
              <div class="col-3">Invoice #</div>
            </div>
            <div class="row">
              <div class="col-3">{{facilityDetail.facility.latitude}}</div>
              <div class="col-3">{{facilityDetail.facility.longitude}}</div>
              <div class="col-3">{{facilityDetail.facility.facility_installation_number}}</div>
              <div class="col-3">{{facilityDetail.facility.invoice_number}}</div>
            </div>
            <br>
            <div class="row labelTitle">
              <div class="col-3">Salesforce ID</div>
              <div class="col-3">Tracker Type</div>
              <div class="col-3">Expected COD</div>
              <div class="col-3">Actual COD</div>
            </div>
            <div class="row">
              <div class="col-3">{{facilityDetail.facility.salesforce_id}}</div>
              <div class="col-3">{{panelType}}</div>
              <div class="col-3">{{facilityDetail.facility.expected_commercial_operating_date | date :'shortDate'}}
              </div>
              <div class="col-3">{{facilityDetail.facility.commercial_operating_date | date:'shortDate'}}</div>
            </div>
            <br>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row" *ngIf="facilityDetail">
      <div class="col-12">
        <div class="row">
          <div class="facility-card col-12">
            <div class="mt-2"></div>
            <span class="title">Developer Information</span>
            <br><br>
            <div class="row labelTitle">
              <div class="col-3">Developer Name</div>
              <div class="col-3">Developer Email</div>
              <div class="col-3">Terms of PPA</div>
            </div>
            <div class="row">
              <div class="col-3">{{facilityDetail.facility.solar_developer_name}}</div>
              <div class="col-3">{{facilityDetail.facility.solar_developer_email}}</div>
              <div class="col-3">{{facilityDetail.facility.terms_of_ppa}}</div>
            </div>
            <br>
            <div class="row labelTitle">
              <div class="col-3">Developer Address - Street</div>
              <div class="col-3">Developer City</div>
              <div class="col-3">Developer State</div>
            </div>
            <div class="row">
              <div class="col-3">{{facilityDetail.facility.solar_developer_address}}</div>
              <div class="col-3">{{facilityDetail.facility.city}}</div>
              <div class="col-3">{{facilityState}}</div>
            </div>
            <br>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="row" *ngIf="facilityDetail">
      <div class="col-12">
        <div class="row">
          <div class="facility-card col-12">
            <div class="mt-2"></div>
            <span class="title">Fees</span>
            <br><br>
            <div class="row labelTitle">
              <div class="col-3">Application Fee</div>
              <div class="col-3">Residential Enrollment Fee/kW</div>
              <div class="col-3">Non-Residential Enrollment Fee/kW</div>
              <div class="col-3">Monthly Fee/kW</div>
            </div>
            <div class="row">
              <div class="col-3">$20.00</div>
              <div class="col-3">$25.00</div>
              <div class="col-3">$25.00</div>
              <div class="col-3">$6.25</div>
            </div>
            <br>
          </div>
        </div>
      </div>
    </div>
    <br><br><br><br><br>
    <span></span>
  </div>
</div>