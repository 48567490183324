import { Component, OnInit, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { DataService, ApiService } from 'src/app/services';
import { ProgramModel } from '../models/admin.model';

@Component({
  selector: 'app-program-report',
  templateUrl: './program-report.component.html',
  styleUrls: ['./program-report.component.scss']
})
export class ProgramReportComponent implements OnInit {
  public dataSource = new MatTableDataSource<any>(null);
  public exportData = new EventEmitter();

  constructor(public datasvc: DataService,
    public apisvc: ApiService) { }

  ngOnInit() {
    setTimeout(() => {
      this.datasvc.setHeaderTitle('Program Report');
    });
  }


  exportExcel() {
    this.exportData.emit('');
  }

}
