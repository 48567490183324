<div *ngIf="hasUserAdminSettingsPerms" class="row">
    <div class="col-3"></div>
    <div class="col-7">
        <div class="container">
            <br>
            <span class="biggerFont"><b>User List</b></span>
            <br><br>
            <div class="row">
                <form [formGroup]="searchForm" novalidate>
                    <div class="col-8">
                        <mat-form-field class="filter" floatLabel="never">
                            <mat-label>Search User Id</mat-label>
                            <input matInput formControlName="searchFilter">
                        </mat-form-field>
                    </div>
                </form>
            </div>
            <br><br>
            <div class="mat-table-format">
                <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
                    <ng-container matColumnDef="userid">
                        <th mat-header-cell *matHeaderCellDef>User Id </th>
                        <td mat-cell *matCellDef="let element">{{element.user_id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="currentrole">
                        <th mat-header-cell *matHeaderCellDef>Current Role</th>
                        <td mat-cell *matCellDef="let element">{{element.user_role_description}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="role">
                        <th mat-header-cell *matHeaderCellDef>Change Role To:</th>
                        <td mat-cell *matCellDef="let element">
                            <form [formGroup]="roleSelectForm[element.index]" novalidate *ngIf="roleSelectForm.length > 0">
                                <mat-form-field class="filter" floatLabel="never">
                                    <mat-select formControlName="roleSelection" class="long">
                                        <mat-option *ngFor="let role of possibleRoles; let i = index" [(value)]="possibleRoles[i].value">
                                            {{role.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDragLockAxis="y" cdkDrag
                        [cdkDragData]="row">
                    </tr>
                </mat-table>
            </div>
            <br>
            <br>
            <div class="float-right row">
                <de-button size="small" (click)="onCancel()" type="secondary" >Cancel</de-button>
                <de-button size="small" (click)="onSave()" [disabled]="datasvc.getAccountPermType() != 1" type="primary" >Save</de-button>
            </div>
        </div>
    </div>
</div>