<br><br>
<form [formGroup]="formGroup" novalidate>
  <de-loader *ngIf="gettingData" [showOverlay]="showOverlay"></de-loader>
  <!--Search Criteria-->
  <div class="row">
    <div class="col">
      <mat-form-field class="filter" appearance="outline">
        <mat-label>Account Number</mat-label><input matInput class="long" formControlName="accountFilter">
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field class="filter" appearance="outline">
        <mat-label>Customer Name</mat-label><input matInput class="long" formControlName="accountFilter">
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field class="filter" appearance="outline">
        <mat-label>Street</mat-label><input matInput class="long" formControlName="accountFilter">
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field class="filter" appearance="outline">
        <mat-label>Phone</mat-label><input matInput class="long" formControlName="accountFilter">
      </mat-form-field>
    </div>
  </div>
  <!--Data-->
  <div class="mat-table-format table-overflow">
    <table mat-table [dataSource]="dataSource" class="table-whitespace" multiTemplateDataRows>
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef>
            Created
        </th>
        <td mat-cell *matCellDef="let element">
          On: {{element.created_date| date}}<br>
          By: {{element.created_by}}<br>
          <img *ngIf="element.is_successful" class="is-successful-image" src="../../../assets/greencheck.png"/>
          <img *ngIf="!element.is_successful" class="is-successful-image cursor-pointer" src="../../../assets/redx.png" data-toggle="tooltip" title="{{element.error_message}}"/>
          <i *ngIf="element.audit_notes" class="fas fa-comment-alt cursor-pointer" [ngClass]="{ 'active': element.audit_notes }" data-toggle="tooltip" title="{{element.audit_notes}}"></i>
        </td>
      </ng-container>
      <ng-container matColumnDef="changes">
        <th mat-header-cell *matHeaderCellDef>
          Changes
        </th>
        <td mat-cell *matCellDef="let element" style="width: 200px;">
          <b>{{element.change_summary}}</b>
          <div *ngIf="element.cancel_reason_description">{{element.cancel_reason_description}}</div>
          <div *ngIf="element.allocation_size_change_kw">{{element.allocation_size_change_kw}}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="account">
        <th mat-header-cell *matHeaderCellDef>
          Account
        </th>
        <td mat-cell *matCellDef="let element">
            <div *ngIf="element.account_id">
              <a href="javascript:;" (click)='goToAdminDetailView(element.account_id, "EnrollmentAuditReport")'>{{element.account_id}}</a>
            </div>
            <div *ngIf="element.premise_address.line1"><b>{{element.main_customer.full_name}}</b></div>
            <div *ngIf="element.premise_address.line1">{{element.premise_address.line1}}</div>
            <div *ngIf="element.premise_address.city && element.premise_address.state_code">
              {{element.premise_address.city}}, {{element.premise_address.state_code}} {{element.premise_address.zip}}
            </div>
            <div *ngIf="element.main_customer.phone">Phone: {{element.main_customer.phone}}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="item_changed">
        <th mat-header-cell *matHeaderCellDef>
          Item Changed
        </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.allocation_id">Allocation Id:<br>{{element.allocation_id}}</div>
          <div *ngIf="element.wait_list_id">Wait List Id:<br>{{element.wait_list_id}}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="customer_connect">
        <th mat-header-cell *matHeaderCellDef>
          Customer Connect
        </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.customer_connect_request">
            <i class="fas fa-arrow-circle-right" style="color: #00853f;"></i><b><a href="javascript:;" class="cursor-pointer" (click)="toggleCustConnJson(element.enrollment_audit_id, 'REQ')">Request</a></b>
            <div id="REQ_{{element.enrollment_audit_id}}" style="display: none;">
              <i class="fas fa-copy d-inline-block run-audit-image" data-toggle="tooltip" title="click to copy Customer Connect Request" 
                (click)="copyText(element.customer_connect_request)"></i>
                <code>
                  <pre>{{ element.customer_connect_request }}</pre>
                </code>
            </div>
          </div>
          <div *ngIf="element.customer_connect_response">
            <i class="fas fa-arrow-circle-left" [ngStyle]="{color: element.customer_connect_response.includes('Failure') ? '#9e1b32' : '#00853f'}"></i><b><a href="javascript:;" class="cursor-pointer" (click)="toggleCustConnJson(element.enrollment_audit_id, 'RESP')">Response</a></b> 
            <div id="RESP_{{element.enrollment_audit_id}}" style="display: none;">
              <i class="fas fa-copy d-inline-block run-audit-image" data-toggle="tooltip" title="click to copy Customer Connect Response" 
                (click)="copyText(element.customer_connect_response)"></i>
                <code>
                  <pre>{{ element.customer_connect_response }}</pre>
                </code>
            </div>
          </div>
        </td>
      </ng-container>

      <!--Secondary Column (second row)-->
      <ng-container matColumnDef="secondary">
        <td mat-cell [attr.colspan]="displayedColumns.length" *matCellDef="let element" class="notes_cell">
          <div *ngIf="element.old_account_notes || element.new_account_notes">
            <div>
              Old / New Notes:
            </div>
            <td-ngx-text-diff [showToolbar]=false class="w-100" [showBtnToolbar]=false
              [left]="element.old_account_notes" 
              [right]="element.new_account_notes">
            </td-ngx-text-diff>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="primary_row"></tr>
      <tr mat-row *matRowDef="let row; columns: ['secondary'];" class="secondary_row" [hidden]="row.old_account_notes == row.new_account_notes"></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="pageSize" [length]="totalItems" [pageIndex]="pageIndex"></mat-paginator>
</form>