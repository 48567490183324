<div *ngIf="hasFacilityReportPerms" class="container">
    <div class="col-12">
    <form [formGroup]="facilityForm" novalidate>
        <div class="mat-table-format">
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="facility_name">
                    <th mat-header-cell *matHeaderCellDef>
                        Facility Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.facility_name}} </td>
                </ng-container>
                <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef class="cityColumn">
                        City
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.city}} </td>
                </ng-container>

                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef class="stateColumn">
                        State
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.state}} </td>
                </ng-container>

                <ng-container matColumnDef="street">
                    <th mat-header-cell *matHeaderCellDef class="streetColumn">
                        Street
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.street}} </td>
                </ng-container>
                <ng-container matColumnDef="jurisdiction">
                    <th mat-header-cell *matHeaderCellDef class="jurisdictionColumn">
                        Jurisdiction
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.jurisdiction}} </td>
                </ng-container>
                <ng-container matColumnDef="installation_number">
                    <th mat-header-cell *matHeaderCellDef class="installationNumberColumn">
                        Installation #
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.installation_number}} </td>
                </ng-container>
                <ng-container matColumnDef="salesforce_id">
                    <th mat-header-cell *matHeaderCellDef class="salesforceIdColumn">
                        Salesforce Id
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.salesforce_id}} </td>
                </ng-container>
                <ng-container matColumnDef="total_facility_capacity">
                    <th mat-header-cell *matHeaderCellDef class="totalFacilityCapacityColumn">
                        Total Facility Capacity (kW)
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.total_capacity_kw}} </td>
                </ng-container>
                <ng-container matColumnDef="month_status">
                    <th mat-header-cell *matHeaderCellDef class="monthStatus">
                        1 Month Status
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.month_status}} </td>
                </ng-container>
                <ng-container matColumnDef="year_status">
                    <th mat-header-cell *matHeaderCellDef class="yearStatus">
                        12 Month Status
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.year_status}} </td>
                </ng-container>
                <ng-container matColumnDef="commercial_operation_date">
                    <th mat-header-cell *matHeaderCellDef class="commercial_operation_date_column">
                        Commercial Operation Date
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.commercial_operation_date| date:'MM/dd/yyyy'}}&nbsp;&nbsp;&nbsp; </td>
                </ng-container>
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef class="editColumn"> Edit
                        <div></div>
                    </th>
                    <td mat-cell *matCellDef="let element"> <a href="javascript:;"
                            (click)='goToDetailView(element.facility_id)'>Edit</a> &nbsp; 
                            <a href="javascript:;"
                            (click)='onView(element.facility_id)'>View</a> </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>
    </form>
    <div class="background-white col-12 give-size">
        <div class="row float-right">
            <de-button (click)="onNewFacility()" type="secondary">Add Facility</de-button>
            <de-button (click)="exportExcel()" type="secondary">
                <div>Export Data &nbsp; <i class="fas fa-caret-up"></i></div>
            </de-button>
        </div>
    </div>
</div>
    <br><br>