<div *ngIf="hasCustomerAlertsPerms" class="container">
    <div class="row pl-5 pr-4">
        <div class="col-10 autoMargins">            
            <!-- <app-customer-alerts-report-table [exportData]="exportData" (dataRetrieved)="dataRetrieved($event)"
                 [jurisdiction_id]="selectedJurisdictionId" [program_id]="selectedProgramId">
            </app-customer-alerts-report-table> -->
            <app-customer-alerts-report-table [exportData]="exportData" (dataRetrieved)="dataRetrieved($event)"></app-customer-alerts-report-table>
            <div class="background-white col-12 give-size">
                <div class="row float-right">
                    <de-button (click)="exportExcel()" type="secondary">
                        <div>Export Data &nbsp; <i class="fas fa-caret-up"></i></div>
                    </de-button>
                </div>
            </div>
        </div>
    </div>
</div><BR><BR>

<mat-card class="cardPadding">
    <h2 style="text-align: center;margin-bottom:2%;">Send Alert to Customer Portal by Customer Segment</h2>
    <form [formGroup]="custSeg1Form" novalidate>
        <div>
            <mat-form-field class="filter" style="width:60%;margin-left:20%;" floatLabel="never">
                <mat-label>Customer Segment 1 (IQ) Message</mat-label>
                <input matInput class="long" formControlName="custSegMsg1">
            </mat-form-field>
            <div class="alertExpiry">
                <h5>IQ Alert will expire in: </h5>
                <mat-form-field class="filter" class="daysHours" floatLabel="never">
                    <mat-label>Days</mat-label>
                    <input matInput type="number" min="0" class="long" formControlName="custSegDays1">
                </mat-form-field>
                <mat-form-field class="filter" class="daysHours" floatLabel="never">
                    <mat-label>Hours</mat-label>
                    <input matInput type="number" min="0" class="long" formControlName="custSegHours1">
                </mat-form-field>
                <mat-form-field class="filter" style="width:10%;float:left;" floatLabel="never">
                    <mat-label>Minutes</mat-label>
                    <input matInput type="number" min="0" class="long" formControlName="custSegMinutes1">
                </mat-form-field>
            </div><BR><BR><BR>
            
            <div style="text-align: center;">
                <de-button type="primary" size="small" (click)="editCustomerPortalMessageSegment1()">IQ Broadcast</de-button>
            </div>
        </div>
    </form><BR>

    <form [formGroup]="custSeg2Form" novalidate>
        <div>
            <mat-form-field class="filter" style="width:60%;margin-left:20%;" floatLabel="never">
                <mat-label>Customer Segment 2 (MR) Message</mat-label>
                <input matInput class="long" formControlName="custSegMsg2">
            </mat-form-field>
            <div class="alertExpiry">
                <h5>MR Alert will expire in: </h5>
                <mat-form-field class="filter" class="daysHours" floatLabel="never">
                    <mat-label>Days</mat-label>
                    <input matInput type="number" min="0" class="long" formControlName="custSegDays2">
                </mat-form-field>
                <mat-form-field class="filter" class="daysHours" floatLabel="never">
                    <mat-label>Hours</mat-label>
                    <input matInput type="number" min="0" class="long" formControlName="custSegHours2">
                </mat-form-field>
                <mat-form-field class="filter" style="width:10%;float:left;" floatLabel="never">
                    <mat-label>Minutes</mat-label>
                    <input matInput type="number" min="0" class="long" formControlName="custSegMinutes2">
                </mat-form-field>
            </div><BR><BR><BR>

            <div style="text-align: center;">
                <de-button type="primary" size="small" (click)="editCustomerPortalMessageSegment2()">MR Broadcast</de-button>
            </div>
        </div>
    </form>
    
</mat-card>
