import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/services';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource } from '@angular/material';
import { RSPEnrollmentDialogComponent } from '../rsp-enrollment-dialog/rsp-enrollment-dialog.component';
import { APIServiceCodegen, uploadLrgCorpGovAccntsJson, PendingAccountsInput, CustomerSegmentEditInput, UpdateLoginKillSwitchInput, LoginKillSwitchMetadata } from 'src/app/API.service';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Permissions } from '../models/admin.model';

/**
 * @title Basic use of `<table mat-table>`
 */


@Component({
    selector: 'app-login-turn-off',
    styleUrls: ['./login-turn-off.component.scss'],
    templateUrl: './login-turn-off.component.html'
})
export class LoginTurnOffComponent implements OnInit {
    show_login_boxes = false;
    flag : boolean;

    value : boolean;
    login_kill_switch_audit_id: string;
    created_by: string;
    pageLength = 0;
    pageIndex = 0;
    pageSize = 10;

    displayedColumns: string[] = ['loginId','createdBy', 'createdAt', 'value', 
  ];
    created_at: string;
    dataSource = new MatTableDataSource<LoginKillSwitchMetadata>();  
      isUpdating = false;
    hasLoginTurnOffPerms = false;
    constructor(
        public router: Router,
        public datasvc: DataService,
        public dialog: MatDialog,
        public apiService: APIServiceCodegen,
        public snackBar: MatSnackBar,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit() {
        setTimeout(() => {
            this.datasvc.setHeaderTitle('Login Kill Switch');
          });
        let permList = this.datasvc.getPermissions();
        if (permList.find(num => num == Permissions.LoginKillSwitch)) {
            this.hasLoginTurnOffPerms = true;
        }
            // Gets Login Kill switch's current value...
                from(this.apiService.GetLoginKillSwitch()).pipe(catchError(error => {
                    error.errors.forEach(iError => {
                        this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
                    })
                    return of(error)
                  })).subscribe(resp => {
                    this.show_login_boxes = resp.show_login_boxes;
                })
                from(this.apiService.GetLoginKillSwitchMetadata()).pipe(catchError(error => {
                    error.errors.forEach(iError => {
                        this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
                    })
                    return of(error)
                  })).subscribe(resp => {
                    this.pageLength = resp.length;
                    this.dataSource = new MatTableDataSource<LoginKillSwitchMetadata>(resp);  
                })
    }


    onCanAccessLoginChange(event) {

           // Sets Login Kill switch's value...
            from(this.apiService.UpdateLoginKillSwitch(<UpdateLoginKillSwitchInput>{
                show_login_boxes: event,
                created_by: this.datasvc.getUserName()
            })).pipe(catchError(error => {
                error.errors.forEach(iError => {
                    this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
                })
                return of(error)
              })).subscribe(resp => {
                let sanitizedEvent = this.sanitizer.sanitize(0, event);
                let sanitized_event = Boolean(sanitizedEvent);
                this.show_login_boxes = sanitized_event;
                from(this.apiService.GetLoginKillSwitchMetadata()).pipe(catchError(error => {
                    error.errors.forEach(iError => {
                        this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
                    })
                    return of(error)
                  })).subscribe(resp => {
                    this.pageLength = resp.length;
                    this.dataSource = new MatTableDataSource<LoginKillSwitchMetadata>(resp);
                })
            })
    }
    

}
