import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnDestroy, OnChanges } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatSnackBar } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
// import { EnrollmentAuditResponse } from '../models/customer-report.model';
import { Subscription } from 'rxjs';
import { DataService, ApiService } from 'src/app/services';
import { APIServiceCodegen, ReportCriteriaSimpleInput, EnrollmentAuditReport, AccountLookupForEmployee, AccountLookupForEmployeeInput } from 'src/app/API.service';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { AnyRecord } from 'dns';
import { from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DiffResults } from 'ngx-text-diff/lib/ngx-text-diff.model';

@Component({
  selector: 'app-enrollment-audit-report-table',
  templateUrl: './enrollment-audit-report-table.component.html',
  styleUrls: ['./enrollment-audit-report-table.component.scss']
})
export class EnrollmentAuditReportTableComponent implements OnInit, OnDestroy, OnChanges {
  @Output() dataRetrieved = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = ['created', 'changes', 'account',
    'item_changed', 'customer_connect'];
  public jurisdictions = [];
  dataSource = new MatTableDataSource<any>();
  subscriptionList: Subscription[] = [];
  gettingData = false;
  showOverlay = true;
  formGroup: FormGroup = new FormGroup({
    customerNameFilter: new FormControl(''),
    accountFilter: new FormControl(''),
    streetFilter: new FormControl(''),
    phoneFilter: new FormControl('')
  });
  totalItems = 0;
  pageIndex = 0;
  pageSize = 20;
  pageLength = 0;

  zipOpts = [];
  statusOpts = [];
  cancelReasonOpts = [];
  enrollmentOpts = [];

  filterValues = {
    createdDate: '',
    customerName: '',
    account: '',
    street: '',
    city: '',
    email:'',
    phone: '',
    cancelReason: ''
  };
  allRecordsList: any[];
  allEnrollments: EnrollmentAuditReport[];

  constructor(public datasvc: DataService,
    public router: Router,
    public apiService: ApiService,
    public api: APIServiceCodegen,
    public snackBar: MatSnackBar) {
    }

  ngOnInit() {
    this.paginator.page.subscribe(x => {
      this.pageIndex = x.pageIndex;
      this.getData();
    });
    this.getData();
  }

  ngOnChanges() {
    if (!this.allRecordsList) {
      return;
    }
    this.getData();
  }

  getData() {
    this.resetFormFilter();
    if (this.gettingData) {
      return;
    }
    this.gettingData = true;
    this.subscriptionList.push(
      from(
        this.api.ListEnrollmentAuditReport(<ReportCriteriaSimpleInput>{
          id_token: this.datasvc.getIdTokenFromLocalStorage()[1].toString(),
        })
      ).pipe(
        catchError(error => {
          error.errors.forEach(iError => {
            this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
          });
          return of(error)
        })
      ).subscribe((results: EnrollmentAuditReport[]) => {
        this.zipOpts = [...new Set(results.map(x => x.premise_address.zip))];
        // this.statusOpts = [...new Set(results.map(x => x.allocation_status_description))];
        this.cancelReasonOpts = [...new Set(results.map(x => x.cancel_reason_description))];
        // this.enrollmentOpts = [...new Set(results.map(x => x.enrollment_channel_description))];
        this.zipOpts.push("");
        // this.statusOpts.push("");
        // this.enrollmentOpts.push("");
        console.log('Enrollment AUDITS: ', results)

        this.pageLength = results.length;
        this.dataSource = new MatTableDataSource<EnrollmentAuditReport>(results);
        this.dataSource.filterPredicate = this.datasvc.createEnrollmentAuditFilter();
        this.allEnrollments = results;
        this.dataRetrieved.emit(results);
        this.gettingData = false;
        this.setUpFormValueChanges();
        this.dataSource.paginator = this.paginator;
      })
    );
  }

  // setUpFormValueChanges() {
  //   Object.keys(this.formGroup.controls).forEach(key => {
  //     this.formGroup.get(key).valueChanges.pipe(debounceTime(1000)).subscribe(value => {
  //         this.pageIndex = 0;
  //         this.paginator.pageIndex = 0;
  //         this.filterValues[key] = value;
  //         this.getData();
  //     });
  //   });
  // }

  setUpFormValueChanges() {
    this.displayedColumns.forEach(column => {
      if (this.formGroup.get(column + 'Filter') == null) {return; }
        this.formGroup.get(column + 'Filter').valueChanges.subscribe(value => {                                                                                                  
          this.filterValues[column] = value;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });
    });

}

  ngOnDestroy() {
    this.subscriptionList.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getJsonFromString(value: string) {
    return JSON.parse(value);
  }

  toggleCustConnJson(enrollmentAuditId: number, reqOrResp: string) {
    const x = document.getElementById(reqOrResp + '_' + enrollmentAuditId);
    if (x.style.display === 'none') {
      x.style.display = 'block';
    } else {
      x.style.display = 'none';
    }
  }

  onCompareResults(diffResults: any) {
    console.log('diffResults', diffResults);
  }

  copyText(value: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = value;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  resetFormFilter() {
    this.formGroup.setValue({
      customerNameFilter: '',
      accountFilter: '',
      streetFilter: '',
      phoneFilter: ''
    });
  }

  goToAdminDetailView(account_id, from_location) {
    this.gettingData = true;
    from(
    this.api.GetAccountLookupForEmployee(<AccountLookupForEmployeeInput>{
        account_number: account_id,
    })
    ).pipe(catchError(error =>  {
        this.gettingData = false;
        error.errors.forEach(iError => {
          this.gettingData  = false;
          this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
        })
        return of(error)
    }))
    .subscribe((searchResults: AccountLookupForEmployee) => {
        if ((<any>searchResults).errors) {
          this.gettingData  = false;
          return;
        }
        this.datasvc.setAccountLookupForEmployee(searchResults);
        this.gettingData  = false;
        this.router.navigate(['admin/customer-detail', {From: from_location}]);
    });
  }
}
