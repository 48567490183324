import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services';
import { FacilityDetail, PanelTypeResponse, FacilitySetupResponse, FacilityGenerationResponse } from '../models/admin.model';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-view-facility',
  templateUrl: './view-facility.component.html',
  styleUrls: ['./view-facility.component.scss']
})
export class ViewFacilityComponent implements OnInit {
  public siteOutput: any[] = new Array(0);
  public siteExpected: any[] = new Array(0);
  public facilityId: number;
  public facilityDetail: FacilityDetail;
  public facilityState: string;
  public facilityJurisdiction: string;
  public panelType: string;
  public programId: number;

  constructor(private route: ActivatedRoute,
    public router: Router,
    public apisvc: ApiService) {

    this.siteExpected = null;

    this.siteOutput = null;

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.facilityId = params['id'];
        const observables = [];
        observables.push(this.apisvc.getStates());
        observables.push(this.apisvc.getFacilityById(params['id']));
        //observables.push(this.apisvc.getJurisdictions());
        observables.push(this.apisvc.getPanelTypes());
        observables.push(this.apisvc.getFacilityGenerationData(this.facilityId));
        forkJoin(observables).subscribe(x => {
          this.facilityDetail = (<FacilitySetupResponse>x[1]).facility_detail;
          this.facilityState = (<any>x[0]).states.find(state => state.state_id === this.facilityDetail.facility.state_id).state_code;
          this.facilityJurisdiction = ((<any>x[2]).jurisdictions.find(jur => jur.jurisdiction_id ===
            this.facilityDetail.facility.jurisdiction_id)).jurisdiction_code;
          this.panelType = (<PanelTypeResponse>x[3]).panel_types.find(type => type.panel_type_id ===
            this.facilityDetail.facility.panel_type_id).panel_type_name;
            let listOfMonthsExpected = [];
            let listOfMonthsOutput = [];
            listOfMonthsExpected = (<FacilityGenerationResponse>x[4]).data.site_expected.map(d => d.name);
            listOfMonthsOutput = (<FacilityGenerationResponse>x[4]).data.site_output.map(d => d.name);
            const listOfMonthsIntected = _.intersection(listOfMonthsOutput, listOfMonthsExpected);
          this.siteExpected = (<FacilityGenerationResponse>x[4]).data.site_expected
          .filter(d => listOfMonthsIntected.find(i => i === d.name));
          this.siteOutput = (<FacilityGenerationResponse>x[4]).data.site_output
          .filter(d => listOfMonthsIntected.find(i => i === d.name));
        });
      }
    });
  }

  onGoBack() {
    this.router.navigate(['/admin/facility-management']);
  }

  onEdit() {
    this.router.navigate(['/admin/facility-setup/' + this.facilityId]);
  }

}
