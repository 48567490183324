import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AccountDetailModel } from '../models/customer-detail.model';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { DropDownSelection, FacilitySetupModel, FacilitySetupResponse, ProjectedCapacityFactor
    , FacilityDetail, 
    Permissions} from '../models/admin.model';
import { forkJoin, Observable } from 'rxjs';
import { SubscriptionDetail } from '../models/subscription.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService, DataService, FacilityManagementService } from 'src/app/services';
//import { JurisdictionModel } from '../models/customer-report.model';
//import { SubscriptionCardDialogComponent } from '../details-dialog/details-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { EnrollmentFeeDialogComponent } from '../enrollment-fee-dialog/enrollment-fee-dialog.component';

@Component({
    selector: 'app-solar-facility',
    templateUrl: './solar-facility.component.html',
    styleUrls: ['./solar-facility.component.scss']
})
export class SolarFacilityComponent implements OnInit {
    public onSaveEmitter: EventEmitter<any> = new EventEmitter();
    public measureTypes: DropDownSelection[];
    public jurisdictions: DropDownSelection[];
    //public jurisdictionObjects: JurisdictionModel[];
    public facilityId: number;
    public states: DropDownSelection[];
    public panelTypes: DropDownSelection[];
    public counties: DropDownSelection[];
    public facilityObj: FacilitySetupModel;
    public projectedFactors: ProjectedCapacityFactor;
    public enrollmentFeeDialogRef: MatDialogRef<EnrollmentFeeDialogComponent>;
    public refreshingCounties = false;
    public adjacentCounties = [];
    public lmi_allocation_amount = 1234;
    public market_rate_allocation_amount = 200;
    public isButtonLoading = false;
    public hideAdjacentCounties = false;
    hasFacilityAddPerms = false;
    facilityForm: FormGroup = new FormGroup({
        facility_name: new FormControl('', Validators.required),
        jurisdiction_id: new FormControl('', Validators.required),
        address_line1: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
        state_id: new FormControl('', Validators.required),
        county_id: new FormControl('', Validators.required),
        latitude: new FormControl('', Validators.required),
        longitude: new FormControl('', Validators.required),
        facility_installation_number: new FormControl('', Validators.required),
        invoice_number: new FormControl('', Validators.required),
        salesforce_id: new FormControl('', Validators.required),
        total_capacity: new FormControl('', Validators.required),
        ac_to_dc_conversion_ratio: new FormControl('', Validators.required),
        solar_developer_name: new FormControl('', Validators.required),
        solar_developer_address: new FormControl('', Validators.required),
        solar_developer_email: new FormControl('', Validators.required),
        terms_of_ppa: new FormControl('', Validators.required),
        expected_commercial_operating_date: new FormControl('', Validators.required),
        commercial_operating_date: new FormControl('', Validators.required),
        panel_type_id: new FormControl('', Validators.required),
        maintenance_percentage: new FormControl('', Validators.required),
        operation_and_maintenance_contact_info: new FormControl(''),
        notes: new FormControl('')
      });

    constructor(public apisvc: ApiService,
        public datasvc: DataService,
        private route: ActivatedRoute,
        private facilitysvc: FacilityManagementService,
        public router: Router,
        public dialog: MatDialog) {
            setTimeout(() => {
                this.datasvc.setHeaderTitle('Solar Facility Set-up');
              });
         }

    ngOnInit() {
        let permList = this.datasvc.getPermissions();
        if (permList.find(num => num == Permissions.AddFacility)) {
            this.hasFacilityAddPerms = true;
        } else {
          return;
        }
console.log("--------------------")

        this.measureTypes = this.datasvc.getMeasureTypes();
        const callList = <Observable<any>[]> [
            //this.apisvc.getJurisdictions(),
            //this.apisvc.getPanelTypes(),
            //this.apisvc.getStates()
        ];



        this.facilityId = null;
        // this.route.params.subscribe(params => {
        //     if (params['id']) {
        //       this.facilityId = params['id'];
        //       callList.push(this.apisvc.getFacilityById(params['id']));
        //     }
        //  });
         this.facilityForm.get('county_id').valueChanges.subscribe(newCountyId => {
            this.onCountyChange(newCountyId);
        });
        // this.facilityForm.get('jurisdiction_id').valueChanges.subscribe(newJurisdictionId => {
        //     this.onJurisdictionChange(newJurisdictionId);
        // });
        // this.apisvc.getStates().subscribe( states => {
        //     this.states = this.datasvc.mapStatesDropDown(states.data)
        // })
        

        forkJoin(callList).subscribe(calls => {
           // this.jurisdictions = this.datasvc.mapJurisdictionsDropDown(calls[0]);
            //this.jurisdictionObjects = calls[0].jurisdictions;
           // this.panelTypes = this.datasvc.mapPanelTypesDropDown(calls[1]);
           // this.states = this.datasvc.mapStatesDropDown(calls[2]);
           // this.states = this.datasvc.mapStatesDropDown(calls[0]);
            if (this.facilityId) {
                this.facilityObj = (<FacilitySetupResponse>calls[3]).facility_detail.facility;
                this.projectedFactors = (<FacilitySetupResponse>calls[3]).facility_detail.facility_projected_capacity_factor;
                this.apisvc.getCountiesByState(this.facilityObj.state_id).subscribe(countyResponse => {
                    this.counties = this.datasvc.mapCountyDropDown(countyResponse);
                });
                this.facilityForm.patchValue({
                    facility_name: this.facilityObj.facility_name,
                    jurisdiction_id: this.facilityObj.jurisdiction_id,
                    address_line1: this.facilityObj.address_line1,
                    city: this.facilityObj.city,
                    state_id: this.facilityObj.state_id,
                    county_id: this.facilityObj.county_id,
                    latitude: this.facilityObj.latitude,
                    longitude: this.facilityObj.longitude,
                    facility_installation_number: this.facilityObj.facility_installation_number,
                    invoice_number: this.facilityObj.invoice_number,
                    salesforce_id: this.facilityObj.salesforce_id,
                    total_capacity: this.facilityObj.total_capacity_kw,
                    ac_to_dc_conversion_ratio: this.facilityObj.ac_to_dc_conversion_ratio,
                    solar_developer_name: this.facilityObj.solar_developer_name,
                    solar_developer_address: this.facilityObj.solar_developer_address,
                    solar_developer_email: this.facilityObj.solar_developer_email,
                    terms_of_ppa: this.facilityObj.terms_of_ppa,
                    expected_commercial_operating_date: new Date(this.facilityObj.expected_commercial_operating_date),
                    commercial_operating_date: new Date(this.facilityObj.commercial_operating_date),
                    panel_type_id: this.facilityObj.panel_type_id,
                    maintenance_percentage: this.facilityObj.maintenance_percentage,
                    operation_and_maintenance_contact_info: this.facilityObj.operation_and_maintenance_contact_info,
                    notes: this.facilityObj.notes,
                  });
            }

        });

    }

    onStateChange(stateId) {

        this.facilityForm.patchValue({
            county: null,
          });
        this.apisvc.getCountiesByState(stateId).subscribe(countyResponse => {
            this.counties = this.datasvc.mapCountyDropDown(countyResponse);
        });
        this.adjacentCounties = [];
    }

    onCountyChange(countyId: number) {
        this.apisvc.getAdjacentCounties(countyId).subscribe(adjCounties => {
            this.adjacentCounties = adjCounties.counties.map(county => county.county_name);
        });
    }

    // onJurisdictionChange(jurisdictionId: number) {
    //   this.hideAdjacentCounties = !this.jurisdictionObjects.find(x => x.jurisdiction_id === jurisdictionId).facility_required_for_enrollment;
    // }

    onCancel() {
        this.router.navigate(['admin/facility-management']);
    }

    onShowEnrollmentFees() {
            this.enrollmentFeeDialogRef = this.dialog.open(EnrollmentFeeDialogComponent, {
                id: 'enrollmentFeeDialog',
                minWidth: '60vw',
                data: {
                    facility_data: this.facilityObj
                }
            });
    }

    onSave() {
        this.isButtonLoading = true;
        if (!this.facilityId) {
            this.onSaveEmitter.emit();
            const saveObj = <FacilityDetail> {
                facility: this.facilityForm.value,
                facility_projected_capacity_factor: this.datasvc.getProjectedMonthlyCapacity()
            };
            saveObj.facility_projected_capacity_factor.facility_id = null;
            saveObj.facility_projected_capacity_factor.facility_projected_capacity_factor_id = null;
            saveObj.facility_projected_capacity_factor =
             this.facilitysvc.organizeMonthlyCapacityData(saveObj.facility_projected_capacity_factor);
            saveObj.facility.facility_id = null;
            saveObj.facility.latitude = +saveObj.facility.latitude;
            saveObj.facility.longitude = +saveObj.facility.longitude;
            saveObj.facility.total_capacity_kw = +saveObj.facility.total_capacity_kw;
            saveObj.facility.maintenance_percentage = +saveObj.facility.maintenance_percentage;
            saveObj.facility.last_modified_by = 'apdesro';
            this.apisvc.insertFacility(saveObj).subscribe(x => { this.isButtonLoading = false; });
        } else {
            this.onSaveEmitter.emit();
            const saveObj = <FacilityDetail> {
                facility: this.facilityForm.value,
                facility_projected_capacity_factor: this.datasvc.getProjectedMonthlyCapacity()
            };
            saveObj.facility_projected_capacity_factor.facility_id = this.facilityId;
            saveObj.facility_projected_capacity_factor =
             this.facilitysvc.organizeMonthlyCapacityData(saveObj.facility_projected_capacity_factor);
            saveObj.facility.facility_id = this.facilityId;
            saveObj.facility.latitude = +saveObj.facility.latitude;
            saveObj.facility.longitude = +saveObj.facility.longitude;
            saveObj.facility.total_capacity_kw = +saveObj.facility.total_capacity_kw;
            saveObj.facility.maintenance_percentage = +saveObj.facility.maintenance_percentage;
            saveObj.facility.last_modified_by = 'apdesro';
            this.apisvc.updateFacility(saveObj).subscribe(x => { this.isButtonLoading = false; });
        }
    }

}
