import { Component, OnInit, Input, EventEmitter, Output, ViewChild, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MatPaginator, MatSnackBar, MatTableDataSource } from '@angular/material';
import { DataService, ApiService } from 'src/app/services';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { EnrollmentAlertModel} from '../models/admin.model';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'app-enrollment-alerts-main-page',
    templateUrl: './enrollment-alerts-main-page.component.html',
    styleUrls: ['./enrollment-alerts-main-page.component.scss']
})

export class EnrollmentAlertsMainPageComponent implements OnInit, OnChanges {
    @Input() jurisdiction_id: number;
    @Input() program_id: number;
    @Output() dataRetrieved = new EventEmitter();
    public alertDialogRef: MatDialogRef<AlertDialogComponent>;
    displayedColumns: string[] = ['customerName', 'status', 'date' ];
    public jurisdictions = [];
    dataSource = new MatTableDataSource<EnrollmentAlertModel>();
    gettingEnrollmentAlertData = true;
    showOverlay = true;
    ROWLIMIT = 10;


    constructor(public datasvc: DataService,
        public apiService: ApiService,
        private router: Router,
        public dialog: MatDialog,
        public snackBar: MatSnackBar) { }

    ngOnInit() {
          this.getData();
    }

    ngOnChanges() {
      this.getData();
    }

    getData() {
      this.gettingEnrollmentAlertData = true;
      this.apiService.getEnrollmentAlertList(this.jurisdiction_id, this.program_id, this.ROWLIMIT).pipe(catchError(error => {
        error.errors.forEach(iError => {
            this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
        })
        return of(error)
      })).subscribe(enrollmentAlerts => {
        this.dataSource = new MatTableDataSource<EnrollmentAlertModel>(enrollmentAlerts.data.enrollmentAlerts);
        this.dataRetrieved.emit(enrollmentAlerts.data.enrollmentAlerts);
        this.gettingEnrollmentAlertData = false;
      });
    }


      onShowDialog(issue_id) {
        const issue = this.dataSource.data.find(x => x.enrollment_audit_id === issue_id);
        this.alertDialogRef = this.dialog.open(AlertDialogComponent, {
            id: 'alertDialog',
            minWidth: '60vw',
            data: {
                issue_id: issue_id,
                dialog_type: 'Enrollment',
                notes: issue.notes,
                customer_name: issue.full_name,
                facility_name: '',
                issue: issue.status_change,
                date: issue.created_date,
                account: issue.account_id
            }
        });
        this.alertDialogRef.afterClosed().subscribe(x => {
          this.getData();
        });
      }

      viewMore() {
        this.router.navigate(['admin/enrollment-alert-report']);
      }
}








