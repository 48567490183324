import { Component, OnInit, OnDestroy, Input, EventEmitter, ViewChild, Output } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataService, ApiService } from 'src/app/services';
import { ProgramModel, FinancialModel } from '../models/admin.model';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'app-financial-report-table',
    templateUrl: './financial-report-table.component.html',
    styleUrls: ['./financial-report-table.component.scss']
})
export class FinancialReportTableComponent implements OnInit, OnDestroy {
    @Input() exportData: EventEmitter<any> = new EventEmitter();
    @Output() dataRetrieved = new EventEmitter();
    @Input() facility_name = '';
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    displayedColumns: string[] = ['year',  'month', 'fees_collected', 'enrollment_fees', 'subscription_fees',
        'credits_earned', 'savings'];
    dataSource = new MatTableDataSource<FinancialModel>();
    isMonthly = true;
    subscriptionList: Subscription[] = [];
    financialForm: FormGroup = null;


    constructor(public datasvc: DataService,
        public apisvc: ApiService) { }

    ngOnInit() {
        this.financialForm = new FormGroup({
            reservationDate: new FormControl(''),
        });
        this.financialForm.get('reservationDate').valueChanges.subscribe(value => {
            // Change date
        });
        this.subscriptionList.push(this.exportData.subscribe(() => {
            this.exportExcel();
        }));
        this.apisvc.getFinancial(this.isMonthly).subscribe(finance => {
            this.dataSource = new MatTableDataSource<FinancialModel>(finance.financials);
        });
        this.dataSource.paginator = this.paginator;
    }

    ngOnDestroy() {
        this.subscriptionList.forEach(sub => {
            sub.unsubscribe();
        });
    }

    setIsMonthly(isMonthly: boolean) {
        if (this.isMonthly !== isMonthly) {
            if (isMonthly) {
                this.displayedColumns.splice(1, 0, 'month');
            } else {
                this.displayedColumns.splice(1, 1);
            }
        }
        this.isMonthly = isMonthly;
        this.apisvc.getFinancial(this.isMonthly).subscribe(finance => {
            this.dataSource = new MatTableDataSource<FinancialModel>(finance.financials);
        });
    }


    exportExcel() {
    }


}








