import { Component, OnInit, Input, EventEmitter, Output, ViewChild, OnChanges, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MatPaginator, MatTableDataSource } from '@angular/material';
import { of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APIServiceCodegen } from 'src/app/API.service';
import { DataService, ApiService } from 'src/app/services';
import { SegmentService } from 'src/app/services/segment.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { FacilityAlertModel } from '../models/admin.model';

@Component({
    selector: 'app-facility-alerts-report-table',
    templateUrl: './facility-alerts-report-table.component.html',
    styleUrls: ['./facility-alerts-report-table.component.scss']
})

export class FacilityAlertsReportTableComponent implements OnInit, OnChanges {
    @Input() jurisdiction_id: number;
    @Input() program_id: number;
    @Input() exportData: EventEmitter<any> = new EventEmitter();
    @Input() showFilters = true;
    @Output() dataRetrieved = new EventEmitter();
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    displayedColumns: string[] = ['facilityName', 'issue', 'date', 'note', 'delete' ];
    public jurisdictions = [];
    dataSource = new MatTableDataSource<FacilityAlertModel>();
    gettingFacilityAlertData = true;
    showOverlay = true;
    public alertDialogRef: MatDialogRef<AlertDialogComponent>;
    facilityAlertForm: FormGroup = new FormGroup({
      facilityNameFilter: new FormControl(''),
      issueFilter: new FormControl(''),
      dateFilter: new FormControl('')
    });
    filterValues = {
      facilityName: '',
      issue: '',
      date: '',
    };

    public subscription: Subscription;
    public segmentId: String;


    constructor(public datasvc: DataService,
        public apiService: ApiService,
        public dialog: MatDialog,
        private api: APIServiceCodegen,
        private segService: SegmentService) {
          this.subscription = this.segService.currentSegment$.subscribe( res => {
            this.updateByParticipationSegment(res);
            this.segmentId = res;
         })
        }

    ngOnInit() {
        this.exportData.subscribe(() => {
            this.exportExcel();
          });
          if (!this.showFilters) {
            this.displayedColumns.splice(4, 1);
            this.displayedColumns.splice(3, 1);
          }
          this.getData();
    }

    ngOnChanges() {
      this.getData();
    }

    getData() {
      this.gettingFacilityAlertData = true;
      this.apiService.getFacilityAlertList(this.jurisdiction_id, this.program_id)
      .pipe(catchError(error => of(error))).subscribe(facilityAlerts => {
          console.log(facilityAlerts)
          // this.dataSource = new MatTableDataSource<any>(facilityAlerts);
          // this.dataRetrieved.emit(facilityAlerts.alerts);
          this.gettingFacilityAlertData = false;
          // this.dataSource.paginator = this.paginator;
          // this.dataSource.filterPredicate = this.datasvc.createFacilityAlertsFilter();
          // this.setUpFormValueChanges();
        });
    }

    setUpFormValueChanges() {
        this.displayedColumns.forEach(column => {
          if (column === 'note' || column === 'delete') { return; }
           this.facilityAlertForm.get(column + 'Filter').valueChanges.subscribe(value => {
              this.filterValues[column] = value;
              this.dataSource.filter = JSON.stringify(this.filterValues);
            });
          });
      }

    exportExcel() {
        const exportFacilityAlertList = this.dataSource.data.map(rec => {
          return {
            'Facility Name': rec.facility_name,
            'Issue': rec.issue,
            'Date': rec.created_date,
            'Notes' : rec.notes
          };
        });
        this.datasvc.exportExcelSpreadsheet(exportFacilityAlertList,
          'Facility Alert' + this.datasvc.getDate(),
          'FacilityAlertExport' + this.datasvc.getDate() + '.xlsx');
      }

      onShowDialog(issue_id) {
        const issue = this.dataSource.data.find(x => x.id === issue_id);
        this.alertDialogRef = this.dialog.open(AlertDialogComponent, {
            id: 'alertDialog',
            minWidth: '60vw',
            data: {
                id: issue_id,
                dialog_type: 'Facility',
                notes: issue.notes,
                customer_name: '',
                facility_name: issue.facility_name,
                issue: issue.issue,
                date: issue.created_date,
                account: null
            }
        });
        this.alertDialogRef.afterClosed().subscribe(x => {
          // this.getData();
          this.updateByParticipationSegment();
        });
      }

      updateByParticipationSegment(segmentId?: string){
        //this.gettingCustomerAlertData = true;
        this.api.ListFacilityAlert().then( response => {
          //this.dataSource = new MatTableDataSource<CustomerAlertModel>(response);
            //this.dataSource = (<CustomerAlertModel>response);
            // console.log(response.items);
            this.dataSource = new MatTableDataSource<any>(response.items);
           // this.gettingCustomerAlertData = false;
            // this.subscribed_mw = response.subscribed_mw;
            // this.available_mw = response.available_mw;
            // this.wait_list_mw = response.wait_list_mw;
        })
        .catch( err => {
                  console.log(ErrorDialogComponent.API_GENERIC_ERROR_500_MSG)
                  console.log(err)
         });
    }

    ngOnDestroy() {
      this.subscription.unsubscribe()
   }
}








