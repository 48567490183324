<br><br>
  <div class="mat-table-format">
      <div class="background-white">
        <br>
        <div class="row underlineded">
            <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <u [ngClass]="{ 'selected': isMonthly === true}" (click)="setIsMonthly(true)">Monthly</u>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <u [ngClass]="{ 'selected': isMonthly === false}" (click)="setIsMonthly(false)">Yearly</u>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div class="col-3"></div>
            <mat-form-field class="filter" floatLabel="never" class="col-3">
                <input matInput [matDatepicker]="reservationPicker" formControlName="reservationDate" class="long">
                <mat-datepicker-toggle matSuffix [for]="reservationPicker"></mat-datepicker-toggle>
                <mat-datepicker #reservationPicker></mat-datepicker>
            </mat-form-field>
        </div>

      </div>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="year">
        <th mat-header-cell *matHeaderCellDef class="year_column">
          Year
        </th>
        <td mat-cell *matCellDef="let element"> {{element.year}} </td>
      </ng-container>
      <ng-container matColumnDef="month">
        <th mat-header-cell *matHeaderCellDef class="month_column">
          Month
        </th>
        <td mat-cell *matCellDef="let element"> {{element.month}} </td>
      </ng-container>

      <ng-container matColumnDef="fees_collected">
        <th mat-header-cell *matHeaderCellDef class="fees_collected_column">
          Fees Collected
        </th>
        <td mat-cell *matCellDef="let element"> {{element.fees_collected}} </td>
      </ng-container>

      <ng-container matColumnDef="enrollment_fees">
        <th mat-header-cell *matHeaderCellDef class="enrollment_fee_column">
            Enrollment Fees
        </th>
        <td mat-cell *matCellDef="let element"> {{element.enrollment_fees}} </td>
      </ng-container>

      <ng-container matColumnDef="subscription_fees">
        <th mat-header-cell *matHeaderCellDef class="subscription_fees_column">
          Subscription Fees
        </th>
        <td mat-cell *matCellDef="let element"> {{element.subscription_fees}}</td>
      </ng-container>
      
      <ng-container matColumnDef="credits_earned">
        <th mat-header-cell *matHeaderCellDef class="credits_earned_column">
          Credits Earned
        </th>
        <td mat-cell *matCellDef="let element"> {{element.credits_earned}} </td>
      </ng-container>

      <ng-container matColumnDef="savings">
        <th mat-header-cell *matHeaderCellDef class="savings_column">
          Savings
        </th>
        <td mat-cell *matCellDef="let element"> {{element.savings}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>