const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_a2csqgDzs",
    "aws_user_pools_web_client_id": "5vmbl8qehrc0raeelii50fu6f5",
    "oauth": {
      "domain": "cec-userpooldomain-sbx.auth.us-east-1.amazoncognito.com",
      "scope": [
        "email",
        "profile",
        "openid"      
        ],
        "redirectSignIn": "http://localhost:4200/",
        "redirectSignOut": "http://localhost:4200",
      "responseType": "token"
    },
    "aws_appsync_graphqlEndpoint": "https://nugekr4mvngt3gploq3pdj6ksu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
