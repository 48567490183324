<div class="slider-wrapper" (window:resize)="onResize()" #sliderWrapper>
    <input class="slider" [ngClass]="{ 'slider__disabled' : input.disabled }" type="range" id="sliderOutput" min="{{min}}" max="{{max}}" step="1" #input
        (input)="onSliderChange($event)"
        (change)="onFinalSliderChange()">
    <output class="slider-thumb" for="sliderOutput" #sliderThumb>
        <ng-container *ngIf="!input.disabled">
            {{sliderValue}}kW
        </ng-container>
    </output>
    <div class="percentage-labels">
        <div class="percentage-labels__25">
            <p class="m-auto">|</p>
            <p>25%</p>
        </div>
        <div class="percentage-labels__50">
            <p class="m-auto">|</p>
            <p>50%</p>
        </div>
        <div class="percentage-labels__100">
            <p class="m-auto">|</p>
            <p>100%</p>
        </div>
    </div>
</div>