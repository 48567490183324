import { Component, OnInit, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { DataService, ApiService } from 'src/app/services';
import { Router } from '@angular/router';
import { CustomerSegments, Permissions } from '../models/admin.model';

@Component({
  selector: 'app-enrolled-allocations-report',
  templateUrl: './enrolled-allocations-report.component.html',
  styleUrls: ['./enrolled-allocations-report.component.scss']
})
export class EnrolledAllocationsReportComponent implements OnInit {
  public customerSegmentId: string;
  public dataSource = new MatTableDataSource<any>(null);
  public exportData = new EventEmitter();
  public facilityDataLoaded: EventEmitter<any> = new EventEmitter();
  hasEnrolledAllocationsReportPerms = false;

  constructor(public datasvc: DataService,
    private router: Router,
    public apisvc: ApiService) { }

  ngOnInit() {
    let permList = this.datasvc.getPermissions();
    if (permList.find(num => num == Permissions.EnrolledAllocationsReport)) {
        this.hasEnrolledAllocationsReportPerms = true;
    }
    setTimeout(() => {
      this.datasvc.setHeaderTitle('Enrolled Allocations Report');
    });
    if (this.datasvc.getEnrolledAllocationsReportCustomerSegmentId() != null) {
      this.customerSegmentId = this.datasvc.getEnrolledAllocationsReportCustomerSegmentId();
    } else {
      this.customerSegmentId = String(CustomerSegments.LowIncome)
    }
  }

  dataRetrieved($event) {
    this.facilityDataLoaded.emit($event);
  }

  customerSegmentChanged(event) {
    if (String(event.customer_segment_id) !== this.customerSegmentId) {
      this.customerSegmentId = String(event.customer_segment_id);
      this.datasvc.setEnrolledAllocationsReportCustomerSegmentId(this.customerSegmentId);
    }
  }


  exportExcel() {
    this.exportData.emit('');
  }

  onNewCustomer() {
    this.router.navigate(['admin/customer-lookup']);
  }

}
