<mat-expansion-panel [(expanded)]="isExpanded">
    <mat-expansion-panel-header class="blueHeader">
        <div class="blueBar row">
            <span class="text-left col-5">Account {{accountDetail.account_number}}</span>
            <span
                class="text-right col-6">{{accountDetail.current_allocation_size_kw ? accountDetail.current_allocation_size_kw : 0 }}
                kW
                ({{accountDetail.max_subscription_size_kw ? accountDetail.max_subscription_size_kw : 0}} Max)</span>
        </div>
    </mat-expansion-panel-header>
    <br>
    <div class="row sectionHeader pb-4">
        Premise Address
    </div>
    <div class="row text-size-reg">
        <div class="col-4 detail">
            <mat-label>Street</mat-label>
            <br>
            <span>{{accountDetail.premise_address.line1}}</span>
        </div>
        <div class="col-4 detail">
            <mat-label>City</mat-label>
            <br>
            <span>{{accountDetail.premise_address.city}}</span>
        </div>
        <div class="col-3 detail">
            <mat-label>State</mat-label>
            <br>
            <span>{{accountDetail.premise_address.state}}</span>
        </div>
    </div>
    <br><br>
    <div class="row sectionHeader pb-4">
        Billing Address
    </div>
    <div class="row text-size-reg">
        <div class="col-4 detail">
            <mat-label>Street</mat-label>
            <br>
            <span>{{accountDetail.billing_address.line1}}</span>
        </div>
        <div class="col-4 detail">
            <mat-label>City</mat-label>
            <br>
            <span>{{accountDetail.billing_address.city}}</span>
        </div>
        <div class="col-3 detail">
            <mat-label>State</mat-label>
            <br>
            <span>{{accountDetail.billing_address.state}}</span>
        </div>
    </div>
    <br><br>
    <div class="row sectionHeader pb-4">
        Main Customer
    </div>
    <div class="row text-size-reg">
        <div class="col-4 detail">
            <mat-label>Name</mat-label>
            <br>
            <span *ngIf="accountDetail.main_customer">{{accountDetail.main_customer.full_name}}</span>
        </div>
        <div class="col-4 detail">
            <mat-label>Email</mat-label>
            <br>
            <span *ngIf="accountDetail.main_customer">{{accountDetail.main_customer.email}}</span>
        </div>
        <div class="col-3 detail">
            <mat-label>Phone Number</mat-label>
            <br>
            <span *ngIf="accountDetail.main_customer">{{accountDetail.main_customer.phone}}</span>
        </div>
    </div>
    <br><br>
    <div *ngIf="(accountDetail).lmi_file_validation">
        <div class="row sectionHeader pb-4">
            Documents
        </div>
        <table class="allocations_table w-100" *ngIf="accountDetail?.lmi_file_validation?.length > 0">
            <tr *ngFor="let fileVal of (accountDetail).lmi_file_validation; let index = index;" class="row text-size-reg">
                <td class="col detail">
                    Status: {{fileVal.customer_status}} <br>
                    Date: {{fileVal.verification_date | date}} <br>
                    File: <a *ngIf="fileVal.link; else elseBlock" target="_blank" [href]="fileVal.link">{{fileVal.file_name}}</a>
                    <ng-template #elseBlock>{{fileVal.file_name}}</ng-template>
                </td>
            </tr>
        </table>
    </div>
    <br><br>
    <div>
        <div class="row sectionHeader pb-4">
            Allocations
        </div>
        <table class="allocations_table w-100" *ngIf="accountDetail?.allocations?.length > 0">
            <tr class="row text-size-reg">
                <th class="col-2 detail">
                    <mat-label>Subscription/Enrollment</mat-label>
                </th>
                <th class="col-2 detail">
                    <mat-label>Queue</mat-label>
                </th>
                <th class="col-2 detail">
                    <mat-label>Status Date</mat-label>
                </th>
                <th class="col-2 detail">
                    <mat-label>Status</mat-label>
                </th>
            </tr>
            <tr *ngFor="let allocation of accountDetail.allocations; let index = index;" class="row text-size-reg">
                <td class="col-2 detail">
                    <span>{{allocation.allocation_size_kw}} Kw ({{accountDetail.maxKw}} Max)</span>
                </td>
                <td class="col-2 detail">
                    <span *ngIf="allocation.allocation_status_id == 3">{{accountDetail.wait_list_position}}</span>
                </td>
                <td class="col-2 detail">
                    <span>{{dateToShow(allocation)| date :'shortDate'}}</span>
                </td>
                <td class="col-2 detail">
                    <span>{{allocation.allocation_status_description}}</span>
                </td>
                <td *ngIf="allocation.res_low_income" class="col-2 detail">
                    <span>Low Income Allocation</span>
                </td>
                <td *ngIf="allocation.res_market_rate" class="col-2 detail">
                    <span>Market Rate Allocation</span>
                </td>
                <td class="col-auto detail options_column">
                    <span *ngIf="hasAllocationViewPerms"><a href="javascript:;"
                        (click)='onNavigateTo(index, "View");'>View</a></span>
                </td>
                <td class="col-auto detail options_column">
                    <span *ngIf="hasAllocationEditPerms && allocation.allocation_status_id != allocationStatus.Cancelled && allocation.allocation_status_id != allocationStatus.PendingEnrollment && allocation.allocation_status_id != allocationStatus.VerificationFailed"><a href="javascript:;"
                        (click)='onNavigateTo(index, "Edit");'>Edit</a></span>
                    <span *ngIf="hasAllocationCancelPerms && allocation.allocation_status_id != allocationStatus.Cancelled && allocation.allocation_status_id != allocationStatus.VerificationFailed" ><a href="javascript:;"
                        (click)='onNavigateTo(index, "Cancel");'>Cancel</a></span>
                </td>
            </tr>
        </table>
        <div class="no_allocations_message" *ngIf="accountDetail?.allocations?.length === 0">
            <p>No Allocations</p>
        </div>
    </div>
    <br>
    <div class="row pr-5 float-right">
        <!-- <de-button *ngIf="accountDetail.eligibility.is_eligible === true && canMakeNewAllocation(accountDetail) && hasAllocationNewPerms" type="secondary" [loading]="isButtonLoading" (click)="onNewSubscription()">New Subscription</de-button> -->
        <de-button *ngIf="accountDetail.eligibility.is_eligible === false && accountDetail.eligibility.ineligibility_reasons[0].endsWith('not active.') === true" type="secondary" [loading]="isButtonLoading">New Subscription</de-button>
        <de-button *ngIf="accountDetail.eligibility.is_eligible === true || accountDetail.eligibility.ineligibility_reasons[0].endsWith('not active.') === false" type="primary" [loading]="isButtonLoading" (click)="onNewSubscription()">New Subscription</de-button>
        <span *ngIf="accountDetail.eligibility.is_eligible === false" class="show-in-middle">Ineligible. <a href="javascript:;" (click)="showIneligibilityReasons();"> See Why</a></span>
    </div>
</mat-expansion-panel>