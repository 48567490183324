import { Injectable } from '@angular/core';

@Injectable()

export class StyleHelperService {
  setElementHeightFromTop(elementId: string) {
    const element = <HTMLElement>document.getElementById(elementId);
    const elementRect = element.getBoundingClientRect();
    const styles = {
      'height': 'calc(100vh -  ' + elementRect.top + 'px)'
    };
    return styles;
  }

  makeChartResponsive(elementId: string): any[] {
    const chartContainerSize = <HTMLElement>document.getElementById(elementId);
    if (chartContainerSize) {
      return [chartContainerSize.clientWidth, chartContainerSize.clientHeight];
    } else {
      return null;
    }
  }


}
