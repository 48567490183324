<div *ngIf="hasFacilityAddPerms">
    <form [formGroup]="facilityForm" novalidate class="row">
        <div class="col-3"></div>
        <div class="col-4">
            <div class="container dashboard-card">
                <mat-label class="main-label row">Facility Name</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="facility_name">
                </mat-form-field>
                <br>
                <mat-label class="main-label row">Address - Street</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="address_line1">
                </mat-form-field>
                <br>
                <mat-label class="main-label row">City</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="city">
                </mat-form-field>
                <br>
                <mat-label class="main-label row">State</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <mat-select formControlName="state_id" class="long" (selectionChange)="onStateChange($event.value)">
                        <mat-option *ngFor="let stateObj of states; let i = index" [(value)]="states[i].value">
                            {{stateObj.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
                <mat-label class="main-label row">County</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <mat-select formControlName="county_id" class="long">
                        <mat-option *ngFor="let county of counties; let i = index" [(value)]="counties[i].value">
                            {{county.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
                <mat-label class="main-label row">Jurisdiction</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <mat-select formControlName="jurisdiction_id" class="long">
                        <mat-option *ngFor="let jurisdiction of jurisdictions; let i = index"
                            [(value)]="jurisdictions[i].value">
                            {{jurisdiction.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
                <div class="row">
                    <div class="col-6 no-front-padding">
                        <mat-label class="main-label">Facility Lat</mat-label>
                    </div>
                    <div class="col-6">
                        <mat-label class="main-label">Facility Long</mat-label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 no-front-padding">
                        <mat-form-field class="filter" floatLabel="never">
                            <input matInput class="long" formControlName="latitude">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="filter" floatLabel="never">
                            <input matInput class="long" formControlName="longitude">
                        </mat-form-field>
                    </div>
                </div>
                <mat-label class="row main-label">Facility Installation Number</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="facility_installation_number">
                </mat-form-field>
                <br>
                <mat-label class="row main-label">Invoice Number</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="invoice_number">
                </mat-form-field>
                <br>
                <mat-label class="row main-label">Salesforce ID</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="salesforce_id">
                </mat-form-field>
                <br>
                <mat-label class="row main-label">Total Facility Capacity (kW)</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="total_capacity">
                </mat-form-field>
                <br>
                <mat-label class="row main-label">AC to DC Conversion Ratio</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="ac_to_dc_conversion_ratio">
                </mat-form-field>
                <br>
                <mat-label class="row main-label">Solar Developer Name</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="solar_developer_name">
                </mat-form-field>
                <br>
                <mat-label class="row main-label">Solar Developer Address</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="solar_developer_address">
                </mat-form-field>
                <br>
                <mat-label class="row main-label">Solar Developer E-mail</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="solar_developer_email">
                </mat-form-field>
                <br>
                <mat-label class="row main-label">Terms of PPA</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="terms_of_ppa">
                </mat-form-field>
                <br>
                <mat-label class="row main-label">Expected Commercial Operating Date</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="expected_commercial_operating_date"
                        [matDatepicker]="expected_commercial_operating_date">
                    <mat-datepicker-toggle matSuffix [for]="expected_commercial_operating_date"></mat-datepicker-toggle>
                    <mat-datepicker #expected_commercial_operating_date></mat-datepicker>
                </mat-form-field>
                <br>
                <mat-label class="row main-label">Actual Commercial Operating Date</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="commercial_operating_date"
                        [matDatepicker]="commercial_operating_date">
                    <mat-datepicker-toggle matSuffix [for]="commercial_operating_date"></mat-datepicker-toggle>
                    <mat-datepicker #commercial_operating_date></mat-datepicker>
                </mat-form-field>
                <br>
                <mat-label class="main-label row">Tracking or Fixed Panels</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <mat-select formControlName="panel_type_id" class="long">
                        <mat-option *ngFor="let type of panelTypes; let i = index" [(value)]="panelTypes[i].value">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br>
                <mat-label class="row main-label">Maintenance</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <input matInput class="long" formControlName="maintenance_percentage">
                </mat-form-field>
                <br>
                <mat-label class="row main-label">Operations & Maintenance Contact Information</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <textarea matInput formControlName="operation_and_maintenance_contact_info" class="long"
                        rows="4"></textarea>
                </mat-form-field>
                <br>
                <br>
                <section *ngIf="!hideAdjacentCounties">
                    <mat-label class="row main-label">Available Counties</mat-label>
                    <br>
                    <div class="row">
                        <span *ngFor="let county of adjacentCounties"
                            class="badge badge-pill badge-primary">{{county}}</span>
                    </div>
                    <br>
                </section>
                <mat-label class="main-label row">Notes</mat-label>
                <mat-form-field class="filter row" floatLabel="never">
                    <textarea matInput formControlName="notes" class="long" rows="4"></textarea>
                </mat-form-field>
                <br>
                <div class="float-right row">
                    <de-button type="secondary" size="small" (click)="onCancel()">Go Back</de-button>
                    <de-button type="secondary" [loading]="isButtonLoading" size="small" (click)="onSave()"
                        [disabled]="!facilityForm.valid">Save
                    </de-button>
                </div>
                <br><br><br>
            </div>

        </div>
        <div class="col-2">
            <div class="row">
                <div class="col-8 container dashboard-card">
                    <app-total-capacity [lmi_allocation_amount]="lmi_allocation_amount"
                        [market_rate_allocation_amount]="market_rate_allocation_amount"></app-total-capacity>
                </div>
            </div>
            <div class="row">
                <div class="container col-8 dashboard-card">
                    <app-monthly-capacity-factor [ProjectedCapacityFactor]="projectedFactors" [onSave]="onSaveEmitter">
                    </app-monthly-capacity-factor>
                </div>
            </div>
        </div>
        <div class="col-3"></div>

    </form>
</div>