<de-loader *ngIf="gettingEnrolledAllocationsReportData || gettingDetailData" [showOverlay]="showOverlay"></de-loader>
<!--Button for reverifying eligibility-->
<div *ngIf="allAllocations.length">
    <de-button type="secondary" (click)="onReverifyEligibility()" [loading]="isReverifyEligibilityLoading">REVERIFY ELIGIBILITY</de-button>
    <span *ngIf="isReverifyEligibilityLoading">Reverifying: {{itemsReverified}} of {{allAllocations.length}}...</span>
</div>
<div class="mat-table-format" class="table-overflow">
    <table *ngIf="!gettingEnrolledAllocationsReportData" mat-table [dataSource]="dataSource" class="table-whitespace">
        <!-- Eligibility -->
        <ng-container matColumnDef="eligibility">
            <th mat-header-cell *matHeaderCellDef>
                Eligibility
            </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!isReverifyEligibilityLoading">
                    {{element.eligibility_status}}
                    <i class="fas fa-question-circle d-inline-block cursor-pointer" *ngIf="element.ineligibility_reasons" data-toggle="tooltip" title="{{element.ineligibility_reasons}}" ></i>
                    <br />
                    {{element.eligibility_check_date| date: 'MM/dd/yyyy'}}
                </div>
                <div *ngIf="isReverifyEligibilityLoading">
                    <de-loader></de-loader>
                </div>
            </td>
        </ng-container>
        <!--Account Info-->
        <ng-container matColumnDef="account">
            <th mat-header-cell *matHeaderCellDef>
            Account
            </th>
            <td mat-cell *matCellDef="let element" class="accountColumn"> 
                <div *ngIf="element.account_id"><a href="javascript:;" (click)='goToAdminDetailView(element.account_id, "EnrolledAllocationsReport");'>{{element.account_id}}</a></div><BR>
                <div *ngIf="element.main_customer.full_name">{{element.main_customer.full_name}}</div>
                <div *ngIf="element.premise_address.line1">{{element.premise_address.line1}} </div>
                <div *ngIf="element.premise_address.city">{{element.premise_address.city}} {{element.premise_address.zip}} </div>
                <div *ngIf="element.main_customer.phone">Phone: {{element.main_customer.phone}}</div>
                <div *ngIf="element.main_customer.email">Email: {{element.main_customer.email}}</div> 
            </td>
        </ng-container>
        <!--Business Partner Id-->
        <ng-container matColumnDef="businessPartnerId">
            <th mat-header-cell *matHeaderCellDef class="businessPartnerIdColumn">
            Busi. Partner Id
            </th>
            <td mat-cell *matCellDef="let element"> {{element.business_partner_id}} </td>
        </ng-container>
        <!--Allocation Size-->
        <ng-container matColumnDef="allocationSize">
            <th mat-header-cell *matHeaderCellDef>
                <div>Allocation Size</div>
            </th>
            <td mat-cell *matCellDef="let element" class="allocationSizeColumn"> {{element.allocation_size_kw}} kW</td>
        </ng-container>
        <!--Dates: Active, Eligibility (Last) Check(ed)-->
        <ng-container matColumnDef="dates">
            <th mat-header-cell *matHeaderCellDef>
            <div>Dates</div>
            </th>
            <td mat-cell *matCellDef="let element" class="datesColumn"> 
                <div *ngIf="element.active_date">Active: {{element.active_date| date: 'shortDate'}} </div>
                <div *ngIf="element.eligibility_check_date">Eligibility Last Checked: {{element.eligibility_check_date| date: 'shortDate'}} </div>      
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>
            <div>Status</div>
            </th>
            <td mat-cell *matCellDef="let element"> Enrolled </td>
        </ng-container>
        <ng-container matColumnDef="enrollmentCode">
            <th mat-header-cell *matHeaderCellDef>
            <div>Enrollment Code</div>
            </th>
            <td mat-cell *matCellDef="let element"> 
            <div *ngIf="element.customer_connect_enrollment_code"> {{element.customer_connect_enrollment_code}} </div>        
            </td>
        </ng-container>
        <ng-container matColumnDef="productCode">
            <th mat-header-cell *matHeaderCellDef>
            <div>Product Code</div>
            </th>
            <td mat-cell *matCellDef="let element"> 
            <div *ngIf="element.customer_connect_product_code"> {{element.customer_connect_product_code}} </div>        
            </td>
        </ng-container>
        <ng-container matColumnDef="enrollmentChannel">
            <th mat-header-cell *matHeaderCellDef>
            <div>Enrollment Channel</div>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.enrollment_channel_description}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element" class="actionsColumn">
                <span *ngIf="hasAllocationViewPerms">
                <a href="javascript:;" (click)='goToAllocation(element.allocation_id, element.account_id, "View");'>View</a>
                </span>
                <span *ngIf="hasAllocationEditPerms && element.allocation_status_id != allocationStatus.Cancelled && element.allocation_status_id != allocationStatus.PendingEnrollment && element.allocation_status_id != allocationStatus.VerificationFailed"> 
                <a href="javascript:;" (click)='goToAllocation(element.allocation_id, element.account_id, "Edit");'>Edit</a>
                </span>
                <span *ngIf="hasAllocationCancelPerms && element.allocation_status_id != allocationStatus.Cancelled && element.allocation_status_id != allocationStatus.VerificationFailed">
                <a href="javascript:;" (click)='goToAllocation(element.allocation_id, element.account_id, "Cancel");'>Cancel</a>
                </span>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<mat-paginator [length]="pageLength" [pageSize]="pageSize" showFirstLastButtons></mat-paginator>