<div *ngIf="hasAllocationReportPerms" class="container-fluid">
    <BR>
    <div class="d-flex justify-content-center">
        <app-report-drop-downs (valuesChanged)="filtersSelected($event)" [canShowAll]="true"></app-report-drop-downs>
    </div>
    <div class="col-12">
        <app-allocation-table [exportData]="exportData" (dataRetrieved)="dataRetrieved($event)"
            [customer_segment_id]="selectedCustomerSegmentId"></app-allocation-table>
        <div class="background-white col-12 give-size">
            <div class="row float-right">
                <de-button (click)="onNewCustomer()" type="secondary">New Customer</de-button>
                <de-button (click)="exportExcel()" type="secondary">
                    <div>Export Data &nbsp; <i class="fas fa-caret-up"></i></div>
                </de-button>
            </div>
        </div>
    </div>
</div>