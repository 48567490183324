<div class="row">
    <div class="col">
        <div class="container">
            <br>
            <span class="biggerFont"><b>Login Controls</b></span>
        </div>
    </div>
</div>
<div *ngIf="hasLoginTurnOffPerms" class="row">
    <div class="col-5">
        <div class="container pb-5">
            <br>
            <br>
            <h3>Turn Off Login access?</h3>
            <br><br>
            <div class="row">
                <div class="col-3">
                    <h4>{{show_login_boxes ? 'Login is On' : 'Login is Off'}}</h4>
                    <br>
                    <mat-slide-toggle (change)="onCanAccessLoginChange($event.checked)" [checked]="show_login_boxes">
                    </mat-slide-toggle>
                </div>
                <div class="col-10">
                    <h5></h5>
                </div>
                <div class="col-12">
                    <hr>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="container">
            <br>
            <span class="biggerFont"><b>Login Switch Audit</b></span>
        </div>
    </div>
</div>
<table  mat-table [dataSource]="dataSource" class="table-whitespace">
    <ng-container matColumnDef="loginId">
      <th mat-header-cell *matHeaderCellDef>
        Audit Id
      </th>
      <td mat-cell *matCellDef="let element"> 
          <div >{{ element.login_kill_switch_audit_id }}</div> 
      </td>
    </ng-container>
    <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef>
          Created By
        </th>
        <td mat-cell *matCellDef="let element"> 
            <div >{{ element.created_by }}</div> 
        </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>
         Created At
        </th>
        <td mat-cell *matCellDef="let element"> 
            <div >{{ element.created_at | date:'medium' }}</div> 
        </td>
      </ng-container>
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>
          value
        </th>
        <td mat-cell *matCellDef="let element" > 
            <div *ngIf="element.value === true">On</div> 
            <div *ngIf="element.value === false">Off</div> 
        </td>
      </ng-container>
   
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="pageLength" [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
