import { Component, OnInit, Input, EventEmitter, Output, ViewChild, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MatPaginator, MatTableDataSource } from '@angular/material';
import { DataService, ApiService } from 'src/app/services';
import { CustomerAlertModel, EnrollmentAlertModel } from '../models/admin.model';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { APIServiceCodegen } from 'src/app/API.service';
import { SegmentService } from 'src/app/services/segment.service';
import { Subscription } from 'rxjs';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
  selector: 'app-customer-alerts-report-table',
  templateUrl: './customer-alerts-report-table.component.html',
  styleUrls: ['./customer-alerts-report-table.component.scss']
})

export class CustomerAlertsReportTableComponent implements OnInit, OnChanges {
  @Input() jurisdiction_id: number;
  @Input() exportData: EventEmitter<any> = new EventEmitter();
  @Input() program_id: number;
  @Input() showFilters = true;
  @Output() dataRetrieved = new EventEmitter();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public alertDialogRef: MatDialogRef<AlertDialogComponent>;
  displayedColumns: string[] = ['full_name', 'issue', 'date', 'note', 'delete'];
  public jurisdictions = [];
  dataSource = new MatTableDataSource<CustomerAlertModel>();
  gettingCustomerAlertData = false; // temporarily set to false for testing purposes
  showOverlay = true;
  customerAlertForm: FormGroup = new FormGroup({
    full_nameFilter: new FormControl(''),
    issueFilter: new FormControl(''),
    dateFilter: new FormControl('')
  });
  filterValues = {
    full_name: '',
    issue: '',
    date: '',
  };

  public subscription: Subscription;
  public segmentId: String;

  constructor(public datasvc: DataService,
    public apiService: ApiService,
    public dialog: MatDialog,
    private api: APIServiceCodegen,
    private segService: SegmentService) {
      this.subscription = this.segService.currentSegment$.subscribe( res => {
        this.updateByParticipationSegment(res);
        this.segmentId = res;
     })
    }

  ngOnInit() {
    this.exportData.subscribe(() => {
      this.exportExcel();
    });
    if (!this.showFilters) {
      this.displayedColumns.splice(4, 1);
      this.displayedColumns.splice(3, 1);
    }
    this.getData();
  }

  ngOnChanges() {
    this.getData();
  }

  getData() {
    // this.gettingCustomerAlertData = true;
    // this.apiService.getCustomerAlertList(this.jurisdiction_id, this.program_id).subscribe(customerAlerts => {
    //   console.log(JSON.stringify(customerAlerts.data.customerAlerts))
    //   this.dataSource = new MatTableDataSource<CustomerAlertModel>(customerAlerts.data.customerAlerts);
    //   this.dataRetrieved.emit(customerAlerts.alerts);
    //   this.gettingCustomerAlertData = false;
    //   this.dataSource.paginator = this.paginator;
    //   this.dataSource.filterPredicate = this.datasvc.createCustomerAlertsFilter();
    //   this.setUpFormValueChanges();
    // });
  }

  setUpFormValueChanges() {
    this.displayedColumns.forEach(column => {
      if (column !== 'delete' && column !== 'note') {
        this.customerAlertForm.get(column + 'Filter').valueChanges.subscribe(value => {
          this.filterValues[column] = value;
          this.dataSource.filter = JSON.stringify(this.filterValues);
        });
      }
    });
  }

  exportExcel() {
    const exportCustomerAlertList = this.dataSource.data.map(rec => {
      return {
        'Customer Name': rec.full_name,
        'Issue': rec.issue,
        'Date': rec.created_date
      };
    });
    this.datasvc.exportExcelSpreadsheet(exportCustomerAlertList,
      'Customer Alert ' + this.datasvc.getDate(),
      'CustomerAlertExport' + this.datasvc.getDate() + '.xlsx');
  }

  onShowDialog(issue_id) {
    const issue = this.dataSource.data.find(x => x.alert_id === issue_id);
    this.alertDialogRef = this.dialog.open(AlertDialogComponent, {
        id: 'alertDialog',
        minWidth: '60vw',
        data: {
            issue_id: issue_id,
            dialog_type: 'Customer',
            notes: issue.notes,
            customer_name: issue.full_name,
            facility_name: '',
            issue: issue.issue,
            date: issue.created_date,
            account: issue.account_id
        }
    });
    this.alertDialogRef.afterClosed().subscribe(x => {
      this.getData();
    });
  }

  updateByParticipationSegment(segmentId: string){
    //this.gettingCustomerAlertData = true;
    this.api.ListFacilityAlert().then( response => {
      //this.dataSource = new MatTableDataSource<CustomerAlertModel>(response);
        //this.dataSource = (<CustomerAlertModel>response);
        console.log(response.items)
       // this.gettingCustomerAlertData = false;
        // this.subscribed_mw = response.subscribed_mw;
        // this.available_mw = response.available_mw;
        // this.wait_list_mw = response.wait_list_mw;
    })
    .catch( err => {
              console.log(ErrorDialogComponent.API_GENERIC_ERROR_500_MSG)
              console.log(err)
     });
}

ngOnDestroy() {
  this.subscription.unsubscribe()
}
}








