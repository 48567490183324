<div class="container dashboard-card">
    <br>
    <div class="row">
        <div class="col text-center"> 
            <i class="fa fa-2x fa-solar-panel"></i>
            <span class="title">Capacity</span>
        </div>
    </div>
    <br>
    <div class="row">
        <div class="col text-center">
            <span class="bigNumbers">{{ customerCapacity?.subscribed_kw | number }}</span>
            <!-- <span class="smallPL">{{customerCapacity.subscribed_mw }}</span> -->
            <br>
            <div>Subscribed</div>
        </div>
        <div class="col text-center">
            <span class="bigNumbers">{{ customerCapacity?.available_kw | number}}</span>
            <!-- <span class="smallPL">{{customerCapacity.available_mw | number }}</span> -->
            <br>
            <div>Avaliable</div>
        </div>
        <div class="col text-center">
            <span class="bigNumbers">{{ customerCapacity?.wait_list_kw | number }}</span>
            <!-- <span class="smallPL">{{ customerCapacity.wait_list_mw | number }}</span> -->
            <br>
            <div>Wait&nbsp;List</div>
        </div>
    </div>
</div>