import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ApiService } from 'src/app/services';
import { AlertUpdateRequest } from '../models/admin.model';
import { APIServiceCodegen, UpdateCustomerAlertInput,
  UpdateFacilityAlertInput, UpdateEnrollmentAlertInput } from 'src/app/API.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

class InputData {
  id: string;
  dialog_type: string;
  notes: string;
  customer_name: string;
  facility_name: string;
  issue: string;
  createdAt: Date;
  account: string;
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  alertForm: FormGroup = new FormGroup({
    notes: new FormControl(''),
    id: new FormControl('')
  });

  constructor(
    private alertDialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData,
    private apiSvc: ApiService,
    private api: APIServiceCodegen) {
    this.alertDialogRef.disableClose = true;
  }

  ngOnInit() {
    this.alertForm.patchValue({
      notes: this.data.notes,
      id: this.data.id
  });
  }

  // Close modal window...
  closeModal() {
    this.alertDialogRef.close("cancel");
  }

  onSave() {
    let type = this.data.dialog_type;
      if(type === "Customer"){
        console.log("----"+this.alertForm.get('notes').value)
        let noteInput: UpdateCustomerAlertInput = {
          id: this.alertForm.get('id').value,
          notes: this.alertForm.get('notes').value};
        this.api.UpdateCustomerAlert(noteInput).then( response => {
          console.log("----"+response)
        })
        .catch( err => {
          console.log(ErrorDialogComponent.API_GENERIC_ERROR_500_MSG)
          console.log(err)
        });
      }
      else if(type === "Facility"){
        console.log(this.data)
        let noteInput: UpdateFacilityAlertInput = {
          id: this.data.id,
          notes: this.alertForm.get('notes').value};
          console.log(noteInput);
        this.api.UpdateFacilityAlert(noteInput, null).then( response => {
          console.log("---"+JSON.stringify(response))
        })
        .catch( err => {
          console.log(ErrorDialogComponent.API_GENERIC_ERROR_500_MSG)
          console.log(err)
        });
      }
      else if(type === "Enrollment"){
        let noteInput: UpdateEnrollmentAlertInput = {
          id: this.alertForm.get('id').value,
          notes: this.alertForm.get('notes').value};
        this.api.UpdateEnrollmentAlert(noteInput).then( response => {
        })
        .catch( err => {
          console.log(ErrorDialogComponent.API_GENERIC_ERROR_500_MSG)
          console.log(err)
        });
      }
      this.closeModal();
  }
}
