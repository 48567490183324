import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /** The day, does not include a time. */
  Date: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
};



/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  id: Scalars['ID'];
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** Reads and enables pagination through a set of `AlertsCustomer`. */
  allAlertsCustomers?: Maybe<AlertsCustomersConnection>;
  /** Reads and enables pagination through a set of `AlertsEnrollment`. */
  allAlertsEnrollments?: Maybe<AlertsEnrollmentsConnection>;
  /** Reads and enables pagination through a set of `AlertsFacility`. */
  allAlertsFacilities?: Maybe<AlertsFacilitiesConnection>;
  /** Reads and enables pagination through a set of `CancelReason`. */
  allCancelReasons?: Maybe<CancelReasonsConnection>;
  /** Reads and enables pagination through a set of `Facility`. */
  allFacilities?: Maybe<FacilitiesConnection>;
  /** Reads and enables pagination through a set of `ProgramAccountCapacity`. */
  allProgramAccountCapacities?: Maybe<ProgramAccountCapacitiesConnection>;
  alertsCustomerByAlertId?: Maybe<AlertsCustomer>;
  alertsEnrollmentByRowId?: Maybe<AlertsEnrollment>;
  cancelReasonByRowId?: Maybe<CancelReason>;
  /** Reads a single `AlertsCustomer` using its globally unique `ID`. */
  alertsCustomer?: Maybe<AlertsCustomer>;
  /** Reads a single `AlertsEnrollment` using its globally unique `ID`. */
  alertsEnrollment?: Maybe<AlertsEnrollment>;
  /** Reads a single `CancelReason` using its globally unique `ID`. */
  cancelReason?: Maybe<CancelReason>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  id: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAllAlertsCustomersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AlertsCustomersOrderBy>>;
  condition?: Maybe<AlertsCustomerCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllAlertsEnrollmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AlertsEnrollmentsOrderBy>>;
  condition?: Maybe<AlertsEnrollmentCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllAlertsFacilitiesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AlertsFacilitiesOrderBy>>;
  condition?: Maybe<AlertsFacilityCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CancelReasonsOrderBy>>;
  condition?: Maybe<CancelReasonCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllFacilitiesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FacilitiesOrderBy>>;
  condition?: Maybe<FacilityCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllProgramAccountCapacitiesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ProgramAccountCapacitiesOrderBy>>;
  condition?: Maybe<ProgramAccountCapacityCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAlertsCustomerByAlertIdArgs = {
  alertId: Scalars['BigFloat'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAlertsEnrollmentByRowIdArgs = {
  rowId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCancelReasonByRowIdArgs = {
  rowId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAlertsCustomerArgs = {
  id: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAlertsEnrollmentArgs = {
  id: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCancelReasonArgs = {
  id: Scalars['ID'];
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID'];
};

/** A connection to a list of `AlertsCustomer` values. */
export type AlertsCustomersConnection = {
  __typename?: 'AlertsCustomersConnection';
  /** A list of `AlertsCustomer` objects. */
  nodes: Array<Maybe<AlertsCustomer>>;
  /** A list of edges which contains the `AlertsCustomer` and cursor to aid in pagination. */
  edges: Array<AlertsCustomersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AlertsCustomer` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type AlertsCustomer = Node & {
  __typename?: 'AlertsCustomer';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID'];
  alertId: Scalars['BigFloat'];
  accountId?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  alertCustomerTypeId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  createdDate?: Maybe<Scalars['Date']>;
  fullName?: Maybe<Scalars['String']>;
};



/** A `AlertsCustomer` edge in the connection. */
export type AlertsCustomersEdge = {
  __typename?: 'AlertsCustomersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AlertsCustomer` at the end of the edge. */
  node?: Maybe<AlertsCustomer>;
};


/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
};

/** Methods to use when ordering `AlertsCustomer`. */
export enum AlertsCustomersOrderBy {
  Natural = 'NATURAL',
  AlertIdAsc = 'ALERT_ID_ASC',
  AlertIdDesc = 'ALERT_ID_DESC',
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  IssueAsc = 'ISSUE_ASC',
  IssueDesc = 'ISSUE_DESC',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  AlertCustomerTypeIdAsc = 'ALERT_CUSTOMER_TYPE_ID_ASC',
  AlertCustomerTypeIdDesc = 'ALERT_CUSTOMER_TYPE_ID_DESC',
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CreatedDateAsc = 'CREATED_DATE_ASC',
  CreatedDateDesc = 'CREATED_DATE_DESC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `AlertsCustomer` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AlertsCustomerCondition = {
  /** Checks for equality with the object’s `alertId` field. */
  alertId?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `issue` field. */
  issue?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `alertCustomerTypeId` field. */
  alertCustomerTypeId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `active` field. */
  active?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdDate` field. */
  createdDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `fullName` field. */
  fullName?: Maybe<Scalars['String']>;
};

/** A connection to a list of `AlertsEnrollment` values. */
export type AlertsEnrollmentsConnection = {
  __typename?: 'AlertsEnrollmentsConnection';
  /** A list of `AlertsEnrollment` objects. */
  nodes: Array<Maybe<AlertsEnrollment>>;
  /** A list of edges which contains the `AlertsEnrollment` and cursor to aid in pagination. */
  edges: Array<AlertsEnrollmentsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AlertsEnrollment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type AlertsEnrollment = Node & {
  __typename?: 'AlertsEnrollment';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID'];
  enrollmentId?: Maybe<Scalars['BigInt']>;
  rowId: Scalars['BigInt'];
};


/** A `AlertsEnrollment` edge in the connection. */
export type AlertsEnrollmentsEdge = {
  __typename?: 'AlertsEnrollmentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AlertsEnrollment` at the end of the edge. */
  node?: Maybe<AlertsEnrollment>;
};

/** Methods to use when ordering `AlertsEnrollment`. */
export enum AlertsEnrollmentsOrderBy {
  Natural = 'NATURAL',
  EnrollmentIdAsc = 'ENROLLMENT_ID_ASC',
  EnrollmentIdDesc = 'ENROLLMENT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `AlertsEnrollment` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AlertsEnrollmentCondition = {
  /** Checks for equality with the object’s `enrollmentId` field. */
  enrollmentId?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `AlertsFacility` values. */
export type AlertsFacilitiesConnection = {
  __typename?: 'AlertsFacilitiesConnection';
  /** A list of `AlertsFacility` objects. */
  nodes: Array<Maybe<AlertsFacility>>;
  /** A list of edges which contains the `AlertsFacility` and cursor to aid in pagination. */
  edges: Array<AlertsFacilitiesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AlertsFacility` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type AlertsFacility = {
  __typename?: 'AlertsFacility';
  alertId?: Maybe<Scalars['BigInt']>;
  facilityName?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  issue?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

/** A `AlertsFacility` edge in the connection. */
export type AlertsFacilitiesEdge = {
  __typename?: 'AlertsFacilitiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AlertsFacility` at the end of the edge. */
  node?: Maybe<AlertsFacility>;
};

/** Methods to use when ordering `AlertsFacility`. */
export enum AlertsFacilitiesOrderBy {
  Natural = 'NATURAL',
  AlertIdAsc = 'ALERT_ID_ASC',
  AlertIdDesc = 'ALERT_ID_DESC',
  FacilityNameAsc = 'FACILITY_NAME_ASC',
  FacilityNameDesc = 'FACILITY_NAME_DESC',
  CreatedDateAsc = 'CREATED_DATE_ASC',
  CreatedDateDesc = 'CREATED_DATE_DESC',
  IssueAsc = 'ISSUE_ASC',
  IssueDesc = 'ISSUE_DESC',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC'
}

/**
 * A condition to be used against `AlertsFacility` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AlertsFacilityCondition = {
  /** Checks for equality with the object’s `alertId` field. */
  alertId?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `facilityName` field. */
  facilityName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDate` field. */
  createdDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `issue` field. */
  issue?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: Maybe<Scalars['String']>;
};

/** A connection to a list of `CancelReason` values. */
export type CancelReasonsConnection = {
  __typename?: 'CancelReasonsConnection';
  /** A list of `CancelReason` objects. */
  nodes: Array<Maybe<CancelReason>>;
  /** A list of edges which contains the `CancelReason` and cursor to aid in pagination. */
  edges: Array<CancelReasonsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CancelReason` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type CancelReason = Node & {
  __typename?: 'CancelReason';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars['ID'];
  rowId: Scalars['BigInt'];
  cancelReasonId?: Maybe<Scalars['BigInt']>;
  cancelReasonDescription?: Maybe<Scalars['String']>;
};

/** A `CancelReason` edge in the connection. */
export type CancelReasonsEdge = {
  __typename?: 'CancelReasonsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CancelReason` at the end of the edge. */
  node?: Maybe<CancelReason>;
};

/** Methods to use when ordering `CancelReason`. */
export enum CancelReasonsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CancelReasonIdAsc = 'CANCEL_REASON_ID_ASC',
  CancelReasonIdDesc = 'CANCEL_REASON_ID_DESC',
  CancelReasonDescriptionAsc = 'CANCEL_REASON_DESCRIPTION_ASC',
  CancelReasonDescriptionDesc = 'CANCEL_REASON_DESCRIPTION_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `CancelReason` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CancelReasonCondition = {
  /** Checks for equality with the object’s `rowId` field. */
  rowId?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `cancelReasonId` field. */
  cancelReasonId?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `cancelReasonDescription` field. */
  cancelReasonDescription?: Maybe<Scalars['String']>;
};

/** A connection to a list of `Facility` values. */
export type FacilitiesConnection = {
  __typename?: 'FacilitiesConnection';
  /** A list of `Facility` objects. */
  nodes: Array<Maybe<Facility>>;
  /** A list of edges which contains the `Facility` and cursor to aid in pagination. */
  edges: Array<FacilitiesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Facility` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type Facility = {
  __typename?: 'Facility';
  facilityId?: Maybe<Scalars['BigInt']>;
  jurisdictionId?: Maybe<Scalars['BigInt']>;
  facilityName?: Maybe<Scalars['String']>;
};

/** A `Facility` edge in the connection. */
export type FacilitiesEdge = {
  __typename?: 'FacilitiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Facility` at the end of the edge. */
  node?: Maybe<Facility>;
};

/** Methods to use when ordering `Facility`. */
export enum FacilitiesOrderBy {
  Natural = 'NATURAL',
  FacilityIdAsc = 'FACILITY_ID_ASC',
  FacilityIdDesc = 'FACILITY_ID_DESC',
  JurisdictionIdAsc = 'JURISDICTION_ID_ASC',
  JurisdictionIdDesc = 'JURISDICTION_ID_DESC',
  FacilityNameAsc = 'FACILITY_NAME_ASC',
  FacilityNameDesc = 'FACILITY_NAME_DESC'
}

/**
 * A condition to be used against `Facility` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FacilityCondition = {
  /** Checks for equality with the object’s `facilityId` field. */
  facilityId?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `jurisdictionId` field. */
  jurisdictionId?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `facilityName` field. */
  facilityName?: Maybe<Scalars['String']>;
};

/** A connection to a list of `ProgramAccountCapacity` values. */
export type ProgramAccountCapacitiesConnection = {
  __typename?: 'ProgramAccountCapacitiesConnection';
  /** A list of `ProgramAccountCapacity` objects. */
  nodes: Array<Maybe<ProgramAccountCapacity>>;
  /** A list of edges which contains the `ProgramAccountCapacity` and cursor to aid in pagination. */
  edges: Array<ProgramAccountCapacitiesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProgramAccountCapacity` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type ProgramAccountCapacity = {
  __typename?: 'ProgramAccountCapacity';
  prgAccountCntsId?: Maybe<Scalars['BigInt']>;
  prgAccountCntsType?: Maybe<Scalars['String']>;
  enrollmentCount?: Maybe<Scalars['BigInt']>;
  cancelledYtdCount?: Maybe<Scalars['BigInt']>;
  pendingCount?: Maybe<Scalars['BigInt']>;
  waitListCount?: Maybe<Scalars['BigInt']>;
};

/** A `ProgramAccountCapacity` edge in the connection. */
export type ProgramAccountCapacitiesEdge = {
  __typename?: 'ProgramAccountCapacitiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProgramAccountCapacity` at the end of the edge. */
  node?: Maybe<ProgramAccountCapacity>;
};

/** Methods to use when ordering `ProgramAccountCapacity`. */
export enum ProgramAccountCapacitiesOrderBy {
  Natural = 'NATURAL',
  PrgAccountCntsIdAsc = 'PRG_ACCOUNT_CNTS_ID_ASC',
  PrgAccountCntsIdDesc = 'PRG_ACCOUNT_CNTS_ID_DESC',
  PrgAccountCntsTypeAsc = 'PRG_ACCOUNT_CNTS_TYPE_ASC',
  PrgAccountCntsTypeDesc = 'PRG_ACCOUNT_CNTS_TYPE_DESC',
  EnrollmentCountAsc = 'ENROLLMENT_COUNT_ASC',
  EnrollmentCountDesc = 'ENROLLMENT_COUNT_DESC',
  CancelledYtdCountAsc = 'CANCELLED_YTD_COUNT_ASC',
  CancelledYtdCountDesc = 'CANCELLED_YTD_COUNT_DESC',
  PendingCountAsc = 'PENDING_COUNT_ASC',
  PendingCountDesc = 'PENDING_COUNT_DESC',
  WaitListCountAsc = 'WAIT_LIST_COUNT_ASC',
  WaitListCountDesc = 'WAIT_LIST_COUNT_DESC'
}

/**
 * A condition to be used against `ProgramAccountCapacity` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ProgramAccountCapacityCondition = {
  /** Checks for equality with the object’s `prgAccountCntsId` field. */
  prgAccountCntsId?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `prgAccountCntsType` field. */
  prgAccountCntsType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `enrollmentCount` field. */
  enrollmentCount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `cancelledYtdCount` field. */
  cancelledYtdCount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `pendingCount` field. */
  pendingCount?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `waitListCount` field. */
  waitListCount?: Maybe<Scalars['BigInt']>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a single `AlertsCustomer`. */
  createAlertsCustomer?: Maybe<CreateAlertsCustomerPayload>;
  /** Creates a single `AlertsEnrollment`. */
  createAlertsEnrollment?: Maybe<CreateAlertsEnrollmentPayload>;
  /** Creates a single `AlertsFacility`. */
  createAlertsFacility?: Maybe<CreateAlertsFacilityPayload>;
  /** Creates a single `CancelReason`. */
  createCancelReason?: Maybe<CreateCancelReasonPayload>;
  /** Creates a single `Facility`. */
  createFacility?: Maybe<CreateFacilityPayload>;
  /** Creates a single `ProgramAccountCapacity`. */
  createProgramAccountCapacity?: Maybe<CreateProgramAccountCapacityPayload>;
  /** Updates a single `AlertsCustomer` using its globally unique id and a patch. */
  updateAlertsCustomer?: Maybe<UpdateAlertsCustomerPayload>;
  /** Updates a single `AlertsCustomer` using a unique key and a patch. */
  updateAlertsCustomerByAlertId?: Maybe<UpdateAlertsCustomerPayload>;
  /** Updates a single `AlertsEnrollment` using its globally unique id and a patch. */
  updateAlertsEnrollment?: Maybe<UpdateAlertsEnrollmentPayload>;
  /** Updates a single `AlertsEnrollment` using a unique key and a patch. */
  updateAlertsEnrollmentByRowId?: Maybe<UpdateAlertsEnrollmentPayload>;
  /** Updates a single `CancelReason` using its globally unique id and a patch. */
  updateCancelReason?: Maybe<UpdateCancelReasonPayload>;
  /** Updates a single `CancelReason` using a unique key and a patch. */
  updateCancelReasonByRowId?: Maybe<UpdateCancelReasonPayload>;
  /** Deletes a single `AlertsCustomer` using its globally unique id. */
  deleteAlertsCustomer?: Maybe<DeleteAlertsCustomerPayload>;
  /** Deletes a single `AlertsCustomer` using a unique key. */
  deleteAlertsCustomerByAlertId?: Maybe<DeleteAlertsCustomerPayload>;
  /** Deletes a single `AlertsEnrollment` using its globally unique id. */
  deleteAlertsEnrollment?: Maybe<DeleteAlertsEnrollmentPayload>;
  /** Deletes a single `AlertsEnrollment` using a unique key. */
  deleteAlertsEnrollmentByRowId?: Maybe<DeleteAlertsEnrollmentPayload>;
  /** Deletes a single `CancelReason` using its globally unique id. */
  deleteCancelReason?: Maybe<DeleteCancelReasonPayload>;
  /** Deletes a single `CancelReason` using a unique key. */
  deleteCancelReasonByRowId?: Maybe<DeleteCancelReasonPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAlertsCustomerArgs = {
  input: CreateAlertsCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAlertsEnrollmentArgs = {
  input: CreateAlertsEnrollmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAlertsFacilityArgs = {
  input: CreateAlertsFacilityInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCancelReasonArgs = {
  input: CreateCancelReasonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFacilityArgs = {
  input: CreateFacilityInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProgramAccountCapacityArgs = {
  input: CreateProgramAccountCapacityInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAlertsCustomerArgs = {
  input: UpdateAlertsCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAlertsCustomerByAlertIdArgs = {
  input: UpdateAlertsCustomerByAlertIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAlertsEnrollmentArgs = {
  input: UpdateAlertsEnrollmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAlertsEnrollmentByRowIdArgs = {
  input: UpdateAlertsEnrollmentByRowIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCancelReasonArgs = {
  input: UpdateCancelReasonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCancelReasonByRowIdArgs = {
  input: UpdateCancelReasonByRowIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAlertsCustomerArgs = {
  input: DeleteAlertsCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAlertsCustomerByAlertIdArgs = {
  input: DeleteAlertsCustomerByAlertIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAlertsEnrollmentArgs = {
  input: DeleteAlertsEnrollmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAlertsEnrollmentByRowIdArgs = {
  input: DeleteAlertsEnrollmentByRowIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCancelReasonArgs = {
  input: DeleteCancelReasonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCancelReasonByRowIdArgs = {
  input: DeleteCancelReasonByRowIdInput;
};

/** The output of our create `AlertsCustomer` mutation. */
export type CreateAlertsCustomerPayload = {
  __typename?: 'CreateAlertsCustomerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AlertsCustomer` that was created by this mutation. */
  alertsCustomer?: Maybe<AlertsCustomer>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `AlertsCustomer`. May be used by Relay 1. */
  alertsCustomerEdge?: Maybe<AlertsCustomersEdge>;
};


/** The output of our create `AlertsCustomer` mutation. */
export type CreateAlertsCustomerPayloadAlertsCustomerEdgeArgs = {
  orderBy?: Maybe<Array<AlertsCustomersOrderBy>>;
};

/** All input for the create `AlertsCustomer` mutation. */
export type CreateAlertsCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AlertsCustomer` to be created by this mutation. */
  alertsCustomer: AlertsCustomerInput;
};

/** An input for mutations affecting `AlertsCustomer` */
export type AlertsCustomerInput = {
  alertId: Scalars['BigFloat'];
  accountId?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  alertCustomerTypeId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  createdDate?: Maybe<Scalars['Date']>;
  fullName?: Maybe<Scalars['String']>;
};

/** The output of our create `AlertsEnrollment` mutation. */
export type CreateAlertsEnrollmentPayload = {
  __typename?: 'CreateAlertsEnrollmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AlertsEnrollment` that was created by this mutation. */
  alertsEnrollment?: Maybe<AlertsEnrollment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `AlertsEnrollment`. May be used by Relay 1. */
  alertsEnrollmentEdge?: Maybe<AlertsEnrollmentsEdge>;
};


/** The output of our create `AlertsEnrollment` mutation. */
export type CreateAlertsEnrollmentPayloadAlertsEnrollmentEdgeArgs = {
  orderBy?: Maybe<Array<AlertsEnrollmentsOrderBy>>;
};

/** All input for the create `AlertsEnrollment` mutation. */
export type CreateAlertsEnrollmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AlertsEnrollment` to be created by this mutation. */
  alertsEnrollment: AlertsEnrollmentInput;
};

/** An input for mutations affecting `AlertsEnrollment` */
export type AlertsEnrollmentInput = {
  enrollmentId?: Maybe<Scalars['BigInt']>;
  rowId: Scalars['BigInt'];
};

/** The output of our create `AlertsFacility` mutation. */
export type CreateAlertsFacilityPayload = {
  __typename?: 'CreateAlertsFacilityPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AlertsFacility` that was created by this mutation. */
  alertsFacility?: Maybe<AlertsFacility>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `AlertsFacility`. May be used by Relay 1. */
  alertsFacilityEdge?: Maybe<AlertsFacilitiesEdge>;
};


/** The output of our create `AlertsFacility` mutation. */
export type CreateAlertsFacilityPayloadAlertsFacilityEdgeArgs = {
  orderBy?: Maybe<Array<AlertsFacilitiesOrderBy>>;
};

/** All input for the create `AlertsFacility` mutation. */
export type CreateAlertsFacilityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AlertsFacility` to be created by this mutation. */
  alertsFacility: AlertsFacilityInput;
};

/** An input for mutations affecting `AlertsFacility` */
export type AlertsFacilityInput = {
  alertId?: Maybe<Scalars['BigInt']>;
  facilityName?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  issue?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

/** The output of our create `CancelReason` mutation. */
export type CreateCancelReasonPayload = {
  __typename?: 'CreateCancelReasonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CancelReason` that was created by this mutation. */
  cancelReason?: Maybe<CancelReason>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `CancelReason`. May be used by Relay 1. */
  cancelReasonEdge?: Maybe<CancelReasonsEdge>;
};


/** The output of our create `CancelReason` mutation. */
export type CreateCancelReasonPayloadCancelReasonEdgeArgs = {
  orderBy?: Maybe<Array<CancelReasonsOrderBy>>;
};

/** All input for the create `CancelReason` mutation. */
export type CreateCancelReasonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CancelReason` to be created by this mutation. */
  cancelReason: CancelReasonInput;
};

/** An input for mutations affecting `CancelReason` */
export type CancelReasonInput = {
  rowId: Scalars['BigInt'];
  cancelReasonId?: Maybe<Scalars['BigInt']>;
  cancelReasonDescription?: Maybe<Scalars['String']>;
};

/** The output of our create `Facility` mutation. */
export type CreateFacilityPayload = {
  __typename?: 'CreateFacilityPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Facility` that was created by this mutation. */
  facility?: Maybe<Facility>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Facility`. May be used by Relay 1. */
  facilityEdge?: Maybe<FacilitiesEdge>;
};


/** The output of our create `Facility` mutation. */
export type CreateFacilityPayloadFacilityEdgeArgs = {
  orderBy?: Maybe<Array<FacilitiesOrderBy>>;
};

/** All input for the create `Facility` mutation. */
export type CreateFacilityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Facility` to be created by this mutation. */
  facility: FacilityInput;
};

/** An input for mutations affecting `Facility` */
export type FacilityInput = {
  facilityId?: Maybe<Scalars['BigInt']>;
  jurisdictionId?: Maybe<Scalars['BigInt']>;
  facilityName?: Maybe<Scalars['String']>;
};

/** The output of our create `ProgramAccountCapacity` mutation. */
export type CreateProgramAccountCapacityPayload = {
  __typename?: 'CreateProgramAccountCapacityPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProgramAccountCapacity` that was created by this mutation. */
  programAccountCapacity?: Maybe<ProgramAccountCapacity>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `ProgramAccountCapacity`. May be used by Relay 1. */
  programAccountCapacityEdge?: Maybe<ProgramAccountCapacitiesEdge>;
};


/** The output of our create `ProgramAccountCapacity` mutation. */
export type CreateProgramAccountCapacityPayloadProgramAccountCapacityEdgeArgs = {
  orderBy?: Maybe<Array<ProgramAccountCapacitiesOrderBy>>;
};

/** All input for the create `ProgramAccountCapacity` mutation. */
export type CreateProgramAccountCapacityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ProgramAccountCapacity` to be created by this mutation. */
  programAccountCapacity: ProgramAccountCapacityInput;
};

/** An input for mutations affecting `ProgramAccountCapacity` */
export type ProgramAccountCapacityInput = {
  prgAccountCntsId?: Maybe<Scalars['BigInt']>;
  prgAccountCntsType?: Maybe<Scalars['String']>;
  enrollmentCount?: Maybe<Scalars['BigInt']>;
  cancelledYtdCount?: Maybe<Scalars['BigInt']>;
  pendingCount?: Maybe<Scalars['BigInt']>;
  waitListCount?: Maybe<Scalars['BigInt']>;
};

/** The output of our update `AlertsCustomer` mutation. */
export type UpdateAlertsCustomerPayload = {
  __typename?: 'UpdateAlertsCustomerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AlertsCustomer` that was updated by this mutation. */
  alertsCustomer?: Maybe<AlertsCustomer>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `AlertsCustomer`. May be used by Relay 1. */
  alertsCustomerEdge?: Maybe<AlertsCustomersEdge>;
};


/** The output of our update `AlertsCustomer` mutation. */
export type UpdateAlertsCustomerPayloadAlertsCustomerEdgeArgs = {
  orderBy?: Maybe<Array<AlertsCustomersOrderBy>>;
};

/** All input for the `updateAlertsCustomer` mutation. */
export type UpdateAlertsCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AlertsCustomer` to be updated. */
  id: Scalars['ID'];
  /** An object where the defined keys will be set on the `AlertsCustomer` being updated. */
  alertsCustomerPatch: AlertsCustomerPatch;
};

/** Represents an update to a `AlertsCustomer`. Fields that are set will be updated. */
export type AlertsCustomerPatch = {
  alertId?: Maybe<Scalars['BigFloat']>;
  accountId?: Maybe<Scalars['String']>;
  issue?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  alertCustomerTypeId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  createdDate?: Maybe<Scalars['Date']>;
  fullName?: Maybe<Scalars['String']>;
};

/** All input for the `updateAlertsCustomerByAlertId` mutation. */
export type UpdateAlertsCustomerByAlertIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AlertsCustomer` being updated. */
  alertsCustomerPatch: AlertsCustomerPatch;
  alertId: Scalars['BigFloat'];
};

/** The output of our update `AlertsEnrollment` mutation. */
export type UpdateAlertsEnrollmentPayload = {
  __typename?: 'UpdateAlertsEnrollmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AlertsEnrollment` that was updated by this mutation. */
  alertsEnrollment?: Maybe<AlertsEnrollment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `AlertsEnrollment`. May be used by Relay 1. */
  alertsEnrollmentEdge?: Maybe<AlertsEnrollmentsEdge>;
};


/** The output of our update `AlertsEnrollment` mutation. */
export type UpdateAlertsEnrollmentPayloadAlertsEnrollmentEdgeArgs = {
  orderBy?: Maybe<Array<AlertsEnrollmentsOrderBy>>;
};

/** All input for the `updateAlertsEnrollment` mutation. */
export type UpdateAlertsEnrollmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AlertsEnrollment` to be updated. */
  id: Scalars['ID'];
  /** An object where the defined keys will be set on the `AlertsEnrollment` being updated. */
  alertsEnrollmentPatch: AlertsEnrollmentPatch;
};

/** Represents an update to a `AlertsEnrollment`. Fields that are set will be updated. */
export type AlertsEnrollmentPatch = {
  enrollmentId?: Maybe<Scalars['BigInt']>;
  rowId?: Maybe<Scalars['BigInt']>;
};

/** All input for the `updateAlertsEnrollmentByRowId` mutation. */
export type UpdateAlertsEnrollmentByRowIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `AlertsEnrollment` being updated. */
  alertsEnrollmentPatch: AlertsEnrollmentPatch;
  rowId: Scalars['BigInt'];
};

/** The output of our update `CancelReason` mutation. */
export type UpdateCancelReasonPayload = {
  __typename?: 'UpdateCancelReasonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CancelReason` that was updated by this mutation. */
  cancelReason?: Maybe<CancelReason>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `CancelReason`. May be used by Relay 1. */
  cancelReasonEdge?: Maybe<CancelReasonsEdge>;
};


/** The output of our update `CancelReason` mutation. */
export type UpdateCancelReasonPayloadCancelReasonEdgeArgs = {
  orderBy?: Maybe<Array<CancelReasonsOrderBy>>;
};

/** All input for the `updateCancelReason` mutation. */
export type UpdateCancelReasonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CancelReason` to be updated. */
  id: Scalars['ID'];
  /** An object where the defined keys will be set on the `CancelReason` being updated. */
  cancelReasonPatch: CancelReasonPatch;
};

/** Represents an update to a `CancelReason`. Fields that are set will be updated. */
export type CancelReasonPatch = {
  rowId?: Maybe<Scalars['BigInt']>;
  cancelReasonId?: Maybe<Scalars['BigInt']>;
  cancelReasonDescription?: Maybe<Scalars['String']>;
};

/** All input for the `updateCancelReasonByRowId` mutation. */
export type UpdateCancelReasonByRowIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CancelReason` being updated. */
  cancelReasonPatch: CancelReasonPatch;
  rowId: Scalars['BigInt'];
};

/** The output of our delete `AlertsCustomer` mutation. */
export type DeleteAlertsCustomerPayload = {
  __typename?: 'DeleteAlertsCustomerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AlertsCustomer` that was deleted by this mutation. */
  alertsCustomer?: Maybe<AlertsCustomer>;
  deletedAlertsCustomerId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `AlertsCustomer`. May be used by Relay 1. */
  alertsCustomerEdge?: Maybe<AlertsCustomersEdge>;
};


/** The output of our delete `AlertsCustomer` mutation. */
export type DeleteAlertsCustomerPayloadAlertsCustomerEdgeArgs = {
  orderBy?: Maybe<Array<AlertsCustomersOrderBy>>;
};

/** All input for the `deleteAlertsCustomer` mutation. */
export type DeleteAlertsCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AlertsCustomer` to be deleted. */
  id: Scalars['ID'];
};

/** All input for the `deleteAlertsCustomerByAlertId` mutation. */
export type DeleteAlertsCustomerByAlertIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  alertId: Scalars['BigFloat'];
};

/** The output of our delete `AlertsEnrollment` mutation. */
export type DeleteAlertsEnrollmentPayload = {
  __typename?: 'DeleteAlertsEnrollmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `AlertsEnrollment` that was deleted by this mutation. */
  alertsEnrollment?: Maybe<AlertsEnrollment>;
  deletedAlertsEnrollmentId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `AlertsEnrollment`. May be used by Relay 1. */
  alertsEnrollmentEdge?: Maybe<AlertsEnrollmentsEdge>;
};


/** The output of our delete `AlertsEnrollment` mutation. */
export type DeleteAlertsEnrollmentPayloadAlertsEnrollmentEdgeArgs = {
  orderBy?: Maybe<Array<AlertsEnrollmentsOrderBy>>;
};

/** All input for the `deleteAlertsEnrollment` mutation. */
export type DeleteAlertsEnrollmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `AlertsEnrollment` to be deleted. */
  id: Scalars['ID'];
};

/** All input for the `deleteAlertsEnrollmentByRowId` mutation. */
export type DeleteAlertsEnrollmentByRowIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  rowId: Scalars['BigInt'];
};

/** The output of our delete `CancelReason` mutation. */
export type DeleteCancelReasonPayload = {
  __typename?: 'DeleteCancelReasonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CancelReason` that was deleted by this mutation. */
  cancelReason?: Maybe<CancelReason>;
  deletedCancelReasonId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `CancelReason`. May be used by Relay 1. */
  cancelReasonEdge?: Maybe<CancelReasonsEdge>;
};


/** The output of our delete `CancelReason` mutation. */
export type DeleteCancelReasonPayloadCancelReasonEdgeArgs = {
  orderBy?: Maybe<Array<CancelReasonsOrderBy>>;
};

/** All input for the `deleteCancelReason` mutation. */
export type DeleteCancelReasonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CancelReason` to be deleted. */
  id: Scalars['ID'];
};

/** All input for the `deleteCancelReasonByRowId` mutation. */
export type DeleteCancelReasonByRowIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  rowId: Scalars['BigInt'];
};

export type CreateAlertsCustomerMutationVariables = Exact<{
  alertId: Scalars['BigFloat'];
  accountId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  alertCustomerTypeId?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  issue?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
}>;


export type CreateAlertsCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createAlertsCustomer?: Maybe<(
    { __typename?: 'CreateAlertsCustomerPayload' }
    & { alertsCustomer?: Maybe<(
      { __typename?: 'AlertsCustomer' }
      & Pick<AlertsCustomer, 'accountId' | 'active' | 'alertCustomerTypeId' | 'alertId' | 'createdDate' | 'issue' | 'notes'>
    )> }
  )> }
);

export type AllAlertsCustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllAlertsCustomersQuery = (
  { __typename?: 'Query' }
  & { allAlertsCustomers?: Maybe<(
    { __typename?: 'AlertsCustomersConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'AlertsCustomer' }
      & Pick<AlertsCustomer, 'alertId' | 'createdDate' | 'fullName' | 'issue' | 'notes'>
    )>> }
  )> }
);

export type AllFacilitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllFacilitiesQuery = (
  { __typename?: 'Query' }
  & { allFacilities?: Maybe<(
    { __typename?: 'FacilitiesConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'Facility' }
      & Pick<Facility, 'facilityId' | 'facilityName' | 'jurisdictionId'>
    )>> }
  )> }
);

export type AllAlertsFacilitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllAlertsFacilitiesQuery = (
  { __typename?: 'Query' }
  & { allAlertsFacilities?: Maybe<(
    { __typename?: 'AlertsFacilitiesConnection' }
    & { nodes: Array<Maybe<(
      { __typename?: 'AlertsFacility' }
      & Pick<AlertsFacility, 'notes' | 'issue' | 'facilityName' | 'createdDate' | 'alertId'>
    )>> }
  )> }
);

export type CancelReasonByRowIdQueryVariables = Exact<{ [key: string]: never; }>;


export type CancelReasonByRowIdQuery = (
  { __typename?: 'Query' }
  & { cancelReasonByRowId?: Maybe<(
    { __typename?: 'CancelReason' }
    & Pick<CancelReason, 'cancelReasonDescription' | 'cancelReasonId' | 'id' | 'rowId'>
  )> }
);

export const CreateAlertsCustomerDocument = gql`
    mutation CreateAlertsCustomer($alertId: BigFloat!, $accountId: String, $active: Boolean, $alertCustomerTypeId: String, $createdDate: Date, $issue: String, $notes: String) {
  createAlertsCustomer(
    input: {alertsCustomer: {alertId: $alertId, accountId: $accountId, active: $active, alertCustomerTypeId: $alertCustomerTypeId, createdDate: $createdDate, issue: $issue, notes: $notes}}
  ) {
    alertsCustomer {
      accountId
      active
      alertCustomerTypeId
      alertId
      createdDate
      issue
      notes
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAlertsCustomerGQL extends Apollo.Mutation<CreateAlertsCustomerMutation, CreateAlertsCustomerMutationVariables> {
    document = CreateAlertsCustomerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllAlertsCustomersDocument = gql`
    query allAlertsCustomers {
  allAlertsCustomers {
    nodes {
      alertId
      createdDate
      fullName
      issue
      notes
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllAlertsCustomersGQL extends Apollo.Query<AllAlertsCustomersQuery, AllAlertsCustomersQueryVariables> {
    document = AllAlertsCustomersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllFacilitiesDocument = gql`
    query AllFacilities {
  allFacilities {
    nodes {
      facilityId
      facilityName
      jurisdictionId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllFacilitiesGQL extends Apollo.Query<AllFacilitiesQuery, AllFacilitiesQueryVariables> {
    document = AllFacilitiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AllAlertsFacilitiesDocument = gql`
    query AllAlertsFacilities {
  allAlertsFacilities {
    nodes {
      notes
      issue
      facilityName
      createdDate
      alertId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AllAlertsFacilitiesGQL extends Apollo.Query<AllAlertsFacilitiesQuery, AllAlertsFacilitiesQueryVariables> {
    document = AllAlertsFacilitiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CancelReasonByRowIdDocument = gql`
    query CancelReasonByRowId {
  cancelReasonByRowId(rowId: Int) {
    cancelReasonDescription
    cancelReasonId
    id
    rowId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelReasonByRowIdGQL extends Apollo.Query<CancelReasonByRowIdQuery, CancelReasonByRowIdQueryVariables> {
    document = CancelReasonByRowIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }