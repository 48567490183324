<div class="container dashboard-card">
    <br>
    <div class="row title">
        &nbsp;&nbsp;
        <i class="fa fa-2x fa-hand-holding-usd"></i>
        <div class="col-3"></div>
        Financials
    </div>
    <br>
    <div class="row label-item descriptionText">
        <div class="col-2"></div>
        $<span class="bigNumbers"> {{financialModel?.fees_collected | number}}</span> &nbsp;&nbsp;&nbsp;
        <span class="descriptionText">Fees Collected</span>
    </div>
    <br>
    <div class="row label-item descriptionText">
        <div class="col-2"></div>
        $<span class="bigNumbers">{{financialModel?.credits_earned | number}}</span> &nbsp;&nbsp;&nbsp;
        <span class="descriptionText">Credits Provided</span>
    </div>
    <br>
    <!-- <div class="row label-item descriptionText">
        <div class="col-2"></div>
        $<span class="bigNumbers">{{savings}}</span> &nbsp;&nbsp;&nbsp;
        <span class="descriptionText">Savings*</span>
    </div> -->
    <br>
<!-- <div class="row descriptionText">
    <div class="col-6"></div>
    *YTD - All Jurisdictions
</div> -->
</div>