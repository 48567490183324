  <div class="container">
    <div class="row">
          <section >
            <mat-form-field appearance="fill" >
              <mat-label *ngIf="canShowAll">All Customer Segments</mat-label>
              <mat-label *ngIf="!canShowAll"></mat-label>
                <mat-select [(ngModel)]="segmentId" name="segments" >
                  <mat-option [key]="segmentId" [value]="segmentName" *ngFor="let segment of customerSegmentTypes;"
                      [value]="segment.customer_segment_id" (click)="selectSegment(segment.customer_segment_id)">
                      {{ segment.customer_segment_description }}
                  </mat-option>
                </mat-select>
                <mat-hint align="end"></mat-hint>
              </mat-form-field>
            </section>
    </div>
  </div>