import { Component, OnInit, Input } from '@angular/core';
import { DataService, ApiService } from 'src/app/services';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-total-capacity',
  templateUrl: './total-capacity-box.component.html',
  styleUrls: ['./total-capacity-box.component.scss']
})

export class TotalCapacityBoxComponent implements OnInit {
    @Input() lmi_allocation_amount: number;
    @Input() market_rate_allocation_amount: number;

  constructor(public datasvc: DataService,
    public apiService: ApiService) { }

  ngOnInit() {

  }

}








