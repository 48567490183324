<de-loader *ngIf="gettingCustomerData" [showOverlay]="showOverlay"></de-loader>
<div class="container">
    <br><br>
    <div class="row">
        <div class="col">
            <a class="description" href="javascript:;" (click)='onGoBack()'>Back To {{backToText}}</a>
        </div>
    </div>
    <br>
    <div *ngIf="accountDetails">
        <app-account-panel [accountDetail]="accountDetails" [closePanel]="closePanelsOtherThanEmitter"
           [prevPage]="previousPage" (panelOpened)="closePanelsOtherThan($event)"></app-account-panel>
        <br><br>
    </div>
</div>