import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AccountLookupForEmployee } from 'src/app/API.service';

class InputData {
  account_details: AccountLookupForEmployee;
}

@Component({
  selector: 'app-ineligibility-reasons-dialog',
  templateUrl: './ineligibility-reasons-dialog.component.html',
  styleUrls: ['./ineligibility-reasons-dialog.component.scss']
})
export class IneligibilityReasonsDialogComponent implements OnInit {

  constructor(
    private ineligibilityDialogRef: MatDialogRef<IneligibilityReasonsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputData) {
    this.ineligibilityDialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  // Close modal window...
  closeModal() {
    this.ineligibilityDialogRef.close();
  }
}
