import { Component } from '@angular/core';
import { DataService } from 'src/app/services';
import { APIServiceCodegen } from 'src/app/API.service';
import { CustomerCapacityModel } from 'src/app/components/models/admin.model';
import { SegmentService } from 'src/app/services/segment.service';
import { Subscription, of } from 'rxjs';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';

@Component({
    selector: 'app-capacity-box',
    templateUrl: './capacity-box.component.html',
    styleUrls: ['./capacity-box.component.scss']
})

export class CapacityBoxComponent {
    public subscribedMeasure: string = "";
    public avaliableMeasure: string = "";
    public waitlistMeasure: string = "";
    public subscribed_mw: number;
    public available_mw: number;
    public wait_list_mw: number;
    public customerCapacity: CustomerCapacityModel = new CustomerCapacityModel();
    private subscription: Subscription;

    constructor(
        public datasvc: DataService, 
        public segService: SegmentService,
        public api: APIServiceCodegen) { 
            this.subscription = this.segService.currentSegment$.subscribe( res => {
                this.updateByParticipationSegment(res);
            })
        }

    updateByParticipationSegment(segmentId: string){
        this.api.GetProgramAccountCapacity(segmentId).then( response => {
            this.customerCapacity = (<CustomerCapacityModel>response);
            console.log(this.customerCapacity)
        })
        .catch( err => {
            console.log(ErrorDialogComponent.API_GENERIC_ERROR_500_MSG)
            console.log(err)
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
     }
}