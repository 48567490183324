import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { AccountDetailModel } from '../models/customer-detail.model';
import { Router } from '@angular/router';
import { SubscriptionDetail } from '../models/subscription.model';
import { ApiService, DataService } from 'src/app/services';
import { IneligibilityReasonsDialogComponent } from '../ineligibility-reasons-dialog/ineligibility-reasons-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material';
import { AlertObject, CustomerAlertModel, CustomerAlertResponse, EnrollmentAlertModel, EnrollmentAlertResponse,
   FacilityAlertModel, FacilityAlertResponse } from '../models/admin.model';
import { forkJoin } from 'rxjs';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { Subscription } from 'rxjs';
import { APIServiceCodegen } from 'src/app/API.service';
import { SegmentService } from 'src/app/services/segment.service';

@Component({
    selector: 'app-alerts-box',
    templateUrl: './alerts-box.component.html',
    styleUrls: ['./alerts-box.component.scss']
})
export class AlertsBoxComponent  {
    public maxAlertsPerSubject = 4;
    public customerAlerts: any[];
    public facilityAlerts: any[];
    public enrollmentAlerts: any[];
    // @Input() jurisdiction_id: number;
    // @Input() program_id: number;
    @Output() dataRetrieved = new EventEmitter();
    public alertDialogRef: MatDialogRef<AlertDialogComponent>;
    private subscription: Subscription;
    private segmentId: number;

    constructor(
        private router: Router,
        public dialog: MatDialog,
        public segService: SegmentService,
        private api: APIServiceCodegen,
        ) {
          this.subscription = this.segService.currentSegment$.subscribe( res => {
            this.segmentId = res;
            this.updateByParticipationSegment();
         })
    }
   
    updateByParticipationSegment(){
      let segId = this.segmentId;
      this.api.ListCustomerAlert(null, 3, null).then( response => {
        console.log(response)
        this.customerAlerts = response.items;
      }).catch( err => {
          console.log(ErrorDialogComponent.API_GENERIC_ERROR_500_MSG)
          console.log(err)
      });

      this.api.ListFacilityAlert(null, 2, null).then(event => {
            console.log(event.items);
            this.facilityAlerts = event.items;
      }).catch( err => {
          console.log(ErrorDialogComponent.API_GENERIC_ERROR_500_MSG)
          console.log(err)
      });

      this.api.ListEnrollmentAlert(null, 2, null).then( response => {
            console.log(response)
            this.enrollmentAlerts = response.items;
      }).catch( err => {
                  console.log(ErrorDialogComponent.API_GENERIC_ERROR_500_MSG)
                  console.log(err)
      });
    }

    onShowCustomerDialog(id) {
        const issue = this.customerAlerts.find(x => x.id === id);
        this.alertDialogRef = this.dialog.open(AlertDialogComponent, {
            id: 'alertDialog',
            minWidth: '60vw',
            data: {
                id: id,
                dialog_type: 'Customer',
                notes: issue.notes,
                full_name: issue.full_name,
                facility_name: '',
                issue: issue.issue,
                date: issue.created_date,
                account: issue?.account_id
            }
        });
        
        this.alertDialogRef.afterClosed().subscribe(x => {
          //if (typeof x === 'undefined'){
            this.updateByParticipationSegment();
          //}
        });
      }

    viewCustomerAlerts() {
        this.router.navigate(['admin/customer-alert-report']);
    }

    onShowFacilityDialog(id) {
        const issue = this.facilityAlerts.find(x => x.id === id);
        this.alertDialogRef = this.dialog.open(AlertDialogComponent, {
            id: 'alertDialog',
            minWidth: '60vw',
            data: {
                id: id,
                dialog_type: 'Facility',
                notes: issue.notes,
                customer_name: '',
                facility_name: issue.facility_name,
                issue: issue.issue,
                date: issue.created_date,
                account: null
            }
        });
        this.alertDialogRef.afterClosed().subscribe(x => {
          //if (typeof x === 'undefined'){
            this.updateByParticipationSegment();
          //}
        });
    }

    viewFacilityAlerts() {
        this.router.navigate(['admin/facility-alert-report']);
    }

    onShowEnrollmentDialog(id) {
        const issue = this.enrollmentAlerts.find(x => x.id === id);
        this.alertDialogRef = this.dialog.open(AlertDialogComponent, {
            id: 'alertDialog',
            minWidth: '60vw',
            data: {
                id: id,
                dialog_type: 'Enrollment',
                notes: issue.notes,
                full_name: issue.full_name,
                facility_name: '',
                issue: issue.status_change,
                date: issue.created_date,
                account: issue.account_id
            }
        });
        this.alertDialogRef.afterClosed().subscribe(x => {
          console.log(x)
          //if (x !== "cancel"){
            this.updateByParticipationSegment();
         // }
        });
    }

    viewEnrollmentAlerts() {
        this.router.navigate(['admin/enrollment-alert-report']);
    }

    viewAlertMainPage() {
          this.router.navigate(['admin/alert-main']);
    }
}
