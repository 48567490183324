import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SegmentService } from 'src/app/services/segment.service';
import { APIServiceCodegen, MetadataPicklist, MetadataPicklistCriteriaInput, ConfigurableCustomerSegment } from 'src/app/API.service';
import { from, of, Subscription } from 'rxjs'
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-report-drop-downs',
  templateUrl: './report-drop-downs.component.html',
  styleUrls: ['./report-drop-downs.component.scss']
})
export class ReportDropDownComponent implements OnInit {
  @Output() valuesChanged = new EventEmitter();
  @Input() canShowAll = false;
  @Input() defaultValue = null;
  public segmentId: string;
  public segmentName: string;
  private subscription: Subscription;
  public customerSegmentTypes: ConfigurableCustomerSegment[] | null;

  constructor(
    private api: APIServiceCodegen,
    public segService: SegmentService,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getCustomerSegments();
    this.subscription = this.segService.currentSegment$.subscribe(currentSegment => {
      this.segmentId = currentSegment;
    })
  }

  getCustomerSegments() {
    from(
      this.api.ListCustomerSegments()
    ).pipe(catchError(error => {
      error.errors.forEach(iError => {
        this.snackBar.open('Error: ' + iError.message, 'Ok', { panelClass: 'snackbar' });
      })
      return of(error)
    })).subscribe((resp: any) => {
      this.customerSegmentTypes = <ConfigurableCustomerSegment[]>resp;
      if (this.canShowAll) {
        this.customerSegmentTypes.unshift({
          customer_segment_description: "All Segments",
          customer_segment_id: null,
          is_in_rsp_mode: null,
          customer_segment_alert_message: null,
          __typename: "ConfigurableCustomerSegment"
        })
      }
        if (this.defaultValue) {
          this.selectSegment(this.defaultValue);
        }
    })
  }

  selectSegment(segmentId: string) {
    this.segmentId = segmentId;
    this.segService.selectedSegment(segmentId);
    this.valuesChanged.emit({
      customer_segment_id: segmentId
    });
  }
}