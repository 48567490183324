import { Component, OnInit, ViewChild, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormGroup, FormControl, Validators } from '@angular/forms';
//import { CustomerDetail, CustomerSearchCriteria } from '../models/customer-detail.model';
import { Router } from '@angular/router';
import { DataService, ApiService, FacilityManagementService } from 'src/app/services';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { FacilityResponse, ReportFacility, FacilityReportModel, Permissions } from '../models/admin.model';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
//import { JurisdictionResponse, StateJurisdictionDropDownDataModel, JurisdictionDropDownDataModel } from '../models/customer-report.model';

@Component({
  selector: 'app-facility-management-report',
  templateUrl: './facility-management-report.component.html',
  styleUrls: ['./facility-management-report.component.scss']
})
export class FacilityManagementReportComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    // public jurisdictionResponse: JurisdictionResponse = null;
    // public stateList = <StateJurisdictionDropDownDataModel[]>[];
    displayedColumns: string[] = ['facility_name', 'city', 'state', 'street', 'jurisdiction',
      'installation_number', 'salesforce_id', 'total_facility_capacity', 'month_status', 'year_status',
       'commercial_operation_date', 'edit'];
  subscriptionList: Subscription[] = [];
  public showJurisdictionDropdown = false;
  public panelExpanded: boolean[] = [];
  // public selectedJurisdictionTitle = 'All Jurisdictions';
  // public selectedJurisdictionStateId: number;
  // public selectedJurisdictionId: number;
  // public jurisdictions = [];
  public exportData = new EventEmitter();
  public facilitiesDataList: FacilityReportModel[] = [];
  dataSource = new MatTableDataSource<ReportFacility>();
  facilityForm: FormGroup = new FormGroup({
    facility_name_filter: new FormControl(''),
    city_filter: new FormControl(''),
    state_filter: new FormControl(''),
    street_filter: new FormControl(''),
    jurisdiction_filter: new FormControl(''),
    installation_number_filter: new FormControl(''),
    salesforce_id_filter: new FormControl(''),
    total_facility_capacity_filter: new FormControl(''),
    month_status_filter: new FormControl(''),
    year_status_filter: new FormControl('')
  });
  filterValues = {
    facility_name: '',
    city: '',
    state: '',
    street: '',
    jurisdiction: '',
    installation_number: '',
    salesforce_id: '',
    total_facility_capacity: '',
    month_status: '',
    year_status: ''
  };
  hasFacilityReportPerms = false

  constructor(public datasvc: DataService,
    public router: Router,
    public apisvc: ApiService,
    public facilitysvc: FacilityManagementService) { }

  ngOnInit() {
    let permList = this.datasvc.getPermissions();
    if (permList.find(num => num == Permissions.FacilitiesReport)) {
        this.hasFacilityReportPerms = true;
    } else {
      return;
    }

console.log("================================")

    setTimeout(() => {
      this.datasvc.setHeaderTitle('Facility Management');
    });
    //this.stateList = [];
    // this.apisvc.getJurisdictions().subscribe(Jurisdictionresponse => {
    //     this.jurisdictionResponse = Jurisdictionresponse;
    //     Jurisdictionresponse.jurisdictions.forEach(jur => {
    //       if (!this.stateList.find(x => x.stateCode === jur.state.state_code)) { // If state is not found in list already add it
    //         this.stateList.push( <StateJurisdictionDropDownDataModel>{
    //           stateName: jur.state.state_name,
    //           stateCode: jur.state.state_code,
    //           state_id: jur.state.state_id,
    //           jurisdictions: [{jurisdiction_name: jur.jurisdiction_name, jurisdiction_id: jur.jurisdiction_id}]
    //         });
    //       } else {
    //         this.stateList.find(x => x.stateCode === jur.state.state_code).jurisdictions
    //         .push({jurisdiction_name: jur.jurisdiction_name, jurisdiction_id: jur.jurisdiction_id});
    //         // If State is found add current jurisdiction to list
    //       }
    //     });
    //     this.stateList.forEach(x => {
    //       this.panelExpanded.push(false);
    //     });
    //   this.jurisdictionResponse.jurisdictions.forEach(jurs => {
    //       this.jurisdictions.push({name: jurs.jurisdiction_name, value: jurs.jurisdiction_id.toString()});
    //     });
    //   this.setUpFormValueChanges();
    //   });
    this.subscriptionList.push(this.apisvc.getFacilitiesReport()
    .pipe(catchError(error => of(error))).subscribe(facilityList => {
        this.facilitiesDataList = facilityList.facilities;

        console.log("--------------------------------1")
        console.log(facilityList.data.facilityReport)


        const mappedFacilities = this.datasvc.mapFacilityManagementTableData(facilityList.data.facilityReport);
        this.dataSource = new MatTableDataSource<ReportFacility>(mappedFacilities);
      }));
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = this.facilitysvc.createFilter();
   }

  ngOnDestroy() {
    this.subscriptionList.forEach(sub => {
      sub.unsubscribe();
    });
  }


  // isSelected(state: StateJurisdictionDropDownDataModel, jurisdiction: JurisdictionDropDownDataModel) {
  //   this.selectedJurisdictionTitle = '';
  //   if (!state && !jurisdiction) {
  //     this.selectedJurisdictionTitle = 'All Jurisdictions';
  //     this.showJurisdictionDropdown = false;
  //     this.toggleState(-1);
  //     return;
  //   }

  //   if (jurisdiction) {
  //     this.selectedJurisdictionTitle = jurisdiction.jurisdiction_name;
  //     this.selectedJurisdictionId = jurisdiction.jurisdiction_id;
  //     this.selectedJurisdictionStateId = state.state_id;
  //   } else {
  //     if (state) {
  //       this.selectedJurisdictionTitle = state.stateCode;
  //       this.selectedJurisdictionStateId = state.state_id;
  //       this.selectedJurisdictionId = undefined;
  //     }
  //     this.selectedJurisdictionTitle += ' (All Jurisdictions)';
  //   }
  //   this.showJurisdictionDropdown = false;
  //   this.toggleState(-1);
  // }

  toggleState(expansionPanel: any) {
    this.panelExpanded.forEach((panel, index) => {
      if (expansionPanel === index) {
        panel = !panel;
      } else {
        this.panelExpanded[index] = false;
      }
    });
    this.panelExpanded[expansionPanel] = !this.panelExpanded[expansionPanel];
  }

  setUpFormValueChanges() {
    this.displayedColumns.forEach(column => {
      if (column === 'edit' || column === 'commercial_operation_date') { return; }
      this.subscriptionList.push( this.facilityForm.get(column + '_filter').valueChanges.subscribe(value => {
        this.filterValues[column] = value;
        this.dataSource.filter = JSON.stringify(this.filterValues);
    } ));
  });
}

goToDetailView(facility_id: any) {
 this.router.navigate(['admin/facility-setup/', facility_id]);
 this.datasvc.setSelectedFacility(this.facilitiesDataList.find(facility => facility.facility_id === facility_id));
}

onView(facility_id: any) {
  this.router.navigate(['admin/view-facility/', facility_id]);
}

onNewFacility() {
  this.router.navigate(['admin/facility-setup']);
}

exportExcel() {
  const exportFacilityList = this.dataSource.data.map(rec => {
    return {
      'Facility Name': rec.facility_name,
      'City': rec.city,
      'State': rec.state_code,
      'Street': rec.street,
      'Jurisdiction': rec.jurisdiction,
      'Instillation #': rec.installation_number,
      'Salesforce Id': rec.salesforce_id,
      'Total Facility Capacity': rec.total_capacity_kw,
      '1 Month Status': rec.month_status,
      '12 Month Status': rec.year_status,
      ' Commercial Operation Date': rec.commercial_operation_date
    };
  });
  this.datasvc.exportExcelSpreadsheet(exportFacilityList,
    'Facility Export ' + this.datasvc.getDate(),
    'FacilityExport' + this.datasvc.getDate() + '.xlsx');
}

}
