<div class="container dashboard-card">
    <br>
    <div class="row">
        <div class="col text-center"> 
            <i class="fa fa-2x fa-users"></i>
            <br>
            <span class="card-title">Customers</span>
        </div>
    </div>  
    <br>
    <div class="row">
        <div class="col text-center">
            <span class="bigNumbers">{{ customerAccounts?.enrollment_count | number }}</span>
            <br>
            <div class="descriptionText">Enrolled</div>
        </div>
        <div class="col text-center">
            <span class="bigNumbers">{{ customerAccounts?.pending_count | number }}</span>
            <br>
            <div>Pending</div>
        </div>
        <!-- <div class="col text-center">
            <span class="bigNumbers">{{programAccountCountsModel.cancelled_ytd_count| number}}</span>
            <br>
            <div class="text-center">Cancelled*</div>
        </div> -->
        <div class="col text-center">
            <span class="bigNumbers">{{ customerAccounts?.wait_list_count | number }}</span>
            <br>
            <div>Wait&nbsp;List</div>
        </div>
    </div>
</div>