import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, DataService } from 'src/app/services';
import { MatDialogRef, MatDialog } from '@angular/material';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'app-facility-alerts',
    templateUrl: './facility-alerts.component.html',
    styleUrls: ['./facility-alerts.component.scss']
})
export class FacilityAlertsComponent implements OnInit {
    public maxAlertsPerSubject = 10;
    public alerts = [];
    @Input() facility_id: number;
    public alertDialogRef: MatDialogRef<AlertDialogComponent>;
    constructor(public apisvc: ApiService,
        private router: Router,
        public dialog: MatDialog) {
         }

    ngOnInit() {
        this.getData();
    }

    getData() {
        this.apisvc.getFacilityAlertList(null, null, 10, this.facility_id).pipe(catchError(error => of(error))).subscribe(x => {
            this.alerts = x.alerts;
        });
    }

    onShowDialog(issue_id) {
        const issue = this.alerts.find(x => x.alert_id === issue_id);
        this.alertDialogRef = this.dialog.open(AlertDialogComponent, {
            id: 'alertDialog',
            minWidth: '60vw',
            data: {
                issue_id: issue_id,
                dialog_type: 'Facility',
                notes: issue.notes,
                customer_name: '',
                facility_name: issue.facility_name,
                issue: issue.issue,
                date: issue.created_date,
                account: null
            }
        });
        this.alertDialogRef.afterClosed().subscribe(x => {
          this.getData();
        });
      }


}
