import { Component, OnInit, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { Allocation } from '../models/customer-report.model';
import { DataService, ApiService } from 'src/app/services';
import { Router } from '@angular/router';
import { Permissions } from '../models/admin.model';

@Component({
  selector: 'app-allocation-report',
  templateUrl: './allocation-report.component.html',
  styleUrls: ['./allocation-report.component.scss']
})
export class AllocationReportComponent implements OnInit {
  public selectedCustomerSegmentId: string = null;
  public dataSource = new MatTableDataSource<any>(null);
  public exportData = new EventEmitter();
  public facilityDataLoaded: EventEmitter<any> = new EventEmitter();
  hasAllocationReportPerms = false;

  constructor(public datasvc: DataService,
    private router: Router,
    public apisvc: ApiService) { }

  ngOnInit() {
    let permList = this.datasvc.getPermissions();
    if (permList.find(num => num == Permissions.AllocationReport)) {
        this.hasAllocationReportPerms = true;
    }
    setTimeout(() => {
      this.datasvc.setHeaderTitle('Allocation Report');
    });
  }

  dataRetrieved($event) {
    this.facilityDataLoaded.emit($event);
  }

  filtersSelected(event) {
    this.selectedCustomerSegmentId = event.customer_segment_id;
  }


  exportExcel() {
    this.exportData.emit('');
  }

  onNewCustomer() {
    this.router.navigate(['admin/customer-lookup']);
  }

}
