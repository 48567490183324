import { Injectable } from '@angular/core';
import { ProjectedCapacityFactor } from '../components/models/admin.model';

@Injectable({
    providedIn: 'root'
})

export class FacilityManagementService {

organizeMonthlyCapacityData(capacityFactor: ProjectedCapacityFactor) {
  capacityFactor.jan_percent = +capacityFactor.jan_percent;
  capacityFactor.feb_percent = +capacityFactor.feb_percent;
  capacityFactor.mar_percent = +capacityFactor.mar_percent;
  capacityFactor.apr_percent = +capacityFactor.apr_percent;
  capacityFactor.may_percent = +capacityFactor.may_percent;
  capacityFactor.jun_percent = +capacityFactor.jun_percent;
  capacityFactor.jul_percent = +capacityFactor.jul_percent;
  capacityFactor.aug_percent = +capacityFactor.aug_percent;
  capacityFactor.sep_percent = +capacityFactor.sep_percent;
  capacityFactor.oct_percent = +capacityFactor.oct_percent;
  capacityFactor.nov_percent = +capacityFactor.nov_percent;
  capacityFactor.dec_percent = +capacityFactor.dec_percent;
  return capacityFactor;
}

createFilter(): (data: any, filter: string) => boolean {
    const filterFunction = function(data, filter): boolean {
      const searchTerms = JSON.parse(filter);
      return data.facility_name.toLowerCase().indexOf(searchTerms.facility_name.toLowerCase()) !== -1
       && data.city.toLowerCase().indexOf(searchTerms.city.toLowerCase()) !== -1
       && data.state.toLowerCase().indexOf(searchTerms.state.toLowerCase()) !== -1
       && data.street.toLowerCase().indexOf(searchTerms.street.toLowerCase()) !== -1
       && data.salesforce_id.toLowerCase().indexOf(searchTerms.salesforce_id.toLowerCase()) !== -1
       && data.total_facility_capacity.toLowerCase().indexOf(searchTerms.total_facility_capacity.toLowerCase()) !== -1
       && data.month_status.toLowerCase().indexOf(searchTerms.month_status.toLowerCase()) !== -1
       && data.year_status.toLowerCase().indexOf(searchTerms.year_status.toLowerCase()) !== -1
       && (!searchTerms.jurisdiction || data.jurisdiction.toLowerCase().indexOf(searchTerms.jurisdiction.toLowerCase()) !== -1);
    };

    return filterFunction;
  }

}
