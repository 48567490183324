import { Injectable } from '@angular/core';
import {BehaviorSubject } from "rxjs/BehaviorSubject"

@Injectable({
    providedIn: 'root'
})
export class SegmentService {

    segmentId: string = "0";

    currentSegment$ = new BehaviorSubject<any>(this.segmentId);

    selectedSegment(segmentId: string) {
        this.currentSegment$.next(segmentId)
    }

}