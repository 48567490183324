
<form [formGroup]="allocationForm" novalidate>
  <de-loader *ngIf="gettingAlloactionData || gettingDetailData" [showOverlay]="showOverlay"></de-loader>
  <!--Search Criteria-->
  <div class="row">
    <div class="col">
      <mat-form-field class="filter" appearance="outline">
        <mat-label>Account Number</mat-label><input matInput class="long" formControlName="accountNumberFilter">
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field class="filter" appearance="outline">
        <mat-label>Customer Name</mat-label><input matInput class="long" formControlName="accountNumberFilter">
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field class="filter" appearance="outline">
        <mat-label>Street</mat-label><input matInput class="long" formControlName="accountNumberFilter">
      </mat-form-field>
    </div>
    <div class="col">
      <mat-form-field class="filter" appearance="outline">
        <mat-label>Phone</mat-label><input matInput class="long" formControlName="accountNumberFilter">
      </mat-form-field>
    </div>
  </div>
  <div class="mat-table-format" class="table-overflow">
    <table *ngIf="!gettingAlloactionData" mat-table [dataSource]="dataSource" class="table-whitespace">
      <ng-container matColumnDef="accountNumber">
        <th mat-header-cell *matHeaderCellDef>
          Account
          <!-- <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search Accounts</mat-label>
            <input matInput class="long" formControlName="accountNumberFilter">
          </mat-form-field> -->
        </th>
        <td mat-cell *matCellDef="let element"> 
            <!-- <i class="fas fa-copy d-inline-block cursor-pointer" data-toggle="tooltip" title="click to copy Acct Number" (click)="copyText(element.account_id)"></i>&nbsp; -->
            <div *ngIf="element.account_id"><a href="javascript:;" (click)='goToAdminDetailView(element.account_id, "AllocationReport");'>{{element.account_id}}</a></div><BR>
            <div *ngIf="element.main_customer.full_name">{{element.main_customer.full_name}}</div>
            <div *ngIf="element.premise_address.line1">{{element.premise_address.line1}} </div>
            <div *ngIf="element.premise_address.city">{{element.premise_address.city}} {{element.premise_address.zip}} </div>
            <div *ngIf="element.main_customer.phone">Phone: {{element.main_customer.phone}}</div>
            <div *ngIf="element.main_customer.email">Email: {{element.main_customer.email}}</div> 
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef>
          Customer Name
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search Names</mat-label>
            <input matInput class="long" formControlName="customerNameFilter">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.main_customer.full_name}} </td>
      </ng-container> -->
      <ng-container matColumnDef="customerSegment">
        <th mat-header-cell *matHeaderCellDef>
          Customer Segment
        </th>
        <td mat-cell *matCellDef="let element">{{element.customer_segment_description}}</td>
      </ng-container>
      <ng-container matColumnDef="resNonRes">
        <th mat-header-cell *matHeaderCellDef>
          Res/Non-Res
          <!-- <mat-form-field>
            <mat-select formControlName="resNonResFilter">
              <mat-option *ngFor="let opt of resNonResOpts" [value]="opt.value">
                {{opt.description}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </th>
        <td mat-cell *matCellDef="let element"> {{element.res_non_res == 'R' ? 'Res' : 'Non-Res'}} </td>
      </ng-container>
      <ng-container matColumnDef="lowIncome">
        <th mat-header-cell *matHeaderCellDef>
          Low Income
          <!-- <mat-form-field>
            <mat-select formControlName="lowIncomeFilter">
              <mat-option *ngFor="let opt of lowIncomeOpts" [value]="opt.value">
                {{opt.description}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </th>
        <td mat-cell *matCellDef="let element"> {{element.low_income_flag? 'Low' : 'Market'}} </td>
      </ng-container>
      <ng-container matColumnDef="installationId">
        <th mat-header-cell *matHeaderCellDef>
          Installation
        </th>
        <td mat-cell *matCellDef="let element">{{element.installation_id}}</td>
      </ng-container>
      <ng-container matColumnDef="billingPeriod">
        <th mat-header-cell *matHeaderCellDef>
          Contract Id
        </th>
        <td mat-cell *matCellDef="let element">{{element.billing_period}}</td>
      </ng-container>
      <!-- <ng-container matColumnDef="accountNumber">
        <th mat-header-cell *matHeaderCellDef class="accountNumberColumn">
          Acct Number
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search Acct</mat-label>
            <input matInput class="long" formControlName="accountNumberFilter">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element">
          <i class="fas fa-copy d-inline-block cursor-pointer" data-toggle="tooltip" title="click to copy Acct Number" (click)="copyText(element.account_id)"></i>&nbsp;
          <a href="javascript:;" (click)='goToAdminDetailView(element.account_id);'>{{element.account_id}}</a>
        </td>
      </ng-container> -->
      <ng-container matColumnDef="businessPartnerId">
        <th mat-header-cell *matHeaderCellDef class="businessPartnerIdColumn">
          Busi. Partner ID
          <!-- <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search ID</mat-label>
            <input matInput class="long" formControlName="businessPartnerIdFilter">
          </mat-form-field> -->
        </th>
        <td mat-cell *matCellDef="let element"> {{element.business_partner_id}} </td>
      </ng-container>
      <!-- <ng-container matColumnDef="street">
        <th mat-header-cell *matHeaderCellDef>
          <div>Street</div>
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search Street</mat-label>
            <input matInput class="long" formControlName="streetFilter">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.premise_address.line1}} </td>
      </ng-container>
      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef>
          <div>City</div>
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search City</mat-label>
            <input matInput class="long" formControlName="cityFilter">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.premise_address.city}} </td>
      </ng-container>
      <ng-container matColumnDef="zip">
        <th mat-header-cell *matHeaderCellDef>
          <div>Zip</div>
          <mat-form-field>
            <mat-select formControlName="zipFilter">
              <mat-option *ngFor="let opt of zipOpts" [value]="opt">
                {{opt}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.premise_address.zip}} </td>
      </ng-container> -->
      <ng-container matColumnDef="subscriptionSize">
        <th mat-header-cell *matHeaderCellDef>
          <div>Subscription Size</div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.allocation_size_kw}} </td>
      </ng-container>
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef>
          <div>Dates</div>
          <!-- <mat-form-field class="filter" floatLabel="never">
            <input matInput class="long" formControlName="createdDateFilter">
          </mat-form-field> -->
        </th>
        <td mat-cell *matCellDef="let element"> 
          <div *ngIf="element.created_at">Created: {{element.created_at| date: 'short'}} </div>
          <div *ngIf="element.pending_date">Pending: {{element.pending_date| date: 'shortDate'}} </div>
          <div *ngIf="element.active_date">Active: {{element.active_date| date: 'shortDate'}} </div>
          <div *ngIf="element.cancelled_date">Cancelled: {{element.cancelled_date| date: 'shortDate'}} </div>         
        </td>
      </ng-container>
      <ng-container matColumnDef="enrollmentCode">
        <th mat-header-cell *matHeaderCellDef>
          <div>Enrollment Code</div>
        </th>
        <td mat-cell *matCellDef="let element"> 
          <div *ngIf="element.customer_connect_enrollment_code"> {{element.customer_connect_enrollment_code}} </div>        
        </td>
      </ng-container>
      <ng-container matColumnDef="productCode">
        <th mat-header-cell *matHeaderCellDef>
          <div>Product Code</div>
        </th>
        <td mat-cell *matCellDef="let element"> 
          <div *ngIf="element.customer_connect_product_code"> {{element.customer_connect_product_code}} </div>        
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="pendingDate">
        <th mat-header-cell *matHeaderCellDef>
          <div>Pending Date</div>
          <mat-form-field class="filter" floatLabel="never">
            <input matInput class="long" formControlName="pendingDateFilter">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.pending_date| date: 'shortDate'}} </td>
      </ng-container>
      <ng-container matColumnDef="activeDate">
        <th mat-header-cell *matHeaderCellDef>
          <div>Active Date</div>
          <mat-form-field class="filter" floatLabel="never">
            <input matInput class="long" formControlName="activeDateFilter">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.active_date| date: 'shortDate'}} </td>
      </ng-container>
      <ng-container matColumnDef="cancelledDate">
        <th mat-header-cell *matHeaderCellDef>
          <div>Cancelled Date</div>
          <mat-form-field class="filter" floatLabel="never">
            <input matInput class="long" formControlName="cancelledDateFilter">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.cancelled_date| date: 'shortDate'}} </td>
      </ng-container> -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <div>Status</div>
          <!-- <mat-form-field>
            <mat-select formControlName="statusFilter">
              <mat-option *ngFor="let opt of statusOpts" [value]="opt">
                {{opt}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </th>
        <td mat-cell *matCellDef="let element"> {{element.allocation_status_description}} </td>
      </ng-container>
      <ng-container matColumnDef="cancelReason">
        <th mat-header-cell *matHeaderCellDef>
          <div>Cancel Reason</div>
          <!-- <mat-form-field>
            <mat-select formControlName="cancelReasonFilter">
              <mat-option *ngFor="let opt of cancelReasonOpts" [value]="opt">
                {{opt}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </th>
        <td mat-cell *matCellDef="let element"> {{element.cancel_reason_description}} </td>
      </ng-container>
      <ng-container matColumnDef="enrollment">
        <th mat-header-cell *matHeaderCellDef>
          <div>Enrollment</div>
          <!-- <mat-form-field>
            <mat-select formControlName="enrollmentFilter">
              <mat-option *ngFor="let opt of enrollmentOpts" [value]="opt">
                {{opt}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </th>
        <td mat-cell *matCellDef="let element"> {{element.enrollment_channel_description}} </td>
      </ng-container>
      <!-- <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>
          <div>Email</div>
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search Email</mat-label>
            <input matInput class="long" formControlName="emailFilter">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.main_customer.email}} </td>
      </ng-container>
      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>
          <div>Phone</div>
          <mat-form-field class="filter" floatLabel="never">
            <mat-label>Search Phone</mat-label>
            <input matInput class="long" formControlName="phoneFilter">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.main_customer.phone}} </td>
      </ng-container> -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element" class="actions_column">
            <span *ngIf="hasAllocationViewPerms">
              <a href="javascript:;" (click)='goToAllocation(element.allocation_id, element.account_id, "View");'>View</a>
            </span>
            <span *ngIf="hasAllocationEditPerms && element.allocation_status_id != allocationStatus.Cancelled && element.allocation_status_id != allocationStatus.PendingEnrollment && element.allocation_status_id != allocationStatus.VerificationFailed"> 
              <a href="javascript:;" (click)='goToAllocation(element.allocation_id, element.account_id, "Edit");'>Edit</a>
            </span>
            <span *ngIf="hasAllocationCancelPerms && element.allocation_status_id != allocationStatus.Cancelled && element.allocation_status_id != allocationStatus.VerificationFailed">
              <a href="javascript:;" (click)='goToAllocation(element.allocation_id, element.account_id, "Cancel");'>Cancel</a>
            </span>
            <span *ngIf="hasAllocationActAsPerms && element.allocation_status_id == allocationStatus.Enrolled"> 
              <a href="javascript:;" (click)='goToViewAs(element.account_id);'>View As</a>
            </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [length]="pageLength" [pageSize]="pageSize" showFirstLastButtons></mat-paginator>
</form>