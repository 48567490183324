import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, InMemoryCache, ApolloLink} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { HttpHeaders } from '@angular/common/http';
import { setContext } from '@apollo/client/link/context';
// import {setContext} from 'apollo-link-context'

//const aws_exports = require('../aws-exports').default;


// const pancakesLink = setContext((operation, previousContext) => { 
//   const { headers, canHazPancakes } = previousContext
//   if (!canHazPancakes) { 
//     return previousContext
//   }

//   return {
//     ...previousContext,
//     headers: {    
//       ...headers,
//       "x-with-pancakes": "yes" //your custom header
//     }
//   }
// })



//const uri = 'https://iqlqamn90g.execute-api.us-east-1.amazonaws.com/dev/postgraphile'; // <-- add the URL of the GraphQL server here
const uri = 'http://localhost:9002/graphql';
// const uri = aws_exports.aws_appsync_graphqlEndpoint;


export function createApollo(httpLink: HttpLink) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
      Authorization: "eyJraWQiOiJVZjVjNE9IWWE3MHlxUFpXcWVhQjNDd3hKYkFQXC9JWTg2UGtkbnk4c3FBVT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIwYTQ1NTE3Mi1iYWFiLTQyZWItOThjNy03OTAxZjEwMjg0ZTEiLCJhdWQiOiIyZGx1ZTdkZms3MTBlNDkwM3M1ZDloazg0aiIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJldmVudF9pZCI6IjczYmQ1NGQ4LWFkYWYtNDFhYi1iY2ZhLWU1ZmI1ZWYxOWM0MSIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjE0MzYyODMzLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9qSEFDelBZc1ciLCJjb2duaXRvOnVzZXJuYW1lIjoiMGE0NTUxNzItYmFhYi00MmViLTk4YzctNzkwMWYxMDI4NGUxIiwiZXhwIjoxNjE0NjM2NTkxLCJpYXQiOjE2MTQ2MzI5OTEsImVtYWlsIjoic2FtLnphaGFiaUBkdWtlLWVuZXJneS5jb20ifQ.DIkqJtVNUEaPXjJz-atue8aUJ1qF3kG-ibbQc9sbf7ak5GrbfzSyOp35pfQXVZvr6Q4EplyYs9NBPhA12aKw8G0k_1Z8GT9f2hQ2JpcG7JJwRtYY15vetVTrbDTlZizIsjQnmpk9lZDqFn43ciPIoMv3Z2DGV0U06SuIPtT04PSlIR7KidYOYmat6boIUjBJtt7T_EO95cGVwUyVcYyMYxcDl9kubfE0yjEIEXdBXTvG3JKthfqIeYCqmImLBNvmgs4NI1PoWMbk1PqwTQVDkH0rJX121Fkn9so_3KFsxm2gYJHocWMoijmnRTyl2e_nk6_yOk6u9Gl5_7VADMCqdw"

    }
  }));

  // const auth = setContext((operation, context) => {
  //   // const token = localStorage.getItem('token');

  //        return 
  //           headers: {
  //             Authorization: "JWT test"
  //           }
         
  // }

  // );

  const link = ApolloLink.from([basic, httpLink.create({ uri })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache
  }
}


// export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {

// const http = httpLink.create({uri: uri});

//     const auth = setContext((_, { headers }) => {
//       // get the authentication token from local storage if it exists
//       // const token = localStorage.getItem('token');
//       const token = "eyJraWQiOiJVZjVjNE9IWWE3MHlxUFpXcWVhQjNDd3hKYkFQXC9JWTg2UGtkbnk4c3FBVT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIwYTQ1NTE3Mi1iYWFiLTQyZWItOThjNy03OTAxZjEwMjg0ZTEiLCJhdWQiOiIyZGx1ZTdkZms3MTBlNDkwM3M1ZDloazg0aiIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJldmVudF9pZCI6IjczYmQ1NGQ4LWFkYWYtNDFhYi1iY2ZhLWU1ZmI1ZWYxOWM0MSIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjE0MzYyODMzLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV9qSEFDelBZc1ciLCJjb2duaXRvOnVzZXJuYW1lIjoiMGE0NTUxNzItYmFhYi00MmViLTk4YzctNzkwMWYxMDI4NGUxIiwiZXhwIjoxNjE0MzY2NDMzLCJpYXQiOjE2MTQzNjI4MzMsImVtYWlsIjoic2FtLnphaGFiaUBkdWtlLWVuZXJneS5jb20ifQ.w_XRv5_kt9A3Zb8VcDJl1JeP9cB__9_gLFzvQsb91QfCcBrk8kj-ToTjzGMRsry9DaeXLRYkMijtEIaowvzR1JSTRXzI5LGkKlVp5b0JhSN4WSLcW9IW9XZejnIDghhQnV2KpHJmJUclhAVUDsaI_QniO1ldx7fqF6hRFJbxROknjVbvPPHUeQw-9wCKufuRFqnMz_7xLPJfvm6vswm7icnwKLPLreJO_hruWVA-Z7kku9i6aTgw0Zbeyz7drSBP3fg4RKY1b4gRkdK4c6kI0kwjz82AoftTCvPfUgtjqsKrOvABmo-ZCCLPxpHStjDpAn81B0Uj9WNciF2Hn719jg";

//       // return the headers to the context so httpLink can read them
//       // in this example we assume headers property exists
//       // and it is an instance of HttpHeaders
//       if (!token) {
//         return {};
//       } else {
//         return {
//           headers: headers.append('Authorization', 'Bearer test')
//         };
//       }
//     });


//   return {
//     // link: httpLink.create({uri,
//     //                         headers: { 'Authorization': 'Bearer test'},
//     //                       }),
//     link: http
//     cache: new InMemoryCache(),
//   };
// }




@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}



