import { Component, OnInit, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MatTableDataSource } from '@angular/material';
import { DataService, ApiService } from 'src/app/services';
import { CustomerAlertModel} from '../models/admin.model';
import { SegmentService } from 'src/app/services/segment.service';
import { APIServiceCodegen } from 'src/app/API.service';
import { Subscription } from 'rxjs';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-alerts-main-page',
  templateUrl: './customer-alerts-main-page.component.html',
  styleUrls: ['./customer-alerts-main-page.component.scss']
})

export class CustomerAlertsMainPageComponent {
  // @Input() jurisdiction_id: number;
  // @Input() program_id: number;
  // @Output() dataRetrieved = new EventEmitter();
  public alertDialogRef: MatDialogRef<AlertDialogComponent>;
  displayedColumns: string[] = ['full_name', 'issue', 'date'];
  //public jurisdictions = [];

  list:CustomerAlertModel[]
  dataSource = new MatTableDataSource<CustomerAlertModel>();
  gettingCustomerAlertData = true;
  showOverlay = true;
  ROWLIMIT = 10;

  private subscription: Subscription;
  private segmentId: string;

  constructor(public datasvc: DataService,
    public apiService: ApiService,
    private router: Router,
    public dialog: MatDialog,
    public segService: SegmentService,
    public api: APIServiceCodegen) {
      this.subscription = this.segService.currentSegment$.subscribe( res => {
        this.updateByParticipationSegment(res);
        this.segmentId = res;
     })
     }

     updateByParticipationSegment(segmentId: string){
      //this.gettingCustomerAlertData = true;
      this.api.ListCustomerAlert().then( response => {
        //this.dataSource = new MatTableDataSource<CustomerAlertModel>(response);
          //this.dataSource = (<CustomerAlertModel>response);
          console.log(response)
         // this.gettingCustomerAlertData = false;
          // this.subscribed_mw = response.subscribed_mw;
          // this.available_mw = response.available_mw;
          // this.wait_list_mw = response.wait_list_mw;
      })
      .catch( err => {
                console.log(ErrorDialogComponent.API_GENERIC_ERROR_500_MSG)
                console.log(err)
       });
  }

  // ngOnInit() {
  //   this.getData();
  // }

  // ngOnChanges() {
  //   this.getData();
  // }

  // getData() {
  //   this.gettingCustomerAlertData = true;
  //   this.apiService.getCustomerAlertList(this.jurisdiction_id, this.program_id, this.ROWLIMIT).subscribe(customerAlerts => {
   //    this.dataSource = new MatTableDataSource<CustomerAlertModel>(customerAlerts.data.customerAlerts);
  //     console.log("")
  //     this.dataRetrieved.emit(customerAlerts.data.customerAlerts);
  //     this.gettingCustomerAlertData = false;
  //   });
  // }

  onShowDialog(alert_id) {
    const issue = this.dataSource.data.find(x => x.alert_id === alert_id);
    this.alertDialogRef = this.dialog.open(AlertDialogComponent, {
        id: 'alertDialog',
        minWidth: '60vw',
        data: {
            //issue_id: issue_id,
            alert_id: alert_id,
            dialog_type: 'Customer',
            notes: issue.notes,
            customer_name: issue.full_name,
            facility_name: '',
            issue: issue.issue,
            date: issue.created_date,
            account: issue.account_id
        }
    });
    this.alertDialogRef.afterClosed().subscribe(x => {
      //this.getData();
      this.updateByParticipationSegment(this.segmentId);
    });
  }

  viewMore() {
    this.router.navigate(['admin/customer-alert-report']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
 }
}








