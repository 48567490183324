import { Component, OnInit, Input, EventEmitter, Output, ViewChild, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MatPaginator, MatTableDataSource } from '@angular/material';
import { DataService, ApiService } from 'src/app/services';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { EnrollmentAlertModel} from '../models/admin.model';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { APIServiceCodegen } from 'src/app/API.service';
import { SegmentService } from 'src/app/services/segment.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-enrollment-alerts-report-table',
    templateUrl: './enrollment-alerts-report-table.component.html',
    styleUrls: ['./enrollment-alerts-report-table.component.scss']
})

export class EnrollmentAlertsReportTableComponent implements OnInit, OnChanges {
    @Input() jurisdiction_id: number;
    @Input() exportData: EventEmitter<any> = new EventEmitter();
    @Input() program_id: number;
    @Input() showFilters = true;
    @Output() dataRetrieved = new EventEmitter();
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    public alertDialogRef: MatDialogRef<AlertDialogComponent>;
    displayedColumns: string[] = ['customerName', 'status', 'accountNumber', 'date', 'note', 'delete' ];
    public jurisdictions = [];
    dataSource = new MatTableDataSource<any>();
    gettingEnrollmentAlertData = true;
    showOverlay = true;
    enrollmentAlertForm: FormGroup = new FormGroup({
      customerNameFilter: new FormControl(''),
      statusFilter: new FormControl(''),
      accountNumberFilter: new FormControl(''),
      dateFilter: new FormControl('')
    });
    filterValues = {
      customerName: '',
      status: '',
      accountNumber: '',
      date: '',
    };

    public subscription: Subscription;
    public segmentId: String;

    constructor(public datasvc: DataService,
        public apiService: ApiService,
        public dialog: MatDialog,
        private api: APIServiceCodegen,
        private segService: SegmentService) {
          this.subscription = this.segService.currentSegment$.subscribe( res => {
            this.updateByParticipationSegment();
            this.segmentId = res;
         })
        }

    ngOnInit() {
        this.exportData.subscribe(() => {
            this.exportExcel();
          });
          if (!this.showFilters) {
            this.displayedColumns.splice(5, 1);
            this.displayedColumns.splice(4, 1);
            this.displayedColumns.splice(2, 1);
          }
          //this.getData();
    }

    ngOnChanges() {
     // this.getData();
    }

    // getData() {
    //   this.gettingEnrollmentAlertData = true;
    //   this.apiService.getEnrollmentAlertList(this.jurisdiction_id, this.program_id).subscribe(enrollmentAlerts => {
    //     console.log(enrollmentAlerts.data.enrollmentAlerts)
    //     this.dataSource = new MatTableDataSource(enrollmentAlerts.data.enrollmentAlerts);
    //     this.dataRetrieved.emit(enrollmentAlerts.alerts);
    //     this.gettingEnrollmentAlertData = false;
    //     this.dataSource.paginator = this.paginator;
    //     this.dataSource.filterPredicate = this.datasvc.createEnrollmentAlertsFilter();
    //     this.setUpFormValueChanges();
    //   });

      // this.api.ListEnrollmentAlert().then( response => {
      //   console.log(response.items);
      //   // this.enrollmentAlerts = response.items;
      //   // this.enrollmentAlerts = this.enrollmentAlerts.slice(0, 2);

      // })
      // .catch( err => {
      //       console.log(ErrorDialogComponent.API_GENERIC_ERROR_500_MSG)
      //       console.log(err)
      //   });
   // }

    setUpFormValueChanges() {
        this.displayedColumns.forEach(column => {
          if (column === 'note' || column === 'delete') { return; }
           this.enrollmentAlertForm.get(column + 'Filter').valueChanges.subscribe(value => {
              this.filterValues[column] = value;
              this.dataSource.filter = JSON.stringify(this.filterValues);
            });
          });
      }

    exportExcel() {
        const exportEnrollmentAlertList = this.dataSource.data.map(rec => {
          return {
            'Customer Name': rec.full_name,
            'Status Change': rec.status_change,
            'Account Number': rec.account_id,
            'Date' : rec.created_date
          };
        });
        this.datasvc.exportExcelSpreadsheet(exportEnrollmentAlertList,
          'Enrollment Alert ' + this.datasvc.getDate(),
          'EnrollmentAlertExport' + this.datasvc.getDate() + '.xlsx');
      }

      onShowDialog(id) {
        const issue = this.dataSource.data.find(x => x.id === id);
        this.alertDialogRef = this.dialog.open(AlertDialogComponent, {
            id: 'alertDialog',
            minWidth: '60vw',
            data: {
                id: id,
                dialog_type: 'Enrollment',
                notes: issue.notes,
                full_name: issue.full_name,
                facility_name: '',
                issue: issue.status_change,
                date: issue.created_date,
                account: issue.account_id
            }
        });
        this.alertDialogRef.afterClosed().subscribe(x => {
          console.log(x)
          //if (x !== 'cancel'){
            this.updateByParticipationSegment();
          //}
        });
      }

      updateByParticipationSegment(){
        //this.gettingCustomerAlertData = true;
        this.api.ListEnrollmentAlert().then( response => {
        console.log(response.items)
        this.dataSource = new MatTableDataSource(response.items);
        this.dataRetrieved.emit(response.items);
        this.gettingEnrollmentAlertData = false;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = this.datasvc.createEnrollmentAlertsFilter();
        this.setUpFormValueChanges();
        })
        .catch( err => {
                  console.log(ErrorDialogComponent.API_GENERIC_ERROR_500_MSG)
                  console.log(err)
         });
    }

    ngOnDestroy() {
      this.subscription.unsubscribe()
   }
}








