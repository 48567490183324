import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { DataService, ApiService } from 'src/app/services';
import { Permissions } from '../models/admin.model';
//import { JurisdictionResponse } from '../models/customer-report.model';

@Component({
    selector: 'app-enrollment-alerts-report',
    templateUrl: './enrollment-alerts-report.component.html',
    styleUrls: ['./enrollment-alerts-report.component.scss']
})

export class EnrollmentAlertsReportComponent implements OnInit {

    public selectedFacility = '';
    // public selectedJurisdictionStateId: number;
    // public selectedJurisdictionId: number;
    // public selectedProgramId: number;
    public dataSource = new MatTableDataSource<any>(null);
    public exportData = new EventEmitter();
    //public jurisdictionResponse: JurisdictionResponse = null;
    public facilityDataLoaded: EventEmitter<any> = new EventEmitter();
    hasEnrollmentAlertsPerms = false;

    constructor(public datasvc: DataService,
        public apiService: ApiService) { }

    ngOnInit() {
        let permList = this.datasvc.getPermissions();
        if (permList.find(num => num == Permissions.EnrollmentAlerts)) {
            this.hasEnrollmentAlertsPerms = true;
        } 
    
        setTimeout(() => {
            this.datasvc.setHeaderTitle('Enrollment Alerts');
          });
    }

    dataRetrieved($event) {
        this.facilityDataLoaded.emit($event);
    }

    // filtersSelected(event) {
    //     this.jurisdictionResponse = event.jurisdictionResponse;
    //     this.selectedJurisdictionStateId = event.state_id;
    //     this.selectedJurisdictionId = event.jurisdiction_id;
    //     this.selectedFacility = event.facility_name;
    //     this.selectedProgramId = event.program_id;
    // }

    exportExcel() {
        this.exportData.emit('');
    }

}








