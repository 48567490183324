<br><br>
  <div class="mat-table-format">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="program_id">
        <th mat-header-cell *matHeaderCellDef>
          Program Id
        </th>
        <td mat-cell *matCellDef="let element"> {{element.program_id}} </td>
      </ng-container>
      <ng-container matColumnDef="res_market_rate">
        <th mat-header-cell *matHeaderCellDef class="padding-20">
          Market Rate
        </th>
        <td mat-cell *matCellDef="let element"> {{element.res_market_rate ? 'Market Rate' : 'Low Income'}} </td>
      </ng-container>

      <ng-container matColumnDef="application_fee">
        <th mat-header-cell *matHeaderCellDef>
            Application Fee
        </th>
        <td mat-cell *matCellDef="let element"> {{element.application_fee}} </td>
      </ng-container>

      <ng-container matColumnDef="monthly_fee_per_kw">
        <th mat-header-cell *matHeaderCellDef>
          Monthly Fee &nbsp;&nbsp;&nbsp;&nbsp;
        </th>
        <td mat-cell *matCellDef="let element"> {{element.monthly_fee_per_kw}} per kW </td>
      </ng-container>
      
      <ng-container matColumnDef="credit_per_kwh">
        <th mat-header-cell *matHeaderCellDef>
          Credit Per kWh
        </th>
        <td mat-cell *matCellDef="let element"> {{element.credit_per_kwh}} </td>
      </ng-container>
      
      <ng-container matColumnDef="customer_connect_product_code">
        <th mat-header-cell *matHeaderCellDef>
          CC Product Code
        </th>
        <td mat-cell *matCellDef="let element"> {{element.customer_connect_product_code}} </td>
      </ng-container>
      
      <ng-container matColumnDef="program_capacity_kw">
        <th mat-header-cell *matHeaderCellDef>
          Program Capacity
        </th>
        <td mat-cell *matCellDef="let element"> {{element.program_capacity_kw}} kW </td>
      </ng-container>
      
      <ng-container matColumnDef="facility_names">
        <th mat-header-cell *matHeaderCellDef>
          Facilities
        </th>
        <td mat-cell *matCellDef="let element"> {{element.facility_names}} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="editColumn"> Edit
          <div></div>
        </th>
        <td mat-cell *matCellDef="let element" class="editColumn"> <a href="javascript:;"
            (click)='goToDetailView(element.program_id)'>Edit</a> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[10]"></mat-paginator>
<br><br>