import { CustomerModel, Address } from './customer-detail.model';

export interface AllocationResponseModel {
    allocations: Allocation[];
    total: number;
    errors: string[];
}

export interface Allocation {
    business_patner_id: number,
    cancelled_date: Date,
    created_at: Date,
    enrollment_channel_description: string,
    low_income_flag: boolean,
    pending_date: Date,
    res_non_res: string
    allocation_id: number;
    account_type_name: string;
    account_type: string;
    is_low_income: boolean;
    account_lmi_verification_type_id: number;
    account_lmi_verification_date: Date;
    low_income_display: string;
    account_id: number;
    business_partner_id: number;
    facility_id: number;
    facility_name: string;
    allocation_size_kw: number;
    max_subscription_size_kw: number;
    active_date: Date;
    created_date: Date;
    allocation_status_id: number;
    allocation_status_description: string;
    cancel_reason_id: number;
    cancel_reason_description: string;
    enrollment_channel_id: number;
    enrollment_channel_name: string;
    source_system: string;
    program_id: number;
    res_low_income: boolean;
    res_market_rate: boolean;
    account_notes: string;
    main_customer: CustomerModel;
    premise_address: Address;
    billing_address: Address;
}

export interface Customer {
    name: string;
    email: string;
    primary_phone: string;
    account_number: string;
    address: string;
    jurisdiction_name: string;
    subscribed_allocation_size: string;
}

export interface ReportCustomerResponse {
    customers: CustomerResponse[];
    errors: string[];
}

export interface CustomerResponse {
    account_id: string;
    business_partner_id: number;
    active_allocation_size_kw: number;
    max_subscription_size_kw: number;
    zip: string;
    bill_cycle: string;
    main_customer: CustomerModel;
    premise_address: Address;
}

export class StateJurisdictionDropDownDataModel {
    stateName: string;
    stateCode: string;
    state_id: number;
    jurisdictions: JurisdictionDropDownDataModel[];
}

export class JurisdictionDropDownDataModel {
    jurisdiction_name: string;
    jurisdiction_id: number;
}

export class JurisdictionResponse {
    jurisdictions: JurisdictionModel[];
    errors: string[];
}
export class JurisdictionModel {
    facility_required_for_enrollment: boolean;
    jurisdiction_id: number;
    jurisdiction_name: string;
    jurisdiction_code: string;
    state: State;
    state_id: number;
}

export class State {
    state_id: number;
    state_name: string;
    state_code: string;
}

export class CancelReasonResponse {
    cancel_reasons: CancelReasonModel[];
    errors: string[];
}

export class CancelReasonModel {
    id: number;
    description: string;
}

export class CustomerSegmentModel {
    id: number;
    description: string;
}

export class CustomerSegmentResponse {
    metadata_picklist: CustomerSegmentModel[];
    errors: string[];
}
