import gql from 'graphql-tag'
import { FinancialModel } from '../app/components/models/admin.model'

export const PROGRAMS = gql`
query programs {
  programs{
    active
    applicationFee
    creditPerKwh
    customerConnectProductCode
    endDate
    jurisdictionId
    monthlyFeePerKw
    programCapacityKw
    programId
    resLowIncome
    resMarketRate
    startDate
    waitListProcessing
  }
}
`;

export const JURISDICTIONS = gql`
query Jurisdiction {
   Jurisdiction {
    facility_required_for_enrollment
    jurisdiction_id
    jurisdiction_name
    jurisdiction_code
    state {
      state_name
      state_code
    }
    state_id
  }
}
`;

export const FACILITIES = gql`
query Facilities {
  facilities {
      facility_id
      jurisdiction_id
      facility_name
  }
}
`;

export const FINANCIALS = gql`
query Financials($isMonthly: Boolean) {
  financials(isMonthly: $isMonthly) {
    #isMonthly
    year
    month
    fees_collected
    subscription_fees
    credits_earned
    enrollment_fees
    savings
  }
}
`;

export const ENVIRONMENTAL_IMPACTS = gql`
query EnvironmentalImpacts($isMonthly: Boolean) {
  environmentalImpacts(isMonthly: $isMonthly) {
    year
    month
    co2_tons_avoided
    trees_planted
    cars_avoided
  }
}
`;

export const PRGM_ACCNT_COUNT_BY_ID = gql`
query ProgramAccountCountById($prgAccountCntsId: Int) {
  programAccountCountById(prgAccountCntsId: $prgAccountCntsId) {
    prgAccountCntsId
    prgAccountCntsType
    wait_list_count
    enrollment_count
    cancelled_ytd_count
    pending_count
  }
}
  `;

export const PRGM_ACCNT_CAPACITY_BY_ID = gql`
query ProramAccountCapacityById($prgAccountCapId: Int) {
  programAccountCapacityById(prgAccountCapId: $prgAccountCapId) {
    prgAccountCapId
    prgAccountCapsType
    subscribed_kw
    subscribed_mw
    available_kw
    available_mw
    wait_list_kw
    wait_list_mw
  }
}
`;

export const CUSTOMER_ALERTS = gql`
query CustomerAlerts($rowLimits: Int) {
  customerAlerts(rowLimits: $rowLimits) {
    fullName
    created_date
    issue
    account_id
    alert_id
    notes
  }
}
`;

// export const CUSTOMER_ALERTS = gql`
// query allAlertsCustomers {
//   allAlertsCustomers {
//     nodes {
//       alertId
//       createdDate
//       fullName
//       issue
//       notes
//     }
//   }
// }
// `;

export const FACILITY_ALERTS = gql`
query FacilityAlerts($rowLimits: Int) {
  facilityAlerts(rowLimits: $rowLimits) {
    alert_id
    facility_name
    created_date
    issue
    notes
  }
}
`;

export const ENROLLMENT_ALERTS = gql`
query EnrollmentAlerts($rowLimits: Int) {
  enrollmentAlerts(rowLimits: $rowLimits) {
    enrollment_audit_id
    full_name
    created_date
    status_change
    account_id
    notes
  }
}
`;

export const CUSTOMER_REPORTS = gql`
query CustomerReports {
  customerReports {
    account_id
    account_type
    business_partner_id
    active_allocation_size_kw
    max_subscription_size_kw
    jurisdiction_name
    state_code
    bill_cycle
    main_customer {
      full_name
      email
      phone
    }
    premise_address {
      line1
      city
      state
      zip
    }
  }
}
`;

export const ACCOUNT_LOOKUP = gql`
query AccountLookup {
  accountLookup {
    account_type
    business_partner_id
    account_number
    premise_address {
      line1
      city
      state
      zip
    }
    billing_address {
      line1
      city
      state
      zip
    }
    main_customer {
      full_name
      email
      phone
    }
    jurisdiction_code
    source_system
  }
}
`;

// export const ACCOUNT_LOOKUP = gql`
// query AccountLookup($account_number: Int, $last_4_ssn: Int, 
//     $last_4_fed_tax_id: Int, $email: String, $phone: String,
//     $date_of_birth: String, $res_non_res: String) {
// accountLookup(accountNumber: $account_number, last_4_ssn: $last_4_ssn, 
//     last_4_fed_tax_id: $last_4_fed_tax_id, email: $email, phone: $phone, 
//     date_of_birth: $date_of_birth, res_non_res: $res_non_res) {
//   account_type
//   business_partner_id
//   account_number
//   premise_address{
//     line1
//     city
//     state
//     zip
//   }
//   billing_address{
//     line1
//     city
//     state
//     zip
//   }
//   main_customer{
//     full_name
//     email
//     phone
//   }
//   jurisdiction_code
//   source_system
// }
// }
// `;

export const CUSTOMER_DETAILS = gql`
query CustomerDetails {
  customerDetails {
    account_id
    account_number
    account_notes
    source_system
    business_partner_id
    max_subscription_size_kw
    current_allocation_size_kw
    premise_address {
      line1
      city
      state
      zip
    }
    billing_address {
      line1
      city
      state
      zip
    }
    main_customer {
      full_name
      email
      phone
    }
    eligibility_status
    ineligibility_reasons
    is_low_income
    account_lmi_verification_type_id
    account_lmi_verification_date
    allocations {
      allocation_id
      wait_list_id
      account_id
      jurisdiction_name
      allocation_size_kw
      max_subscription_size_kw
      allocation_date
      allocation_status_id
      allocation_status_description
      res_low_income
      res_market_rate
      current_allocation_size_kw
      active_date
    }
  }
}
`;


export const CUSTOMER_DETAIL = gql`
query CustomerDetail {
  customerDetail {
    account_id
    account_number
    account_notes
    source_system
    business_partner_id
    max_subscription_size_kw
    current_allocation_size_kw
    premise_address {
      line1
      city
      state
      zip
    }
    billing_address {
      line1
      city
      state
      zip
    }
    main_customer {
      full_name
      email
      phone
    }
    eligibility_status
    ineligibility_reasons
    is_low_income
    account_lmi_verification_type_id
    account_lmi_verification_date
    allocations {
      allocation_id
      wait_list_id
      account_id
      jurisdiction_name
      allocation_size_kw
      max_subscription_size_kw
      allocation_date
      allocation_status_id
      allocation_status_description
      res_low_income
      res_market_rate
      current_allocation_size_kw
      active_date
    }
  }
}
`;

export const ALLOCATION_REPORT = gql`
query AllocationReport {
  allocationReport {
    allocation_id
    account_type_name
    is_low_income
    account_lmi_verification_type_id
    account_lmi_verification_date
    account_id
    business_partner_id
    jurisdiction {
      facility_required_for_enrollment
      jurisdiction_id
      jurisdiction_name
      jurisdiction_code
      state {
        state_name
        state_code
      }
      state_id
    }
    facility_id
    facility_name
    allocation_size_kw
    max_subscription_size_kw
    active_date
    created_date
    allocation_status_id
    allocation_status_description
    cancel_reason_id
    cancel_reason_description
    enrollment_channel_id
    enrollment_channel_name
    source_system
    program_id
    res_low_income
    res_market_rate
    account_notes
    main_customer {
      full_name
      email
      phone
    }
    premise_address {
      line1
      city
      state
      zip
    }
    billing_address {
      line1
      city
      state
      zip
    }
  }
}
`;

export const WAITLIST = gql`
query WaitList{
  waitList{
    wait_list_id
    allocation_id
    wait_list_program_rank
    program_id
    account_type_name
    low_income
    account_id
    business_partner_id
    jurisdiction_id
    jurisdiction_name
    allocation_size_kw
    account_assigned_capacity_kw
    account_unassigned_capacity_kw
    created_date
    allocation_status_id
    allocation_status_description
    main_customer{
      full_name
      email
      phone
    }
    premise_address{
      line1
      city
      state
      zip
    }
    source_system
    account_notes
    enrollment_channel_id
    account_lmi_verification_type_id
    account_lmi_verification_date
  }
}
`;

export const CANCEL_REASON = gql`
query CancelReasons{
  cancelReason{
    id
    description
    cc_id
  }
}
`;

export const ENROLLMENT_CHANNEL = gql`
query EnrollmentChannel{
  enrollmentChannel{
    enrollment_channel_id
    enrollment_channel_name
  }
}
`;

export const VERIFICATION_TYPE = gql`
query VerificationTypes{
  verificationType{
    lmi_verification_type_id
    lmi_verification_type_name
  }
}
`;

export const STATE = gql`
query State{
  state{
    state_id
    state_name
  }
}
`;

export const FACIITY_REPORT = gql`
query FacilityReport {
  facilityReport {
    facility_id
    facility_name
    city
    state_code
    address_line1
    jurisdiction_name
    facility_installation_number
    salesforce_id
    total_capacity_kw
    total_capacity_unit
    commercial_operating_date
    one_month_status
    twelve_month_status
  }
}
`;

export const USERS = gql`
query Users{
  users{
    full_name
    email
    role_id
    lan_id
    index
  }
}
`;

export const USER_ROLE = gql`
query UserRole{
  userRole{
    role_id
    role_name
  }
}
`;


export const CREATE_ALERTS_CUSTOMER = gql`
mutation CreateAlertsCustomer {
  createAlertsCustomer(
    input: {alertsCustomer: {alertId: 44, accountId: "", active: false, alertCustomerTypeId: "", 
      createdDate: "now()", issue: "myissue", notes: "nynote"}}
  ) {
    clientMutationId
    alertsCustomer {
      accountId
      active
      alertCustomerTypeId
      alertId
      createdDate
      issue
      notes
    }
  }
}
`;

// export const CREATE_ALERTS_CUSTOMER = gql`
// mutation CreateAlertsCustomer($alertId: Int, $accountId: Int, $active: Boolean, $alertCustomerTypeId: Int, $createdDate: String, $issue: String, $nodeId: Int, $notes: String) {
//   createAlertsCustomer(alertId: $alertId, accountId: $accountId, active: $active, alertCustomerTypeId: $alertCustomerTypeId, createdDate: $createdDate, issue: $issue, nodeId: $nodeId, notes: $notes) {
//     accountId
//     active
//     alertCustomerTypeId
//     alertId
//     createdDate
//     issue
//     nodeId
//     notes
//   }
// }
// `;