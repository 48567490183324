import { Component, OnInit, Input, EventEmitter, Output, ViewChild, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataService, ApiService } from 'src/app/services';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { FacilityAlertModel } from '../models/admin.model';

@Component({
    selector: 'app-facility-alerts-main-page',
    templateUrl: './facility-alerts-main-page.component.html',
    styleUrls: ['./facility-alerts-main-page.component.scss']
})

export class FacilityAlertsMainPageComponent implements OnInit, OnChanges {
    @Input() jurisdiction_id: number;
    @Input() program_id: number;
    @Output() dataRetrieved = new EventEmitter();
    displayedColumns: string[] = ['facilityName', 'issue', 'date'];
    public jurisdictions = [];
    dataSource = new MatTableDataSource<FacilityAlertModel>();
    gettingFacilityAlertData = true;
    showOverlay = true;
    public alertDialogRef: MatDialogRef<AlertDialogComponent>;
    ROWLIMIT = 10;


    constructor(public datasvc: DataService,
        public apiService: ApiService,
        private router: Router,
        public dialog: MatDialog) { }

    ngOnInit() {
          this.getData();
    }

    ngOnChanges() {
      this.getData();
    }

    getData() {
      this.gettingFacilityAlertData = true;
      this.apiService.getFacilityAlertList(this.jurisdiction_id, this.program_id, this.ROWLIMIT)
      .pipe(catchError(error => of(error))).subscribe(facilityAlerts => {
          console.log(facilityAlerts)
          this.dataSource = new MatTableDataSource<FacilityAlertModel>(facilityAlerts);
          this.dataRetrieved.emit(facilityAlerts);
          this.gettingFacilityAlertData = false;
        });
    }

      onShowDialog(issue_id) {
        const issue = this.dataSource.data.find(x => x.alert_id === issue_id);
        this.alertDialogRef = this.dialog.open(AlertDialogComponent, {
            id: 'alertDialog',
            minWidth: '60vw',
            data: {
                issue_id: issue_id,
                dialog_type: 'Facility',
                notes: issue.notes,
                customer_name: '',
                facility_name: issue.facility_name,
                issue: issue.issue,
                date: issue.created_date,
                account: null
            }
        });
        this.alertDialogRef.afterClosed().subscribe(x => {
          this.getData();
        });
      }

      viewMore() {
        this.router.navigate(['admin/facility-alert-report']);
      }

}








