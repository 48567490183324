import { CustomerModel, Address } from './customer-detail.model';

export enum Permissions {
    LoginKillSwitch = 1,
    Dashboard = 2,
    EnrolledAllocationsReport = 3,
    AllocationReport = 4,
    WaitListReport = 5,
    CustomerLookup = 6,
    FacilitiesReport = 7,
    AddFacility = 8,
    AllAlerts = 9,
    CustomerAlerts = 10,
    FacilityAlerts = 11,
    EnrollmentAlerts = 12,
    UserAdminSettings = 13,
    AllocationView = 14,
    AllocationEdit = 15,
    AllocationCancel = 16,
    AllocationNew = 17,
    ReverifyEligibility = 18,
    ProcessWaitList = 19,
    EnrollmentAuditReport = 20,
    TransferListReport = 21
}

export enum AllocationStatus {
    Cancelled = 0,
    Enrolled = 1,
    PendingEnrollment = 2,
    Waitlist = 3,
    RSPRegistered = 4,
    AwaitingVerification = 5,
    VerificationFailed = 6
}

export enum UserRole {
    ReadOnly = 1,
    Admin = 2,
    ElevatedAdmin = 3,
    LoginKillSwitch = 4
}

export enum AccountPerms {
    FullAccess = 1,
    PartialAccess = 2,
    LimitedAccess = 3
}

export const enum ParticipationSegmentsEnum {
    'Local Government',
    'Large Customer',
    'Low Income',
    'Residential/Small - Medium Business'
}

export enum CustomerSegments {
    LowIncome = 1,
    ResidentialSMB = 2,
    LocalGoverment = 3,
    LargeCustomer = 4
}

export interface AccountStatusResponse {
    accountStatuses: string[];
    errors: string[];
}

export interface AccountTypeResponse {
    accountTypes: string[];
    errors: string[];
}


export class AlertsModel {
    customer_alerts: AlertObject[];
    facility_alerts: AlertObject[];
    enrollment_alerts: AlertObject[];
}

export class AlertUpdateRequest {
    notes: string;
}

export class AlertObject {
    name: string;
    reason: string;
    date: Date;
}

export class AlertsResponse {
    alerts: AlertsModel;
    errors: string[];
}

export interface CountyModel {
    county_id: number;
    county_name: string;
    state_id: number;
}

export interface CountyResponse {
    counties: CountyModel[];
    errors: string[];
}

export interface CustomerAlertResponse {
    alerts: CustomerAlertModel[];
    errors: string[];
}

// export interface CustomerAlertModel {
//     full_name: string;
//     created_date: Date;
//     issue: string;
//     account_id: string;
//     alert_id: number;
//     notes: string;
// }

export class CustomerAlertModel {
    full_name: string;
    created_date: Date;
    issue: string;
    account_id: string;
    alert_id: number;
    notes: string;
}

export interface DropDownSelection {
    name: string;
    value: any;
}



// export interface EnrollmentAlertModel {
//     enrollment_audit_id: number;
//     full_name: string;
//     created_date: Date;
//     status_change: string;
//     account_id: string;
//     notes: string;
// }

export class EnrollmentAlertModel {
    id: string;
    enrollment_audit_id?: number;
    full_name?: string;
    created_date?: string;
    status_change?: string;
    account_id?: string;
    notes?: string;
}

export interface EnrollmentAlertResponse {
    alerts: EnrollmentAlertModel[];
    errors: string[];
}

export interface CancellationRequestModel {
    allocation_id: string;
    // wait_list_id: number;
    cancel_reason_id: number;
    account_notes: string;
    created_by: string;
}

export interface CancellationResponseModel {
    message: string;
    errors: string[];
}

export interface FacilityManagementResponse {
    facilities: ReportFacility[];
    errors: string[];
}

export interface FacilityResponse {
    facilities: SimpleFacilityModel[];
    errors: string[];
}

export interface FacilityGenerationResponse {
    data: FacilityGenerationModel;
    errors: string[];
}

export interface FacilityGenerationModel {
    site_expected: NameValuePair[];
    site_output: NameValuePair[];
}

export interface NameValuePair {
    name: string;
    value: any;
}

export interface FacilityManagementResponse {
    facilities: ReportFacility[];
    errors: string[];
}

export interface FacilityReportResponse {
    facilities: FacilityReportModel[];
    errors: string[];
}

export interface FacilityAlertModel {
    alert_id: number;
    facility_name: string;
    created_date: Date;
    issue: string;
    notes: string;
    id: string;
}

export interface FacilityAlertResponse {
    alerts: FacilityAlertModel[];
    errors: string[];
}

export interface FacilityReportModel {
    facility_id: number;
    facility_name: string;
    city: string;
    state_code: string;
    address_line1: string;
    jurisdiction_name: string;
    facility_installation_number: number;
    salesforce_id: string;
    total_capacity_kw: number;
    total_capacity_unit: string;
    commercial_operating_date: Date;
    one_month_status: string;
    twelve_month_status: string;
}
export interface FacilitySetupResponse {
    facility_detail: FacilityDetail;
    errors: string[];
}

export interface FacilityDetail {
    facility: FacilitySetupModel;
    facility_projected_capacity_factor: ProjectedCapacityFactor;
}


export interface FacilitySetupModel {
    facility_id: number;
    facility_name: string;
    jurisdiction_id: number;
    address_line1: string;
    city: string;
    state_id: number;
    county_id: number;
    latitude: number;
    longitude: number;
    facility_installation_number: string;
    invoice_number: string;
    salesforce_id: string;
    total_capacity_kw: number;
    ac_to_dc_conversion_ratio: string;
    solar_developer_name: string;
    solar_developer_address: string;
    solar_developer_email: string;
    terms_of_ppa: string;
    expected_commercial_operating_date: Date;
    commercial_operating_date: Date;
    panel_type_id: number;
    maintenance_percentage: number;
    operation_and_maintenance_contact_info: string;
    notes: string;
    last_modified_by: string;
}

export interface FinancialResponse {
    financials: FinancialModel;
    errors: string[];
}

// export interface FinancialModel {
//     year: string;
//     month: string;
//     fees_collected: number;
//     enrollment_fees: number;
//     subscription_fees: number;
//     credits_earned: number;
//     savings: number;
// }

export class FinancialModel {
    year: string;
    month: string;
    fees_collected: number;
    enrollment_fees: number;
    subscription_fees: number;
    credits_earned: number;
    savings: number;
}

export interface FeeDate {
    fee_date: Date;
    residential_fee: number;
    non_residential_fee: number;
}

export interface UserRoleModel {
    user_role_id: number;
    user_role_description: string;
}

export interface PanelTypeResponse {
    panel_types: PanelTypeModel[];
    errors: string[];
}

export interface PanelTypeModel {
    panel_type_id: number;
    panel_type_name: string;
}

export interface ProgramResponse {
    program_detail: ProgramDetailModel;
    errors: string[];
}

export interface ProgramLookupResponse {
    program: ProgramModel;
    errors: string[];
}

export interface ProgramDetailModel {
    program: ProgramModel;
    facility_names: string;
    residential_fee: string;
    non_residential_fee: number;
    assigned_capacity_kw: number;
    unassigned_capacity_kw: number;
    wait_list_kw: number;
    program_enrollment_fee_schedules: ProgramEnrollmentFee[];
    program_facilities: ProgramFacility[];
}

export interface ProgramEnrollmentFee {
    program_enrollment_fee_schedule_id: number;
    program_id: number;
    date_of_change: Date;
    residential_fee: number;
    non_residential_fee: number;
}

export interface ProgramFacility {
    program_facility_id: number;
    program_id: number;
    facility_id: number;
}

export interface ProgramReportResponse {
    programs: ProgramModel[];
    errors: string[];
}

export interface ProgramModel {
    application_fee: number;
    jurisdiction_id: number;
    jurisdiction_name: string;
    residential_fee: number;
    non_residential_fee: number;
    assigned_capacity_kw: number;
    unassigned_capacity_kw: number;
    wait_list_kw: number;
    program_id: number;
    res_low_income: boolean;
    res_market_rate: boolean;
    monthly_fee_per_kw: string;
    credit_per_kwh: string;
    customer_connect_product_code: string;
    program_capacity_kw: string;
    facility_names: string;
}

export interface ProjectedCapacityFactor {
    facility_projected_capacity_factor_id: number;
    facility_id: number;
    jan_percent: number;
    feb_percent: number;
    mar_percent: number;
    apr_percent: number;
    may_percent: number;
    jun_percent: number;
    jul_percent: number;
    aug_percent: number;
    sep_percent: number;
    oct_percent: number;
    nov_percent: number;
    dec_percent: number;
}

// export interface ProgramAccountCountsResponse {
//     counts: ProgramAccountCountsModel;
//     errors: string[];
// }

// export interface ProgramCapacityResponse {
//     capacity: ProgramCapacityModel;
//     errors: string[];
// }

// export interface ProgramCapacityModel {
//     subscribed_kw: number;
//     subscribed_mw: number;
//     available_kw: number;
//     available_mw: number;
//     wait_list_kw: number;
//     wait_list_mw: number;
// }

// export interface CustomerCapacityResponse {
//     capacity: CustomerCapacityModel;
//     errors: string[];
// }

// export interface CustomerCapacityModel {
//     subscribed_kw: number;
//     subscribed_mw: number;
//     available_kw: number;
//     available_mw: number;
//     wait_list_kw: number;
//     wait_list_mw: number;
// }

export class CustomerCapacityModel {
    subscribed_kw: number;
    subscribed_mw: number;
    available_kw: number;
    available_mw: number;
    wait_list_kw: number;
    wait_list_mw: number;
}

// export interface ProgramAccountCountsModel {
//     partSegId: string;
//     enrollment_count: number;
//     cancelled_ytd_count: number;
//     pending_count: number;
//     wait_list_count: number;
// }

// export interface CustomerAccountsModel {
//     id: string;
//     enrollment_count: number;
//     cancelled_ytd_count: number;
//     pending_count: number;
//     wait_list_count: number;
// }

export class CustomerAccountsModel {
    id: string;
    enrollment_count: number;
    cancelled_ytd_count: number;
    pending_count: number;
    wait_list_count: number;
}

export interface ProgramFeeSchedule {
    program_fee_schedule_id: number;
    program_id: number;
    date_of_change: Date;
    residential_fee: string;
    non_residential_fee: string;
}

export interface ReportFacility {
    facility_id: number;
    facility_name: string;
    city: string;
    state_code: string;
    street: string;
    jurisdiction: string;
    installation_number: string;
    salesforce_id: string;
    total_capacity_kw: string;
    month_status: string;
    year_status: string;
    commercial_operation_date: string;
}

export interface SimpleFacilityModel {
    facility_id: number;
    facility_name: string;
}

export interface StateResponse {
    states: StateModel[];
    errors: string[];
}

export interface StateModel {
    state_id: number;
    state_name: string;
    state_code: string;
}

export interface SubscriptionCardResponse {
    calculations: SubscriptionCardModel;
    errors: string[];
}


export interface SubscriptionCardModel {
    monthly_est_avg_credit_after_fees: number;
    est_lifetime_savings: number;
    est_lifetime_year: number;
    fees: FeesModel;
}


export interface FeesModel {
    monthly_subscription: number;
    enrollment_fee: number;
    application_fee: number;
}

export interface VerificationTypeResponse {
    lmi_verification_types: VerificationTypeModel[];
    errors: string[];
}

export interface VerificationTypeModel {
    lmi_verification_type_id: number;
    lmi_verification_type_name: string;
}

export class WaitListResponse {
    items: WaitListModel[];
    errors: string[];
}

export class WaitListModel {
    wait_list_id: number;
    allocation_id: number;
    wait_list_program_rank: number;
    program_id: number;
    account_type_name: string;
    low_income: boolean;
    account_id: string;
    business_partner_id: number;
    jurisdiction_id: number;
    zip: string;
    facility_names: string;
    allocation_size_kw: number;
    account_assigned_capacity_kw: number;
    account_unassigned_capacity_kw: number;
    created_date: Date;
    allocation_status_id: number;
    allocation_status_description: string;
    main_customer: CustomerModel;
    premise_address: Address;
    source_system: string;
    account_notes: string;
    enrollment_channel_id: number;
    account_lmi_verification_type_id: number;
    account_lmi_verification_date: Date;
}

export class WaitListReorderRequest {
    wait_list_id: number;
    program_id: number;
    current_wait_list_program_rank: number;
    new_wait_list_program_rank: number;
}

export class WaitListReorderResponse {
    message: string;
    errors: string[];
}

/* Transfer Admin models */
export class TransferResponse {
    items: WaitListModel[];
    errors: string[];
}

export class TransferModel {
    wait_list_id: number;
    allocation_id: number;
    wait_list_program_rank: number;
    program_id: number;
    account_type_name: string;
    low_income: boolean;
    account_id: string;
    business_partner_id: number;
    jurisdiction_id: number;
    zip: string;
    facility_names: string;
    allocation_size_kw: number;
    account_assigned_capacity_kw: number;
    account_unassigned_capacity_kw: number;
    created_date: Date;
    allocation_status_id: number;
    allocation_status_description: string;
    main_customer: CustomerModel;
    premise_address: Address;
    source_system: string;
    account_notes: string;
    enrollment_channel_id: number;
    account_lmi_verification_type_id: number;
    account_lmi_verification_date: Date;
}

export class TransferReorderRequest {
    wait_list_id: number;
    program_id: number;
    current_wait_list_program_rank: number;
    new_wait_list_program_rank: number;
}

export class TransferReorderResponse {
    message: string;
    errors: string[];
}

export class UserRoleResponse {
    users: UserSettingModel[];
    errors: string[];
}

export class UserInfoResponse {
    user: UserSettingModel;
    errors: string[];
}

export class UserRoleDropDownResponse {
    user_roles: UserRoleDropDownModel[];
    errors: string[];
}

export class UpdateUsersRequest {
    users: UserSettingModel[];
}

export class UserRoleDropDownModel {
    role_id: number;
    role_name: string;
}

export class UserSettingModel {
    full_name: string;
    lan_id: string;
    email: string;
    role_id: number;
    index: number;
}




