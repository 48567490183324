<span class="close cursor-pointer" (click)="closeModal()">&times;</span>
<form [formGroup]="detailsForm" *ngIf="detailsForm" novalidate>
    <div class="container">
        <div class="row pb-5">
            <div class="main-label">Account #</div>&nbsp;<h6>{{data.account_id}}</h6>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="row main-label pb-2">Subscription Size (kW)</div>
                <div class="row">
                    <input matInput type="number" class="long" formControlName="subscriptionSize"> &nbsp; kW
                </div>
            </div>
            <div class="col-6">
                <app-kw-slider [min]="minSubscriptionSize" [max]="maxSubscriptionSize" [current]="subscriptionSize" [disabled$]="disabledSlider$" (inputChange)="onSliderChange($event)"></app-kw-slider>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="row pb-3">
                    <div class="col-lg-12">
                        <h4 class="to-center main-header">* We've calculated your estimated fee, credit and net savings based on your selections above. Actual savings may vary based on solar production.</h4>
                    </div>
                </div>
                <!--INCOME-QUALIFIED CALCULATOR RESULTS-->
                <div *ngIf="data.customer_segment_id == 1" class="row">
                    <div class="col">
                        <div class="row pt-3 to-center">
                            <h3 class="font-bold">Subscription Fee <span class="hidden"><br>{{netSavingsText}}</span></h3>
                        </div>
                        <div class="row to-center blue-text">
                            <h1><b>${{monthlySubscriptionFee| number : '1.2-2'}}</b></h1>
                        </div>`
                        <div class="row to-center pb-5">
                            <h5>Monthly</h5>
                        </div>
                    </div>
                    <div class="col left-border mr-2">
                        <div class="row pt-3 to-center">
                            <h3 class="font-bold">Subscription Credit* <span class="hidden"><br>{{netSavingsText}}</span></h3>
                        </div>
                        <div class="col to-center blue-text">
                            <h1><b>${{monthlyCreditFirstYear| number : '1.2-2'}}</b></h1>
                        </div>`
                        <div class="row to-center pb-5">
                            <h5>Monthly Credit</h5>
                        </div>
                    </div>
                    <div class="col left-border ml-2">
                        <div class="row pt-3 to-center">
                            <h3><span class="font-bold">Net Savings</span><br>{{netSavingsText}}</h3>
                        </div>
                        <div class="row to-center blue-text">
                            <h1><b>${{avgMonthlyNetSavingsFirstYear| number: '1.2-2'}}</b></h1>
                        </div>`
                        <div class="row to-center pb-5">
                            <h5>Monthly Net Savings</h5>
                        </div>
                        <div class="row pt-2 to-center mr-5 ml-5">
                            <h4>You’ll see savings from your first bill as your bill credits are always higher than your subscription fee!</h4>
                        </div>
                    </div>
                </div>
                <!--MARKET RATE CALCULATOR RESULTS-->
                <div *ngIf="data.customer_segment_id != 1" class="row">
                    <div class="col">
                        <div class="row pt-3 to-center">
                            <h3 class="font-bold">Subscription Fee<span class="hidden"><br>{{netSavingsText}}</span></h3>
                        </div>
                        <div class="col to-center blue-text hidden">
                            <h1><b>${{monthlyCreditFirstYear| number : '1.2-2'}}</b></h1>
                        </div>
                        <div class="row to-center pb-3 hidden">
                            <h5>Average Monthly Credit (1<sup>st</sup> Year)</h5>
                        </div>
                        <div class="row to-center blue-text">
                            <h1><b>${{monthlySubscriptionFee| number : '1.2-2'}}</b></h1>
                        </div>
                        <div class="row to-center pb-3">
                            <h5>Monthly</h5>
                        </div>
                    </div>
                    <div class="col left-border mr-2">
                        <div class="row pt-3 to-center">
                            <h3 class="font-bold">Subscription Credit*<span class="hidden"><br>{{netSavingsText}}</span></h3>
                        </div>
                        <div class="col to-center blue-text">
                            <h1><b>${{monthlyCreditFirstYear| number : '1.2-2'}}</b></h1>
                        </div>
                        <div class="row to-center pb-3">
                            <h5>Average Monthly Credit (1<sup>st</sup> Year)</h5>
                        </div>
                        <div class="col to-center blue-text">
                            <h1><b>${{monthlyCreditFifthYear| number : '1.2-2'}}</b></h1>
                        </div>
                        <div class="row to-center pb-3">
                            <h5>Average Monthly Credit (5<sup>th</sup> Year)</h5>
                        </div>
                        <div class="col to-center blue-text">
                            <h1><b>${{monthlyCreditTenthYear| number : '1.2-2'}}</b></h1>
                        </div>
                        <div class="row to-center pb-3">
                            <h5>Average Monthly Credit (10<sup>th</sup> Year)</h5>
                        </div>
                        <div class="row pt-2 to-center mr-5 ml-5">
                            <h4>After 3 years of continuous subscription, your bill credits increase.</h4>
                        </div>
                    </div>
                    <div class="col left-border ml-2">
                        <div class="row pt-3 to-center">
                            <h3><span class="font-bold">Net Savings</span><br>{{netSavingsText}}</h3>
                        </div>
                        <div class="row to-center" [ngClass]="getTextColor(avgMonthlyNetSavingsFirstYear)">
                            <h1><b>${{avgMonthlyNetSavingsFirstYear| number: '1.2-2'}}</b></h1>
                        </div>
                        <div class="row to-center pb-3">
                            <h5>Average Monthly Net Savings (1<sup>st</sup> Year)</h5>
                        </div>
                        <div class="row to-center" [ngClass]="getTextColor(avgMonthlyNetSavingsFifthYear)">
                            <h1><b>${{avgMonthlyNetSavingsFifthYear| number: '1.2-2'}}</b></h1>
                        </div>
                        <div class="row to-center pb-3">
                            <h5>Average Monthly Net Savings (5<sup>th</sup> Year)</h5>
                        </div>
                        <div class="row to-center" [ngClass]="getTextColor(avgMonthlyNetSavingsTenthYear)">
                            <h1><b>${{avgMonthlyNetSavingsTenthYear| number: '1.2-2'}}</b></h1>
                        </div>
                        <div class="row to-center pb-3">
                            <h5>Average Monthly Net Savings (10<sup>th</sup> Year)</h5>
                        </div>
                        <div class="row pt-2 to-center mr-5 ml-5">
                            <h4>The longer you’re subscribed, the more you save as your bill credit increase.</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="float-right row">
        <de-button (click)="closeModal()" type="secondary">CANCEL</de-button>
        <de-button (click)="onSave()" type="primary" size="small">SAVE</de-button>
    </div>
</form>