<de-loader *ngIf="gettingWaitlistData" [showOverlay]="showOverlay"></de-loader>
<div #mainTopContainer>
</div>
<div *ngIf="hasWaitListPerms" class="container-fluid p-5 overflow-hidden main-container">
    <div class="col d-table main-col">
        <div class="d-table-row">
            <div class="d-table-cell search-criteria-and-summary-row">
                <!--Customer Segment drop down-->
                <div class="float-left mb-1">
                    <app-report-drop-downs [defaultValue]="customerSegmentId" (valuesChanged)="customerSegmentChanged($event)"></app-report-drop-downs>
                    <!--Button for reverifying eligibility-->
                    <!-- <div *ngIf="hasReverifyPerms && allWaitListers.length" style="float:left;">
                        <de-button type="secondary" (click)="onReverifyEligibility()" [loading]="isReverifyEligibilityLoading">REVERIFY TransferraBILITY</de-button>
                        <span *ngIf="isReverifyEligibilityLoading">Reverifying: {{waitListItemsReverified}} of {{allWaitListers.length}}...</span>
                    </div> -->
                </div>
                <div class="float-left">
                    <!--Button for refreshing data-->
                    <de-button type="secondary" (click)="onRefresh()" [size]="refreshButtonSize">REFRESH</de-button>
                </div>
                <!--Summary Info-->
                <!-- <div class="float-right mb-1 outlined waitlist-card wait-list-summary-card" style="display:inline-block; width: 60%;">
                    <div class="container m-0">
                        <div class="row title pl-3 black">
                            <span><i class="fa fa-2x fa-check"></i></span>&nbsp;Summary
                        </div>
                        <div class="row summary-data">
                            <div class="col black"><span class="runs-column-header">Wait List Eligible:</span>
                               <span *ngIf="waitListSummary">&nbsp;{{waitListSummary.wait_list_current_counts.eligible | number}} 
                                accts / {{waitListSummary.wait_list_current_counts.eligiblekw | number}} kW</span>
                            </div>
                            <div class="col black"><span class="runs-column-header">Wait List
                                    Ineligible:</span>
                                    <span *ngIf="waitListSummary">&nbsp;{{waitListSummary.wait_list_current_counts.ineligible | number}}
                                 accts / {{waitListSummary.wait_list_current_counts.ineligiblekw | number}} kW </span></div>
                            <div class="col black"><span class="runs-column-header">Wait List Total:</span>
                                <span *ngIf="waitListSummary">&nbsp;{{waitListSummary.wait_list_current_counts.total | number}}
                                 accts / {{waitListSummary.wait_list_current_counts.totalkw | number}} kW</span></div>
                        </div>
                        <div class="row summary-data summary-data-segment" *ngIf="waitListSummary && waitListSummary.segment_current_capacity">
                            <div class="col black"><span class="runs-column-header">Segment Taken:</span>
                                 {{waitListSummary.segment_current_capacity.segment_taken_capacity_kw | number}} kW<br>
                                 (Pending Enrollment + Enrolled)
                            </div>
                            <div class="col black"><span class="runs-column-header">Segment Available:</span>
                                {{waitListSummary.segment_current_capacity.segment_available_capacity_kw | number}} kW<br>
                                (Total {{waitListSummary.segment_percentage.segment_total_capacity_kw | number}} kW - Taken {{waitListSummary.segment_current_capacity.segment_taken_capacity_kw | number}} kW)
                            </div>
                            <div class="col black"><span class="runs-column-header">Segment Total:</span>
                                {{waitListSummary.segment_percentage.segment_total_capacity_kw | number}} kW<br>
                                ({{waitListSummary.segment_percentage.segment_capacity_percentage * 100 | number : '1.2-2'}}% of {{waitListSummary.segment_percentage.facilities_total_capacity_kw | number}} kW)
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="d-table-row overflow-hidden">
            <div display="d-table-row">
                <div class="d-table-cell wait-list-items-panel">
                    <!--Our Actual Wait List Items...-->
                    <form novalidate class="h-100">
                        <div>
                            <mat-table #waitlist [dataSource]="transferListItemsDataSource"
                                class="mat-elevation-z8" matSort>
                                <ng-container matColumnDef="queue">
                                    <th mat-header-cell mat-sort-header="queue" *matHeaderCellDef>Queue</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="editingAccountId == ''"><a href="javascript:;"
                                            (click)='editingAccountId = element.account_id;'>Edit</a></span>
                                        <span *ngIf="editingAccountId == element.account_id"><a href="javascript:;"
                                            (click)='onSaveWaitlistPosition(element);'>Save</a>&nbsp;</span>
                                        <span *ngIf="editingAccountId == element.account_id"><a href="javascript:;"
                                            (click)="onCancel()">Cancel</a></span>
                                            &nbsp;
                                    <span *ngIf="editingAccountId != element.account_id">{{element.transfer_list_position}}</span>
                                    <span *ngIf="editingAccountId == element.account_id">
                                        <input class="long"  (change)="onPositionChange($event, element)" [(value)]="element.transfer_list_position" style="max-width: 30px;">
                                    </span>
                                    </td>
                                </ng-container>
                                <!-- Eligibility -->
                                <!-- <ng-container matColumnDef="eligibility">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Eligibility
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div *ngIf="!isReverifyEligibilityLoading">
                                            {{element.eligibility_status}}
                                            <i class="fas fa-question-circle d-inline-block cursor-pointer" *ngIf="element.ineligibility_reasons" data-toggle="tooltip" title="{{element.ineligibility_reasons}}" ></i>
                                            <br />
                                            {{element.eligibility_check_date| date: 'MM/dd/yyyy'}}
                                        </div>
                                        <div *ngIf="isReverifyEligibilityLoading">
                                            <de-loader></de-loader>
                                        </div>
                                    </td>
                                </ng-container> -->
                                <!-- Customer -->
                                <ng-container matColumnDef="customer_name">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Customer Name
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.main_customer.full_name}}</td>
                                </ng-container>
                                <!-- Low Income -->
                                <!-- <ng-container matColumnDef="low_income">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Customer Segment
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.customer_segment_id}}</td>
                                </ng-container> -->
                                <!-- Acct Number -->
                                <ng-container matColumnDef="acct_number">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Acct Number
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <i class="fas fa-copy d-inline-block cursor-pointer" data-toggle="tooltip"
                                            title="click to copy Acct Number"
                                            (click)="copyText(element.account_id)"></i>&nbsp;
                                            <a href="javascript:;" (click)='goToAdminDetailView(element.account_id, "WaitListReport");'>{{element.account_id}}</a>
                                    </td>
                                </ng-container>
                                <!-- Business Partner Id -->
                                <ng-container matColumnDef="busi_partner_id">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Business Partner Id
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.business_partner_id}} </td>
                                </ng-container>
                                <!-- Installation ID -->
                                <ng-container matColumnDef="install_id">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Installation Id
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.installation_id}} </td>
                                </ng-container>
                                <!-- Street City -->
                                <ng-container matColumnDef="street">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <div>Street</div>
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.premise_address.line1}} </td>
                                </ng-container>
                                <ng-container matColumnDef="city">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <div>City</div>
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.premise_address.city}} </td>
                                </ng-container>



                                <!-- Allocation Size kW -->
                                <!-- <ng-container matColumnDef="allocation">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Allocation Size kW
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.allocation_size_kw}} </td>
                                </ng-container> -->
                                <!-- Assigned kW -->
                                <!-- <ng-container matColumnDef="assigned_kw">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Assigned kW
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.total_subscription_kw}} </td>
                                </ng-container> -->
                                <!-- Created Date -->
                                <!-- <ng-container matColumnDef="created_date">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Created Date
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.created_at| date}} </td>
                                </ng-container> -->

                                <!-- Operand Key -->
                                <ng-container matColumnDef="operand_key">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Operand Key
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.operand_key}} </td>
                                </ng-container>
                                <!-- Move-In Date -->
                                <ng-container matColumnDef="movein_date">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Move-in Date
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{formatMoveDate(element.move_in_date) | date}} </td>
                                </ng-container>
                                <!-- Move-Out Date -->
                                <ng-container matColumnDef="moveout_date">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Move-out Date
                                    </th>
                                    <td mat-cell *matCellDef="let element"> {{formatMoveDate(element.move_out_date) | date}} </td>
                                </ng-container>
                                <!-- Email -->
                                <!-- <ng-container matColumnDef="email">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <div>Email</div>
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.main_customer.email}} </td>
                                </ng-container> -->
                                <!-- Phone -->
                                <ng-container matColumnDef="phone">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Phone
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element.main_customer.phone}} </td>
                                </ng-container>
                                <!-- Active Accounts -->
                                <ng-container matColumnDef="active_accts">
                                    <th mat-header-cell *matHeaderCellDef>
                                        Active Accounts with same Business Partner
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="!element.active_accounts.length">
                                            No New CA Found
                                        </span>
                                        <mat-form-field *ngIf="element.active_accounts.length">
                                            <mat-select [(ngModel)]="active_accts" [ngModelOptions]="{standalone: true}"
                                             matTooltip="{{getActiveAcctsToolTipData(active_accts)}}" matTooltipPosition="after" multiple>
                                                <mat-option *ngFor="let account of element.active_accounts" [value]="element">
                                                    {{account.account_id}}
                                                </mat-option>                                                                                              
                                            </mat-select>
                                        </mat-form-field>
                                        <!-- <div class="tooltip">
                                            <span class="tooltiptext">Installation ID: {{element.installation_id}}</span> 
                                        </div>  -->
                                    </td>
                                </ng-container>
                                <!-- Actions -->
                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef>Actions</th>
                                    <td mat-cell *matCellDef="let element" class="actions_column">
                                        <span *ngIf="hasAllocationViewPerms && !element.active_accounts.length">
                                          <a href="javascript:;" (click)='onRefresh();'>Check for New CA</a>
                                        </span>
                                        <!-- <span *ngIf="hasAllocationEditPerms && element.allocation_status_id != allocationStatus.Cancelled && element.allocation_status_id != allocationStatus.PendingEnrollment && element.allocation_status_id != allocationStatus.VerificationFailed"> 
                                          <a href="javascript:;" (click)='goToAllocation(element.allocation_id, element.account_id, "Edit");'>Edit</a>
                                        </span>
                                        <span *ngIf="hasAllocationCancelPerms && element.allocation_status_id != allocationStatus.Cancelled && element.allocation_status_id != allocationStatus.VerificationFailed">
                                          <a href="javascript:;" (click)='goToAllocation(element.allocation_id, element.account_id, "Cancel");'>Cancel</a>
                                        </span> -->
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                                </tr>
                            </mat-table>
                        </div>
                    </form>
                </div>
                <div class="d-table-cell h-100 wait-list-runs-panel">
                    <!--Historical Wait List Runs-->
                    <div class="container h-100 waitlist-card">
                        <div class="row title pl-3 black">
                            <span><i class="fa fa-2x fa-history"></i></span>&nbsp;Last {{WAIT_LIST_RUNS_ITEM_LIMIT}} Transfer List Runs
                        </div>
                        <div class="row title pl-3" *ngIf="hasProcessPerms && allWaitListers.length">
                            <!--Button to process Wait List-->
                            <div>
                                <form [formGroup]="processWaitListForm" novalidate>
                                    Max Num Eligible Items To Process (Up To {{MAX_NUM_ITEMS_WAIT_LIST_LIMIT}}):
                                    <mat-form-field class="max-num-items" floatLabel="never">
                                        <input matInput type="text" formControlName="inputMaxNumItems"
                                        (keydown)="maxNumItemsChanged($event, inputMaxNumItems)" #inputMaxNumItems/>
                                    </mat-form-field><BR>
                                    <!-- disable eligibility check -->
                                    <!-- <input type="checkbox" formControlName="disableEligibilityCheck" id="disable_eligibility_check" name="disable_eligibility_check" (click)="disableEligibilityCheck($event)" value="True">
                                    <label for="disable_eligibility_check"> &nbsp; Disable Eligibility Check? </label><br> -->
                                    <de-button type="primary" (click)="onProcessWaitList()" size="small"
                                        [loading]="isProcessingWaitListLoading" *ngIf="!selectedProgramIsWaitListProcessing">
                                        PROCESS
                                    </de-button>
                                </form>
                            </div>
                        </div>
                         <div class="row title pl-3 black" *ngIf="allWaitListers.length">
                            <div *ngIf="isProcessingWaitListLoading">Processing...</div>
                            <div *ngIf="!isProcessingWaitListLoading">Processing Status: {{selectedProgramIsWaitListProcessing ? 'Processing' : 'Not Processing'}}</div>
                        </div> 
                        <div class="outlined wait-list-runs-list">
                            <div class="row margined pt-1 runs-column-header">
                                <div class="col-8 p-0">Date/Time/Items</div>
                                <div class="col-4 p-0">Pass/Fail</div>
                                <!-- <div class="col-3 p-0">Audit</div> -->
                            </div>
                            <div class="wait-list-runs-data">
                                <div class="row margined pt-1" *ngFor="let run of runs">
                                    <div class="col-12 p-0 font-weight-bold">
                                        {{run.start_time | date:'medium'}}
                                    </div>
                                    <div class="col-8 p-0 ">
                                        <div *ngIf="!run.num_items_at_end">Run Processing...</div>
                                        <div *ngIf="run.num_items_at_end">{{(run.num_items_at_start - run.num_items_at_end) | number}} items taken off transfer list</div>
                                        <!-- <div *ngIf="getRunErrorCount(run.enrollment_audits) > 0">{{getRunErrorCount(run.enrollment_audits) | number}} items with errors</div> -->
                                    </div>
                                    <div class="col-4 p-0">
                                        <img class="run-is-successful-image" src="../../../assets/greencheck.png" *ngIf="run.is_successful && run.num_items_at_end"/>
                                        <img class="run-is-successful-image cursor-pointer" src="../../../assets/redx.png" *ngIf="!run.is_successful && run.num_items_at_end" data-toggle="tooltip" title="{{run.error_message}}"/>
                                    </div>
                                    <!-- <div class="col-3 p-0">
                                        <i class="fas fa-question-circle d-inline-block run-audit-image" *ngIf="run.enrollment_audits.length > 0" data-toggle="tooltip" 
                                            title="{{getRunEnrollmentAuditTooltip(run.wait_list_run_id)}}"></i>&nbsp;
                                        <i class="fas fa-copy d-inline-block run-audit-image" *ngIf="run.enrollment_audits.length > 0" data-toggle="tooltip" 
                                            title="click to copy Enrollment Audit" 
                                            (click)="copyText(getRunEnrollmentAuditTooltip(run.wait_list_run_id))"></i>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Buttons to add new enrollment and export-->
        <div class="d-table-row">
            <div class="give-size col background-white">
                <span class="no-data" *ngIf="!allWaitListers.length">There are no accounts on the Transfer List for the selected Customer Segment.  Add one by clicking "New Customer".</span>
                <de-button (click)="goToTransfer()" type="secondary">Transfer</de-button>
                <de-button (click)="onNewEnrollment()" type="secondary">New Enrollment</de-button>
                <de-button (click)="exportExcel(transferListItemsDataSource.data)" type="secondary" *ngIf="allWaitListers.length">
                    <div>Export Data &nbsp;<i class="fas fa-caret-up"></i></div>
                </de-button>
            </div>
        </div>
    </div>
</div>