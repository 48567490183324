<div class="align-center">
    <b>Projected Monthly Capacity Factor</b>
</div>
<br><br>
<form [formGroup]="facilityForm" novalidate class="row">
    <div>
        <div class="row">
            <mat-label class="main-label col-1">JAN</mat-label>
            <mat-form-field class="filter col-5" floatLabel="never">
                <input matInput class="" formControlName="jan_percent">
            </mat-form-field>
            %
        </div>
        <div class="row">
            <mat-label class="main-label col-1">FEB</mat-label>
            <mat-form-field class="filter col-5" floatLabel="never">
                <input matInput class="" formControlName="feb_percent">
            </mat-form-field>
            %
        </div>
        <div class="row">
            <mat-label class="main-label col-1">MAR</mat-label>
            <mat-form-field class="filter col-5" floatLabel="never">
                <input matInput class="" formControlName="mar_percent">
            </mat-form-field>
            %
        </div>
        <div class="row">
            <mat-label class="main-label col-1">APR</mat-label>
            <mat-form-field class="filter col-5" floatLabel="never">
                <input matInput class="" formControlName="apr_percent">
            </mat-form-field>
            %
        </div>
        <div class="row">
            <mat-label class="main-label col-1">MAY</mat-label>
            <mat-form-field class="filter col-5" floatLabel="never">
                <input matInput class="" formControlName="may_percent">
            </mat-form-field>
            %
        </div>
        <div class="row">
            <mat-label class="main-label col-1">JUN</mat-label>
            <mat-form-field class="filter col-5" floatLabel="never">
                <input matInput class="" formControlName="jun_percent">
            </mat-form-field>
            %
        </div>
        <div class="row">
            <mat-label class="main-label col-1">JUL</mat-label>
            <mat-form-field class="filter col-5" floatLabel="never">
                <input matInput class="" formControlName="jul_percent">
            </mat-form-field>
            %
        </div>
        <div class="row">
            <mat-label class="main-label col-1">AUG</mat-label>
            <mat-form-field class="filter col-5" floatLabel="never">
                <input matInput class="" formControlName="aug_percent">
            </mat-form-field>
            %
        </div>
        <div class="row">
            <mat-label class="main-label col-1">SEP</mat-label>
            <mat-form-field class="filter col-5" floatLabel="never">
                <input matInput class="" formControlName="sep_percent">
            </mat-form-field>
            %
        </div>
        <div class="row">
            <mat-label class="main-label col-1">OCT</mat-label>
            <mat-form-field class="filter col-5" floatLabel="never">
                <input matInput class="" formControlName="oct_percent">
            </mat-form-field>
            %
        </div>
        <div class="row">
            <mat-label class="main-label col-1">NOV</mat-label>
            <mat-form-field class="filter col-5" floatLabel="never">
                <input matInput class="" formControlName="nov_percent">
            </mat-form-field>
            %
        </div>
        <div class="row">
            <mat-label class="main-label col-1">DEC</mat-label>
            <mat-form-field class="filter col-5" floatLabel="never">
                <input matInput class="" formControlName="dec_percent">
            </mat-form-field>
            %
        </div>
    </div>
</form>