const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_??????",  
    "aws_user_pools_web_client_id": "?????", 
    "oauth": {
      "domain": "cec-userpooldomain-dev.auth.us-east-1.amazoncognito.com",
      "scope": [
        "email",
        "profile",
        "openid"      
        ],
      "redirectSignIn": "https://cec-admin-dev.duke-energy.app/",
      "redirectSignOut": "https://cec-admin-dev.duke-energy.app/",
      "responseType": "token"
    },

    "aws_appsync_graphqlEndpoint": "https://?????.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
