import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, DataService } from 'src/app/services';
import { MatDialog } from '@angular/material';
import { Permissions } from '../models/admin.model';

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
    public hasDashboardPerms = false;

    constructor(public apisvc: ApiService,
        public router: Router,
        public datasvc: DataService,
        public dialog: MatDialog) {
    }

    ngOnInit() {
        let permList = this.datasvc.getPermissions();
        if (permList.find(num => num == Permissions.Dashboard)) {
            this.hasDashboardPerms = true;
        }
        setTimeout(() => {
            this.datasvc.setHeaderTitle('Dashboard');
          });
    }
}